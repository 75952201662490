import { landingItems, landingUrl } from '../../Constants/MenuItems';
import { toggleMenu } from '../../Utils/toggleMenu';
import { useNavigate } from 'react-router-dom';
import logoImg from '../../Assets/Images/header-logo-animated.svg';

const GuestHeader = () => {
  const navigate = useNavigate();
  const onRegisterClick = () => navigate('/registration');

  return (
    <header className={window.scrollY > 0 ? 'header scrolled' : 'header'}>
      <div className="header__container">
        <div className="header__wrapper">
          <div className="logo">
            <a href={landingUrl}>
              <img src={logoImg} alt="Verum logistics" />
            </a>
          </div>
          <div className="header__nav">
            <ul className="header__nav-list">
              {landingItems.map((item) => (
                <li className="header__nav-item" key={item.label}>
                  <a href={item.link}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="header__auth">
            <button className="header__auth-link header__register" onClick={onRegisterClick}>
              Регистрация
            </button>
            <button className="header__auth-link header__login" onClick={() => navigate('/signin')}>
              Вход
            </button>
          </div>
          <button type="button" className="header__menu-toggle" onClick={toggleMenu}>
            <svg
              className="open"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 20.7305H35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 10.7305H35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 30.7305H35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <svg
              className="close"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M30 10.7305L10 30.7305" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10 10.7305L30 30.7305" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div className="menu">
        <div className="container">
          <ul className="menu__list">
            <li className="menu__item">
              <a href="#">На главную</a>
            </li>
            <li className="menu__item">
              <a href="#">Владельцам грузов</a>
            </li>
            <li className="menu__item">
              <a href="#">Организаторам перевозок</a>
            </li>
            <li className="menu__item">
              <a href="#">Калькулятор</a>
            </li>
            <li className="menu__item">
              <a href="#">Контакты</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default GuestHeader;

import { API } from '../../../../../Services/axios';
import { Alert, Card, Col, Row, Typography } from 'antd';
import { ContractReport } from '../../../../../Models/Contract';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ReportStatuses } from '../../../../../Constants/ReportStatuses';
import { reverse } from 'lodash';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import CompletedWorksActCard from './completed-works-act';
import Loader from '../../../../../Components/Loader/Loader';
import ReportTimeline from '../../../../../Components/Reports/ReportTimeline';

interface ReportProps {
  executorOrganisationId: number;
  executorOrganisationName: string;
}

const Report = (props: ReportProps) => {
  const { showErrorModal } = useErrorModal();
  const { executorOrganisationId, executorOrganisationName } = props;
  const { id } = useParams();
  const [data, setData] = useState<ContractReport[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/report/getReportsList/${id}`)
      .then((response) => setData(reverse(response.data.reportsList)))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  const completedWorksActRecord: ContractReport | undefined = data?.find(
    (item) => item.status === ReportStatuses.WORK_DONE,
  );
  const paidConfirmedRecord: ContractReport | undefined = data?.find((item) => item.status === ReportStatuses.PAID);
  const cancelledContractReport: ContractReport | undefined = data?.find(
    (item) => item.status === ReportStatuses.CANCELLED,
  );

  if (loading) return <Loader />;

  return (
    <Row gutter={[0, 24]} className="contract__report contract__full-width">
      {cancelledContractReport && (
        <Col span={24}>
          <Alert
            message={
              <Typography.Text className="contract__info">
                Организатор перевозки инициировал принудительное завершение исполнения договора в системе, так как он не
                может быть исполнен
              </Typography.Text>
            }
            type="error"
            showIcon
            icon={<ExclamationCircleFilled />}
          />
        </Col>
      )}
      {paidConfirmedRecord && (
        <Col span={24}>
          <Alert
            message={<Typography.Text className="contract__info">Договор успешно завершен</Typography.Text>}
            type="success"
            showIcon
          />
        </Col>
      )}
      <Col span={24}>
        <Card title={<Typography.Title level={4}>Информация об исполнителе</Typography.Title>}>
          <Row>
            <Col span={8}>
              <Typography.Text>Организатор перевозки</Typography.Text>
            </Col>
            <Col span={16}>
              <Typography.Link
                href={`/operator/organizations/${executorOrganisationId}`}
                className="order__info-large"
                underline
              >
                {executorOrganisationName}
              </Typography.Link>
            </Col>
          </Row>
        </Card>
      </Col>
      {completedWorksActRecord && (
        <Col span={24}>
          <CompletedWorksActCard completedWorksActRecord={completedWorksActRecord} />
        </Col>
      )}
      <Col span={24}>
        <Card title={<Typography.Title level={4}>Отчет об исполнении</Typography.Title>}>
          {data && <ReportTimeline reportList={data} />}
        </Card>
      </Col>
    </Row>
  );
};

export default Report;

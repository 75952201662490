import { API } from '../../Services/axios';
import { Card, Col, Result, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';

const UnsubscribePage = () => {
  const { showErrorModal } = useErrorModal();
  const { token } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.put(`/api/v1/register/unsubscribe/system-messages/${token}`)
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Row className="profile__container" justify="center">
      <Col span={18}>
        <Card>
          {loading ? (
            <Loader />
          ) : (
            <Result
              status="success"
              title={
                <>
                  Вы отписались от получения системных уведомлений на электронную почту. <br /> Подписаться снова Вы
                  можете через настройки в профиле.
                </>
              }
            />
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default UnsubscribePage;

import { Alert, Col, Row, Space, Typography } from 'antd';
import { MAX_HEIGHT, MAX_LENGTH, MAX_WIDTH } from '../../Constants/OverallDimensions';

interface CargoDimensionsProps {
  height: number;
  weight: number;
  width: number;
  length: number;
}

const CargoDimensions = (props: CargoDimensionsProps) => {
  const { height, weight, width, length } = props;

  const isCargoOversized = length > MAX_LENGTH || width > MAX_WIDTH || height > MAX_HEIGHT;

  return (
    <>
      {isCargoOversized && (
        <Col span={24}>
          <Row>
            <Alert message="Груз является негабаритным" type="warning" showIcon />
          </Row>
        </Col>
      )}
      <Col sm={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} span={12}>
        <Space direction="vertical">
          <Typography.Text className="order__info" type="secondary" strong>
            Вес, кг
          </Typography.Text>
          <Typography className="order__info-large">{weight}</Typography>
        </Space>
      </Col>
      <Col sm={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} span={12}>
        <Space direction="vertical">
          <Typography.Text className="order__info" type="secondary" strong>
            Длина, м
          </Typography.Text>
          <Typography className="order__info-large">{length}</Typography>
        </Space>
      </Col>
      <Col sm={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} span={12}>
        <Space direction="vertical">
          <Typography.Text className="order__info" type="secondary" strong>
            Ширина, м
          </Typography.Text>
          <Typography className="order__info-large">{width}</Typography>
        </Space>
      </Col>
      <Col sm={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} span={12}>
        <Space direction="vertical">
          <Typography.Text className="order__info" type="secondary" strong>
            Высота, м
          </Typography.Text>
          <Typography className="order__info-large">{height}</Typography>
        </Space>
      </Col>
    </>
  );
};

export default CargoDimensions;

import footerLogo from '../../Assets/Images/footer-logo.svg';

const AppFooter = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="logo">
          <a className="logo__icon" href="https://verumlogistics.ru/">
            <img src={footerLogo} alt="Verum Logistics" />
          </a>
        </div>
        <div className="footer__contacts">
          <h6>Телефон</h6>
          <a className="footer__contacts-link" href="tel:84951980110">
            8 495 198 01 10
          </a>
        </div>
        <div className="footer__license">
          <p>© 2024 OOО «ВЕРУМ-СОФТ»</p>
          <p>Деятельность не лицензируется</p>
        </div>
        <div className="footer__policy">
          <a href="https://verumlogistics.ru/offer/" target="_blank" rel="noreferrer">
            Правила использования сайта «verumlogistics.ru»
          </a>
          <a href="https://verumlogistics.ru/politika-konfidencialnosti/" target="_blank" rel="noreferrer">
            Политика конфиденциальности сайта «verumlogistics.ru»
          </a>
          <a href="https://verumlogistics.ru/politika-cookie/" target="_blank" rel="noreferrer">
            Политика cookie «verumlogistics.ru»
          </a>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;

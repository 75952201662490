import { Card, Col, Row, Typography } from 'antd';

interface ClaimAgreementInfoProps {
  organisationTitle: string;
  organisationName: string;
  addressFrom: string;
  addressTo: string;
}

const ClaimAgreementInfo = (props: ClaimAgreementInfoProps) => {
  const { organisationTitle, organisationName, addressFrom, addressTo } = props;
  return (
    <Card title={null}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row>
            <Col span={9}>
              <Typography.Text className="form__info-text">{organisationTitle}</Typography.Text>
            </Col>
            <Col span={15}>
              <Typography.Text className="form__info">{organisationName}</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={9}>
              <Typography.Text className="form__info">Адрес отправки</Typography.Text>
            </Col>
            <Col span={15}>
              <Typography.Text className="form__info">{addressFrom}</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={9}>
              <Typography.Text className="form__info">Адрес получения</Typography.Text>
            </Col>
            <Col span={15}>
              <Typography.Text className="form__info">{addressTo}</Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ClaimAgreementInfo;

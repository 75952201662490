import { API } from '../../../../../Services/axios';
import { Button, Form, Input, Modal, Row, Space } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { ModalProps } from '../../../../../Models/Modal';
import { NumericFormat } from 'react-number-format';
import { PaymentData } from '../../../../../Models/PaymentData';
import {
  getKppFieldsRules,
  getLegalInnFieldRules,
  getOrgNameFieldRules,
} from '../../../../../Utils/Rules/registration';
import {
  getRequiredBankFieldRule,
  getRequiredNumericFieldRule,
  getRequiredOgrnFieldRules,
  getRequiredOrgFieldRule,
} from '../../../../../Utils/Rules/profile';
import { getRequiredFieldRule } from '../../../../../Utils/Rules/order';
import { getVatValue } from '../../../../../Utils/getVatValue';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import ModalTitle from '../../../../../Components/Modals/ModalTitle';

interface EditPaymentDataModalProps extends ModalProps {
  data: PaymentData;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const EditPaymentDataModal = (props: EditPaymentDataModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, data, setReload } = props;
  const [form] = useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    inn: data.inn,
    name: data.name,
    kpp: data.kpp,
    ogrn: data.ogrn,
    okpo: data.okpo,
    bank: data.bank,
    paymentAccount: data.paymentAccount,
    postalAddress: data.postalAddress,
    correspondentAccount: data.correspondentAccount,
    bic: data.bic,
    licenseOrServiceName: data.licenseOrServiceName,
    payment: data.payment,
    vatPercentage: data.vatPercentage,
  };

  const onCloseModal = () => {
    handleCancel();
    form.resetFields();
    setLoading(false);
  };

  const onFinish = (values) => {
    setLoading(true);

    const unformattedCost = values.payment
      ? parseFloat(values.payment.toString().replaceAll(' ', '').replace(',', '.'))
      : undefined;

    const updatedData = {
      inn: data.inn,
      name: values.name,
      kpp: values.kpp,
      ogrn: values.ogrn,
      okpo: values.okpo,
      bank: values.bank,
      postalAddress: values.postalAddress,
      paymentAccount: values.paymentAccount,
      correspondentAccount: values.correspondentAccount,
      bic: values.bic,
      licenseOrServiceName: values.licenseOrServiceName,
      payment: unformattedCost,
      vatPercentage: Number(values.vatPercentage),
      vatValue: getVatValue(unformattedCost, Number(values.vatPercentage)),
    };

    API.put('/api/v1/admin/settings/payment-details', updatedData)
      .then((response) => onCloseModal())
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(true);
      });
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Редактирование платежной информации" handleCancel={onCloseModal} />}
      open={open}
      onCancel={onCloseModal}
      className="modal modal__scrollable"
      footer={
        <Row justify="end">
          <Button size="large" onClick={onCloseModal} className="modal__cancel-btn">
            Отменить
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Сохранить
          </Button>
        </Row>
      }
      centered
      width={645}
      closable={false}
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      <Form
        id="form"
        form={form}
        initialValues={initialValues}
        colon={false}
        layout="vertical"
        className="form"
        onFinish={onFinish}
      >
        <Space direction="vertical" size={24} style={{ width: '100%', marginBottom: '4px' }}>
          <Form.Item
            name="name"
            label="Название организации"
            validateFirst
            rules={getOrgNameFieldRules('Введите название организации')}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item name="inn" label="ИНН" rules={getLegalInnFieldRules('Введите ИНН организации')}>
            <Input size="large" />
          </Form.Item>

          <Form.Item name="kpp" label="КПП" validateFirst rules={getKppFieldsRules()}>
            <Input maxLength={9} size="large" />
          </Form.Item>

          <Form.Item
            name="postalAddress"
            label="Почтовый адрес"
            validateFirst
            rules={getRequiredOrgFieldRule('Введите почтовый адрес организации')}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="paymentAccount"
            label="Расчетный счет"
            validateFirst
            normalize={(value) => value.replace(/\D/g, '')}
            rules={getRequiredNumericFieldRule('Введите расчетный счет организации', 20)}
          >
            <Input maxLength={20} size="large" />
          </Form.Item>
          <Form.Item name="bank" label="Банк" validateFirst rules={getRequiredBankFieldRule()}>
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="correspondentAccount"
            label="Корреспондентский счет"
            validateFirst
            normalize={(value) => value.replace(/\D/g, '')}
            rules={getRequiredNumericFieldRule('Введите корреспондентский счет организации', 20)}
          >
            <Input maxLength={20} size="large" />
          </Form.Item>
          <Form.Item
            name="bic"
            label="БИК"
            validateFirst
            normalize={(value) => value.replace(/\D/g, '')}
            rules={getRequiredNumericFieldRule('Введите БИК организации', 9)}
          >
            <Input maxLength={9} size="large" />
          </Form.Item>
          <Form.Item
            name="ogrn"
            label="ОГРН"
            validateFirst
            normalize={(value) => value.replace(/\D/g, '')}
            rules={getRequiredOgrnFieldRules()}
          >
            <Input maxLength={13} size="large" />
          </Form.Item>
          <Form.Item
            name="okpo"
            label="ОКПО"
            validateFirst
            normalize={(value) => value.replace(/\D/g, '')}
            rules={getRequiredNumericFieldRule('Введите ОКПО организации', 8)}
          >
            <Input maxLength={8} size="large" />
          </Form.Item>
          <Form.Item
            name="licenseOrServiceName"
            label="Наименование лицензии/услуги"
            validateFirst
            rules={getRequiredOrgFieldRule('Введите наименование лицензии/услуги')}
          >
            <Input size="large" maxLength={200} />
          </Form.Item>
          <Form.Item
            name="payment"
            label="Сумма платежа, ₽"
            validateFirst
            rules={getRequiredFieldRule('Введите сумму платежа')}
          >
            <NumericFormat
              customInput={Input}
              allowNegative={false}
              maxLength={12}
              size="large"
              thousandSeparator=" "
              decimalSeparator=","
              allowedDecimalSeparators={['.']}
              decimalScale={2}
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue === undefined || floatValue < 10000000;
              }}
            />
          </Form.Item>
          <Form.Item
            name="vatPercentage"
            label="Размер НДС, %"
            validateFirst
            rules={getRequiredFieldRule('Введите размер НДС')}
          >
            <NumericFormat customInput={Input} allowNegative={false} maxLength={2} size="large" thousandSeparator=" " />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditPaymentDataModal;

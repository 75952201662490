import { Button, InputRef, Typography } from 'antd';
import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { Contract } from '../../../../../Models/Contract';
import { DateFilter, filterDate } from '../../../../../Components/Filters/DateFilter';
import { FilterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { NumberFilter, filterNumber } from '../../../../../Components/Filters/NumberFilter';
import { StringFilter, filterString } from '../../../../../Components/Filters/StringFilter';
import { defaultDateFormat } from '../../../../../Constants/DateFormats';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getContractPropertyType } from '../../../../../Utils/getContractPropertyType';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';

export const getContractsTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
) => {
  type DataIndex = keyof Contract;
  const columns: ColumnsType<Contract> = [
    {
      key: 'agreementUserFriendlyId',
      title: '№',
      dataIndex: 'agreementUserFriendlyId',
      sorter: (a, b) => a.agreementUserFriendlyId.localeCompare(b.agreementUserFriendlyId),
      render: (_, record) => (
        <Link to={`/operator/contracts/${record.agreementId}`}>
          <Button type="default">{record.agreementUserFriendlyId}</Button>
        </Link>
      ),
      width: 110,
    },
    {
      key: 'customerOrganisationName',
      title: 'Заказчик',
      dataIndex: 'customerOrganisationName',
      ellipsis: true,
      sorter: (a, b) => a.customerOrganisationName.localeCompare(b.customerOrganisationName),
      render: (_, record) => (
        <Typography.Link href={`/operator/organizations/${record.customerOrganisationId}`} underline>
          {record.customerOrganisationName}
        </Typography.Link>
      ),
      width: 156,
    },
    {
      key: 'executorOrganisationName',
      title: 'Организатор',
      dataIndex: 'executorOrganisationName',
      ellipsis: true,
      sorter: (a, b) => a.executorOrganisationName.localeCompare(b.executorOrganisationName),
      render: (_, record) => (
        <Typography.Link href={`/operator/organizations/${record.executorOrganisationId}`} underline>
          {record.executorOrganisationName}
        </Typography.Link>
      ),
      width: 160,
    },
    {
      key: 'cargoTypeNameWithParentName',
      title: 'Категория техники',
      dataIndex: 'cargoTypeNameWithParentName',
      ellipsis: true,
      sorter: (a, b) => a.cargoTypeNameWithParentName.localeCompare(b.cargoTypeNameWithParentName),
      width: 250,
    },
    {
      key: 'cityFrom',
      title: 'Город отправки',
      dataIndex: 'cityFrom',
      ellipsis: true,
      sorter: (a, b) => a.cityFrom.localeCompare(b.cityFrom),
      width: 180,
    },
    {
      key: 'cityTo',
      title: 'Город доставки',
      dataIndex: 'cityTo',
      ellipsis: true,
      sorter: (a, b) => a.cityTo.localeCompare(b.cityTo),
      width: 180,
    },
    {
      key: 'arrival',
      title: 'Дата получения',
      dataIndex: 'arrival',
      ellipsis: true,
      sorter: (a, b) => new Date(a.arrival).getTime() - new Date(b.arrival).getTime(),
      render: (text) => dayjs(text).format(defaultDateFormat),
      width: 145,
    },
    {
      key: 'price',
      title: 'Цена',
      dataIndex: 'price',
      align: 'right',
      ellipsis: true,
      sorter: (a, b) => a.price - b.price,
      render: (_, record) => `${formatPrice(+record.price)} ₽`,
      width: 129,
    },
  ];
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
  };

  const searchInput = useRef<InputRef>(null);
  const getColumnSearchProps = (dataIndex: keyof Contract): ColumnType<Contract> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const [showSegment, setShowSegment] = useState(false);
      const [selectedFilter, setSelectedFilter] = useState('eq');
      const handleFilterChange = (operator: string) => {
        setSelectedKeys([]);
        setShowSegment(operator === 'seg');
        setSelectedFilter(operator);
      };
      return `${getContractPropertyType(dataIndex)}` === 'string' || dataIndex === 'agreementId' ? (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      ) : `${getContractPropertyType(dataIndex)}` === 'number' ? (
        <NumberFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleFilterChange={handleFilterChange}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          showSegment={showSegment}
          selectedFilter={selectedFilter}
        />
      ) : `${getContractPropertyType(dataIndex)}` === 'object' ? (
        <DateFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleFilterChange={handleFilterChange}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          showSegment={showSegment}
          selectedFilter={selectedFilter}
        />
      ) : (
        <></>
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (_, record) => {
      let result = true;
      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] !== null) {
          if (`${getContractPropertyType(key)}` === 'string' || key === 'agreementId')
            result &&= filterString(record[key], filteredInfo[key]![0]);
          else if (`${getContractPropertyType(key)}` === 'number')
            result &&= filterNumber(record[key], filteredInfo[key]!);
          else if (`${getContractPropertyType(key)}` === 'object')
            result &&= filterDate(record[key], filteredInfo[key]!);
        }
      });
      return result;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<Contract> = columns.map((col, index) => {
    const dataIndex = col.key! as DataIndex;
    return {
      ...col,
      ...getColumnSearchProps(dataIndex),
    };
  });

  return filterableColumns;
};

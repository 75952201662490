// Formats price changing decimal separator '. to ','
export const formatPrice = (amount?: number) => {
  if (amount !== undefined) {
    const formattedPrice = amount.toLocaleString('ru-Ru').replace('.', ',');

    return formattedPrice;
  }
  return '0';
};

// Formats price changing decimal separator '. to ',' but rounded to 2 decimal places
export const formatPriceWithDecimals = (amount?: number) => {
  if (amount !== undefined) {
    const formattedPrice = amount.toLocaleString('ru-Ru').replace('.', ',');
    if (formattedPrice.includes(',')) {
      return formattedPrice;
    } else {
      return `${formattedPrice},00`;
    }
  }
  return '0';
};

import { Roles } from '../../Constants/Profile';
import { createSlice } from '@reduxjs/toolkit';

const parsedStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')!) : {};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    role: parsedStorage.role as Roles,
    name: parsedStorage.name ? `${parsedStorage.name} ${parsedStorage.surname}` : null,
    avatarLink: parsedStorage.avatarLink,
    confirmed: parsedStorage.confirmed,
    phone: parsedStorage.phone,
    fioPdf: parsedStorage.fioPdf,
  },
  reducers: {
    getUserInfo: (_, action) => ({
      role: action.payload.role,
      name: action.payload.name,
      avatarLink: action.payload.avatarLink,
      confirmed: action.payload.confirmed,
      phone: action.payload.phone,
      fioPdf: parsedStorage.fioPdf,
    }),
    getUserAvatar: (state, action) => ({
      ...state,
      avatarLink: action.payload,
    }),
  },
});

export const { getUserInfo, getUserAvatar } = userSlice.actions;

export default userSlice.reducer;

import { API } from '../../Services/axios';
import { Button, Card, Col, Form, Input, Row, Typography, notification } from 'antd';
import { Roles } from '../../Constants/Profile';
import { getConfirmPasswordRule, getPasswordRule } from '../../Utils/Rules/registration';
import { useAppSelector } from '../../Store/hooks';
import { useErrorModal } from '../Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';

const ChangePassword = () => {
  const { showErrorModal } = useErrorModal();
  const role = useAppSelector((state) => state.user.role);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      newPassword: values.password,
      oldPassword: values.oldPassword,
    };

    API.put('/api/v1/profile/change-password', data)
      .then((response) => {
        notification.success({ message: 'Пароль успешно изменен', placement: 'top' });
        form.resetFields();
      })
      .catch((error) => {
        if (error.toString().toLowerCase().includes(`passwords don't match`))
          showErrorModal({
            title: 'Текущий пароль неверный',
            message: 'Если вы забыли пароль, то можете восстановить его на странице входа в систему.',
          });
        else showErrorModal({ error });
      })
      .finally(() => setLoading(false));
  };

  const notOperatorOrAdmin = role === Roles.DELIVERY || role === Roles.CUSTOMER;

  return (
    <Card title={<Typography.Title level={4}>Смена пароля</Typography.Title>}>
      <Form form={form} layout="vertical" colon={false} className="form" onFinish={onFinish}>
        <Row justify="space-between" gutter={[24, 24]}>
          <Col lg={notOperatorOrAdmin ? 19 : 24} span={24}>
            <Form.Item name="oldPassword" label="Текущий пароль" validateFirst rules={getPasswordRule()}>
              <Input.Password size="large" />
            </Form.Item>
          </Col>
          <Col lg={notOperatorOrAdmin ? 19 : 24} span={24}>
            <Form.Item name="password" label="Новый пароль" validateFirst rules={getPasswordRule()}>
              <Input.Password size="large" />
            </Form.Item>
          </Col>
          <Col lg={notOperatorOrAdmin ? 19 : 24} span={24}>
            <Form.Item
              name="confirmPassword"
              label="Повторите новый пароль"
              validateFirst
              rules={getConfirmPasswordRule()}
              dependencies={['password']}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Col>
          <Col lg={notOperatorOrAdmin ? 19 : 24} span={24}>
            <Form.Item className="profile__change-pass-btn">
              <Button type="primary" htmlType="submit" size="large" loading={loading}>
                Изменить
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ChangePassword;

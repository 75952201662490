import { API } from '../../../../../Services/axios';
import { Agreement } from '../../../../../Models/Agreement';
import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import { OfferType } from '../../../../../Models/Offer';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';

interface FinalAgreementOfferProps {
  offerData: OfferType;
}

const FinalAgreementOffer = (props: FinalAgreementOfferProps) => {
  const { showErrorModal } = useErrorModal();
  const { offerData } = props;
  const [agreements, setAgreements] = useState<Agreement[]>();

  useEffect(() => {
    API.get(`/api/v1/agreement/${offerData.id}/list`)
      .then((response) => setAgreements(sortBy(response.data, 'version').reverse()))
      .catch((error) => showErrorModal({ error }));
  }, [offerData.id]);

  return (
    <Card className="offer__full-width">
      <Col span={24} className="offer__title">
        <Row justify="space-between" align="middle">
          <Typography.Title level={4}>Ваше предложение</Typography.Title>
        </Row>
      </Col>
      <Col>
        <Space direction="vertical" className="offer__info" size={8}>
          <Typography.Text type="secondary" strong>
            Ваше предложение по стоимости с НДС
          </Typography.Text>
          {offerData.price && (
            <Typography.Text className="offer__text">{formatPrice(+offerData.price)} &#8381;</Typography.Text>
          )}
        </Space>
        <Space direction="vertical" size={8}>
          <Typography.Text type="secondary" strong>
            Срок исполнения в календарных днях
          </Typography.Text>
          <Typography.Text className="offer__text">{offerData.dueDatePeriod}</Typography.Text>
        </Space>
        {offerData?.comment && (
          <Space direction="vertical" className="offer__comment" size={8}>
            <Typography.Text type="secondary" strong>
              Комментарий к предложению
            </Typography.Text>
            <Typography.Text className="offer__text">{offerData.comment}</Typography.Text>
          </Space>
        )}

        {agreements && (
          <>
            <Divider />
            <Space key={agreements[0].id} size={16} direction="vertical">
              <Space size={12} direction="vertical">
                {/*<Space direction="vertical" size={10}>*/}
                {/*  <Typography.Text type="secondary" strong>*/}
                {/*    Проект договора*/}
                {/*  </Typography.Text>*/}
                {/*  <Space size={10}>*/}
                {/*    <FileOutlined className="offer__file-icon" />*/}
                {/*    <Typography.Link className="offer__text" href={agreements[0].fileInfo[0].link}>*/}
                {/*      {agreements[0].fileInfo[0].name}*/}
                {/*    </Typography.Link>*/}
                {/*  </Space>*/}
                {/*</Space>*/}
                {agreements[0].comment && (
                  <Space direction="vertical" className="offer__comment" size={8}>
                    <Typography.Text type="secondary" strong>
                      Комментарий к проекту договора (видимый только заказчику)
                    </Typography.Text>
                    <Typography className="offer__info-large">{agreements[0].comment}</Typography>
                  </Space>
                )}
              </Space>
            </Space>
          </>
        )}
      </Col>
    </Card>
  );
};

export default FinalAgreementOffer;

import { API } from '../../../../../Services/axios';
import { Button, Card, Col, Divider, Form, Input, Row, Space, Typography } from 'antd';
import { DownCircleOutlined, MinusCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';
import { OfferFormType } from '../../../../../Models/Offer';
import { OrderMarginCalculation } from '../../../../../Models/Order';
import { OrderMarginCalculationInitialValue } from '../../../../../Constants/Order';
import { SliderMarks } from 'antd/lib/slider';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getDueDatePeriodRule, getPriceRule, getRelevancePeriodRule } from '../../../../../Utils/Rules/offer';
import { useAppSelector } from '../../../../../Store/hooks';
import { useDebounce } from '../../../../../Utils/useDebounce';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import EditOrgOrderMarginCalcSettingsModal from '../../../../../Components/Modals/EditOrgOrderMarginCalcSettingsModal';
import LabelWithTooltip from '../../../../../Components/Label/LabelWithTooltip';
import dayjs from 'dayjs';

interface OfferFormProps {
  orderId: number;
}

const OfferForm = (props: OfferFormProps) => {
  const { showErrorModal } = useErrorModal();
  const { orderId } = props;
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const [form] = useForm();
  const [formWithCustomPrice] = useForm();
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userPrice, setUserPrice] = useState(0);
  const [isNeedToSendUserPrice, setNeedToSendUserPrice] = useState(false);
  const debouncedUserPrice = useDebounce(userPrice || 0, 1000);

  const [openEditOrgOrderMarginCalcSettingsModal, setOpenEditOrgOrderMarginCalcSettingsModal] = useState(false);
  const handleOpenEditOrgOrderMarginCalcSettingsModal = () => setOpenEditOrgOrderMarginCalcSettingsModal(true);
  const handleCancelEditOrgOrderMarginCalcSettingsModal = () => setOpenEditOrgOrderMarginCalcSettingsModal(false);

  const onCancel = () => form.resetFields();

  const [orderMarginCalculation, setOrderMarginCalculation] = useState<OrderMarginCalculation>(
    OrderMarginCalculationInitialValue,
  );
  useEffect(() => {
    API.get(`/api/v1/calculator/for-order/${orderId}`)
      .then((response) => {
        setOrderMarginCalculation((prev) => ({ ...prev, ...response.data }));
        setUserPrice(response.data.customCost);
        formWithCustomPrice.setFieldValue('price', +response.data.customCost);
      })
      .catch((error) => showErrorModal({ error }));
  }, []);

  const onFinish = (values: OfferFormType) => {
    setLoading(true);
    const formData = {
      comment: values.comment,
      created: dayjs(Date.now()).toISOString(),
      dueDatePeriod: values.dueDatePeriod,
      offerValidityTime: values.offerValidityTime,
      orderId: orderId,
      price: Number(`${values.price}`.replaceAll(' ', '')),
    };

    API.post('/api/v1/offer', formData)
      .then(() => location.reload())
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  const calculateCustomCost = (getParams?: string) => {
    setLoading(true);

    const dto = {
      desiredProfit: orderMarginCalculation.desiredProfit,
      platonFee: orderMarginCalculation.platonFee,
      fuelConsumption: orderMarginCalculation.fuelConsumption,
      adblueConsumption: orderMarginCalculation.adblueConsumption,
      driverSalary: orderMarginCalculation.driverSalary,
      driverSalaryPercentage: orderMarginCalculation.driverSalaryPercentage,
      driverSalaryDay: orderMarginCalculation.driverSalaryDay,
      driverWorkingDay: orderMarginCalculation.driverWorkingDay,
      logisticianSalary: orderMarginCalculation.logisticianSalary,
      additionalFee: orderMarginCalculation.additionalFee,
      insuranceCargoPriceFee: orderMarginCalculation.insuranceCargoPriceFee,
      rateSeason: orderMarginCalculation.rateSeason,
      rateRegion: orderMarginCalculation.rateRegion,
      rateTransport: orderMarginCalculation.rateTransport,
      customRecommendedPrice: userPrice,
    };

    API.post(`/api/v1/calculator/for-order/${orderMarginCalculation.orderId}${getParams ?? ''}`, dto)
      .then((response) => {
        setOrderMarginCalculation({ ...OrderMarginCalculationInitialValue, ...response.data });
        formWithCustomPrice.setFieldValue('price', +response.data.customCost);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  const handleCalculateCustomCost = () => calculateCustomCost('');

  useEffect(() => {
    if (isNeedToSendUserPrice) {
      calculateCustomCost('?custom-calculation=true');
      setNeedToSendUserPrice(false);
    }
  }, [debouncedUserPrice]);

  const handleSetUserPrice = (value) => {
    setNeedToSendUserPrice(true);
    if (+value < 0) setUserPrice(0);
    else if (+value > 999999999999) setUserPrice(999999999999);
    else setUserPrice(+value);
  };

  const handleSetUserPriceFromInput = (e) => {
    handleSetUserPrice(Number(e.target.value.replaceAll(' ', '')));
  };

  const sliderMarks: SliderMarks = {
    0: {
      style: {
        transform: 'translateX(0)',
      },
      label: '0 ₽',
    },
    500000: '500 000 ₽',
    1000000: {
      style: {
        transform: 'translateX(-100%)',
      },
      label: '1 000 000 ₽',
    },
  };

  const sliderTooltipFormatter = (value?: number) => `${formatPrice(value)} ₽`;

  return (
    <Row gutter={[0, 32]}>
      <Col span={23} offset={1}>
        Если Вас заинтересовал этот заказ, сделайте предложение по цене. Заказчик изучит предложения всех Перевозчиков и
        выберет наиболее релевантный вариант.
        <Divider type="horizontal" />
        <Row justify="space-between" align="middle" gutter={[24, 0]} style={{ padding: '0 36px 30px' }}>
          <Col>
            <Typography.Title level={4}>Предварительная оценочная стоимость</Typography.Title>
          </Col>
          <Col>
            <Typography.Text strong style={{ fontSize: 27 }}>
              ≈ {orderMarginCalculation.orderPrice ? formatPrice(orderMarginCalculation.orderPrice) : 0} ₽
            </Typography.Text>
          </Col>
        </Row>
        {orderMarginCalculation.orderPrice && (
          <Card
            title={
              <Row justify="space-between" align="middle" gutter={[24, 0]}>
                <Col>
                  <Typography.Title level={4}>Расчет выгоды</Typography.Title>
                </Col>
                <Col>
                  <Typography.Text
                    onClick={handleOpenEditOrgOrderMarginCalcSettingsModal}
                    className="contract__info__margin"
                  >
                    К деталям и параметрам расчета
                  </Typography.Text>
                  <EditOrgOrderMarginCalcSettingsModal
                    open={openEditOrgOrderMarginCalcSettingsModal}
                    handleCancel={handleCancelEditOrgOrderMarginCalcSettingsModal}
                    orderMarginCalculation={orderMarginCalculation}
                  />
                </Col>
              </Row>
            }
          >
            {orderMarginCalculation.resultProfit >= 0 && (
              <Row justify="space-between" style={{ width: '100%' }} align="middle">
                <Col className="contract__info-subtitle">
                  <Typography.Title level={5}>Прибыль (от цены заказчика)</Typography.Title>
                </Col>
                <Col>
                  <Typography.Text className="contract__info__margin-details">
                    {orderMarginCalculation.resultProfit > 0 && <UpCircleOutlined style={{ marginRight: 5 }} />}
                    {orderMarginCalculation.resultProfit === 0 && <MinusCircleOutlined style={{ marginRight: 5 }} />}
                    {`${formatPrice(orderMarginCalculation.resultProfit)} ₽`}
                  </Typography.Text>
                </Col>
              </Row>
            )}
            {orderMarginCalculation.resultProfit < 0 && (
              <Row justify="space-between" style={{ width: '100%' }} align="middle">
                <Col className="contract__info-subtitle">
                  <Typography.Title level={5}>Убыток (от цены заказчика)</Typography.Title>
                </Col>
                <Col>
                  <Typography.Text className="contract__info__margin-details">
                    <DownCircleOutlined style={{ marginRight: 5 }} />
                    {`${formatPrice(Math.abs(orderMarginCalculation.resultProfit))} ₽`}
                  </Typography.Text>
                </Col>
              </Row>
            )}
            <Divider />
            <Row justify="space-between" style={{ width: '100%' }} align="middle">
              <Col>
                <Typography.Text className="contract__info" type="secondary">
                  Общая сумма затрат на перевозку{' '}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text>{`${formatPrice(orderMarginCalculation.resultExpenses)} ₽`}</Typography.Text>
              </Col>
            </Row>
            <Row justify="space-between" style={{ width: '100%', paddingTop: 10 }} align="middle">
              <Col>
                <Typography.Text className="contract__info" type="secondary">
                  Планируемая маржинальность{' '}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text>{`${formatPrice(orderMarginCalculation.resultPlannedMarginality)} ₽`}</Typography.Text>
              </Col>
            </Row>
            <Row justify="space-between" style={{ width: '100%', paddingTop: 10 }} align="middle">
              <Col>
                <Typography.Text className="contract__info" type="secondary">
                  Ставка заказчику, без НДС{' '}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text>{`${formatPrice(orderMarginCalculation.resultPriceWithoutVat)} ₽`}</Typography.Text>
              </Col>
            </Row>
            <Divider />
            <Row justify="space-between" align="middle" gutter={[0, 12]}>
              <Col>
                <Typography.Text className="contract__info" type="secondary">
                  {orderMarginCalculation.resultRecommendedPrice === 50000 ? 'Минимальная' : 'Рекомендуемая'} стоимость
                  для <br></br> этого предложения (с учетом НДС){' '}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text
                  className="contract__info__margin-details contract__info__margin-icon"
                  ellipsis={{ tooltip: true }}
                  copyable={{ tooltips: false }}
                >{`${formatPrice(orderMarginCalculation.resultRecommendedPrice)} ₽`}</Typography.Text>
              </Col>
            </Row>
          </Card>
        )}
      </Col>
      <Col span={23} offset={1}>
        <Typography.Title level={4}>Ваше предложение</Typography.Title>
      </Col>
      <Col span={23} offset={1}>
        <Form
          form={form}
          colon={false}
          layout="vertical"
          className="form"
          onFinish={onFinish}
          onValuesChange={() => setIsFormEdited(true)}
          disabled={!isVerified}
        >
          <Form.Item
            label="Ваше предложение по стоимости с НДС, ₽"
            name="price"
            rules={getPriceRule('Введите вашу стоимость')}
            validateFirst
          >
            <NumericFormat
              customInput={Input}
              allowNegative={false}
              maxLength={11}
              size="large"
              thousandSeparator=" "
              decimalScale={0}
            />
          </Form.Item>
          <Space className="offer__space-full-width offer__periods" size="large" align="start">
            <Form.Item
              label={
                <LabelWithTooltip
                  label="Срок исполнения в календарных днях"
                  message="Сколько календарных дней, по Вашей оценке, займет доставка"
                />
              }
              name="dueDatePeriod"
              rules={getDueDatePeriodRule('Введите срок исполнения')}
              normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}
              validateFirst
            >
              <Input size="large" min={1} />
            </Form.Item>
            <Form.Item
              label={
                <LabelWithTooltip
                  label="Срок актуальности, мин. 14 дней"
                  message="Сколько календарных дней действует Ваше предложение. После истечения срока предложение отменится без возможности отправить новое."
                />
              }
              name="offerValidityTime"
              rules={getRelevancePeriodRule('Введите срок актуальности предложения')}
              normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}
              validateFirst
            >
              <Input size="large" min={1} />
            </Form.Item>
          </Space>
          <Form.Item
            label="Комментарий к предложению (увидит только заказчик)"
            name="comment"
            extra="Например, способ оплаты, особенности перевозки"
          >
            <Input.TextArea maxLength={500} showCount className="form__comment" disabled={!isVerified} />
          </Form.Item>

          <Form.Item className="offer__bottom-btns">
            <Button type="primary" size="large" htmlType="submit" loading={loading}>
              Отправить
            </Button>
            {isFormEdited && (
              <Button size="large" htmlType="submit" onClick={onCancel}>
                Отменить
              </Button>
            )}
          </Form.Item>
        </Form>
      </Col>
      <Col span={23} offset={1}>
        {orderMarginCalculation.customCalculationUpdated && (
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Text strong>
                Загружен черновик расчёта стоимости от{' '}
                {dayjs(orderMarginCalculation.customCalculationUpdated).format('DD.MM.YYYY')}
              </Typography.Text>
            </Col>
          </Row>
        )}

        <Divider type="horizontal" />
        {/*{orderMarginCalculation.orderPrice && (*/}
        {/*  <Card*/}
        {/*    title={*/}
        {/*      <Row justify="space-between" align="middle" gutter={[24, 24]}>*/}
        {/*        <Col span={4}>*/}
        {/*          <Typography.Title level={4}>Рассчитать свою стоимость</Typography.Title>*/}
        {/*          <Typography.Text type="secondary">Стоимость c НДС, ₽</Typography.Text>*/}
        {/*        </Col>*/}
        {/*        <Col>*/}
        {/*          <InputNumber*/}
        {/*            size="large"*/}
        {/*            value={!userPrice ? '' : formatPrice(userPrice)}*/}
        {/*            onChange={handleSetUserPrice}*/}
        {/*            addonAfter="₽"*/}
        {/*            placeholder={'0'}*/}
        {/*            className="contract__user_price_input"*/}
        {/*            style={{ minWidth: 212, fontSize: 27 }}*/}
        {/*          />*/}
        {/*        </Col>*/}
        {/*        <Col span={24} style={{ padding: '0 24px' }}>*/}
        {/*          <Slider*/}
        {/*            min={0}*/}
        {/*            max={1000000}*/}
        {/*            step={1}*/}
        {/*            marks={sliderMarks}*/}
        {/*            tooltip={{ formatter: sliderTooltipFormatter }}*/}
        {/*            value={userPrice}*/}
        {/*            onChange={handleSetUserPrice}*/}
        {/*          />*/}
        {/*        </Col>*/}
        {/*        <Col>*/}
        {/*          <Typography.Title level={4}>Расчет выгоды вашего предложения</Typography.Title>*/}
        {/*        </Col>*/}
        {/*        <Col>*/}
        {/*          <Typography.Text*/}
        {/*            onClick={handleOpenEditOrgOrderMarginCalcSettingsModal}*/}
        {/*            className="contract__info__margin"*/}
        {/*          >*/}
        {/*            К деталям и параметрам расчета*/}
        {/*          </Typography.Text>*/}
        {/*        </Col>*/}
        {/*      </Row>*/}
        {/*    }*/}
        {/*  >*/}
        {/*    {orderMarginCalculation.customProfit >= 0 && (*/}
        {/*      <Row justify="space-between" style={{ width: '100%' }} align="middle">*/}
        {/*        <Col className="contract__info-subtitle">*/}
        {/*          <Typography.Title level={5}>Прибыль (от цены перевозчика)</Typography.Title>*/}
        {/*        </Col>*/}
        {/*        <Col>*/}
        {/*          <Typography.Text className="contract__info__margin-details">*/}
        {/*            {orderMarginCalculation.customProfit > 0 && <UpCircleOutlined style={{ marginRight: 5 }} />}*/}
        {/*            {`${formatPrice(orderMarginCalculation.customProfit)} ₽`}*/}
        {/*          </Typography.Text>*/}
        {/*        </Col>*/}
        {/*      </Row>*/}
        {/*    )}*/}
        {/*    {orderMarginCalculation.customProfit < 0 && (*/}
        {/*      <Row justify="space-between" style={{ width: '100%' }} align="middle">*/}
        {/*        <Col className="contract__info-subtitle">*/}
        {/*          <Typography.Title level={5}>Убыток (от цены заказчика)</Typography.Title>*/}
        {/*        </Col>*/}
        {/*        <Col>*/}
        {/*          <Typography.Text className="contract__info__margin-details">*/}
        {/*            <DownCircleOutlined style={{ marginRight: 5 }} />*/}
        {/*            {`${formatPrice(Math.abs(orderMarginCalculation.customProfit))} ₽`}*/}
        {/*          </Typography.Text>*/}
        {/*        </Col>*/}
        {/*      </Row>*/}
        {/*    )}*/}
        {/*    <Divider />*/}
        {/*    <Row justify="space-between" style={{ width: '100%' }} align="middle">*/}
        {/*      <Col>*/}
        {/*        <Typography.Text className="contract__info" type="secondary">*/}
        {/*          Общая сумма затрат на перевозку{' '}*/}
        {/*        </Typography.Text>*/}
        {/*      </Col>*/}
        {/*      <Col>*/}
        {/*        <Typography.Text>{`${formatPrice(orderMarginCalculation.customExpenses)} ₽`}</Typography.Text>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row justify="space-between" style={{ width: '100%', paddingTop: 10 }} align="middle">*/}
        {/*      <Col>*/}
        {/*        <Typography.Text className="contract__info" type="secondary">*/}
        {/*          Планируемая маржинальность{' '}*/}
        {/*        </Typography.Text>*/}
        {/*      </Col>*/}
        {/*      <Col>*/}
        {/*        <Typography.Text>{`${formatPrice(orderMarginCalculation.customPlannedMarginality)} ₽`}</Typography.Text>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Row justify="space-between" style={{ width: '100%', paddingTop: 10 }} align="middle">*/}
        {/*      <Col>*/}
        {/*        <Typography.Text className="contract__info" type="secondary">*/}
        {/*          Ставка заказчику, без НДС{' '}*/}
        {/*        </Typography.Text>*/}
        {/*      </Col>*/}
        {/*      <Col>*/}
        {/*        <Typography.Text>{`${formatPrice(orderMarginCalculation.customPriceWithoutVat)} ₽`}</Typography.Text>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*    <Divider />*/}
        {/*    <Row justify="space-between" align="middle" gutter={[0, 12]}>*/}
        {/*      <Col>*/}
        {/*        <Typography.Text className="contract__info" type="secondary">*/}
        {/*          Рекомендуемая стоимость для <br></br> этого предложения (с учетом НДС){' '}*/}
        {/*        </Typography.Text>*/}
        {/*      </Col>*/}
        {/*      <Col>*/}
        {/*        <Typography.Text*/}
        {/*          className="contract__info__margin-details contract__info__margin-icon"*/}
        {/*          style={{ width: '100%', textAlign: 'right' }}*/}
        {/*          copyable={{ tooltips: false }}*/}
        {/*        >{`${formatPrice(orderMarginCalculation.customCost)} ₽`}</Typography.Text>*/}
        {/*      </Col>*/}
        {/*      <Col span={24}>*/}
        {/*        <Form*/}
        {/*          form={formWithCustomPrice}*/}
        {/*          colon={false}*/}
        {/*          layout="vertical"*/}
        {/*          className="form"*/}
        {/*          onFinish={onFinish}*/}
        {/*          onValuesChange={() => setIsFormEdited(true)}*/}
        {/*          disabled={!isVerified}*/}
        {/*        >*/}
        {/*          <Form.Item*/}
        {/*            label="Ваше предложение по стоимости с НДС, ₽"*/}
        {/*            rules={getPriceRule('Введите вашу стоимость')}*/}
        {/*            name="price"*/}
        {/*            validateFirst*/}
        {/*          >*/}
        {/*            <NumericFormat*/}
        {/*              customInput={Input}*/}
        {/*              allowNegative={false}*/}
        {/*              maxLength={11}*/}
        {/*              size="large"*/}
        {/*              thousandSeparator=" "*/}
        {/*              decimalScale={0}*/}
        {/*              value={formatPrice(userPrice)}*/}
        {/*              onChange={handleSetUserPriceFromInput}*/}
        {/*            />*/}
        {/*          </Form.Item>*/}
        {/*          <Space className="offer__space-full-width offer__periods" size="large" align="start">*/}
        {/*            <Form.Item*/}
        {/*              label={*/}
        {/*                <LabelWithTooltip*/}
        {/*                  label="Срок исполнения в календарных днях"*/}
        {/*                  message="Сколько календарных дней, по Вашей оценке, займет доставка"*/}
        {/*                />*/}
        {/*              }*/}
        {/*              name="dueDatePeriod"*/}
        {/*              rules={getDueDatePeriodRule('Введите срок исполнения')}*/}
        {/*              normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}*/}
        {/*              validateFirst*/}
        {/*            >*/}
        {/*              <Input size="large" min={1} />*/}
        {/*            </Form.Item>*/}
        {/*            <Form.Item*/}
        {/*              label={*/}
        {/*                <LabelWithTooltip*/}
        {/*                  label="Срок актуальности, мин. 14 дней"*/}
        {/*                  message="Сколько календарных дней действует Ваше предложение. После истечения срока предложение отменится без возможности отправить новое."*/}
        {/*                />*/}
        {/*              }*/}
        {/*              name="offerValidityTime"*/}
        {/*              rules={getRelevancePeriodRule('Введите срок актуальности предложения')}*/}
        {/*              normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}*/}
        {/*              validateFirst*/}
        {/*            >*/}
        {/*              <Input size="large" min={1} />*/}
        {/*            </Form.Item>*/}
        {/*          </Space>*/}
        {/*          <Form.Item*/}
        {/*            label={*/}
        {/*              <Space direction="vertical">*/}
        {/*                <label>Комментарий к предложению (увидит только заказчик)</label>*/}
        {/*              </Space>*/}
        {/*            }*/}
        {/*            name="comment"*/}
        {/*          >*/}
        {/*            <>*/}
        {/*              <TextArea maxLength={500} showCount className="form__comment" disabled={!isVerified} />{' '}*/}
        {/*              <Typography.Text type="secondary" className="offer__subtitle">*/}
        {/*                Например, способ оплаты, особенности перевозки*/}
        {/*              </Typography.Text>*/}
        {/*            </>*/}
        {/*          </Form.Item>*/}

        {/*          <Form.Item className="offer__bottom-btns">*/}
        {/*            <Row justify="space-between">*/}
        {/*              <Col>*/}
        {/*                <Button type="primary" size="large" htmlType="submit" loading={loading}>*/}
        {/*                  Отправить*/}
        {/*                </Button>*/}
        {/*                {isFormEdited && (*/}
        {/*                  <Button size="large" htmlType="submit" onClick={onCancel}>*/}
        {/*                    Отменить*/}
        {/*                  </Button>*/}
        {/*                )}*/}
        {/*              </Col>*/}
        {/*              <Col>*/}
        {/*                <Button size="large" htmlType="button" disabled={false} onClick={handleCalculateCustomCost}>*/}
        {/*                  Сохранить расчет*/}
        {/*                </Button>*/}
        {/*              </Col>*/}
        {/*            </Row>*/}
        {/*          </Form.Item>*/}
        {/*        </Form>*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Card>*/}
        {/*)}*/}
      </Col>
    </Row>
  );
};

export default OfferForm;

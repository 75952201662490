import { API } from '../../Services/axios';
import { Alert, Button, Col, Form, InputNumber, Modal, Row, Space, Tooltip, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { OrderMarginCalculation } from '../../Models/Order';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getRequiredFieldRule } from '../../Utils/Rules/order';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from './ModalTitle';

interface EditOrgOrderMarginCalcSettingsModalProps extends ModalProps {
  orderMarginCalculation: OrderMarginCalculation;
}

const EditOrgOrderMarginCalcSettingsModal = (props: EditOrgOrderMarginCalcSettingsModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, orderMarginCalculation } = props;

  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const [platonPrice, setPlatonPrice] = useState(orderMarginCalculation.platonFee);
  const [calculatePlatonLoading, setCaclucatePlatonLoading] = useState<boolean>(false);
  const calculatePlaton = () => {
    setCaclucatePlatonLoading(true);
    setLoading(true);

    API.get(`/api/v1/calculator/platon-for-order/${orderMarginCalculation.orderId}`)
      .then((response) => {
        form.setFieldValue('platonFee', response.data.price);
        form.validateFields();
        setPlatonPrice(response.data.price);
        // if (response.data.price === 0)
        if (response.data.apiFail === 'null cities') {
          showErrorModal({
            title: 'Не удалось рассчитать стоимость',
            message:
              'Автоматический рассчёт стоимости Платона для данного заказа не удался. Такое бывает в случаях когда адрес указан недостаточно детально или сервис недоступен.',
          });
        } else if (response.data.price === 0) {
          showErrorModal({
            title: 'Не удалось рассчитать стоимость',
            message: 'На данном маршруте отсутствуют обязательства по уплате стоимости Платона.',
          });
        }
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setCaclucatePlatonLoading(false);
        setLoading(false);
      });
  };

  const initialValues = {
    desiredProfit: orderMarginCalculation.desiredProfit,
    platonFee: orderMarginCalculation.platonFee,
    fuelConsumption: orderMarginCalculation.fuelConsumption,
    adblueConsumption: orderMarginCalculation.adblueConsumption,
    driverSalary: orderMarginCalculation.driverSalary,
    driverSalaryPercentage: orderMarginCalculation.driverSalaryPercentage,
    driverSalaryDay: orderMarginCalculation.driverSalaryDay,
    driverWorkingDay: orderMarginCalculation.driverWorkingDay,
    logisticianSalary: orderMarginCalculation.logisticianSalary,
    additionalFee: orderMarginCalculation.additionalFee,
    insuranceCargoPriceFee: orderMarginCalculation.insuranceCargoPriceFee,
    rateSeason: orderMarginCalculation.rateSeason,
    rateRegion: orderMarginCalculation.rateRegion,
    rateTransport: orderMarginCalculation.rateTransport,
  };

  const onCloseModal = () => {
    handleCancel();
    form.resetFields();
  };

  const onFinish = () => {
    form.validateFields();
    setLoading(true);

    const values = form.getFieldsValue();
    const dto = {
      desiredProfit: values.desiredProfit,
      platonFee: values.platonFee,
      fuelConsumption: values.fuelConsumption,
      adblueConsumption: values.adblueConsumption,
      driverSalary: values.driverSalary,
      driverSalaryPercentage: values.driverSalaryPercentage,
      driverSalaryDay: values.driverSalaryDay,
      driverWorkingDay: values.driverWorkingDay,
      logisticianSalary: values.logisticianSalary,
      additionalFee: values.additionalFee,
      insuranceCargoPriceFee: values.insuranceCargoPriceFee,
      rateSeason: values.rateSeason,
      rateRegion: values.rateRegion,
      rateTransport: values.rateTransport,
    };

    API.post(`/api/v1/calculator/for-order/${orderMarginCalculation.orderId}`, dto)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Редактирование параметров калькулятора" handleCancel={onCloseModal} />}
      open={open}
      onCancel={onCloseModal}
      className="modal modal__scrollable"
      footer={
        <Row justify="end">
          <Button size="large" onClick={onCloseModal} className="modal__cancel-btn">
            Отмена
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Рассчитать
          </Button>
        </Row>
      }
      centered
      width={645}
      closable={false}
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      <Form
        id="form"
        form={form}
        initialValues={initialValues}
        colon={false}
        layout="vertical"
        className="form"
        onFinish={onFinish}
      >
        <Space direction="vertical" size={56} style={{ width: '100%' }}>
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Typography.Title level={4}>Чистая маржинальность</Typography.Title>
            <Form.Item
              name="desiredProfit"
              label={<Typography.Text>Планируемая маржинальность, % от оборота</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
            </Form.Item>
          </Space>
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Typography.Title level={4}>Расходы на поездку</Typography.Title>
            <Row gutter={[24, 24]} align="bottom">
              <Col lg={12} span={24}>
                <Form.Item
                  name="platonFee"
                  label={<Typography.Text>Расчет стоимости Платона, ₽</Typography.Text>}
                  rules={getRequiredFieldRule('Поле обязательно для заполнения')}
                >
                  <InputNumber
                    value={platonPrice}
                    controls={false}
                    size="large"
                    decimalSeparator=","
                    min={0}
                    max={9999999.99}
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} span={24} className="modal__action-btn">
                <Button
                  size="large"
                  style={{ width: '100%' }}
                  type="primary"
                  loading={calculatePlatonLoading}
                  onClick={() => calculatePlaton()}
                >
                  Рассчитать автоматически
                </Button>
              </Col>
            </Row>
            <Form.Item
              name="fuelConsumption"
              label={<Typography.Text>Средний расход топлива (на 100 км), л</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber
                controls={false}
                size="large"
                decimalSeparator=","
                min={0.1}
                max={9999999.99}
                maxLength={10}
              />
            </Form.Item>
            <Form.Item
              name="adblueConsumption"
              label={<Typography.Text>Средний расход мочевины (на 100 км), л</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber
                controls={false}
                size="large"
                decimalSeparator=","
                min={0.1}
                max={9999999.99}
                maxLength={10}
              />
            </Form.Item>
            <Form.Item
              name="driverSalary"
              label={<Typography.Text>Заработная плата водителя (за 1 км), ₽</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
            </Form.Item>
            <Form.Item
              name="driverSalaryPercentage"
              label={<Typography.Text>Заработная плата водителя, % от оборота</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
            </Form.Item>
            <Alert
              message="Калькулятор может учитывать один или оба параметра расчета заработной платы водителя одновременно. Если Вы используете только один из параметров, оставьте в другом значение 0."
              type="warning"
              showIcon
            />
            <Form.Item
              name="driverSalaryDay"
              label={<Typography.Text>Стоимость суточных водителя, ₽</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
            </Form.Item>
            <Form.Item
              name="driverWorkingDay"
              label={<Typography.Text>Продолжительность рабочего дня водителя, ч</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={1} max={24} maxLength={3} />
            </Form.Item>
            <Form.Item
              name="logisticianSalary"
              label={<Typography.Text>Заработная плата логиста, % от оборота</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
            </Form.Item>
            <Form.Item
              name="additionalFee"
              label={<Typography.Text>Дополнительные расходы, % от затрат</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
            </Form.Item>
            <Form.Item
              name="insuranceCargoPriceFee"
              label={<Typography.Text>Страхование груза, % от стоимости груза</Typography.Text>}
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
            </Form.Item>
          </Space>
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Typography.Title level={4}>Коэффициенты</Typography.Title>
            <Row gutter={[24, 24]}>
              <Col lg={12} span={24}>
                <Form.Item
                  name="rateSeason"
                  label={
                    <Typography.Text>
                      Коэффициент сезона
                      <Tooltip title="Вы можете указать коэффициент сезона. По умолчанию он равен 1. Если Вы укажете значение, большее 1, финальная стоимость увеличится. Если меньше 1, то стоимость уменьшится. Число должно быть больше 0">
                        <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                      </Tooltip>
                    </Typography.Text>
                  }
                  rules={getRequiredFieldRule('Поле обязательно для заполнения')}
                >
                  <InputNumber
                    controls={false}
                    size="large"
                    decimalSeparator=","
                    min={0.01}
                    max={99.99}
                    maxLength={5}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} span={24}>
                <Form.Item
                  name="rateRegion"
                  label={
                    <Typography.Text>
                      Коэффициент региона
                      <Tooltip title="Вы можете указать коэффициент региона. По умолчанию он равен 1. Если Вы укажете значение, большее 1, финальная стоимость увеличится. Если меньше 1, то стоимость уменьшится. Число должно быть больше 0">
                        <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                      </Tooltip>
                    </Typography.Text>
                  }
                  rules={getRequiredFieldRule('Поле обязательно для заполнения')}
                >
                  <InputNumber
                    controls={false}
                    size="large"
                    decimalSeparator=","
                    min={0.01}
                    max={99.99}
                    maxLength={5}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="rateTransport"
              label={
                <Typography.Text>
                  Коэффициент используемого транспорта
                  <Tooltip title="Вы можете указать коэффициент используемого транспорта. По умолчанию он равен 1. Если Вы укажете значение, большее 1, финальная стоимость увеличится. Если меньше 1, то стоимость уменьшится. Число должно быть больше 0">
                    <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                  </Tooltip>
                </Typography.Text>
              }
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <InputNumber controls={false} size="large" decimalSeparator="," min={0.01} max={99.99} maxLength={5} />
            </Form.Item>
          </Space>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditOrgOrderMarginCalcSettingsModal;

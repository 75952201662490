import { Button, InputRef, Typography } from 'antd';
import { Claim } from '../../../../../../Models/Claims';
import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { FilterOutlined } from '@ant-design/icons';
import { StringFilter, filterString } from '../../../../../../Components/Filters/StringFilter';
import { defaultDateFormat } from '../../../../../../Constants/DateFormats';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';

export const getClaimsTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
  showModal: (organisationId: number) => void,
  showClaimModal: (claimId: number) => void,
) => {
  type DataIndex = keyof Claim;
  const columns: ColumnsType<Claim> = [
    {
      key: 'userFriendlyId',
      title: '№ обращения',
      dataIndex: 'userFriendlyId',
      width: 135,
      sorter: (a, b) => a.id - b.id,
      render: (_, record) => (
        <Button type="default" onClick={() => showClaimModal(record.id)}>
          {record.userFriendlyId}
        </Button>
      ),
    },
    {
      key: 'created',
      title: 'Дата создания',
      dataIndex: 'created',
      ellipsis: true,
      sorter: (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
      render: (text) => dayjs(text).format(defaultDateFormat),
      width: 153,
    },
    {
      key: 'reason',
      title: 'Причина',
      dataIndex: 'reason',
      ellipsis: true,
      width: 206,
    },
    {
      key: 'issue',
      title: 'Проблема',
      dataIndex: 'issue',
      ellipsis: true,
      width: 265,
    },
    {
      key: 'creator',
      title: 'Инициатор',
      dataIndex: 'creator',
      width: 161,
      ellipsis: true,
      sorter: (a, b) => a.creator.localeCompare(b.creator),
      render: (_, record) => (
        <Typography.Link href={`/operator/organizations/${record.creatorOrganisationId}`} target="_blank" underline>
          {record.creator}
        </Typography.Link>
      ),
    },
    {
      key: 'organisation',
      title: 'Кому адресовано',
      dataIndex: 'organisation',
      width: 161,
      ellipsis: true,
      sorter: (a, b) => {
        if (!a.organisation) {
          return +1;
        }
        if (!b.organisation) {
          return -1;
        }
        return a.organisation.localeCompare(b.organisation);
      },
      render: (_, record) =>
        record.organisation ? (
          <Typography.Link href={`/operator/organizations/${record.organisationId}`} target="_blank" underline>
            {record.organisation}
          </Typography.Link>
        ) : (
          '—'
        ),
    },
  ];

  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
  };

  const searchInput = useRef<InputRef>(null);
  const getColumnSearchProps = (dataIndex: keyof Claim): ColumnType<Claim> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (_, record) => {
      let result = true;
      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] !== null) {
          result &&= filterString(record[key], filteredInfo[key]![0]);
        }
      });
      return result;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<Claim> = columns.map((col, index) => {
    const dataIndex = col.key! as DataIndex;
    if (dataIndex === 'userFriendlyId' || dataIndex === 'created') {
      return {
        ...col,
      };
    }
    return {
      ...col,
      ...getColumnSearchProps(dataIndex),
    };
  });

  return filterableColumns;
};

import { API } from '../../../../../Services/axios';
import { Button, Card, Col, Row, Typography } from 'antd';
import { ContractReport } from '../../../../../Models/Contract';
import { ReportStatuses } from '../../../../../Constants/ReportStatuses';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useState } from 'react';

interface CompletedWorksActFormProps {
  contractId: number;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  completedWorksActRecord: ContractReport | undefined;
  cancelledContractReport: ContractReport | undefined;
}

const CompletedWorksActCard = (props: CompletedWorksActFormProps) => {
  const { showErrorModal } = useErrorModal();
  const { setReload, contractId, completedWorksActRecord, cancelledContractReport } = props;

  // const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  // const completedWorksAct = useWatch('completedWorksAct', form);

  // const uploadProps: UploadProps = {
  //   name: 'act',
  //   accept: '.pdf,.docx,.xlsx,.txt,.rtf,.png,.jpg,.jpeg,.heic',
  //   maxCount: 1,
  //   iconRender: () => <PaperClipOutlined className="form__upload-icon" />,
  //   beforeUpload: beforeUpload,
  //   customRequest: ({ file, onSuccess }) =>
  //     setTimeout(() => {
  //       onSuccess!('ok');
  //     }, 0),
  //   showUploadList: {
  //     showRemoveIcon: true,
  //     removeIcon: <DeleteOutlined className="form__remove-icon" />,
  //   },
  // };
  //
  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const normFile = (e: any) => {
  //   return e?.fileList;
  // };
  //
  // const onFinish = (values) => {
  //   setLoading(true);
  //   const reportData = {
  //     agreementId: contractId,
  //     comment: 'Загружен акт выполненных работ, ожидает оплаты',
  //     status: ReportStatuses.WORK_DONE,
  //   };
  //
  //   const formData = new FormData();
  //   formData.append('file', values.completedWorksAct['0'].originFileObj);
  //   formData.append('json', JSON.stringify(reportData));
  //
  //   const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
  //
  //   API.post('/api/v1/report', formData, headers)
  //     .catch((error) => showErrorModal({ error }))
  //     .finally(() => {
  //       setLoading(false);
  //       setReload(true);
  //     });
  // };
  const handleClick = () => {
    const reportData = {
      agreementId: contractId,
      comment: 'Заказ выполнен, ожидает оплаты',
      status: ReportStatuses.WORK_DONE,
    };
    const formData = new FormData();
    formData.append('json', JSON.stringify(reportData));

    API.post('/api/v1/report', formData)
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(true);
      });
  };
  return (
    <>
      {!completedWorksActRecord && !cancelledContractReport && (
        <Card title={<Typography.Title level={4}>Доставка заказа</Typography.Title>}>
          <Row gutter={[0, 24]}>
            <Col span={24}>Подтвердите доставку груза. После этого статус заказа будет изменен на завершенный.</Col>

            <Col span={24}>
              <Button size="large" onClick={handleClick} loading={loading} type={'primary'}>
                Заказ доставлен
              </Button>
              {/*<Form form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>*/}
              {/*  <Form.Item*/}
              {/*    label="Акт выполненных работ"*/}
              {/*    name="completedWorksAct"*/}
              {/*    extra={*/}
              {/*      <Typography.Text type="secondary" className="form__help-text">*/}
              {/*        Для загрузки выберите файл с расширением .pdf, .docx, .xlsx, .txt, .rtf, .png, .jpg, .jpeg, .heic*/}
              {/*      </Typography.Text>*/}
              {/*    }*/}
              {/*    className="form__upload-file"*/}
              {/*    valuePropName="fileList"*/}
              {/*    getValueFromEvent={normFile}*/}
              {/*  >*/}
              {/*    <Upload {...uploadProps}>*/}
              {/*      <Button icon={<UploadOutlined />} size="large">*/}
              {/*        Загрузить*/}
              {/*      </Button>*/}
              {/*    </Upload>*/}
              {/*  </Form.Item>*/}

              {/*  <Form.Item className="offer__bottom-btns">*/}
              {/*    <Button*/}
              {/*      type="primary"*/}
              {/*      size="large"*/}
              {/*      htmlType="submit"*/}
              {/*      loading={loading}*/}
              {/*      disabled={!completedWorksAct || (completedWorksAct && completedWorksAct.length < 1)}*/}
              {/*    >*/}
              {/*      Добавить акт*/}
              {/*    </Button>*/}
              {/*  </Form.Item>*/}
              {/*</Form>*/}
            </Col>
          </Row>
        </Card>
      )}
      {/*{completedWorksActRecord && (*/}
      {/*  <Card title={<Typography.Title level={4}>Акт выполненных работ</Typography.Title>}>*/}
      {/*    <Row gutter={[0, 24]}>*/}
      {/*      <Col span={24}>*/}
      {/*        <Space direction="vertical" size={16}>*/}
      {/*          <Typography.Text type="secondary" strong>*/}
      {/*            Акт выполненных работ*/}
      {/*          </Typography.Text>*/}
      {/*          <Space align="center">*/}
      {/*            <FileOutlined className="contract__file-icon" />*/}
      {/*            <Typography.Link className="contract__info-large" href={completedWorksActRecord.reportFile[0].link}>*/}
      {/*              {completedWorksActRecord.reportFile[0].name}*/}
      {/*            </Typography.Link>*/}
      {/*          </Space>*/}
      {/*        </Space>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </Card>*/}
      {/*)}*/}
    </>
  );
};

export default CompletedWorksActCard;

import { Col, Row, Tabs, Typography } from 'antd';
import { getSettingsTabsItems } from './Component/tab-items';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

const SystemSettings = () => {
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab') ?? '1');

  const tabsItems = getSettingsTabsItems();
  return (
    <div className="settings">
      <Row className="toolbar__title" justify="space-between">
        <Col span={24}>
          <Typography.Title level={2}>Настройки системы</Typography.Title>
        </Col>
      </Row>
      <div className="orders__tabs-table">
        <Tabs
          activeKey={`${tab}`}
          className="tabs"
          items={tabsItems}
          onTabClick={(key) => {
            setTab(key);
            window.history.replaceState(null, '', `/admin/settings?tab=${key}`);
          }}
        />
      </div>
    </div>
  );
};

export default SystemSettings;

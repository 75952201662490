import { Col, Row, Typography } from 'antd';

interface CalcItemProps {
  title: string;
  value: string | number;
}

const CalcItem = (props: CalcItemProps) => {
  const { title, value } = props;
  return (
    <div className="settings__item">
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Text type="secondary" className="profile__info-large">
            {title}
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Text className="profile__info-large">{value}</Typography.Text>
        </Col>
      </Row>
    </div>
  );
};

export default CalcItem;

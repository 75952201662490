import 'dayjs/locale/ru';
import { API } from '../../../../../Services/axios';
import { Button, Checkbox, ConfigProvider, Modal, Row, Space, Typography } from 'antd';
import { Dates } from '../../../AnalyticalReport/AnalyticalReport';
import { ModalProps } from '../../../../../Models/Modal';
import { clickDownloadLink } from '../../../../../Utils/clickDownloadLink';
import { defaultDateFormat } from '../../../../../Constants/DateFormats';
import { disableFutureDates } from '../../../../../Utils/disableFutureDates';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useState } from 'react';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import ModalTitle from '../../../../../Components/Modals/ModalTitle';
import dayjs from 'dayjs';
import locale from 'antd/es/locale/ru_RU';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs().locale('ru');

const { RangePicker } = DatePicker;

const period = {
  dateFrom: dayjs.utc().subtract(30, 'days').tz('Europe/Moscow'),
  dateTo: dayjs.utc().tz('Europe/Moscow'),
};

const ExportStaticticsModal = (props: ModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [newDates, setNewDates] = useState<Dates>(period);
  const [addOrdersHistory, setAddOrdersHistory] = useState<boolean>(true);
  const [addProfileHistory, setAddProfileHistory] = useState<boolean>(true);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  const stringDateFrom = newDates.dateFrom.toISOString();
  const stringDateTo = newDates.dateTo.toISOString();

  const onChange = (values) => {
    if (values) {
      const period = {
        dateFrom: values[0],
        dateTo: values[1],
      };

      setNewDates(period);
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };

  const onFinish = () => {
    setLoading(true);
    if (addOrdersHistory) {
      API.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/calculation-order-history-csv/${stringDateFrom}/${stringDateTo}`,
        {
          responseType: 'blob',
        },
      )
        .then((response) => {
          const blob = response.data;
          clickDownloadLink(blob, `VerumLogistics_история_по_расчетам_в_заказах_${dayjs().format('YYYY_MM_DD')}.csv`);
        })
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
    if (addProfileHistory) {
      API.get(
        `${process.env.REACT_APP_API_URL}/api/v1/admin/calculation-profile-history-csv/${stringDateFrom}/${stringDateTo}`,
        {
          responseType: 'blob',
        },
      )
        .then((response) => {
          const blob = response.data;
          clickDownloadLink(blob, `VerumLogistics_история_по_расчетам_в_профиле_${dayjs().format('YYYY_MM_DD')}.csv`);
        })
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Экспорт статистики" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32} style={{ width: '100%' }}>
        <Space direction="vertical" size={10}>
          <Typography.Text strong type="secondary" className="profile__info-large">
            Диапазон дат
          </Typography.Text>
          <ConfigProvider locale={locale}>
            <RangePicker
              size="large"
              defaultValue={[newDates.dateFrom, newDates.dateTo]}
              format={defaultDateFormat}
              onChange={onChange}
              disabledDate={(current) => disableFutureDates(current)}
            />
          </ConfigProvider>
        </Space>

        <Space size={24} direction="vertical" className="settings__checkboxes">
          <Space size={8} align="start">
            <Checkbox defaultChecked={addOrdersHistory} onChange={(e) => setAddOrdersHistory(e.target.checked)} />
            <Typography.Text className="profile__info-large">История по расчетам в заказах</Typography.Text>
          </Space>

          <Space size={8} align="start" className="settings__checkboxes">
            <Checkbox defaultChecked={addProfileHistory} onChange={(e) => setAddProfileHistory(e.target.checked)} />
            <Typography.Text className="profile__info-large">История по расчетам в профиле</Typography.Text>
          </Space>
        </Space>

        <Row justify="end">
          <Button size="large" className="modal__cancel-btn" onClick={handleCancel}>
            Отменить
          </Button>
          <Button
            type="primary"
            size="large"
            form="form"
            htmlType="submit"
            onClick={onFinish}
            loading={loading}
            disabled={(!addOrdersHistory && !addProfileHistory) || disableBtn}
          >
            Выгрузить
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default ExportStaticticsModal;

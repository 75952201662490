import { Button, Result, Typography } from 'antd';
import { Roles } from '../../Constants/Profile';
import { useAppSelector } from '../../Store/hooks';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const role = useAppSelector((state) => state.user.role);
  const navigate = useNavigate();

  const navigateBack = () => {
    if (role) {
      switch (role) {
        case Roles.CUSTOMER:
          return navigate('/customer/orders');
        case Roles.DELIVERY:
          return navigate('/organizer/orders');
        case Roles.OPERATOR:
          return navigate('/operator/organizations');
      }
    } else navigate('/');
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle={<Typography.Text className="app__text-large">Запрашиваемая страница не найдена</Typography.Text>}
      extra={
        <Button type="primary" onClick={navigateBack} size="large">
          Вернуться на главную
        </Button>
      }
    />
  );
};

export default PageNotFound;

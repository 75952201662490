import { Rule } from 'antd/lib/form';
import { getRequiredFieldRule } from './order';

export const getPriceRule = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator(_, value) {
      const numberValue = Number(`${value}`.replaceAll(' ', ''));
      if (!value || +numberValue >= 1) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Ваше предложение по стоимости не может быть равным 0'));
    },
  },
];

export const getDueDatePeriodRule = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator(_, value) {
      if (!value || +value >= 1) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Минимальный срок - 1 день'));
    },
  },
];

export const getRelevancePeriodRule = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator(_, value) {
      if (!value || +value >= 14) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Минимальный срок - 14 дней'));
    },
  },
];

import { Profile } from '../../../../../../Models/Profile';
import { TablePaginationConfig, TableProps } from 'antd';
import { useState } from 'react';
import type { FilterValue } from 'antd/lib/table/interface';

export const getUsersTableFilter = () => {
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>();
  const handleFilter: TableProps<Profile>['onChange'] = (pagination, filters) => {
    setFilteredInfo(filters);
    setPaginationInfo(pagination);
  };

  return {
    filteredInfo,
    setFilteredInfo,
    handleFilter,
    paginationInfo,
    setPaginationInfo,
  };
};

const parsedOrdersWidth = JSON.parse(localStorage.getItem('orgOrders')!);

export const orgOrdersColsWidth = JSON.stringify({
  userFriendlyId: 120,
  cargoTypeNameWithParentName: parsedOrdersWidth ? parsedOrdersWidth.cargoTypeNameWithParentName : 300,
  cityFrom: parsedOrdersWidth ? parsedOrdersWidth.cityFrom : 220,
  cityTo: parsedOrdersWidth ? parsedOrdersWidth.cityTo : 220,
  distance: parsedOrdersWidth ? parsedOrdersWidth.distance : 155,
  dateArrival: parsedOrdersWidth ? parsedOrdersWidth.dateArrival : 145,
  price: parsedOrdersWidth ? parsedOrdersWidth.price : 143,
  machineName: parsedOrdersWidth ? parsedOrdersWidth.machineName : 235,
  machinePrice: parsedOrdersWidth ? parsedOrdersWidth.machinePrice : 155,
  dateLoading: parsedOrdersWidth ? (parsedOrdersWidth.dateLoading ? parsedOrdersWidth.dateLoading : 135) : 135,
  acceptedAgreementUserFriendlyId: parsedOrdersWidth
    ? parsedOrdersWidth.acceptedAgreementUserFriendlyId
      ? parsedOrdersWidth.acceptedAgreementUserFriendlyId
      : 175
    : 175,
  organisationName: parsedOrdersWidth
    ? parsedOrdersWidth.organisationName
      ? parsedOrdersWidth.organisationName
      : 145
    : 145,
  weight: parsedOrdersWidth ? parsedOrdersWidth.weight : 104,
  length: parsedOrdersWidth ? parsedOrdersWidth.length : 115,
  width: parsedOrdersWidth ? parsedOrdersWidth.width : 125,
  height: parsedOrdersWidth ? parsedOrdersWidth.height : 123,
  comment: parsedOrdersWidth ? parsedOrdersWidth.comment : 322,
  createdAt: parsedOrdersWidth ? parsedOrdersWidth.createdAt : 165,
});

const parsedContractsWidth = JSON.parse(localStorage.getItem('orgContracts')!);

export const orgContractsColsWidth = JSON.stringify({
  agreementId: 120,
  cityFrom: parsedContractsWidth ? parsedContractsWidth.cityFrom : 174,
  cityTo: parsedContractsWidth ? parsedContractsWidth.cityTo : 174,
  arrival: parsedContractsWidth ? parsedContractsWidth.arrival : 165,
  price: parsedContractsWidth ? parsedContractsWidth.price : 148,
  orderUserFriendlyId: parsedOrdersWidth
    ? parsedOrdersWidth.orderUserFriendlyId
      ? parsedOrdersWidth.orderUserFriendlyId
      : 125
    : 125,
  cargoTypeNameWithParentName: parsedContractsWidth ? parsedContractsWidth.cargoTypeNameWithParentName : 237,
  lastReportComment: parsedContractsWidth ? parsedContractsWidth.lastReportComment : 322,
  lastReport: parsedContractsWidth ? parsedContractsWidth.lastReport : 170,
  title: parsedContractsWidth ? parsedContractsWidth.title : 187,
  distance: parsedContractsWidth ? parsedContractsWidth.distance : 155,
  organisationName: parsedContractsWidth ? parsedContractsWidth.organisationName : 232,
  machineName: parsedContractsWidth ? parsedContractsWidth.machineName : 235,
  machinePrice: parsedContractsWidth ? parsedContractsWidth.machinePrice : 155,
  comments: parsedContractsWidth ? parsedContractsWidth.comments : 322,
  weight: parsedContractsWidth ? parsedContractsWidth.weight : 104,
  length: parsedContractsWidth ? parsedContractsWidth.length : 110,
  width: parsedContractsWidth ? parsedContractsWidth.width : 121,
  height: parsedContractsWidth ? parsedContractsWidth.height : 113,
});

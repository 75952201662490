// Declines word 'день' depending on its quantity
export const getDayDeclention = (number: number) => {
  let day = 'дней';

  const remainder = number % 10;
  if (number > 10 && number < 20) {
    day = 'дней';
  } else if (remainder === 1) {
    day = 'день';
  } else if (remainder > 1 && remainder < 5) {
    day = 'дня';
  } else if (number === 0) {
    return 'сегодня';
  }
  return `${number} ${day}`;
};

import { Col, Row, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface LabelWithTooltipProps {
  label: string;
  message: string;
}

const LabelWithTooltip = (props: LabelWithTooltipProps) => {
  const { label, message } = props;
  return (
    <Row align="middle" gutter={[8, 0]}>
      <Col>
        <label>{label}</label>
      </Col>
      <Col>
        <Tooltip title={message}>
          <QuestionCircleOutlined />
        </Tooltip>
      </Col>
    </Row>
  );
};

export default LabelWithTooltip;

export const orgContractColumnsList = [
  {
    key: 'cityFrom',
    title: 'Город отправки',
  },
  {
    key: 'cityTo',
    title: 'Город доставки',
  },
  {
    key: 'arrival',
    title: 'Дата доставки',
  },
  {
    key: 'price',
    title: 'Стоимость',
  },
  {
    key: 'orderUserFriendlyId',
    title: 'Заказ',
  },
  {
    title: 'Категория техники',
    key: 'cargoTypeNameWithParentName',
  },
  {
    key: 'lastReportComment',
    title: 'Текст последнего отчета',
  },
  {
    key: 'lastReport',
    title: 'Последний отчёт',
  },
  {
    key: 'title',
    title: 'Название заказа',
  },
  {
    key: 'machineName',
    title: 'Модель техники',
  },
  {
    key: 'distance',
    title: 'Расстояние',
  },
  {
    key: 'organisationName',
    title: 'Заказчик',
  },
  {
    key: 'machinePrice',
    title: 'Стоимость техники',
  },
  {
    key: 'comments',
    title: 'Комментарий',
  },
  {
    key: 'weight',
    title: 'Вес',
  },
  {
    key: 'length',
    title: 'Длина',
  },
  {
    key: 'width',
    title: 'Ширина',
  },
  {
    key: 'height',
    title: 'Высота',
  },
];

export const customerContractColumnsList = [
  {
    key: 'cityFrom',
    title: 'Город отправки',
  },
  {
    key: 'cityTo',
    title: 'Город доставки',
  },
  {
    key: 'arrival',
    title: 'Дата доставки',
  },
  {
    key: 'price',
    title: 'Стоимость',
  },
  {
    key: 'orderUserFriendlyId',
    title: 'Заказ',
  },
  {
    key: 'organisationName',
    title: 'Организатор',
  },
  {
    key: 'lastReportComment',
    title: 'Текст последнего отчета',
  },
  {
    key: 'lastReport',
    title: 'Последний отчёт',
  },
  {
    key: 'title',
    title: 'Название заказа',
  },
  {
    title: 'Категория техники',
    key: 'cargoTypeNameWithParentName',
  },
  {
    key: 'machineName',
    title: 'Модель техники',
  },
  {
    key: 'distance',
    title: 'Расстояние',
  },
  {
    key: 'comments',
    title: 'Комментарий',
  },
  {
    key: 'machinePrice',
    title: 'Стоимость техники',
  },
];

import { ClaimsProps } from '../../../../Models/Claims';
import { Table } from 'antd';
import { getClaimsTableColumns } from './Component/columns';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { updateClaimId } from '../../../../Store/Slices/claimIdSlice';
import { updateOrganisationId } from '../../../../Store/Slices/organizationProfileModalSlice';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { useState } from 'react';
import ClaimInfoModal from '../../../Modals/ClaimInfoModal';
import OrganizaionInfoModal from '../../../../Components/Modals/OrganizationInfoModal';

const ClaimsTable = (props: ClaimsProps) => {
  const { loading, claims } = props;
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = (organisationId: number) => {
    dispatch(updateOrganisationId(organisationId));
    setOpenModal(true);
  };
  const hideModal = () => setOpenModal(false);

  const [openClaimModal, setOpenClaimModal] = useState<boolean>(false);
  const showClaimModal = (claimId: number) => {
    dispatch(updateClaimId(claimId));
    setOpenClaimModal(true);
  };
  const hideClaimModal = () => {
    setOpenClaimModal(false);
    dispatch(updateClaimId(null));
  };

  const columns = getClaimsTableColumns(showModal, showClaimModal, role);

  return (
    <>
      <Table
        bordered
        rowKey={'id'}
        className="table__organizations"
        scroll={{ x: 915 }}
        loading={loading}
        columns={columns}
        dataSource={claims}
        locale={tableLocaleSettings}
        pagination={tablePagingSettings}
      />
      <OrganizaionInfoModal title="Данные об организации" open={openModal} handleCancel={hideModal} />
      <ClaimInfoModal open={openClaimModal} handleCancel={hideClaimModal} />
    </>
  );
};

export default ClaimsTable;

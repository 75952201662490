import { LoadingOutlined } from '@ant-design/icons';
import { Row } from 'antd';

const Loader = () => (
  <Row justify="center" className="loader">
    <LoadingOutlined className="loader__icon" />
  </Row>
);

export default Loader;

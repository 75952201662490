export const customerActionsClaim = [
  {
    value: 'Заказчик не выходит на связь',
    label: 'Заказчик не выходит на связь',
  },
  {
    value: 'Нарушены условия договора',
    label: 'Нарушены условия договора',
  },
  {
    value: 'Документы не предоставлены в установленный срок',
    label: 'Документы не предоставлены в установленный срок',
  },
  {
    value: 'Не предоставлен оригинал договора',
    label: 'Не предоставлен оригинал договора',
  },
  {
    value: 'Полная или частичная неоплата услуг перевозки',
    label: 'Полная или частичная неоплата услуг перевозки',
  },
  {
    value: 'Предоплата',
    label: 'Предоплата',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];

export const deliveryActionsClaim = [
  {
    value: 'Организатор не выходит на связь',
    label: 'Организатор не выходит на связь',
  },
  {
    value: 'Документы не предоставлены в установленный срок',
    label: 'Документы не предоставлены в установленный срок',
  },
  {
    value: 'Оригинал договора отличается от проекта договора',
    label: 'Оригинал договора отличается от проекта договора',
  },
  {
    value: 'Организатор отправляет спам',
    label: 'Организатор отправляет спам',
  },
  {
    value: 'Срыв погрузки',
    label: 'Срыв погрузки',
  },
  {
    value: 'Опоздания на погрузку/выгрузку',
    label: 'Опоздания на погрузку/выгрузку',
  },
  {
    value: 'Простой на погрузке/выгрузке',
    label: 'Простой на погрузке/выгрузке',
  },
  {
    value: 'Нарушены условия доставки',
    label: 'Нарушены условия доставки',
  },
  {
    value: 'Утрата, порча или недостача имущества',
    label: 'Утрата, порча или недостача имущества',
  },
  {
    value: 'Хищение груза',
    label: 'Хищение груза',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];

export const systemProblemOrgClaim = [
  {
    value: 'Не вижу заказ, на который отправлено предложение',
    label: 'Не вижу заказ, на который отправлено предложение',
  },
  {
    value: 'Не вижу договор',
    label: 'Не вижу договор',
  },
  {
    value: 'Проблема с работой площадки Логистика',
    label: 'Проблема с работой площадки Логистика',
  },
  {
    value: 'Подозрительный заказ в системе',
    label: 'Подозрительный заказ в системе',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];

export const systemProblemCustomerClaim = [
  {
    value: 'Не вижу свой заказ',
    label: 'Не вижу свой заказ',
  },
  {
    value: 'Не вижу свой договор',
    label: 'Не вижу свой договор',
  },
  {
    value: 'Проблема с работой площадки Логистика',
    label: 'Проблема с работой площадки Логистика',
  },
  {
    value: 'Проблема с предложением к моему заказу',
    label: 'Проблема с предложением к моему заказу',
  },
  {
    value: 'Другое',
    label: 'Другое',
  },
];

import { Button, Col, Row, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateOpratorModal from '../../../../Components/Modals/CreateOperatorModal';
import React, { useState } from 'react';

const Toolbar: React.FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpen = () => setOpenModal(true);
  const handleCancel = () => setOpenModal(false);

  return (
    <Row className="toolbar__title" justify="space-between">
      <Col>
        <Typography.Title level={2}>Операторы</Typography.Title>
      </Col>
      <Col className="toolbar__btns-panel">
        <Button size={'large'} type="primary" onClick={() => handleOpen()} icon={<PlusOutlined />}>
          Создать оператора
        </Button>
      </Col>
      <CreateOpratorModal open={openModal} handleCancel={handleCancel} />
    </Row>
  );
};

export default Toolbar;

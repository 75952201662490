import { Badge } from 'antd';
import { ReactNode } from 'react';

interface BlockedRibbonProps {
  isOffer: boolean;
  children: ReactNode;
}

const BlockedRibbon = (props: BlockedRibbonProps) => {
  const { isOffer, children } = props;

  return (
    <div className={isOffer ? 'offer__blocked-ribbon' : 'profile__blocked-ribbon'}>
      <Badge.Ribbon text="Организация временно заблокирована" color="red">
        {children}
      </Badge.Ribbon>
    </div>
  );
};

export default BlockedRibbon;

import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { Order } from '../../Models/Order';
import { convertToMscTime } from '../../Utils/convertToMscTime';
import { defaultDateFormat } from '../../Constants/DateFormats';
import { formatPrice } from '../../Utils/formatPrice';
import { getOrderStatus } from '../../Utils/getOrderStatus';
import dayjs from 'dayjs';

const parsedWidth = JSON.parse(localStorage.getItem('customerOrders')!);

export const initialColumns: ColumnsType<Order> = [
  {
    key: 'userFriendlyId',
    title: '№',
    dataIndex: ['userFriendlyId', 'id'],
    fixed: 'left',
    width: parsedWidth ? parsedWidth.userFriendlyId : 120,
    sorter: true,
  },
  {
    key: 'title',
    title: 'Название заказа',
    dataIndex: 'title',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.title : 170,
  },
  {
    key: 'cityFrom',
    title: 'Город отправки',
    dataIndex: 'cityFrom',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.cityFrom : 160,
  },
  {
    key: 'cityTo',
    title: 'Город доставки',
    dataIndex: 'cityTo',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.cityTo : 160,
  },
  {
    key: 'dateArrival',
    title: 'Дата доставки',
    dataIndex: 'dateArrival',
    ellipsis: true,
    sorter: true,
    render: (text) => dayjs(text).format(defaultDateFormat),
    width: parsedWidth ? parsedWidth.dateArrival : 135,
  },
  {
    key: 'price',
    title: 'Расчет',
    dataIndex: 'price',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${formatPrice(+record.price)}  ₽`,
    width: parsedWidth ? parsedWidth.price : 120,
  },
  {
    key: 'offersCount',
    title: 'Предложения',
    dataIndex: 'offersCount',
    align: 'right',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.offersCount : 169,
  },
  {
    key: 'agreementsCount',
    title: 'Проекты договоров',
    dataIndex: 'agreementsCount',
    align: 'right',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.agreementsCount : 150,
  },
  {
    key: 'acceptedAgreementUserFriendlyId',
    title: 'Договор',
    dataIndex: ['acceptedAgreementId', 'acceptedAgreementUserFriendlyId'],
    ellipsis: true,
    sorter: true,
    render: (_, record) =>
      record.acceptedAgreementId === null || record.acceptedAgreementId === undefined ? (
        '—'
      ) : (
        <Link to={`/customer/contracts/${record.acceptedAgreementId}`}>
          <Button type="default">{record.acceptedAgreementUserFriendlyId}</Button>
        </Link>
      ),
    width: parsedWidth ? parsedWidth.acceptedAgreementUserFriendlyId : 129,
  },
  {
    key: 'cargoTypeNameWithParentName',
    title: 'Категория техники',
    dataIndex: 'cargoTypeNameWithParentName',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.cargoTypeNameWithParentName : 322,
  },
  {
    key: 'machineName',
    title: 'Модель техники',
    ellipsis: true,
    dataIndex: 'machineName',
    sorter: true,
    width: parsedWidth ? parsedWidth.machineName : 236,
  },
  {
    key: 'machinePrice',
    title: 'Стоимость техники',
    dataIndex: 'machinePrice',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${formatPrice(+record.machinePrice)} ₽`,
    width: parsedWidth ? parsedWidth.machinePrice : 155,
  },
  {
    key: 'status',
    title: 'Статус',
    dataIndex: 'status',
    ellipsis: true,
    render: (_, record) => getOrderStatus(record),
    width: parsedWidth && parsedWidth.status ? parsedWidth.status : 155,
  },
  {
    key: 'dateLoading',
    title: 'Дата погрузки техники',
    dataIndex: 'dateLoading',
    ellipsis: true,
    sorter: true,
    render: (text) => dayjs(text).format(defaultDateFormat),
    width: parsedWidth && parsedWidth.dateLoading ? parsedWidth.dateLoading : 135,
  },
  {
    key: 'weight',
    title: 'Вес',
    dataIndex: 'weight',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${record.weight} кг`,
    width: parsedWidth ? parsedWidth.weight : 104,
  },
  {
    key: 'length',
    title: 'Длина',
    dataIndex: 'length',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${record.length} м`,
    width: parsedWidth ? parsedWidth.length : 115,
  },
  {
    key: 'width',
    title: 'Ширина',
    dataIndex: 'width',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${record.width} м`,
    width: parsedWidth ? parsedWidth.width : 125,
  },
  {
    key: 'height',
    title: 'Высота',
    dataIndex: 'height',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${record.height} м`,
    width: parsedWidth ? parsedWidth.height : 125,
  },
  {
    key: 'distance',
    title: 'Расстояние',
    dataIndex: 'distance',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (text) => `${text} км`,
    width: parsedWidth ? parsedWidth.distance : 155,
  },
  {
    key: 'comment',
    title: 'Комментарий',
    dataIndex: 'comment',
    ellipsis: true,
    sorter: true,
    render: (text) => (text ? text : '—'),
    width: parsedWidth ? parsedWidth.comment : 322,
  },
  {
    key: 'createdAt',
    title: 'Дата создания',
    dataIndex: 'createdAt',
    ellipsis: true,
    sorter: true,
    render: (text) => `${convertToMscTime(text)} мск`,
    width: parsedWidth ? parsedWidth.createdAt : 165,
  },
];

import { Col, Image, Space, Typography } from 'antd';
import { EyeOutlined, FileOutlined } from '@ant-design/icons';
import { File } from '../../Models/Files';

interface OrderAttachmentsProps {
  photos: File[];
  files: File[];
  comment?: string | null;
}

const OrderAttachments = (props: OrderAttachmentsProps) => {
  const { photos, files, comment } = props;

  return (
    <>
      {files.length > 0 && (
        <Col span={24}>
          <Space direction="vertical">
            <Typography.Text className="order__info" type="secondary" strong>
              Дополнительные файлы
            </Typography.Text>
            {files.map((file) => (
              <div key={file.id} className="order__attachments-row">
                <div className="order__attachments-icon">
                  <FileOutlined />
                </div>
                <div className="order__attachments-desc">
                  <Typography.Link className="order__attachments-doc" href={file.link}>
                    {file.name}
                  </Typography.Link>
                </div>
              </div>
            ))}
          </Space>
        </Col>
      )}

      {photos.length > 0 && (
        <Col span={24}>
          <Space direction="vertical">
            <Typography.Text className="order__info" type="secondary" strong>
              Фотографии техники
            </Typography.Text>
            <Image.PreviewGroup>
              <Space wrap>
                {photos.map((photo) => (
                  <Image
                    preview={{
                      mask: (
                        <Space size={4}>
                          <EyeOutlined />
                          <span>Просмотр</span>
                        </Space>
                      ),
                    }}
                    key={photo.id}
                    width={100}
                    src={photo.link}
                  />
                ))}
              </Space>
            </Image.PreviewGroup>
          </Space>
        </Col>
      )}

      {comment && (
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="order__info" type="secondary" strong>
              Комментарий заказчика
            </Typography.Text>
            <Typography.Paragraph className="order__info-large" ellipsis={{ rows: 3, tooltip: true }}>
              {comment}
            </Typography.Paragraph>
          </Space>
        </Col>
      )}
    </>
  );
};

export default OrderAttachments;

import { API } from '../../../../../Services/axios';
import { AtiRaiting } from '../../../../../Models/Ati';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { getPointsDeclention } from '../../../../../Utils/pointsDeclention';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import Loader from '../../../../../Components/Loader/Loader';

interface AtiCardProps {
  inn: string;
}

const AtiCard = (props: AtiCardProps) => {
  const { showErrorModal } = useErrorModal();
  const { inn } = props;

  const [atiData, setAtiData] = useState<AtiRaiting | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    API.get(`/api/v1/profile/organisation/ati/${inn}`)
      .then((response) => setAtiData(response.data))
      .catch((error) => {
        setAtiData(undefined);
        //showErrorModal({error});
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title level={4}>Рейтинг ATI.SU</Typography.Title>
      </Col>
      {atiData && (
        <Col>
          <Typography.Link onClick={() => setIsOpened(!isOpened)} underline>
            {isOpened ? 'Скрыть детали' : 'Показать детали'}
          </Typography.Link>
        </Col>
      )}
    </Row>
  );

  return (
    <Card title={cardTitle}>
      {atiData ? (
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <div className="operator__ati-raiting">
              <StarFilled />
              <Typography.Title level={4}>{getPointsDeclention(atiData.score)}</Typography.Title>
            </div>
          </Col>
          {isOpened && (
            <>
              {atiData.points && (
                <Col span={24} className="operator__ati-raiting">
                  <Space direction="vertical" size={16}>
                    {atiData.points
                      .filter((point) => !point.title.includes('Сумма начисленных баллов'))
                      .map((point) => (
                        <Row key={point.type} wrap={false} gutter={[16, 0]} align="middle">
                          <Col flex="80px">
                            <div className="operator__ati-point">{point.value}</div>
                          </Col>
                          <Col flex="auto">
                            <Typography.Text>{point.title}</Typography.Text>
                          </Col>
                        </Row>
                      ))}
                  </Space>
                </Col>
              )}
              <Col span={24} className="operator__ati-raiting">
                <Typography.Link href={atiData.profileLink} target="_blank" style={{ width: '100%' }}>
                  <Button type="primary" className="operator__ati-btn">
                    Посмотреть профиль на ATI.SU
                  </Button>
                </Typography.Link>
              </Col>
            </>
          )}
        </Row>
      ) : (
        <Typography.Text className="profile__info-large">Рейтинг на ATI.SU не найден</Typography.Text>
      )}
    </Card>
  );
};

export default AtiCard;

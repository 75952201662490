// Declines word 'балл' depending on its quantity
export const getPointsDeclention = (number: number) => {
  let points = 'баллов';

  const rounded = Math.abs(number) % 100;
  const amount = rounded % 10;
  if (amount === 1) {
    points = 'балл';
  } else if (amount > 1 && amount < 5) {
    points = 'балла';
  } else if (number === 0) {
    return 'баллов';
  }
  return `${number} ${points}`;
};

import { API } from '../../../../../Services/axios';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { OfferType } from '../../../../../Models/Offer';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getDayDeclention } from '../../../../../Utils/dayDeclension';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';

interface AgreementFormProps {
  offerId: number;
  offerData: OfferType;
  setAgreementId: Dispatch<SetStateAction<number | undefined>>;
}

const AgreementForm = (props: AgreementFormProps) => {
  const { showErrorModal } = useErrorModal();
  const { offerId, offerData, setAgreementId } = props;
  const [form] = useForm();
  // const [isFormEdited, setIsFormEdited] = useState(false);
  const [loading, setLoading] = useState(false);

  // const uploadProps: UploadProps = {
  //   name: 'contractDraft',
  //   accept: '.pdf,.png,.jpg,.jpeg',
  //   multiple: false,
  //   iconRender: () => <PaperClipOutlined className="form__upload-icon" />,
  //   beforeUpload: beforeUploadAgreement,
  //   customRequest: ({ file, onSuccess }) =>
  //     setTimeout(() => {
  //       onSuccess!('ok');
  //     }, 0),
  //   showUploadList: {
  //     showRemoveIcon: true,
  //     removeIcon: <DeleteOutlined className="form__remove-icon" />,
  //   },
  // };
  //
  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const normFile = (e: any) => {
  //   return e?.fileList;
  // };
  //
  const onReset = () => form.resetFields();

  const onFinish = () => {
    setLoading(true);
    const agreementData = {
      offerId: offerId,
      comment: '',
    };

    const formData = new FormData();
    // formData.append('file', values.contractDraft['0'].originFileObj);
    formData.append('json', JSON.stringify(agreementData));
    //
    // const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    API.post('/api/v1/agreement', formData)
      .then((response) => setAgreementId(response.data.id))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  const validityText = (
    <Typography.Text>
      Актуально {offerData.offerValidityTime > 0 && 'еще '}
      {<strong>{getDayDeclention(offerData.offerValidityTime)}</strong>}
    </Typography.Text>
  );

  return (
    <Row gutter={[0, 64]}>
      <Col span={24}>
        <Row gutter={[0, 32]}>
          <Col span={22} offset={1}>
            Заказчик сравнивает предложения перевозчиков.
          </Col>
          <Button type="primary" size="large" onClick={onFinish} loading={loading}>
            Отправить
          </Button>
          <Button size="large" onClick={onReset}>
            Отменить
          </Button>

          {/*<Col span={22} offset={1}>*/}
          {/*  <Typography.Title level={4}>Проект договора</Typography.Title>*/}
          {/*</Col>*/}
          {/*<Col span={22} offset={1}>*/}
          {/*  <Form*/}
          {/*    form={form}*/}
          {/*    colon={false}*/}
          {/*    layout="vertical"*/}
          {/*    className="form"*/}
          {/*    onValuesChange={() => setIsFormEdited(true)}*/}
          {/*    onFinish={onFinish}*/}
          {/*  >*/}
          {/*    <Form.Item*/}
          {/*      label="Проект договора"*/}
          {/*      name="contractDraft"*/}
          {/*      rules={getRequiredFieldRule('Прикрепите проект договора')}*/}
          {/*      className="form__upload-file"*/}
          {/*      extra={*/}
          {/*        <Typography.Text type="secondary" className="form__help-text">*/}
          {/*          Для загрузки выберите файл с расширением .pdf, .png, .jpg, .jpeg.*/}
          {/*        </Typography.Text>*/}
          {/*      }*/}
          {/*      valuePropName="fileList"*/}
          {/*      getValueFromEvent={normFile}*/}
          {/*    >*/}
          {/*      <Upload {...uploadProps}>*/}
          {/*        <Button icon={<UploadOutlined />} size="large">*/}
          {/*          Загрузить*/}
          {/*        </Button>*/}
          {/*      </Upload>*/}
          {/*    </Form.Item>*/}

          {/*    <Form.Item label="Комментарий к предложению (увидит только заказчик)" name="comment">*/}
          {/*      <TextArea size="large" maxLength={200} showCount className="form__comment" />*/}
          {/*    </Form.Item>*/}

          {/*    <Form.Item className="offer__bottom-btns">*/}
          {/*      <Button type="primary" size="large" htmlType="submit" loading={loading}>*/}
          {/*        Отправить*/}
          {/*      </Button>*/}
          {/*      {isFormEdited && (*/}
          {/*        <Button size="large" onClick={onReset}>*/}
          {/*          Отменить*/}
          {/*        </Button>*/}
          {/*      )}*/}
          {/*    </Form.Item>*/}
          {/*  </Form>*/}
          {/*</Col>*/}
        </Row>{' '}
      </Col>
      <Col span={24}>
        <Card className="offer__full-width">
          <Row>
            <Col span={24} className="offer__title">
              <Row justify="space-between" align="middle">
                <Typography.Title level={4}>Ваше предложение</Typography.Title>
                {validityText}
              </Row>
            </Col>
            <Col>
              <Space direction="vertical" className="offer__info" size={8}>
                <Typography.Text type="secondary" strong>
                  Ваше предложение по стоимости с НДС
                </Typography.Text>
                {offerData?.price && (
                  <Typography.Text className="offer__text">{formatPrice(+offerData.price)} &#8381;</Typography.Text>
                )}
              </Space>
              <Space direction="vertical" size={8}>
                <Typography.Text type="secondary" strong>
                  Срок исполнения в календарных днях
                </Typography.Text>
                <Typography.Text className="offer__text">{offerData.dueDatePeriod}</Typography.Text>
              </Space>
              {offerData?.comment && (
                <Space direction="vertical" className="offer__comment" size={8}>
                  <Typography.Text type="secondary" strong>
                    Комментарий к предложению
                  </Typography.Text>
                  <Typography.Text className="offer__text">{offerData.comment}</Typography.Text>
                </Space>
              )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default AgreementForm;

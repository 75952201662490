import { Button, Modal, Row, Space, Typography } from 'antd';
import { Root, createRoot } from 'react-dom/client';
import { useState } from 'react';
import ModalTitle from './ModalTitle';

interface ErrorModalProps {
  isOpen: boolean;
  title: string;
  message: string;
}

export const useErrorModal = () => {
  const [root, setRoot] = useState<Root | null>(null);
  const ErrorModal = (props: ErrorModalProps) => {
    const { isOpen, title, message } = props;
    const [open, setOpen] = useState(isOpen);
    return (
      <Modal
        open={open}
        title={
          <ModalTitle
            level={3}
            title={title}
            handleCancel={() => {
              setOpen(false);
            }}
          />
        }
        width={655}
        closable={false}
        footer={null}
        centered
        className="modal"
      >
        <Space direction="vertical" size={24}>
          <Row>
            <Typography.Text>{message}</Typography.Text>
          </Row>
          <Row>
            <Button size="large" className="modal__cancel-btn" onClick={() => setOpen(false)}>
              Закрыть
            </Button>
          </Row>
        </Space>
      </Modal>
    );
  };

  const showErrorModal = ({
    title = 'Сервис временно недоступен',
    message = 'Произошла внутренняя ошибка. Обновите страницу или попробуйте повторить действие позже.',
    error = '',
  }) => {
    if (error.toString().toLocaleLowerCase().includes('access is denied')) {
      title = 'Нет доступа';
      message = 'В доступе к запрашиваемому ресурсу отказано.';
    }
    const element = document.getElementById('errorModal')!;
    if (error.toString().toLocaleLowerCase().includes('unauthorized')) {
      return;
    }
    if (!root) {
      const modal = createRoot(element);
      setRoot(modal);
      modal.render(<ErrorModal isOpen={true} title={title} message={message} />);
    } else {
      root.render(<ErrorModal isOpen={true} title={title} message={message} />);
    }
  };

  return { showErrorModal };
};

import { API } from '../../../../Services/axios';
import { Card, Col, Row, Space, Typography } from 'antd';
import { ChangeHistoryEvents } from '../../../../Constants/Operator';
import { HistoryItem } from '../../../../Models/Operator';
import { Profile } from '../../../../Models/Profile';
import { getMaskedPhoneNumberInOrder } from '../../../../Utils/getPhoneMasked';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import AdminActions from '../../Users/Detail/Component/admin-actions';
import ChangeHistory from '../../Users/Detail/Component/change-history';
import Loader from '../../../../Components/Loader/Loader';
import OperatorProfileHeader from './Component/operator-header';
import ProfileItem from '../../../../Components/Profile/ProfileItem';

const OperatorProfile = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();

  const [profileData, setProfileData] = useState<Profile>();
  const [auditData, setAuditData] = useState<HistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [auditLoading, setAuditLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setAuditLoading(true);
    API.get(`/api/v1/profile/${id}`)
      .then((response) => setProfileData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
    API.get(`/api/v1/admin/user/profile-audit/${id}`)
      .then((response) => setAuditData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setAuditLoading(false));
  }, [id]);

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title level={3}>Данные пользователя</Typography.Title>
      </Col>
    </Row>
  );

  const isOperatorLogged = !!auditData.find((item) => item.event === ChangeHistoryEvents.CREATED);

  if (!profileData || loading) return <Loader />;

  return (profileData && (
    <Row gutter={[0, 32]} className="operator operator__full-width" justify="space-between">
      <Col span={24}>
        <OperatorProfileHeader />
      </Col>
      <Col lg={{ span: 16, order: 0 }} span={24} order={1} className="operator__main-col">
        <Card title={cardTitle}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <ProfileItem title="Фамилия" value={profileData.surname} />
                <ProfileItem title="Имя" value={profileData.name} />
                <ProfileItem title="Отчество" value={profileData.patronymic ?? '—'} />
                <ProfileItem title="Телефон" value={getMaskedPhoneNumberInOrder(profileData.phone)} />
                <ProfileItem title="Электронная почта" value={profileData.email} />
              </Space>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col lg={{ span: 7 }} span={24} className="operator__action-col">
        <Row gutter={[0, 16]} className="operator__full-width">
          <AdminActions enabled={profileData.enabled} profileData={profileData} isOperatorLogged={isOperatorLogged} />
          {auditLoading ? <Loader /> : <ChangeHistory history={auditData} role={profileData.type} />}
        </Row>
      </Col>
    </Row>
  ))!;
};

export default OperatorProfile;

import { API } from '../../../../../Services/axios';
import { Button, Col, Form, Input, Modal, Space } from 'antd';
import { NumericFormat } from 'react-number-format';
import { OfferFormType, OfferType } from '../../../../../Models/Offer';
import { getDueDatePeriodRule, getPriceRule, getRelevancePeriodRule } from '../../../../../Utils/Rules/offer';
import { useAppSelector } from '../../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import LabelWithTooltip from '../../../../../Components/Label/LabelWithTooltip';
import dayjs from 'dayjs';

interface EditOfferModalProps {
  open: boolean;
  offerData: OfferType;
  handleCancel: () => void;
  priceDisabled: boolean;
}

const EditOfferModal = (props: EditOfferModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, offerData, handleCancel, priceDisabled } = props;
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [isFormEdited, setIsFormEdited] = useState(false);
  const isVerified = useAppSelector((state) => state.user.confirmed);

  useEffect(() => {
    form.setFieldsValue({ ...offerData });
  }, []);
  const onFinish = (values: OfferFormType) => {
    setLoading(true);
    const formData = {
      id: offerData?.id,
      comment: values.comment,
      dueDatePeriod: values.dueDatePeriod ? values.dueDatePeriod : offerData?.dueDatePeriod,
      offerValidityTime: values.offerValidityTime ? values.offerValidityTime : offerData?.offerValidityTime,
      created: dayjs(offerData?.created).toISOString(),
      orderId: offerData?.orderId,
      price: Number(`${values.price ? values.price : offerData?.price}`.replaceAll(' ', '')),
    };

    API.put('/api/v1/offer', formData)
      .then(() => location.reload())
      .then(() => {
        handleCancel();
        location.reload();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  const onCancel = () => form.setFieldsValue({ ...offerData });

  return (
    <Modal open={open} onCancel={handleCancel} className="modal modal__info" footer={null} centered closable={true}>
      <Col span={23} offset={1}>
        <Form
          form={form}
          colon={false}
          layout="vertical"
          className="form"
          onFinish={onFinish}
          onValuesChange={() => setIsFormEdited(true)}
          disabled={!isVerified}
        >
          <Form.Item
            label="Ваше предложение по стоимости с НДС, ₽"
            name="price"
            rules={getPriceRule('Введите вашу стоимость')}
            validateFirst
          >
            <NumericFormat
              customInput={Input}
              allowNegative={false}
              maxLength={11}
              size="large"
              thousandSeparator=" "
              decimalScale={0}
            />
          </Form.Item>
          <Space className="offer__space-full-width offer__periods" size="large" align="start">
            <Form.Item
              label={
                <LabelWithTooltip
                  label="Срок исполнения в календарных днях"
                  message="Сколько календарных дней, по Вашей оценке, займет доставка"
                />
              }
              name="dueDatePeriod"
              rules={getDueDatePeriodRule('Введите срок исполнения')}
              normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}
              validateFirst
            >
              <Input size="large" min={1} disabled={priceDisabled} />
            </Form.Item>
            <Form.Item
              label={
                <LabelWithTooltip
                  label="Срок актуальности, мин. 14 дней"
                  message="Сколько календарных дней действует Ваше предложение. После истечения срока предложение отменится без возможности отправить новое."
                />
              }
              name="offerValidityTime"
              rules={!priceDisabled ? getRelevancePeriodRule('Введите срок актуальности предложения') : undefined}
              normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}
              validateFirst
            >
              <Input size="large" min={1} disabled={!offerData.offerValidityTime || priceDisabled} />
            </Form.Item>
          </Space>
          <Form.Item
            label="Комментарий к предложению (увидит только заказчик)"
            name="comment"
            extra="Например, способ оплаты, особенности перевозки"
          >
            <Input.TextArea
              maxLength={500}
              showCount
              className="form__comment"
              disabled={!isVerified || priceDisabled}
            />
          </Form.Item>

          <Form.Item className="offer__bottom-btns">
            <Button type="primary" size="large" htmlType="submit" loading={loading}>
              Отправить
            </Button>
            {isFormEdited && (
              <Button size="large" htmlType="submit" onClick={onCancel}>
                Отменить
              </Button>
            )}
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
};

export default EditOfferModal;

import { API } from '../../../../../../Services/axios';
import { Button, Form, InputNumber, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../../../../../Models/Modal';
import { Rate } from '../../../../../../Models/Rate';
import { getRequiredFieldRule } from '../../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../../Components/Modals/ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from '../../../../../../Components/Modals/ModalTitle';

interface EditRateModalProps extends ModalProps {
  rate: Rate;
}

const EditDefaultRateModal = (props: EditRateModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { rate, open, handleCancel } = props;

  const [formEditDefault] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const price = useWatch('price', formEditDefault);

  const initialValues = {
    from: rate.regionFrom,
    to: rate.regionTo,
    price: rate.price,
  };

  const onFinish = () => {
    formEditDefault.validateFields();
    setLoading(true);
    const values = formEditDefault.getFieldsValue();

    const priceMatrixDto = {
      price: values.price,
    };

    API.put(`/api/v1/price-matrix/${rate.id}`, priceMatrixDto)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Редактирование стоимости 1км" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32}>
        <Row>
          <Typography.Text className="modal__text-large">
            Вы можете скорректировать тариф стоимости 1км по умолчанию
          </Typography.Text>
        </Row>
      </Space>
      <br />
      <br />
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form
          id="formEditDefault"
          form={formEditDefault}
          initialValues={initialValues}
          colon={false}
          layout="vertical"
          className="form"
        >
          <Form.Item name="price" label="Тариф за 1 км, ₽" rules={getRequiredFieldRule('Введите цену')}>
            <InputNumber min={0} maxLength={9} size="large" />
          </Form.Item>
        </Form>
        <Space>
          <Row>
            <Button size="large" className="modal__cancel-btn" onClick={handleCancel}>
              Отмена
            </Button>
            <Button
              disabled={!price}
              type="primary"
              size="large"
              form="form"
              htmlType="submit"
              onClick={onFinish}
              loading={loading}
            >
              Сохранить
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditDefaultRateModal;

import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';

interface ModalTitleProps {
  title: string;
  handleCancel: () => void;
  level?: 1 | 2 | 3 | 4 | 5;
}

const ModalTitle = (props: ModalTitleProps) => {
  const { title, handleCancel, level } = props;
  return (
    <Row justify="space-between" align="top" wrap={false}>
      <Col flex="auto">
        <Typography.Title level={level}>{title}</Typography.Title>
      </Col>
      <Col flex="24px">
        <CloseCircleOutlined className="modal__close-icon" onClick={handleCancel} />
      </Col>
    </Row>
  );
};

export default ModalTitle;

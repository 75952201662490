import { Rule } from 'antd/lib/form';

export const getRequiredFieldRule = (message: string): Rule[] => [
  {
    required: true,
    message: message,
  },
];
export const getCountryFieldRule = (label: string, isChecked: boolean): Rule[] => [
  ...getRequiredFieldRule(`${label} является обязательным`),
  {
    validator: (_, value) => {
      return isChecked ? Promise.reject() : Promise.resolve();
    },
    message: `${label} введён некорректынй адрес`,
  },
];

export const getSizesRules = (message: string, maxOverallDimension: number): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator: (_, value) => {
      if (+value <= maxOverallDimension) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
    warningOnly: true,
  },
];

export const getMaxLengthRule = (maxLength: number): Rule[] => [
  {
    max: maxLength,
    message: `Вводимое значение не может быть больше ${maxLength} символов`,
  },
];

export const getTextFieldRules = (message: string, maxLength: number): Rule[] => [
  ...getRequiredFieldRule(message),
  ...getMaxLengthRule(maxLength),
];

export const getLinkFieldRules = (): Rule[] => [
  ...getMaxLengthRule(200),
  {
    validator: (_, value) => {
      if (!value || value.startsWith('http://') || value.startsWith('https://')) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('В данное поле необходимо ввести ссылку'));
    },
  },
];

export const getPhoneSizeRule = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator: (_, value) => {
      const digitValue = value.replace(/\D/g, '');
      if (digitValue.length === 10) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что телефон введен верно'));
    },
  },
];

export const getPhotoSizeRule = (): Rule[] => [
  {
    validator: (_, value) => {
      if (!value) {
        return Promise.resolve();
      } else {
        let isOK: boolean = true;
        value.map((file: File) => {
          const mbSize = file.size / 1024 / 1024;
          if (mbSize > 10) {
            return (isOK = false);
          }
        });
        return isOK ? Promise.resolve() : Promise.reject(new Error('Фото должно быть не больше 10 мб'));
      }
    },
  },
];

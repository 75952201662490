import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import { OfferType } from '../../../../../Models/Offer';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getDayDeclention } from '../../../../../Utils/dayDeclension';

interface OffersHistoryProps {
  offers: OfferType[];
  isOpened: boolean;
  manageFilesCollapse: () => void;
}

const OffersHistory = (props: OffersHistoryProps) => {
  const { offers, isOpened, manageFilesCollapse } = props;

  return (
    <Row className="offer__full-width offer__files-history" gutter={[0, 24]} style={{ marginTop: '20px' }}>
      <Col span={24}>
        <Space className="offer__collapse" align="center" size="large">
          <Typography.Title level={4}>Предыдущие версии предложения</Typography.Title>
          <Typography.Text onClick={() => manageFilesCollapse()} className="offer__text-btn">
            {isOpened ? 'Скрыть' : 'Показать'}
          </Typography.Text>
        </Space>
      </Col>
      {isOpened && (
        <Col span={24}>
          <Card className="offer__full-width">
            <Col span={24}>
              <Space direction="vertical" size={24}>
                {offers.map((offer) => (
                  <Space key={offer.id} size={24} direction="vertical" style={{ width: '100%' }}>
                    <Space size={12} direction="vertical">
                      <Col span={24}>
                        <Typography.Text className="offer__info-xxl" strong>
                          {`${formatPrice(offer.price)} ₽`}
                        </Typography.Text>
                        <Typography.Text>&emsp;(с учетом НДС)</Typography.Text>
                      </Col>
                      <Col span={24}>
                        <Typography.Text className="offer__info-xxl" strong>
                          {getDayDeclention(offer.dueDatePeriod)}
                        </Typography.Text>
                        <Typography.Text>&emsp;(срок исполнения)</Typography.Text>
                      </Col>

                      {offer.comment && (
                        <Col span={24}>
                          <Typography className="offer__info-large">{offer.comment}</Typography>
                        </Col>
                      )}
                    </Space>
                    {offer !== offers[offers.length - 1] && <Divider />}
                  </Space>
                ))}
              </Space>
            </Col>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default OffersHistory;

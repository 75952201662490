import { chain } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumns } from '../InitialStates/OrgContractsInitialState';

const preSelectedColKeys = [
  'agreementId',
  'cityFrom',
  'cityTo',
  'arrival',
  'price',
  'orderUserFriendlyId',
  'organisationName',
  'lastReportComment',
  'lastReport',
];

const presetColumns = chain(preSelectedColKeys)
  .toPairs()
  .map((i) => initialColumns[i[0]])
  .value();

export const orgContractsColsSlice = createSlice({
  name: 'orgContractsCols',
  initialState: presetColumns,
  reducers: {
    getUpdatedOrgColumns: (state, action) => {
      const columns = action.payload;
      return columns;
    },
  },
});

export const { getUpdatedOrgColumns } = orgContractsColsSlice.actions;

export default orgContractsColsSlice.reducer;

import { Button, Col, Row, Space, Typography } from 'antd';

const Toolbar = () => {
  return (
    <Row className="toolbar__admin-title" justify="end">
      <Col className="toolbar__admin-btns">
        <Space size={18}>
          <Typography.Text>
            Выгрузить список e-mail пользователей, согласных на информационные сообщения
          </Typography.Text>
          <Button
            size={'large'}
            type="primary"
            onClick={() => window.open(`${process.env.REACT_APP_API_URL}/api/v1/admin/emails-list/`)}
          >
            Выгрузить
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default Toolbar;

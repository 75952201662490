import { API } from '../../Services/axios';
import { Claim } from '../../Models/Claims';
import { Col, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { Roles } from '../../Constants/Profile';
import { useAppSelector } from '../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from './ErrorModal';
import Loader from '../Loader/Loader';
import ModalTitle from './ModalTitle';

const ClaimInfoModal = (props: ModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel } = props;
  const claimId = useAppSelector((state) => state.claimId.claimId);
  const role = useAppSelector((state) => state.user.role);

  const [data, setData] = useState<Claim>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (claimId) {
      API.get(`/api/v1/claim/${claimId}`)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  }, [claimId]);

  const title = data?.userFriendlyId ? `Обращение ${data.userFriendlyId}` : 'Обращение';
  const agreementLink = role === Roles.CUSTOMER ? 'customer' : 'organizer';

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title={title} handleCancel={handleCancel} />}
      width={860}
      closable={false}
      footer={null}
      centered
      className="modal"
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      {loading ? (
        <Loader />
      ) : (
        data && (
          <Space direction="vertical" size={16} className="modal__full-width">
            <Row align="middle" justify={'space-between'}>
              <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                <Typography.Text type="secondary" className="profile__info-large">
                  Причина обращения
                </Typography.Text>
              </Col>
              <Col
                sm={{ span: 15, offset: 1 }}
                lg={{ span: 15, offset: 1 }}
                xl={{ span: 15, offset: 1 }}
                xxl={{ span: 15, offset: 1 }}
                span={24}
              >
                <Typography.Text className="profile__info-large">{data.reason ? data.reason : '—'}</Typography.Text>
              </Col>
            </Row>
            {data.agreementId && (
              <>
                <Row align="middle" justify={'space-between'}>
                  <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                    <Typography.Text type="secondary" className="profile__info-large">
                      № Договора
                    </Typography.Text>
                  </Col>
                  <Col
                    sm={{ span: 15, offset: 1 }}
                    lg={{ span: 15, offset: 1 }}
                    xl={{ span: 15, offset: 1 }}
                    xxl={{ span: 15, offset: 1 }}
                    span={24}
                  >
                    <Typography.Link
                      href={`/${agreementLink}/contracts/${data.agreementId}`}
                      className="profile__info-large"
                    >
                      {data.agreementUserFriendlyId ? data.agreementUserFriendlyId : '—'}
                    </Typography.Link>
                  </Col>
                </Row>
                <Row align="middle" justify={'space-between'}>
                  <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                    <Typography.Text type="secondary" className="profile__info-large">
                      {role === Roles.CUSTOMER ? 'Организатор перевозки' : 'Заказчик'}
                    </Typography.Text>
                  </Col>
                  <Col
                    sm={{ span: 15, offset: 1 }}
                    lg={{ span: 15, offset: 1 }}
                    xl={{ span: 15, offset: 1 }}
                    xxl={{ span: 15, offset: 1 }}
                    span={24}
                  >
                    <Typography.Text className="profile__info-large">
                      {data.organisation ? data.organisation : '—'}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row align="middle" justify={'space-between'}>
                  <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                    <Typography.Text type="secondary" className="profile__info-large">
                      Адрес отправки
                    </Typography.Text>
                  </Col>
                  <Col
                    sm={{ span: 15, offset: 1 }}
                    lg={{ span: 15, offset: 1 }}
                    xl={{ span: 15, offset: 1 }}
                    xxl={{ span: 15, offset: 1 }}
                    span={24}
                  >
                    <Typography.Text className="profile__info-large">{data.addressFrom}</Typography.Text>
                  </Col>
                </Row>
                <Row align="middle" justify={'space-between'}>
                  <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                    <Typography.Text type="secondary" className="profile__info-large">
                      Адрес получения
                    </Typography.Text>
                  </Col>
                  <Col
                    sm={{ span: 15, offset: 1 }}
                    lg={{ span: 15, offset: 1 }}
                    xl={{ span: 15, offset: 1 }}
                    xxl={{ span: 15, offset: 1 }}
                    span={24}
                  >
                    <Typography.Text className="profile__info-large">{data.addressTo}</Typography.Text>
                  </Col>
                </Row>
              </>
            )}
            <Row align="middle" justify={'space-between'}>
              <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                <Typography.Text type="secondary" className="profile__info-large">
                  Проблема
                </Typography.Text>
              </Col>
              <Col
                sm={{ span: 15, offset: 1 }}
                lg={{ span: 15, offset: 1 }}
                xl={{ span: 15, offset: 1 }}
                xxl={{ span: 15, offset: 1 }}
                span={24}
              >
                <Typography.Text className="profile__info-large">{data.issue ? data.issue : '—'}</Typography.Text>
              </Col>
            </Row>
            {data.comments && (
              <Row align="middle" justify={'space-between'}>
                <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                  <Typography.Text type="secondary" className="profile__info-large">
                    Описание проблемы
                  </Typography.Text>
                </Col>
                <Col
                  sm={{ span: 15, offset: 1 }}
                  lg={{ span: 15, offset: 1 }}
                  xl={{ span: 15, offset: 1 }}
                  xxl={{ span: 15, offset: 1 }}
                  span={24}
                >
                  <Typography.Text className="profile__info-large">{data.comments}</Typography.Text>
                </Col>
              </Row>
            )}
            <Row align="middle" justify={'space-between'}>
              <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                <Typography.Text type="secondary" className="profile__info-large">
                  Статус обработки
                </Typography.Text>
              </Col>
              <Col
                sm={{ span: 15, offset: 1 }}
                lg={{ span: 15, offset: 1 }}
                xl={{ span: 15, offset: 1 }}
                xxl={{ span: 15, offset: 1 }}
                span={24}
              >
                <Typography.Text className="profile__info-large">
                  {data.operatorComments ? 'Принято решение' : 'На рассмотрении'}
                </Typography.Text>
              </Col>
            </Row>
            <Row align="middle" justify={'space-between'}>
              <Col sm={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }} span={24}>
                <Typography.Text type="secondary" className="profile__info-large">
                  Решение
                </Typography.Text>
              </Col>
              <Col
                sm={{ span: 15, offset: 1 }}
                lg={{ span: 15, offset: 1 }}
                xl={{ span: 15, offset: 1 }}
                xxl={{ span: 15, offset: 1 }}
                span={24}
              >
                <Typography.Text className="profile__info-large">
                  {data.operatorComments ? data.operatorComments : '—'}
                </Typography.Text>
              </Col>
            </Row>
          </Space>
        )
      )}
    </Modal>
  );
};

export default ClaimInfoModal;

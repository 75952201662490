import { ColumnsType } from 'antd/lib/table';
import { Profile } from '../../../../../Models/Profile';
import { Typography } from 'antd';
import { defaultDateFormat } from '../../../../../Constants/DateFormats';
import dayjs from 'dayjs';

export const getOperatorsTableColumns = () => {
  const columns: ColumnsType<Profile> = [
    {
      title: 'ФИО оператора',
      dataIndex: 'surname',
      width: 496,
      ellipsis: true,
      key: 'surname',
      render: (_, record) => (
        <Typography.Link href={`/admin/operators/${record.id}`} underline>
          {record.patronymic
            ? `${record.surname} ${record.name} ${record.patronymic}`
            : `${record.surname} ${record.name}`}
        </Typography.Link>
      ),
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      width: 298,
      ellipsis: true,
      key: 'phone',
    },
    {
      title: 'Электронная почта',
      dataIndex: 'email',
      width: 322,
      ellipsis: true,
      key: 'email',
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      width: 198,
      ellipsis: true,
      key: 'createdAt',
      render: (text) => dayjs(text).format(defaultDateFormat),
    },
  ];

  return columns;
};

import { API } from '../../../../Services/axios';
import { AdminCalculatorSettingsList } from '../../../../Models/CalculatorSettings';
import { camelCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import Loader from '../../../../Components/Loader/Loader';
import SettingsList from './List/SettingsList';
import Toolbar from './Toolbar';

const OrgCalculatorSettings = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [settings, setSettings] = useState<AdminCalculatorSettingsList>();

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/admin/settings/list`)
      .then((response) => {
        if (response.status === 200) {
          const settingsList = response.data.map((setting) => [[camelCase(setting.name)], Number(setting.value)]);
          const flattenSettingsList = Object.fromEntries(settingsList) as AdminCalculatorSettingsList;
          setSettings(flattenSettingsList);
          setLoading(false);
        }
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  }, [reload]);

  if (loading || !settings) return <Loader />;

  return (
    settings && (
      <div style={{ background: '#fff' }}>
        <Toolbar />
        <SettingsList data={settings} setReload={setReload} />
      </div>
    )
  );
};

export default OrgCalculatorSettings;

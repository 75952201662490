import { UploadProps } from 'antd';
import { beforeUploadImage } from '../../../../../Utils/beforeUpload';

export const uploadPhotosProps: UploadProps = {
  maxCount: 5,
  accept: '.jpg,.jpeg,.heic,.png',
  listType: 'picture-card',
  beforeUpload: beforeUploadImage,
  customRequest: ({ file, onSuccess }) =>
    setTimeout(() => {
      onSuccess!('ok');
    }, 0),
};

import { API } from '../../../../Services/axios';
import { Col, Row, Tabs, Typography } from 'antd';
import { OrganizationsProps } from '../../../../Models/Organizations';
import { getOrganizationsTabsItems } from './Component/tab-items';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';

const Organizations = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrganizationsProps>({
    organisations: [],
    all: 0,
    not_confirmed: 0,
    confirmed: 0,
    customers: 0,
    loading: loading,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab') ?? 'all');
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';

  const restartTable = () => {
    if (tab !== null) {
      setLoading(true);
      API.get(
        `/api/v1/operator/organisations?filter=${tab}&page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`,
      )
        .then((response) => setData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    restartTable();
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && tab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('tab', tab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
    setLoading(true);
    restartTable();
  }, [tab, page, size, sort, sortField]);

  const organizationsProps: OrganizationsProps = {
    organisations: data.organisations,
    loading: loading,
    all: data.all,
    not_confirmed: data.not_confirmed,
    confirmed: data.confirmed,
    customers: data.customers,
  };

  const tabsItems = getOrganizationsTabsItems(organizationsProps);

  return (
    <div>
      <Row className="toolbar__title" justify="space-between">
        <Col>
          <Typography.Title level={2}>Организации</Typography.Title>
        </Col>
      </Row>
      <div className="organizations__tabs-table">
        <Tabs
          activeKey={`${tab}`}
          items={tabsItems}
          onTabClick={(key) => {
            setTab(key);
            setSearchParams((params) => {
              params.set('page', '1');
              return searchParams;
            });
            window.history.replaceState(
              null,
              '',
              `/operator/organizations?tab=${key}&page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`,
            );
          }}
          className="tabs"
        />
      </div>
    </div>
  );
};

export default Organizations;

import { API } from '../../../../../Services/axios';
import { Card, Col, Row, Space, Typography } from 'antd';
import { Contract } from '../../../../../Models/Contract';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../Components/Loader/Loader';

const FinalAgreement = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();
  const [data, setData] = useState<Contract>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/agreement/final-agreement/${id}`)
      .then((response) => setData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <Loader />;
  return (
    <Card title={<Typography.Title level={4}>Финальный договор</Typography.Title>}>
      {data ? (
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Row>
            <Col span={8}>
              <Typography.Text>Выбранный исполнитель</Typography.Text>
            </Col>
            <Col span={16}>
              <Typography.Link
                href={`/operator/organizations/${data.executorOrganisationId}`}
                className="order__info-large"
                underline
              >
                {data.executorOrganisationName}
              </Typography.Link>
            </Col>
          </Row>
          {/*<Row>*/}
          {/*  <Col span={8}>*/}
          {/*    <Typography.Text>Файл подписанного договора</Typography.Text>*/}
          {/*  </Col>*/}
          {/*  <Col span={16}>*/}
          {/*    <Space size={10}>*/}
          {/*      <FileOutlined className="offer__file-icon" />*/}
          {/*      <Typography.Link className="order__info-large" href={data.finalAgreementFile[0].link} underline>*/}
          {/*        {data.finalAgreementFile[0].name}*/}
          {/*      </Typography.Link>*/}
          {/*    </Space>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row>
            <Col span={8}>
              <Typography.Text>№ Договора в системе</Typography.Text>
            </Col>
            <Col span={16}>
              <Typography.Link className="order__info-large" href={`/operator/contracts/${data.agreementId}`} underline>
                {data.agreementUserFriendlyId}
              </Typography.Link>
            </Col>
          </Row>
        </Space>
      ) : (
        <Typography.Text>
          Организатор перевозки по этому заказу еще не выбран, финальный договор еще не загружен в систему
        </Typography.Text>
      )}
    </Card>
  );
};

export default FinalAgreement;

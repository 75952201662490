import { ClaimsProps } from '../../../../../Models/Claims';
import { Dispatch, SetStateAction, useState } from 'react';
import { Table } from 'antd';
import { getClaimsTableColumns } from './Component/columns';
import { getClaimsTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { updateClaimId } from '../../../../../Store/Slices/claimIdSlice';
import { updateOrganisationId } from '../../../../../Store/Slices/organizationProfileModalSlice';
import { useAppDispatch } from '../../../../../Store/hooks';
import ClaimModal from '../../Detail/ClaimModal';

interface ClaimsTableProps extends ClaimsProps {
  reload: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const ClaimsTable = (props: ClaimsTableProps) => {
  const { loading, claims, reload, setReload } = props;
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = (organisationId: number) => {
    dispatch(updateOrganisationId(organisationId));
    setOpenModal(true);
  };
  const hideModal = () => setOpenModal(false);

  const [openClaimModal, setOpenClaimModal] = useState<boolean>(false);
  const showClaimModal = (claimId: number) => {
    dispatch(updateClaimId(claimId));
    setOpenClaimModal(true);
  };
  const hideClaimModal = () => {
    setOpenClaimModal(false);
    dispatch(updateClaimId(null));
  };

  const { filteredInfo, setFilteredInfo, handleFilter } = getClaimsTableFilter();
  const columns = getClaimsTableColumns(filteredInfo, setFilteredInfo, showModal, showClaimModal);

  return (
    <>
      <Table
        bordered
        rowKey={'id'}
        className="table__organizations"
        loading={loading}
        columns={columns}
        scroll={{ x: 1331 }}
        dataSource={claims}
        locale={tableLocaleSettings}
        pagination={tablePagingSettings}
        onChange={handleFilter}
      />
      <ClaimModal open={openClaimModal} handleCancel={hideClaimModal} reload={reload} setReload={setReload} />
    </>
  );
};

export default ClaimsTable;

import { OrgTypes } from './OrgTypes';

export const orgTypeSelectOptions = [
  {
    value: OrgTypes.LEGAL,
    label: 'Юридическое лицо',
  },
  {
    value: OrgTypes.INDIVIDUAL,
    label: 'Индивидуальный предприниматель',
  },
];

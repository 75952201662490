import { Card, Col, Row, Space, Typography } from 'antd';
import { ChangeHistoryEvents } from '../../../../../Constants/Operator';
import { HistoryItem } from '../../../../../Models/Operator';
import { Roles } from '../../../../../Constants/Profile';
import { defaultDateFormat } from '../../../../../Constants/DateFormats';
import dayjs from 'dayjs';

interface ChangeHistoryProps {
  history: HistoryItem[];
  role: Roles;
}

const ChangeHistory = (props: ChangeHistoryProps) => {
  const { history, role } = props;

  const isOperator = role === Roles.OPERATOR;

  const message = (event: ChangeHistoryEvents) => {
    switch (event) {
      case ChangeHistoryEvents.CREATED:
        return 'Профиль создан в системе';
      case ChangeHistoryEvents.SECOND_INVITATION_SENT:
        return 'Выслано повторное приглашение';
      case ChangeHistoryEvents.BLOCKED:
        return isOperator ? 'Оператор заблокирован' : 'Пользователь заблокирован';
      case ChangeHistoryEvents.UNBLOCKED:
        return isOperator ? 'Оператор разблокирован' : 'Пользователь разблокирован';
      default:
        return '';
    }
  };

  const comment = (json) => {
    try {
      return JSON.parse(json).comment;
    } catch (e) {
      return false;
    }
  };

  const changeHistoryItem = (item: HistoryItem) => (
    <Space direction="vertical" size={4} className="operator__full-width" key={item.id}>
      <Row gutter={[4, 0]}>
        <Col span={8}>
          <Typography.Text strong>{dayjs(item.created).format(defaultDateFormat)}</Typography.Text>
        </Col>
        <Col span={16}>
          <Typography.Text>{message(item.event)}</Typography.Text>
        </Col>
      </Row>
      {comment(item.description) && (
        <Row gutter={[4, 0]}>
          <Col span={8}>
            <Typography.Text strong>Причина</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{comment(item.description)}</Typography.Text>
          </Col>
        </Row>
      )}
    </Space>
  );

  return (
    <Card className="operator__full-width" title={<Typography.Title level={4}>История изменений</Typography.Title>}>
      <Space direction="vertical" size={16}>
        {history.length > 0 ? (
          history.map((item) => changeHistoryItem(item))
        ) : (
          <Typography.Text>У этого пользователя еще нет истории изменений</Typography.Text>
        )}
      </Space>
    </Card>
  );
};

export default ChangeHistory;

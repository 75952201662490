import { Card, Col, Row, Typography } from 'antd';
import { ContractReport } from '../../../../../Models/Contract';

interface CompletedWorksActCardProps {
  completedWorksActRecord: ContractReport | undefined;
  paidConfirmedRecord: ContractReport | undefined;
}

const CompletedWorksActCard = (props: CompletedWorksActCardProps) => {
  const { completedWorksActRecord, paidConfirmedRecord } = props;
  return (completedWorksActRecord && (
    <Card title={<Typography.Title level={4}>Груз доставлен</Typography.Title>}>
      <Row gutter={[0, 24]}>
        {!paidConfirmedRecord && (
          <Col span={24}>
            <Typography.Text>
              Организатор перевозки подтвердил доставку груза, вы можете производить оплату по договору. Как только он
              подтвердит получение оплаты, исполнение договора будет завершено.
            </Typography.Text>
          </Col>
        )}
        {/*<Col span={24}>*/}
        {/*  <Space direction="vertical" size={16}>*/}
        {/*    <Typography.Text type="secondary" strong>*/}
        {/*      Акт выполненных работ*/}
        {/*    </Typography.Text>*/}
        {/*    <Space size={10}>*/}
        {/*      <FileOutlined className="offer__file-icon" />*/}
        {/*      <Typography.Link className="offer__text" href={completedWorksActRecord.reportFile[0].link}>*/}
        {/*        {completedWorksActRecord.reportFile[0].name}*/}
        {/*      </Typography.Link>*/}
        {/*    </Space>*/}
        {/*  </Space>*/}
        {/*</Col>*/}
      </Row>
    </Card>
  ))!;
};

export default CompletedWorksActCard;

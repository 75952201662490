import { Button, Col, Row } from 'antd';
import { CargoCategory } from '../../../../Models/CargoCategories';
import { Dispatch, SetStateAction, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import CreateCategoryModal from '../../../../Components/Modals/CreateCategoryModal';

interface ToolbarProps {
  parentCategories: CargoCategory[];
  setReload: Dispatch<SetStateAction<boolean>>;
}

const Toolbar = (props: ToolbarProps) => {
  const { parentCategories, setReload } = props;

  const [openAddCategoryModal, setOpenAddCategoryModal] = useState<boolean>(false);
  const handleOpenAddCategoryModal = () => setOpenAddCategoryModal(true);
  const handleCancelAddCategoryModal = () => setOpenAddCategoryModal(false);

  return (
    <Row className="toolbar__admin-title" justify="end">
      <Col className="toolbar__admin-btns">
        <Button size={'large'} type="primary" icon={<PlusOutlined />} onClick={() => handleOpenAddCategoryModal()}>
          Добавить тип техники
        </Button>
      </Col>
      <CreateCategoryModal
        open={openAddCategoryModal}
        handleCancel={handleCancelAddCategoryModal}
        parentCategories={parentCategories}
        setReload={setReload}
      />
    </Row>
  );
};

export default Toolbar;

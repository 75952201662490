import { API } from '../../../../../Services/axios';
import { EmailTemplate } from '../../../../../Models/EmailTemplate';
import { Table } from 'antd';
import { getSystemMessagesTableColumns } from './Component/columns';
import { sortBy } from 'lodash';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import EditEmailModal from '../Modals/EditEmailModal';
import Toolbar from './Component/toolbar';

const EmailsTable = () => {
  const { showErrorModal } = useErrorModal();
  const [data, setData] = useState<EmailTemplate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/admin/email/list')
      .then((response) => {
        if (response.status === 200) {
          setData(sortBy(response.data, 'id'));
          setLoading(false);
        }
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  }, [reload]);

  const initialRecord = { body: '', created: '', description: '', id: 0, name: '', placeholders: [], subject: '' };

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [record, setRecord] = useState<EmailTemplate>(initialRecord);
  const handleOpenEditModal = (record: EmailTemplate) => {
    setOpenEditModal(true);
    setRecord(record);
  };
  const handleHideDeleteModal = () => {
    setOpenEditModal(false);
    setRecord(initialRecord);
  };

  const columns = getSystemMessagesTableColumns(handleOpenEditModal);

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Toolbar />
      <Table
        bordered
        rowKey={'id'}
        className="table__rates"
        scroll={{ x: 1331 }}
        loading={loading}
        columns={columns}
        dataSource={data}
        locale={tableLocaleSettings()}
        pagination={tablePagingSettings}
      />
      <EditEmailModal open={openEditModal} handleCancel={handleHideDeleteModal} record={record} setReload={setReload} />
    </div>
  );
};

export default EmailsTable;

export const orgOrderColumnsList = [
  {
    title: 'Категория техники',
    key: 'cargoTypeNameWithParentName',
  },
  {
    title: 'Город отправки',
    key: 'cityFrom',
  },
  {
    title: 'Город доставки',
    key: 'cityTo',
  },
  {
    title: 'Расстояние',
    key: 'distance',
  },
  {
    title: 'Дата получения',
    key: 'dateArrival',
  },
  {
    title: 'Дата погрузки техники',
    key: 'dateLoading',
  },
  {
    title: 'Цена',
    key: 'price',
  },
  {
    title: 'Модель техники',
    key: 'machineName',
  },
  {
    title: 'Стоимость техники',
    key: 'machinePrice',
  },
  {
    title: 'Мой номер договора',
    key: 'acceptedAgreementUserFriendlyId',
  },
  {
    title: 'Заказчик',
    key: 'organisationName',
  },
  {
    title: 'Вес',
    key: 'weight',
  },
  {
    title: 'Длина',
    key: 'length',
  },
  {
    title: 'Ширина',
    key: 'width',
  },
  {
    title: 'Высота',
    key: 'height',
  },
  {
    title: 'Комментарий',
    key: 'comment',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
  },
];

export const customerOrderColumnsList = [
  {
    title: 'Название заказа',
    key: 'title',
  },
  {
    title: 'Город отправки',
    key: 'cityFrom',
  },
  {
    title: 'Город доставки',
    key: 'cityTo',
  },
  {
    title: 'Дата доставки',
    key: 'dateArrival',
  },
  {
    title: 'Расчет',
    key: 'price',
  },
  {
    title: 'Предложения',
    key: 'offersCount',
  },
  {
    title: 'Проекты договоров',
    key: 'agreementsCount',
  },
  {
    title: 'Договор',
    key: 'acceptedAgreementUserFriendlyId',
  },
  {
    title: 'Категория техники',
    key: 'cargoTypeNameWithParentName',
  },
  {
    title: 'Модель техники',
    key: 'machineName',
  },
  {
    title: 'Стоимость техники',
    key: 'machinePrice',
  },
  {
    title: 'Статус',
    key: 'status',
  },
  {
    title: 'Дата погрузки техники',
    key: 'dateLoading',
  },
  {
    title: 'Вес',
    key: 'weight',
  },
  {
    title: 'Длина',
    key: 'length',
  },
  {
    title: 'Ширина',
    key: 'width',
  },
  {
    title: 'Высота',
    key: 'height',
  },
  {
    title: 'Расстояние',
    key: 'distance',
  },
  {
    title: 'Комментарий',
    key: 'comment',
  },
  {
    title: 'Дата создания',
    key: 'createdAt',
  },
];

import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { OfferType } from '../../../../../Models/Offer';
import { convertToMscTime } from '../../../../../Utils/convertToMscTime';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getDayDeclention } from '../../../../../Utils/dayDeclension';
import { useState } from 'react';
import CancelOfferModal from './CancelOfferModal';
import EditOfferModal from './EditOfferModal';

interface OfferInformationProps {
  offerData: OfferType;
  isOfferCancelled: boolean;
}

const OfferInformation = (props: OfferInformationProps) => {
  const { offerData, isOfferCancelled } = props;
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const handleCancelEditOfferModal = () => setIsEditModalOpen(false);

  const handleCancelCancelOfferModal = () => setIsCancelModalOpen(false);

  const validityText = (
    <Typography.Text>
      Актуально {offerData.offerValidityTime > 0 && 'еще '}
      {<strong>{getDayDeclention(offerData.offerValidityTime)}</strong>}
    </Typography.Text>
  );

  return isOfferCancelled ? (
    <></>
  ) : (
    <Card className="offer__full-width">
      <Row>
        <Col span={24} className="offer__title">
          <Row justify="space-between" align="middle">
            <Typography.Title level={4}>Ваше предложение</Typography.Title>
            {validityText}
          </Row>
        </Col>
        <Col>
          <Space direction="vertical" className="offer__info" size={8}>
            <Typography.Text type="secondary" strong>
              Ваше предложение по стоимости с НДС
            </Typography.Text>
            {offerData?.price && (
              <Typography.Text className="offer__text">{formatPrice(+offerData?.price)} &#8381;</Typography.Text>
            )}
          </Space>
          <Space className="offer__space-full-width" size="large">
            <Space direction="vertical" size={8}>
              <Typography.Text type="secondary" strong>
                Срок исполнения в календарных днях
              </Typography.Text>
              <Typography.Text className="offer__text">{offerData?.dueDatePeriod}</Typography.Text>
            </Space>
            <Space direction="vertical" size={8}>
              <Typography.Text type="secondary" strong>
                Срок актуальности предложения в днях
              </Typography.Text>
              <Typography.Text className="offer__text">{offerData?.offerValidityPeriod}</Typography.Text>
            </Space>
          </Space>
          {offerData?.comment && (
            <Space direction="vertical" className="offer__comment" size={8}>
              <Typography.Text type="secondary" strong>
                Комментарий к предложению
              </Typography.Text>
              <Typography.Text className="offer__text">{offerData?.comment}</Typography.Text>
            </Space>
          )}
          <Row className="offer__actions offer__space-full-width" justify="space-between" align="middle">
            <Button type="primary" size="large" onClick={() => setIsEditModalOpen(true)}>
              Изменить предложение
            </Button>
            <EditOfferModal
              open={isEditModalOpen}
              handleCancel={handleCancelEditOfferModal}
              offerData={offerData}
              priceDisabled={false}
            />
            <Button size="large" onClick={() => setIsCancelModalOpen(true)}>
              Отозвать предложение
            </Button>
            <Typography.Text>Отправлено: {`${convertToMscTime(offerData?.created)} мск`}</Typography.Text>
          </Row>
        </Col>
      </Row>
      <CancelOfferModal open={isCancelModalOpen} offerId={offerData.id} handleCancel={handleCancelCancelOfferModal} />
    </Card>
  );
};

export default OfferInformation;

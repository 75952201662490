import { API } from '../../../Services/axios';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../Components/Modals/ErrorModal';
import Loader from '../../../Components/Loader/Loader';
import ReportCard from './Component/report-card';
import Toolbar from './Toolbar';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface Dates {
  dateFrom: Dayjs;
  dateTo: Dayjs;
}

export interface DataType {
  [prop: string]: string;
}

const AnalyticalReport = () => {
  const { showErrorModal } = useErrorModal();
  const period = {
    dateFrom: dayjs.utc().subtract(30, 'days').tz('Europe/Moscow'),
    dateTo: dayjs.utc().tz('Europe/Moscow'),
  };
  const [dates, setDates] = useState<Dates>(period);
  const [data, setData] = useState<DataType>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const dateFrom = dates.dateFrom.toISOString();
    const dateTo = dates.dateTo.toISOString();

    setLoading(true);
    API.get(`/api/v1/admin/analytical-report/${dateFrom}/${dateTo}`)
      .then((response) => setData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, [dates]);

  return (
    <Row justify="center" className="settings__report" gutter={[0, 32]}>
      <Col xl={14} lg={18} span={24}>
        <Toolbar dateFrom={period.dateFrom} dateTo={period.dateTo} dates={dates} setDates={setDates} />
      </Col>
      <Col xl={14} lg={18} span={24}>
        {loading || !data ? <Loader /> : <ReportCard data={data} />}
      </Col>
    </Row>
  );
};

export default AnalyticalReport;

import { API } from '../../../../../Services/axios';
import { Button, Form, Input, Row } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { getRequiredFieldRule } from '../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import LabelWithTooltip from '../../../../../Components/Label/LabelWithTooltip';

interface ReplayClaimFormProps {
  claimId: number;
  handleCancel: () => void;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const ReplayClaimForm = (props: ReplayClaimFormProps) => {
  const { showErrorModal } = useErrorModal();
  const { claimId, handleCancel, setReload } = props;
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      comments: values.operatorComments,
    };

    API.put(`/api/v1/claim/${claimId}/reply`, data)
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(true);
      });
  };

  return (
    <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
      <Form.Item
        name="operatorComments"
        label={
          <LabelWithTooltip
            label="Решение по обращению"
            message="Опишите решение проблемы и действия, которые были предприняты для ее решения"
          />
        }
        rules={getRequiredFieldRule('Поле обязательно для заполнения')}
      >
        <Input.TextArea size="large" maxLength={500} showCount className="form__comment" />
      </Form.Item>
      <Row justify="end">
        <Button size="large" onClick={handleCancel} className="modal__cancel-btn">
          Отменить
        </Button>
        <Button type="primary" size="large" htmlType="submit" loading={loading}>
          Опубликовать решение
        </Button>
      </Row>
    </Form>
  );
};

export default ReplayClaimForm;

import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { UploadProps } from 'antd';
import { beforeUploadFile } from '../../../../../Utils/beforeUpload';

export const uploadFilesProps: UploadProps = {
  maxCount: 5,
  accept: '.pdf,.docx,.xlsx,.txt,.rtf',
  iconRender: () => <PaperClipOutlined className="order__attachments-upload" />,
  beforeUpload: beforeUploadFile,
  customRequest: ({ file, onSuccess }) =>
    setTimeout(() => {
      onSuccess!('ok');
    }, 0),
  showUploadList: {
    showRemoveIcon: true,
    removeIcon: <DeleteOutlined className="order__attachments-remove" />,
  },
};

import { Button, Typography } from 'antd';
import { Claim } from '../../../../../Models/Claims';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { Roles } from '../../../../../Constants/Profile';

export const getClaimsTableColumns = (
  showModal: (organisationId: number) => void,
  showClaimModal: (claimId: number) => void,
  role: Roles,
) => {
  const contractLink = role === Roles.CUSTOMER ? '/customer/contracts/' : '/organizer/contracts/';

  const columns: ColumnsType<Claim> = [
    {
      key: 'userFriendlyId',
      title: '№ обращения',
      dataIndex: 'userFriendlyId',
      width: 135,
      render: (_, record) => (
        <Button type="default" onClick={() => showClaimModal(record.id)}>
          {record.userFriendlyId}
        </Button>
      ),
    },
    {
      key: 'reason',
      title: 'Причина обращения',
      dataIndex: 'reason',
      ellipsis: true,
      width: 214,
    },
    {
      key: 'agreementId',
      title: '№ Договора',
      dataIndex: 'agreementId',
      width: 120,
      render: (_, record) =>
        record.agreementUserFriendlyId ? (
          <Link to={`${contractLink}${record.agreementId}`}>
            <Button type="default">{record.agreementUserFriendlyId}</Button>
          </Link>
        ) : (
          '—'
        ),
    },
    {
      key: 'organisationName',
      title: role === Roles.CUSTOMER ? 'Организатор' : 'Заказчик',
      dataIndex: 'organisationName',
      width: 161,
      ellipsis: true,
      render: (_, record) =>
        record.organisation ? (
          <Typography.Link onClick={() => showModal(record.userId)} underline>
            {record.organisation}
          </Typography.Link>
        ) : (
          '—'
        ),
    },
    {
      key: 'issue',
      title: 'Проблема',
      dataIndex: 'issue',
      ellipsis: true,
      width: 241,
    },
    {
      key: 'status',
      title: 'Статус обработки',
      dataIndex: 'status',
      width: 185,
      sorter: (a, b) => {
        if (!b.operatorComments) {
          return +1;
        }
        if (!a.operatorComments) {
          return -1;
        }
        return 0;
      },
      render: (_, record) => (record.operatorComments ? 'Принято решение' : 'На рассмотрении'),
    },
    {
      key: 'reply',
      title: 'Решение',
      ellipsis: true,
      dataIndex: 'operatorComments',
      width: 256,
      render: (text) => (text ? text : '—'),
    },
  ];

  return columns;
};

import { Alert, Card, Col, Space, Steps, Typography } from 'antd';
import { OfferStatuses } from '../../../../Constants/OfferStatuses';
import { OfferType } from '../../../../Models/Offer';
import { OrderStatus } from '../../../../Constants/Order';
import { useEffect, useState } from 'react';
import AgreementCard from './AgreementCard';
import FinalAgreementCard from './FinalAgreementCard';
import OfferCard from './OfferCard';

interface OfferProps {
  offerData: OfferType | undefined;
  orderStatus: OrderStatus;
  contractId: number | undefined;
  loading: boolean;
}

const Offer = (props: OfferProps) => {
  const { offerData, orderStatus, contractId, loading } = props;
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: 'Предложение',
      content: <OfferCard offerData={offerData} orderStatus={orderStatus} contractId={contractId} />,
    },
    {
      title: 'Выбор перевозчика',
      content: <AgreementCard offerData={offerData} />,
    },
    {
      title: 'Перевозка',
      content: <FinalAgreementCard offerData={offerData} />,
    },
  ];

  useEffect(() => {
    const cancelledStatus =
      orderStatus === OrderStatus.CANCELLED_BY_CUSTOMER ||
      orderStatus === OrderStatus.CANCELLED_BY_DELIVERY ||
      offerData?.status === OfferStatuses.CANCELLED_BY_CUSTOMER ||
      offerData?.status === OfferStatuses.CANCELLED_BY_DELIVERY ||
      offerData?.status === OfferStatuses.CANCELLED ||
      offerData?.status === OfferStatuses.OUT_OF_VALIDITY_DATE;

    const orderTakenByAnotherOrg =
      (orderStatus === OrderStatus.AGREEMENT_ACCEPTED ||
        orderStatus === OrderStatus.IN_PROGRESS ||
        orderStatus === OrderStatus.DONE) &&
      !offerData;

    // if (cancelledStatus || orderTakenByAnotherOrg) {
    if (!offerData || offerData.attempt > 3 || orderTakenByAnotherOrg) {
      setCurrent(-1);
    } else if (offerData?.status === OfferStatuses.NEW) {
      setCurrent(0);
    } else if (
      offerData?.status === OfferStatuses.AGREEMENT_REQUEST ||
      offerData?.status === OfferStatuses.PRE_AGREEMENT_LOADED
    ) {
      setCurrent(1);
    } else if (
      offerData?.status === OfferStatuses.ACCEPTED ||
      offerData?.status === OfferStatuses.AGREEMENT_LOADED ||
      offerData?.status === OfferStatuses.DONE
    ) {
      setCurrent(2);
    } else {
      setCurrent(0);
    }
  }, [offerData?.status]);

  return (
    <Col lg={{ span: 14 }} span={24}>
      <Card className="offer">
        <Steps current={current} items={steps} className="offer__steps" />
      </Card>
      {/* {loading && <Loader />} */}
      {current >= 0 && <div className="offer__content-wrap">{steps[current].content}</div>}
      {current < 0 && <div className="offer__content-wrap">{steps[0].content}</div>}
      {current < 0 && contractId && orderStatus === OrderStatus.CANCELLED_BY_DELIVERY && (
        <div className="offer__content-wrap">
          <Space direction="vertical" size={32} className="offer__full-width">
            <Alert
              message={
                <Typography.Text className="offer__info">
                  Заказ отменен, так как вы инициировали принудительное завершение исполнения договора в системе.
                </Typography.Text>
              }
              type="warning"
              showIcon
            />
            {steps[2].content}
          </Space>
        </div>
      )}
    </Col>
  );
};

export default Offer;

import { CargoCategory } from '../../../../../Models/CargoCategories';
import { Dispatch, SetStateAction, useState } from 'react';
import { Table } from 'antd';
import { getCargoCategoriesTableColumns } from './Component/columns';
import { getCargoCategoriesTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import DeleteCategoryModal from '../Modals/DeleteCategoryModal';

export interface CategoriesTableProps {
  data: CargoCategory[];
  loading: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const CargoCategoriesTable = (props: CategoriesTableProps) => {
  const { data, loading, setReload } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<number>(0);
  const handleOpenDeleteModal = (id: number) => {
    setOpenDeleteModal(true);
    setCategoryId(id);
  };
  const handleHideDeleteModal = () => {
    setOpenDeleteModal(false);
    setCategoryId(0);
  };

  const { filteredInfo, setFilteredInfo, handleFilter } = getCargoCategoriesTableFilter();
  const columns = getCargoCategoriesTableColumns(filteredInfo, setFilteredInfo, handleOpenDeleteModal);

  return (
    <div>
      <Table
        bordered
        rowKey={'id'}
        className="table__rates"
        scroll={{ x: 915 }}
        loading={loading}
        columns={columns}
        dataSource={data}
        locale={tableLocaleSettings()}
        pagination={tablePagingSettings}
        onChange={handleFilter}
      />
      <DeleteCategoryModal
        open={openDeleteModal}
        handleCancel={handleHideDeleteModal}
        id={categoryId}
        setReload={setReload}
      />
    </div>
  );
};

export default CargoCategoriesTable;

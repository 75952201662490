import { Helmet } from 'react-helmet-async';
import { Roles } from '../../Constants/Profile';
import { useAppSelector } from '../../Store/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Loader from '../Loader/Loader';

const MainPageWrapper = () => {
  const role = useAppSelector((state) => state.user.role);
  const navigate = useNavigate();

  useEffect(() => {
    switch (role) {
      case Roles.CUSTOMER:
        return navigate('/customer/orders');
      case Roles.DELIVERY:
        return navigate('/organizer/orders');
      case Roles.OPERATOR:
        return navigate('/operator/organizations');
      case Roles.ADMIN:
        return navigate('/admin/users');
    }
  }, [role]);

  return (
    <div
      style={{
        color: '#00bc41',
        width: '250px',
        height: '250px',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
      }}
    >
      <Helmet>
        <title>Авторизация в Личном Кабинете Сайта VERUM LOGISTICS | Сервис Доставки С/Х Техники</title>
        <meta
          name="description"
          content="Авторизуйтесь в личном кабинете на сайте verumlogistics.ru, чтобы получить полный доступ к логистическому сервису. Если вы ранее регистрировались на verum-agro.ru, используйте этот аккаунт для авторизации."
        />
      </Helmet>
      <Loader />
    </div>
  );
};

export default MainPageWrapper;

import { Button, Col, Row, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateClaimModal from '../../Modals/CreateClaimModal';
import React, { useState } from 'react';

const Toolbar: React.FC = () => {
  const [openCreationModal, setOpenCreationModal] = useState<boolean>(false);

  const hideModal = () => setOpenCreationModal(false);
  const showModal = () => setOpenCreationModal(true);

  return (
    <Row className="toolbar__title" justify="space-between">
      <Col>
        <Typography.Title level={2}>Обращения</Typography.Title>
      </Col>
      <Col className="toolbar__btns-panel">
        <Button size={'large'} type="primary" icon={<PlusOutlined />} onClick={showModal}>
          Создать обращение
        </Button>
      </Col>
      <CreateClaimModal open={openCreationModal} handleCancel={hideModal} />
    </Row>
  );
};

export default Toolbar;

import { OrgTypes } from '../Constants/OrgTypes';
import { Organization } from '../Models/Organizations';
import { Roles } from '../Constants/Profile';

// Using to show organizations table filter depending on the type of cell`s data
export const getOrganizationPropertyType = (propName: string) => {
  const o: Organization = {
    businessAddress: '',
    bankName: '',
    bic: '',
    confirmed: false,
    correspondentAccount: '',
    description: '',
    email: '',
    id: 0,
    inn: '',
    kpp: '',
    locked: false,
    managementName: '',
    managementPatronymic: '',
    managementPost: '',
    managementSurname: '',
    name: '',
    ogrn: '',
    ogrnDate: new Date(),
    ogrnip: '',
    okpo: '',
    okved: '',
    paymentAccount: '',
    phone: '',
    postalAddress: '',
    role: Roles.CUSTOMER,
    shortName: '',
    taxationSystem: '',
    type: OrgTypes.INDIVIDUAL,
    fioPDF: '',
  };
  return typeof o[propName];
};

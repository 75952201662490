import { BackTop, Breadcrumb, Col, Row, Typography } from 'antd';

interface ContractHeaderProps {
  title: string;
}

const ContractHeader = (props: ContractHeaderProps) => {
  const { title } = props;

  return (
    <Row justify="space-between" align="middle" gutter={[0, 16]} className="order__header">
      <Col span={24}>
        <BackTop />
        <Breadcrumb className="order__breadcrumb" separator=">">
          <Breadcrumb.Item href="/">Главная страница</Breadcrumb.Item>
          <Breadcrumb.Item href="/operator/contracts">Договоры</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Typography.Text ellipsis>{title}</Typography.Text>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col span={24}>
        <Typography.Title level={2} ellipsis={{ tooltip: false }}>
          {title}
        </Typography.Title>
      </Col>
    </Row>
  );
};

export default ContractHeader;

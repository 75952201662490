import { API } from '../../../../../Services/axios';
import { Rate } from '../../../../../Models/Rate';
import { TransportCoefficients } from '../../../../../Models/TransportCoefficients';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import RatesTable from './Table/RatesTable';
import Toolbar from './Toolbar';

const RateList = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Rate[]>([]);
  const [transportCoefficients, setTransportCoefficients] = useState<TransportCoefficients>({
    truckCoeff: 0,
    trailerCoeff: 0,
  });
  const [defaultRateTruck, setDefaultRateTruck] = useState<Rate>({
    id: 0,
    regionFrom: '',
    regionTo: '',
    displayNameFrom: '',
    cityFrom: '',
    cityTo: '',
    displayNameTo: '',
    price: 0,
  });
  const [defaultRateTrailer, setDefaultRateTrailer] = useState<Rate>({
    id: 0,
    regionFrom: '',
    regionTo: '',
    displayNameFrom: '',
    cityFrom: '',
    cityTo: '',
    displayNameTo: '',
    price: 0,
  });

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/price-matrix`)
      .then((response) => {
        setDefaultRateTruck(response.data.find((rate) => rate.id === 1));
        setDefaultRateTrailer(response.data.find((rate) => rate.id === 2));
        setData(
          sortBy(response.data, (rate) => rate.id)
            .reverse()
            .filter((rate) => rate.regionFrom || rate.regionTo || rate.cityFrom || rate.cityTo),
        );
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
    API.get(`/api/v1/admin/settings/list`)
      .then((response) => {
        const truckCoeff = response.data.find((setting) => setting.name === 'truck_multiplicator').value;
        const trailerCoeff = response.data.find((setting) => setting.name === 'trailer_multiplicator').value;
        setTransportCoefficients({ truckCoeff: truckCoeff, trailerCoeff: trailerCoeff });
        setLoading(false);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div style={{ background: '#fff' }}>
      <Toolbar
        transportCoefficients={transportCoefficients}
        defaultRateTruck={defaultRateTruck}
        defaultRateTrailer={defaultRateTrailer}
        loading={loading}
      />
      <RatesTable data={data} loading={loading} />
    </div>
  );
};

export default RateList;

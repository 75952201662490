import { API } from '../../../../Services/axios';
import { AgreementType, OfferType } from '../../../../Models/Offer';
import { OfferStatuses } from '../../../../Constants/OfferStatuses';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import AgreementForm from './Offer/AgreementForm';
import AgreementInformation from './Offer/AgreementInformation';
import Loader from '../../../../Components/Loader/Loader';

interface AgreementCardProps {
  offerData: OfferType | undefined;
}

const AgreementCard = (props: AgreementCardProps) => {
  const { offerData } = props;
  const [agreement, setAgreement] = useState<AgreementType | null>(null);
  const [agreementId, setAgreementId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/agreement/${offerData!.id}`)
      .then((response) => {
        setAgreement(response.data ?? null);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [agreementId]);

  if (loading) return <Loader />;

  return (
    <Space size={32} direction="vertical" className="offer__full-width">
      {offerData?.status === OfferStatuses.AGREEMENT_REQUEST && !agreement && (
        <AgreementForm offerId={offerData!.id} offerData={offerData} setAgreementId={setAgreementId} />
      )}
      {offerData && agreement && (
        <AgreementInformation
          comment={agreement.comment}
          offerValidityTime={offerData.offerValidityTime}
          offerId={offerData.id}
          file={agreement.fileInfo[0]}
          price={offerData.price}
          dueDatePeriod={offerData.dueDatePeriod}
          status={offerData.status}
          offerData={offerData}
        />
      )}
    </Space>
  );
};

export default AgreementCard;

import { createSlice } from '@reduxjs/toolkit';

export const organizationProfileModalSlice = createSlice({
  name: 'organizationProfileModal',
  initialState: {
    organisationId: null,
  },
  reducers: {
    updateOrganisationId: (state, action) => {
      return {
        ...state,
        organisationId: action.payload,
      };
    },
  },
});

export const { updateOrganisationId } = organizationProfileModalSlice.actions;

export default organizationProfileModalSlice.reducer;

import { API } from '../../../Services/axios';
import { Claim } from '../../../Models/Claims';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../Modals/ErrorModal';
import ClaimsTable from './Table/ClaimsTable';
import Toolbar from './Toolbar';

const Claims = () => {
  const [data, setData] = useState<Claim[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { showErrorModal } = useErrorModal();

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/claim/list')
      .then((response) => {
        if (response.status === 200) {
          const sortedData = sortBy(response.data, 'id').reverse();
          setData(sortedData);
        }
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <Toolbar />
      <div className="contracts__tabs-table">
        <ClaimsTable claims={data} loading={loading} />
      </div>
    </div>
  );
};

export default Claims;

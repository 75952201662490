import { API } from '../../Services/axios';
import { Button, Form, Input, Modal, Row, Select, Space, Tag, Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { ModalProps } from '../../Models/Modal';
import { OrgTypes } from '../../Constants/OrgTypes';
import { PatternFormat } from 'react-number-format';
import { Profile } from '../../Models/Profile';
import { Roles } from '../../Constants/Profile';
import { getKppFieldsRules, getOrgNameFieldRules } from '../../Utils/Rules/registration';
import {
  getOgrnFieldRules,
  getOgrnipFieldRules,
  getOkvedFieldRules,
  getUnrequiredBankFieldRule,
  getUnrequiredEmailFieldRule,
  getUnrequiredNameFieldRuled,
  getUnrequiredNumericFieldRule,
  getUnrequiredOrgNameFieldRule,
  getUnrequiredPostFieldRuled,
  getUrequiredPhoneFieldRule,
} from '../../Utils/Rules/profile';
import { getRequiredFieldRule } from '../../Utils/Rules/order';
import { orgTypeSelectOptions } from '../../Constants/OrgTypesSelectOptions';
import { taxationSysmemSelectOptions } from '../../Constants/TaxationSystem';
import { useAppSelector } from '../../Store/hooks';
import { useErrorModal } from './ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import ModalTitle from './ModalTitle';

interface EditOrgProfileModalProps extends ModalProps {
  profileData: Profile;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const EditOrgProfileModal = (props: EditOrgProfileModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, profileData, setReload } = props;
  const role = useAppSelector((state) => state.user.role);
  const [form] = useForm();
  const orgTypeValue = useWatch('orgType', form);

  const isOrganizationLegal = orgTypeValue === OrgTypes.LEGAL;
  const isDelivery = role === Roles.DELIVERY;

  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    inn: profileData.organisation.inn,
    orgType: profileData.organisation.type,
    name: profileData.organisation.name,
    shortName: profileData.organisation.shortName,
    kpp: profileData.organisation.kpp,
    ogrn: profileData.organisation.ogrn,
    ogrnip: profileData.organisation.ogrnip,
    okpo: profileData.organisation.okpo,
    okved: profileData.organisation.okved,
    // ogrnDate: dayjs(profileData.organisation.ogrnDate).format(defaultDateFormat),
    businessAddress: profileData.organisation.businessAddress,
    postalAddress: profileData.organisation.postalAddress,
    managementName: profileData.organisation.managementName,
    managementPatronymic: profileData.organisation.managementPatronymic,
    fioPDF: profileData.organisation.fioPDF,
    managementPost: profileData.organisation.managementPost,
    managementSurname: profileData.organisation.managementSurname,
    bankName: profileData.organisation.bankName,
    paymentAccount: profileData.organisation.paymentAccount,
    correspondentAccount: profileData.organisation.correspondentAccount,
    bic: profileData.organisation.bic,
    taxationSystem: profileData.organisation.taxationSystem,
    email: profileData.organisation.email,
    phone: profileData.organisation.phone && profileData.organisation.phone.replace(/\D/g, ''),
    description: profileData.organisation.description,
  };

  const onCloseModal = () => {
    handleCancel();
    form.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);
    // const dateValueArr = values.ogrnDate.split('.').reverse();
    // const dateValue = dayjs(dateValueArr).format(serverDateTimeFormat);

    const updatedData = {
      ...profileData.organisation,
      type: values.orgType,
      name: values.name,
      shortName: values.shortName,
      kpp: values.kpp,
      ogrn: values.ogrn,
      ogrnip: values.ogrnip,
      okpo: values.okpo,
      okved: values.okved,
      // ogrnDate: dateValue,
      businessAddress: values.businessAddress,
      postalAddress: values.postalAddress,
      managementName: values.managementName,
      managementPatronymic: values.managementPatronymic,
      fioPDF: values.fioPDF,
      managementPost: values.managementPost,
      managementSurname: values.managementSurname,
      bankName: values.bankName,
      paymentAccount: values.paymentAccount,
      correspondentAccount: values.correspondentAccount,
      bic: values.bic,
      taxationSystem: values.taxationSystem,
      email: values.email,
      phone: values.phone,
      description: values.description,
    };

    API.put('/api/v1/profile/organisation', updatedData)
      .then((response) => {
        setReload(true);
        onCloseModal();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Редактирование данных об организации" handleCancel={onCloseModal} />}
      open={open}
      onCancel={onCloseModal}
      className="modal modal__scrollable"
      footer={
        <Row justify="end">
          <Button size="large" onClick={onCloseModal} className="modal__cancel-btn">
            Отменить
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Сохранить
          </Button>
        </Row>
      }
      centered
      width={645}
      closable={false}
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      <Form
        id="form"
        form={form}
        initialValues={initialValues}
        colon={false}
        layout="vertical"
        className="form"
        onFinish={onFinish}
      >
        <Space direction="vertical" size={56} style={{ width: '100%' }}>
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Typography.Title level={4}>Основная информация</Typography.Title>
            <Form.Item
              name="orgType"
              label={
                <Space>
                  <Typography.Text>Организационно-правовая форма</Typography.Text>
                  {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                </Space>
              }
              rules={getRequiredFieldRule('Выберите организационно-правовую форму организации')}
            >
              <Select options={orgTypeSelectOptions} size="large" disabled />
            </Form.Item>
            <Form.Item
              name="inn"
              label={
                <Space>
                  <Typography.Text>ИНН</Typography.Text>
                  {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                </Space>
              }
              rules={getRequiredFieldRule('')}
            >
              <Input size="large" disabled />
            </Form.Item>
            {orgTypeValue && isOrganizationLegal && (
              <Form.Item
                name="kpp"
                label={
                  <Space>
                    <Typography.Text>КПП</Typography.Text>
                    {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                  </Space>
                }
                validateFirst
                rules={getKppFieldsRules()}
              >
                <Input maxLength={9} size="large" />
              </Form.Item>
            )}
            <Form.Item
              name="shortName"
              label={
                <Space>
                  <Typography.Text>Краткое наименование</Typography.Text>
                  {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                </Space>
              }
              validateFirst
              rules={getOrgNameFieldRules('Введите краткое наименование организации')}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item name="name" label="Полное наименование" validateFirst rules={getUnrequiredOrgNameFieldRule()}>
              <Input size="large" />
            </Form.Item>
            {/* <Form.Item name="ogrnDate" label="Дата внесения в реестр">
          <PatternFormat customInput={Input} size="large" format="##.##.####" mask="_" />
        </Form.Item> */}
            {orgTypeValue && isOrganizationLegal ? (
              <>
                <Form.Item
                  name="businessAddress"
                  label="Юридический адрес"
                  validateFirst
                  rules={getUnrequiredOrgNameFieldRule()}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  name="postalAddress"
                  label={
                    <Space>
                      <Typography.Text>Почтовый адрес</Typography.Text>
                      {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                    </Space>
                  }
                  validateFirst
                  rules={getUnrequiredOrgNameFieldRule()}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  name="ogrn"
                  label={
                    <Space>
                      <Typography.Text>ОГРН</Typography.Text>
                      {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                    </Space>
                  }
                  validateFirst
                  normalize={(value) => value.replace(/\D/g, '')}
                  rules={getOgrnFieldRules()}
                >
                  <Input maxLength={13} size="large" />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name="postalAddress"
                  label={
                    <Space>
                      <Typography.Text>Адрес регистрации</Typography.Text>
                      {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                    </Space>
                  }
                  validateFirst
                  rules={getUnrequiredOrgNameFieldRule()}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  name="ogrnip"
                  label={
                    <Space>
                      <Typography.Text>ОГРНИП</Typography.Text>
                      {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                    </Space>
                  }
                  validateFirst
                  normalize={(value) => value.replace(/\D/g, '')}
                  rules={getOgrnipFieldRules()}
                >
                  <Input maxLength={15} size="large" />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="okpo"
              label={
                <Space>
                  <Typography.Text>ОКПО</Typography.Text>
                  {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                </Space>
              }
              validateFirst
              normalize={(value) => value.replace(/\D/g, '')}
              rules={getUnrequiredNumericFieldRule(isOrganizationLegal ? 8 : 10)}
            >
              <Input maxLength={isOrganizationLegal ? 8 : 10} size="large" />
            </Form.Item>
            <Form.Item
              name="okved"
              label="ОКВЭД"
              validateFirst
              normalize={(value) => value.replace(/[^.\d]/g, '')}
              rules={getOkvedFieldRules()}
            >
              <Input maxLength={8} size="large" />
            </Form.Item>
          </Space>
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Typography.Title level={4}>Платежная информация</Typography.Title>
            <Form.Item
              name="bankName"
              label={
                <Space>
                  <Typography.Text>Банк</Typography.Text>
                  {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                </Space>
              }
              validateFirst
              rules={getUnrequiredBankFieldRule()}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="paymentAccount"
              label={
                <Space>
                  <Typography.Text>Расчетный счет</Typography.Text>
                  {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                </Space>
              }
              validateFirst
              normalize={(value) => value.replace(/\D/g, '')}
              rules={getUnrequiredNumericFieldRule(20)}
            >
              <Input maxLength={20} size="large" />
            </Form.Item>
            <Form.Item
              name="correspondentAccount"
              label={
                <Space>
                  <Typography.Text>Корреспондентский счет</Typography.Text>
                  {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                </Space>
              }
              validateFirst
              normalize={(value) => value.replace(/\D/g, '')}
              rules={getUnrequiredNumericFieldRule(20)}
            >
              <Input maxLength={20} size="large" />
            </Form.Item>
            <Form.Item
              name="bic"
              label={
                <Space>
                  <Typography.Text>БИК</Typography.Text>
                  {isDelivery && <Tag color="processing">Используется для выставления счетов</Tag>}
                </Space>
              }
              validateFirst
              normalize={(value) => value.replace(/\D/g, '')}
              rules={getUnrequiredNumericFieldRule(9)}
            >
              <Input maxLength={9} size="large" />
            </Form.Item>
          </Space>
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Typography.Title level={4}>Информация о руководителе</Typography.Title>
            <Form.Item
              name="managementSurname"
              label="Фамилия руководителя"
              validateFirst
              rules={getUnrequiredNameFieldRuled()}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="managementName"
              label="Имя руководителя"
              validateFirst
              rules={getUnrequiredNameFieldRuled()}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="managementPatronymic"
              label="Отчество руководителя"
              validateFirst
              rules={getUnrequiredNameFieldRuled()}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item name="fioPDF" label="Склонённое ФИО" validateFirst rules={getUnrequiredNameFieldRuled()}>
              <Input size="large" />
            </Form.Item>
            {isOrganizationLegal && (
              <Form.Item
                name="managementPost"
                label="Должность руководителя"
                validateFirst
                rules={getUnrequiredPostFieldRuled()}
              >
                <Input size="large" />
              </Form.Item>
            )}
          </Space>
          <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <Typography.Title level={4}>Дополнительные сведения</Typography.Title>
            <Form.Item name="taxationSystem" label="Система налогообложения">
              <Select options={taxationSysmemSelectOptions} size="large" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Электронная почта организации"
              validateFirst
              rules={getUnrequiredEmailFieldRule()}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Телефон организации"
              normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}
              rules={getUrequiredPhoneFieldRule()}
              validateFirst
            >
              <PatternFormat
                valueIsNumericString
                format="(###) ###-##-##"
                customInput={Input}
                prefix="+7"
                size="large"
                mask="_"
              />
            </Form.Item>
            {profileData.type === Roles.DELIVERY && (
              <Form.Item name="description" label="Краткое описание">
                <Input.TextArea size="large" maxLength={500} showCount className="form__comment" />
              </Form.Item>
            )}
          </Space>
        </Space>
      </Form>
    </Modal>
  );
};

export default EditOrgProfileModal;

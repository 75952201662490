import { CheckCircleFilled, FileOutlined } from '@ant-design/icons';
import { Space, Timeline, Typography } from 'antd';

import { ContractReport } from '../../Models/Contract';
import { ReportStatuses } from '../../Constants/ReportStatuses';
import { convertToMscTime } from '../../Utils/convertToMscTime';

interface ReportTimelineProps {
  reportList: ContractReport[];
}

const ReportTimeline = (props: ReportTimelineProps) => {
  const { reportList } = props;

  const reversedList = [...reportList].reverse();

  const isOrganizerMessage = (item: ContractReport) => item.status === ReportStatuses.NEW;
  const getSystemReportMessage = (item: ContractReport) => {
    switch (item.status) {
      case ReportStatuses.NEW:
        return item.comment && item.comment.includes(';') ? item.comment.split('; ')[0] : item.comment;
      case ReportStatuses.AGREEMENT_LOADED:
        return 'Выполнение заказа';
      default:
        return item.comment && item.comment.includes(';') ? item.comment.split('; ')[0] : item.comment;
    }
  };

  return (
    <Timeline>
      {reversedList.map((item, index) => {
        return (
          <Timeline.Item dot={!isOrganizerMessage(item) && <CheckCircleFilled />} color="#00bc41" key={item.id}>
            <Space direction="vertical">
              <Typography.Text strong={!isOrganizerMessage(item)} className="contract__report-title">
                {getSystemReportMessage(item)}
              </Typography.Text>
              {item.comment && item.comment.split('; ')[1] && (
                <Typography.Text>{`Причина: ${item.comment.split('; ')[1]}`}</Typography.Text>
              )}
              {item.reportFile && item.status === ReportStatuses.CANCELLED && (
                <Space align="center">
                  <FileOutlined className="contract__file-icon" />
                  <Typography.Link className="contract__info-large" href={item.reportFile[0].link}>
                    {item.reportFile[0].name}
                  </Typography.Link>
                </Space>
              )}
              <Typography.Text>{`${convertToMscTime(item.created)} мск`}</Typography.Text>
            </Space>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default ReportTimeline;

import { Alert, Avatar, Card, Col, Row, Space, Typography } from 'antd';
import { OfferStatuses } from '../../../../../Constants/OfferStatuses';
import { OffersListItem } from '../../../../../Models/Offer';
import { UserOutlined } from '@ant-design/icons';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getDayDeclention } from '../../../../../Utils/dayDeclension';
import { useState } from 'react';
import AgreementOfferCardFooter from './AgreementOfferCardFooter';
import BlockedRibbon from '../../../../../Components/Blocked/BlockedRibbon';
import CancelledOfferCardFooter from './CancelledOfferCardFooter';
import FinalAgreementCard from './FinalAgreementCard';
import NewOfferCardFooter from './NewOfferCardFooter';

interface OfferCardProps extends OffersListItem {
  showModal: (organisationId: number) => void;
}

const OfferCard = (props: OfferCardProps) => {
  const {
    id,
    comment,
    organisationName,
    fileInfo,
    dueDatePeriod,
    offerValidityTime,
    price,
    status,
    showModal,
    userId,
    userAvatar,
    organisationLocked,
    attempt,
    lastPrice,
  } = props;

  const [newStatus, setStatusNew] = useState(
    status === OfferStatuses.NEW || status === OfferStatuses.AGREEMENT_REQUEST,
  );
  const [projectReceivedStatus, setStatusProjectReceived] = useState(status === OfferStatuses.PRE_AGREEMENT_LOADED);
  const [cancelledStatus, setStatusCancelled] = useState(
    status === OfferStatuses.CANCELLED ||
      status === OfferStatuses.CANCELLED_BY_CUSTOMER ||
      status === OfferStatuses.CANCELLED_BY_DELIVERY ||
      status === OfferStatuses.OUT_OF_VALIDITY_DATE,
  );
  const [currentStatus, setStatus] = useState(status);

  const acceptedStatus = status === OfferStatuses.ACCEPTED;
  const agreementExistsStatus = status === OfferStatuses.AGREEMENT_LOADED || status === OfferStatuses.DONE;

  const cardTitle = (
    <Row
      justify="space-between"
      className={cancelledStatus ? 'offer__card-title offer__declined' : 'offer__card-title'}
    >
      <Col>
        <Space align="end" size={12}>
          {lastPrice && <div className="offer__new-price"></div>}
          <Avatar
            size={24}
            shape="square"
            icon={<UserOutlined />}
            src={userAvatar?.link}
            onClick={() => showModal(userId)}
          />
          <Typography.Link onClick={() => showModal(userId)} underline>
            {organisationName}
          </Typography.Link>
        </Space>
      </Col>
      <Col>
        {!cancelledStatus && !(currentStatus === OfferStatuses.ACCEPTED) && !organisationLocked && (
          <Typography.Text>
            Предложение действительно {offerValidityTime > 0 && 'еще '}
            <strong>{getDayDeclention(offerValidityTime)}</strong>
          </Typography.Text>
        )}
      </Col>
    </Row>
  );

  const offerCard = (
    <Card title={cardTitle}>
      <Row gutter={[0, 24]}>
        <Space size={16} direction="vertical" className={cancelledStatus ? 'offer__declined' : ''}>
          <Col span={24}>
            {lastPrice && (
              <Typography.Text
                className="offer__info-xxl"
                strong
                style={{ textDecoration: 'line-through', opacity: 0.65, marginRight: '10px' }}
              >
                {`${formatPrice(lastPrice)}`}
              </Typography.Text>
            )}

            <Typography.Text className="offer__info-xxl" strong>
              {`${formatPrice(price)} ₽`}
            </Typography.Text>
            <Typography.Text>&emsp;(с учетом НДС)</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text className="offer__info-xxl" strong>
              {getDayDeclention(dueDatePeriod)}
            </Typography.Text>
            <Typography.Text>&emsp;(срок исполнения)</Typography.Text>
          </Col>

          {attempt && attempt > 1 && status === OfferStatuses.CANCELLED_BY_CUSTOMER && (
            <Alert
              message={
                <Typography.Text className="offer__info">
                  Ранее вы отклоняли предложение этого перевозчика
                </Typography.Text>
              }
              type="warning"
              showIcon
            />
          )}

          {comment && (
            <Col span={24}>
              <Typography className="offer__info-large">{comment}</Typography>
            </Col>
          )}
        </Space>

        {newStatus && <NewOfferCardFooter offerData={props} currentStatus={currentStatus} />}
        {(projectReceivedStatus || acceptedStatus) && (
          <AgreementOfferCardFooter isLocked={organisationLocked} offerId={id} currentStatus={currentStatus} />
        )}
        {cancelledStatus && <CancelledOfferCardFooter offerId={id} fileInfo={fileInfo!} status={currentStatus} />}
      </Row>
    </Card>
  );

  return (
    <>
      {agreementExistsStatus ? (
        <FinalAgreementCard offerId={id} />
      ) : organisationLocked ? (
        <BlockedRibbon isOffer={true}>{offerCard}</BlockedRibbon>
      ) : (
        offerCard
      )}
    </>
  );
};

export default OfferCard;

import { Col, Collapse, Row, Space, Typography } from 'antd';
import { CopyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import YMap from './ymaps/ymaps';

interface AdressesProps {
  from: string;
  to: string;
  pointFrom?: number[];
  pointTo?: number[];
}

const { Panel } = Collapse;

const Address: React.FC<{ address: string }> = (address) => {
  return (
    <Row align="middle" className="order__info-content" style={{ width: '100%' }}>
      <Space align="end">
        <EnvironmentOutlined />
        <Typography.Text
          className="order__info-address order__info-large"
          ellipsis={{ tooltip: true }}
          copyable={{ tooltips: false }}
        >
          {address.address}
        </Typography.Text>
      </Space>
    </Row>
  );
};

const Adresses = (props: AdressesProps) => {
  const { from, to, pointFrom, pointTo } = props;

  return (
    <>
      {!pointFrom && (
        <Col span={24}>
          <Space direction="vertical">
            <Typography.Text className="order__info" type="secondary" strong>
              Адрес отправки
            </Typography.Text>
            <Address address={from} />
          </Space>
        </Col>
      )}
      {pointFrom && (
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="order__info" type="secondary" strong>
              Адрес отправки
            </Typography.Text>
            <Collapse bordered={false}>
              <Panel
                header={
                  <Typography.Text
                    className="order__info-address order__info-large"
                    ellipsis={{ tooltip: true }}
                    copyable={{ tooltips: false }}
                    underline
                  >
                    {from}
                  </Typography.Text>
                }
                key="Адрес отправки"
                className="order__adresses"
              >
                <Space direction="vertical" size={4}>
                  <YMap id="from" coordinates={pointFrom} letter="a" />
                  <Typography.Text
                    className="order__info-address order__info-middle"
                    copyable={{ tooltips: false, text: `${pointFrom[0]}, ${pointFrom[1]}` }}
                  >
                    Копировать координаты
                  </Typography.Text>
                </Space>
              </Panel>
            </Collapse>
          </Space>
        </Col>
      )}
      {!pointTo && (
        <Col span={24}>
          <Space direction="vertical">
            <Typography.Text className="order__info" type="secondary" strong>
              Адрес получения
            </Typography.Text>
            <Address address={to} />
          </Space>
        </Col>
      )}
      {pointTo && (
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="order__info" type="secondary" strong>
              Адрес получения
            </Typography.Text>
            <Collapse bordered={false}>
              <Panel
                header={
                  <Typography.Text
                    className="order__info-address order__info-large"
                    ellipsis={{ tooltip: true }}
                    copyable={{ tooltips: false, icon: <CopyOutlined /> }}
                    underline
                  >
                    {to}
                  </Typography.Text>
                }
                key="Адрес получения"
                className="order__adresses"
              >
                <Space direction="vertical" size={4}>
                  <YMap id="to" coordinates={pointTo} letter="b" />
                  <Typography.Text
                    className="order__info-address order__info-middle"
                    copyable={{ tooltips: false, text: `${pointTo[0]}, ${pointTo[1]}` }}
                  >
                    Копировать координаты
                  </Typography.Text>
                </Space>
              </Panel>
            </Collapse>
          </Space>
        </Col>
      )}
    </>
  );
};

export default Adresses;

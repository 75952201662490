import { Card, Col, Row, Space, Typography } from 'antd';

interface ClaimsProps {
  claimsAmount: number;
}

const Claims = (props: ClaimsProps) => {
  const { claimsAmount } = props;
  return (
    <Card title={<Typography.Title level={4}>Обращения на организацию</Typography.Title>}>
      <Space direction="vertical" size={16}>
        {claimsAmount > 0 ? (
          <Row justify="space-between" className="operator__full-width">
            <Col>Общее количество обращений</Col>
            <Col>{claimsAmount}</Col>
          </Row>
        ) : (
          <Typography.Text>На эту организацию нет обращений</Typography.Text>
        )}
      </Space>
    </Card>
  );
};

export default Claims;

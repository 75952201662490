/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Input, InputRef, Select, Space } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { FilterOutlined } from '@ant-design/icons';
import { defaultDateFormat } from '../../Constants/DateFormats';
import dayjs from 'dayjs';

interface DateFilterProps {
  searchInput: React.RefObject<InputRef>;
  selectedKeys: string[];
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  handleSearch: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => void;
  handleReset: (clearFilters: () => void) => void;
  handleFilterChange: (operator: string) => void;
  confirm: (param?: FilterConfirmProps | undefined) => void;
  dataIndex: any;
  clearFilters: (() => void) | undefined;
  setSearchText: (value: React.SetStateAction<string>) => void;
  setSearchedColumn: (value: React.SetStateAction<string>) => void;
  showSegment: boolean;
  selectedFilter: string;
}

const DateFilter = (props: DateFilterProps) => {
  const {
    searchInput,
    selectedKeys,
    setSelectedKeys,
    handleSearch,
    handleReset,
    handleFilterChange,
    confirm,
    dataIndex,
    clearFilters,
    setSearchText,
    setSearchedColumn,
    showSegment,
    selectedFilter,
  } = props;
  return (
    <div style={{ padding: 8 }}>
      <Select
        defaultValue="eq"
        style={{ marginBottom: 8, display: 'block' }}
        onChange={handleFilterChange}
        options={[
          { value: 'eq', label: 'Точная дата' },
          { value: 'seg', label: 'Диапазон' },
        ]}
      />
      {!showSegment && (
        <Input
          ref={searchInput}
          placeholder={`Значение`}
          value={selectedKeys[0]}
          onChange={(e) => {
            const newSelectedKeys = selectedKeys;
            newSelectedKeys[0] = e.target.value ? e.target.value : '';
            newSelectedKeys[2] = selectedFilter;
            setSelectedKeys(newSelectedKeys);
          }}
          onPressEnter={() => handleSearch([selectedKeys.toString()], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
      )}
      {showSegment && (
        <div>
          <Input
            ref={searchInput}
            placeholder={`От`}
            value={selectedKeys[0]}
            onChange={(e) => {
              const newSelectedKeys = selectedKeys;
              newSelectedKeys[0] = e.target.value ? e.target.value : '';
              newSelectedKeys[2] = selectedFilter;
              setSelectedKeys(newSelectedKeys);
            }}
            onPressEnter={() => handleSearch([selectedKeys.toString()], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Input
            ref={searchInput}
            placeholder={`До`}
            value={selectedKeys[1]}
            onChange={(e) => {
              const newSelectedKeys = selectedKeys;
              newSelectedKeys[1] = e.target.value ? e.target.value : '';
              newSelectedKeys[2] = selectedFilter;
              setSelectedKeys(newSelectedKeys);
            }}
            onPressEnter={() => handleSearch([selectedKeys.toString()], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
        </div>
      )}
      <Space>
        <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Сброс
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<FilterOutlined />}
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys.toString());
            setSearchedColumn(dataIndex);
          }}
        >
          Фильтровать
        </Button>
      </Space>
    </div>
  );
};

const filterDate = (value, filter) => {
  const startDateValues = filter[0].split('.');
  const endDateValues = filter[1]?.split('.') ?? '';
  const startDate = new Date(startDateValues[2], +startDateValues[1] - 1, startDateValues[0]);
  const endDate = new Date(endDateValues[2], +endDateValues[1] - 1, endDateValues[0]);
  switch (filter[2]) {
    case 'eq':
      return dayjs(value).format(defaultDateFormat) === filter[0];
    case 'seg':
      return (
        new Date(new Date(value).setHours(0, 0, 0, 0)) >= startDate &&
        new Date(new Date(value).setHours(0, 0, 0, 0)) <= endDate
      );
    default:
      return false;
  }
};

export { DateFilter, filterDate };

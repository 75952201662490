import { API } from '../../../../Services/axios';
import { AgreementStatuses } from '../../../../Constants/AgreementStatuses';
import { AgreementType, OfferType } from '../../../../Models/Offer';
import { OfferStatuses } from '../../../../Constants/OfferStatuses';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FinalAgreementForm from './Offer/FinalAgreementForm';
import FinalContractInformation from './Offer/FinalAgreementInformation';
import Loader from '../../../../Components/Loader/Loader';

interface AgreementCardProps {
  offerData: OfferType | undefined;
}

const FinalAgreementCard = (props: AgreementCardProps) => {
  const { offerData } = props;
  const { id } = useParams();
  const [agreement, setAgreement] = useState<AgreementType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/agreement/${offerData!.id}`)
      .then((response) => {
        setAgreement(response.data ?? null);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <Loader />;

  return (
    <Space size={32} direction="vertical" className="offer__full-width">
      {offerData?.status === OfferStatuses.ACCEPTED && agreement?.status === AgreementStatuses.NEW && (
        <FinalAgreementForm offerId={offerData.id} offerData={offerData} />
      )}
      {offerData && agreement && agreement?.status !== AgreementStatuses.NEW && (
        <FinalContractInformation id={agreement.id} offerData={offerData} />
      )}
    </Space>
  );
};

export default FinalAgreementCard;

import { Badge, Checkbox, Typography } from 'antd';
import { Contract, ContractTableOrganizerProps } from '../../../../../Models/Contract';
import { OrgTableTabs } from '../Table/Component/empty-message';
import { useSearchParams } from 'react-router-dom';
import ContractsTable from '../Table/ContractsTable';
import React, { Dispatch, SetStateAction, useEffect } from 'react';

interface OrganizerContractsProps extends ContractTableOrganizerProps {
  agreements: Contract[];
  setShowActions: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

export const getContractsTabsItems = (props: OrganizerContractsProps) => {
  const {
    agreements,
    setShowActions,
    selectedRowKeys,
    setSelectedRowKeys,
    loading,
    columnsSettings,
    setTab,
    all,
    in_process,
    awaits_payment,
    finished,
  } = props;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    newSelectedRowKeys.length > 0 ? setShowActions(true) : setShowActions(false);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const toggleSelectAll = (contracts: Contract[]) => {
    setSelectedRowKeys((keys) => (keys.length === contracts.length ? [] : contracts.map((r) => r.agreementId)));
  };

  const headerCheckbox = (contracts: Contract[]) => (
    <Checkbox
      checked={!!selectedRowKeys.length}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < contracts.length}
      onChange={(e) => {
        setShowActions(e.target.checked);
        toggleSelectAll(contracts);
      }}
    />
  );

  const rowSelection = (contracts: Contract[]) => ({
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    columnTitle: headerCheckbox(contracts),
  });

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (+searchParams.get('tab')! <= 0) {
      if (in_process > 0) {
        setTab('in_process');
      } else if (awaits_payment > 0) {
        setTab('awaits_payment');
      } else setTab('all');
    }
  }, [agreements]);

  const tabsItems = [
    {
      key: 'all',
      label: <Typography.Text className="contract__info">Все</Typography.Text>,
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={agreements}
          rowSelection={rowSelection(agreements)}
          loading={loading}
          tabName={OrgTableTabs.ALL}
          totalCount={all}
        />
      ),
    },
    {
      key: 'in_process',
      label: (
        <Typography.Text className="contract__info">
          В работе{' '}
          <Badge
            showZero
            className={in_process === 0 ? 'contract__badge-grey' : 'contract__badge'}
            count={in_process}
          />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={agreements}
          rowSelection={rowSelection(agreements)}
          loading={loading}
          tabName={OrgTableTabs.IN_PROCESS}
          totalCount={in_process}
        />
      ),
    },
    {
      key: 'awaits_payment',
      label: (
        <Typography.Text className="contract__info">
          Ожидают поступления оплаты{' '}
          <Badge
            showZero
            className={awaits_payment === 0 ? 'contract__badge-grey' : 'contract__badge'}
            count={awaits_payment}
          />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={agreements}
          rowSelection={rowSelection(agreements)}
          loading={loading}
          tabName={OrgTableTabs.AWAITS_PAYMENT}
          totalCount={awaits_payment}
        />
      ),
    },
    {
      key: 'finished',
      label: (
        <Typography.Text className="contract__info">
          Завершены <Badge className={'contract__badge-grey'} count={finished} />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={agreements}
          rowSelection={rowSelection(agreements)}
          loading={loading}
          tabName={OrgTableTabs.COMPLETED}
          totalCount={finished}
        />
      ),
    },
  ];

  return tabsItems;
};

import { API } from '../../../../../Services/axios';
import { Agreement } from '../../../../../Models/Agreement';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Grid, Space, Typography } from 'antd';
import { File } from '../../../../../Models/Files';
import { OfferStatuses } from '../../../../../Constants/OfferStatuses';
import { convertToMscTime } from '../../../../../Utils/convertToMscTime';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';

interface CancelledOfferCardFooterProps {
  fileInfo: File[] | undefined;
  status: OfferStatuses;
  offerId: number;
}

const { useBreakpoint } = Grid;

const CancelledOfferCardFooter = (props: CancelledOfferCardFooterProps) => {
  const { showErrorModal } = useErrorModal();
  const { status, fileInfo, offerId } = props;
  const screens = useBreakpoint();
  const smallScreens = (screens.xs || screens.sm) && !screens.md && !screens.lg;

  const [agreements, setAgreements] = useState<Agreement[]>([]);
  useEffect(() => {
    API.get(`/api/v1/agreement/${offerId}/list`)
      .then((response) => setAgreements(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  const declineMessage = () => {
    if (status === OfferStatuses.OUT_OF_VALIDITY_DATE && fileInfo) {
      return 'Срок действия предложения с подтверждением истек';
    } else if ((status === OfferStatuses.CANCELLED_BY_CUSTOMER || status === OfferStatuses.CANCELLED) && fileInfo) {
      return 'Вы отклонили это предложение';
    } else if (status === OfferStatuses.CANCELLED_BY_DELIVERY && fileInfo) {
      return 'Организатор отозвал это предложение';
    } else if (status === OfferStatuses.OUT_OF_VALIDITY_DATE) {
      return 'Срок действия предложения истек';
    } else if (status === OfferStatuses.CANCELLED_BY_CUSTOMER || status === OfferStatuses.CANCELLED) {
      return 'Вы отклонили это предложение';
    } else if (status === OfferStatuses.CANCELLED_BY_DELIVERY) {
      return 'Организатор отозвал это предложение';
    }
  };

  return (
    <Col span={24}>
      <Space size={24} direction="vertical" className="offer__full-width offer__declined">
        {agreements &&
          agreements.map((agreement) => {
            return (
              <Space key={agreement.id} size={16} direction="vertical">
                <Space size={12} direction="vertical">
                  <Space size={smallScreens ? 12 : 24} direction={smallScreens ? 'vertical' : 'horizontal'}>
                    {/*<Space size={10}>*/}
                    {/*  <FileOutlined className="offer__file-icon" />*/}
                    {/*  <Typography.Link className="offer__text" href={agreement.fileInfo[0].link}>*/}
                    {/*    {agreement.fileInfo[0].name}*/}
                    {/*  </Typography.Link>*/}
                    {/*</Space>*/}
                    {`${convertToMscTime(agreement.created)} мск`}
                  </Space>
                  {agreement.comment && <Typography className="offer__info-large">{agreement.comment}</Typography>}
                </Space>
                {agreement !== agreements[agreements.length - 1] && <Divider />}
              </Space>
            );
          })}
        <Space size={12} align="start">
          <CloseCircleOutlined className="offer__icon-declined" />
          <Typography className="offer__info-large">{declineMessage()}</Typography>
        </Space>
      </Space>
    </Col>
  );
};

export default CancelledOfferCardFooter;

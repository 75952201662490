import { Rule } from 'antd/lib/form';
import { getRequiredFieldRule } from './order';

export const getRequiredClaimFieldRules = (number: number): Rule[] => [
  ...getRequiredFieldRule('Поле обязательно для заполнения'),
  {
    max: number,
    message: `Вводимое значение не может быть больше ${number} символов`,
  },
];

import { Badge, Checkbox, Typography } from 'antd';
import { Order } from '../../../../../../Models/Order';
import { OrganizerTableTabs } from '../Table/Component/empty-message';
import { useSearchParams } from 'react-router-dom';
import OrdersTable from '../Table/OrdersTable';
import React, { useEffect } from 'react';

export const getOrdersTabsItems = (props) => {
  const {
    columnsSettings,
    setShowActions,
    selectedRowKeys,
    setSelectedRowKeys,
    setActiveTab,
    loading,
    awaits,
    offered,
    awaits_agreement,
    pre_agreement_loaded,
    accepted,
    signed,
    archive,
    orders,
  } = props;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    newSelectedRowKeys.length > 0 ? setShowActions(true) : setShowActions(false);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const toggleSelectAll = (orders: Order[]) => {
    setSelectedRowKeys((keys) => (keys.length === orders.length ? [] : orders.map((r) => r.id)));
  };

  const headerCheckbox = (orders: Order[]) => (
    <Checkbox
      checked={!!selectedRowKeys.length}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < orders.length}
      onChange={(e) => {
        setShowActions(e.target.checked);
        toggleSelectAll(orders);
      }}
    />
  );

  const rowSelection = (orders: Order[]) => ({
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    columnTitle: headerCheckbox(orders),
  });

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (+searchParams.get('tab')! <= 0) {
      // if (ordersAtSighing.length > 0) setActiveTab(5);
      // else
      if (awaits_agreement > 0) setActiveTab('awaits_agreement');
      else if (pre_agreement_loaded > 0) setActiveTab('pre_agreement_loaded');
      else if (offered > 0) setActiveTab('offered');
      else if (signed) setActiveTab('signed');
      else setActiveTab('awaits');
    }
  }, [orders]);

  const tabsItems = [
    {
      key: 'awaits',
      label: (
        <Typography.Text id="1" className="order__info">
          Ждут предложения <Badge showZero className={'order__badge-grey'} count={awaits} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          rowSelection={rowSelection(orders)}
          loading={loading}
          tabName={OrganizerTableTabs.NEW}
          totalCount={awaits}
        />
      ),
    },
    {
      key: 'offered',
      label: (
        <Typography.Text id="2" className="order__info">
          Выслано предложение <Badge showZero className={'order__badge-grey'} count={offered} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          rowSelection={rowSelection(orders)}
          loading={loading}
          tabName={OrganizerTableTabs.WITH_OFFER}
          totalCount={offered}
        />
      ),
    },
    {
      key: 'awaits_agreement',
      label: (
        <Typography.Text id="3" className="order__info">
          Ждут подтверждения{' '}
          <Badge
            showZero
            className={awaits_agreement === 0 ? 'order__badge-grey' : 'order__badge'}
            count={awaits_agreement}
          />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          rowSelection={rowSelection(orders)}
          loading={loading}
          tabName={OrganizerTableTabs.WAITING_FOR_AGREEMENT}
          totalCount={awaits_agreement}
        />
      ),
    },
    {
      key: 'pre_agreement_loaded',
      label: (
        <Typography.Text id="4" className="order__info">
          Подтвержденные <Badge showZero className={'order__badge-grey'} count={pre_agreement_loaded} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          rowSelection={rowSelection(orders)}
          loading={loading}
          tabName={OrganizerTableTabs.WITH_AGREEMENT}
          totalCount={pre_agreement_loaded}
        />
      ),
    },
    // {
    //   key: '5',
    //   label: (
    //     <Typography.Text id="5" className="order__info">
    //       На подписании{' '}
    //       <Badge
    //         showZero
    //         className={ordersAtSighing.length === 0 ? 'order__badge-grey' : 'order__badge'}
    //         count={ordersAtSighing.length}
    //       />
    //     </Typography.Text>
    //   ),
    //   children: (
    //     <OrdersTable
    //       columnsSettings={columnsSettings}
    //       data={ordersAtSighing}
    //       rowSelection={rowSelection(ordersAtSighing)}
    //       loading={loading}
    //       tabName={OrganizerTableTabs.AT_SIGNING}
    //     />
    //   ),
    // },
    {
      key: 'signed',
      label: (
        <Typography.Text id="5" className="order__info">
          В исполнении <Badge showZero className={'order__badge-grey'} count={signed} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          rowSelection={rowSelection(orders)}
          loading={loading}
          tabName={OrganizerTableTabs.WITH_FINAL_AGREEMENT}
          totalCount={signed}
        />
      ),
    },
    {
      key: 'archive',
      label: (
        <Typography.Text id="6" className="order__info">
          Архив
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          rowSelection={rowSelection(orders)}
          loading={loading}
          tabName={OrganizerTableTabs.ARCHIVED}
          totalCount={archive}
        />
      ),
    },
  ];

  return tabsItems;
};

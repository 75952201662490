/* eslint-disable @typescript-eslint/no-explicit-any */
import { defaults, fromPairs } from 'lodash';
import { saveInitialColsWidth } from './saveInitialColsWidth';

// Updates columns` width saved in localStorage when it changes
export const updateColsWidth = (localStorageItem: string, newColumns: any, allColumns: any) => {
  const newColsWidth = newColumns.map((col) => [col.key, col.width]);
  const flattenNewColsWidth = fromPairs(newColsWidth);
  const allColumnsWidth = allColumns.map((col) => [col.key, col.width]);
  const flattenAllColsWidth = fromPairs(allColumnsWidth);
  const widthToSave = JSON.stringify(
    defaults({
      ...flattenAllColsWidth,
      ...flattenNewColsWidth,
    }),
  );

  saveInitialColsWidth(localStorageItem, widthToSave);
};

import { Claim } from '../../../../../../Models/Claims';
import { TableProps } from 'antd';
import { useState } from 'react';
import type { FilterValue } from 'antd/lib/table/interface';

export const getClaimsTableFilter = () => {
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const handleFilter: TableProps<Claim>['onChange'] = (_, filters) => {
    setFilteredInfo(filters);
  };

  return {
    filteredInfo,
    setFilteredInfo,
    handleFilter,
  };
};

import { OfferStatuses } from '../Constants/OfferStatuses';
import { Order } from '../Models/Order';
import { OrderStatus } from '../Constants/Order';

// Using to show orders table filter depending on the type of cell`s data
export const getOrderPropertyType = (propName: string) => {
  const o: Order = {
    id: 0,
    key: 0,
    acceptedAgreementId: 0,
    acceptedAgreementUserFriendlyId: '',
    addressFrom: '',
    addressTo: '',
    cityFrom: '',
    cityTo: '',
    agreementsCount: 0,
    cargoTypeNameWithParentName: '',
    machinePrice: 0,
    comment: '',
    customerId: 0,
    createdAt: new Date(),
    dateArrival: new Date(),
    dateLoading: new Date(0),
    distance: 0,
    executorOrderLink: '',
    files: [],
    finishCoordinate: '',
    height: 0,
    length: 0,
    machineCategoryId: 0,
    machineName: '',
    offersCount: 0,
    executorOffersCount: 0,
    offerStatus: OfferStatuses.NEW,
    organisationId: 0,
    organisationName: '',
    organisationLocked: false,
    phone: '',
    photos: [],
    pointFrom: '',
    pointTo: '',
    price: 0,
    specificationLink: '',
    startCoordinate: '',
    status: OrderStatus.NEW,
    title: '',
    userId: 0,
    userFriendlyId: 0,
    weight: 0,
    width: 0,
    draft: false,
    transport: 'TRUCK',
  };
  return typeof o[propName];
};

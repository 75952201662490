import { API } from '../../../Services/axios';
import { Button, Col, Row, notification } from 'antd';
import { Profile } from '../../../Models/Profile';
import { clickDownloadLink } from '../../../Utils/clickDownloadLink';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../Components/Modals/ErrorModal';
import BillCard from './Component/bill-card';
import BillHeader from './Component/bill-header';
import Loader from '../../../Components/Loader/Loader';

const Bill = () => {
  const { showErrorModal } = useErrorModal();
  const [profileData, setProfileData] = useState<Profile>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/profile`)
      .then((response) => setProfileData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  if (loading || !profileData) return <Loader />;

  const downloadBill = () => {
    setLoadingBtn(true);

    API.get('/api/v1/profile/verification', {
      responseType: 'blob',
    })
      .then((response) => {
        const blob = response.data;
        clickDownloadLink(blob, 'Счет на оплату.pdf');
        notification.success({ message: 'Счет успешно скачан', placement: 'top' });
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoadingBtn(false);
      });
  };

  return (
    profileData && (
      <Row justify="center" className="profile__container profile__bill" gutter={[0, 32]}>
        <Col lg={{ span: 24 }} span={24}>
          <BillHeader />
        </Col>
        <BillCard profileData={profileData} />
        <Col span={24}>
          <Button type="primary" size="large" onClick={downloadBill} loading={loadingBtn}>
            Скачать в формате PDF
          </Button>
        </Col>
      </Row>
    )
  );
};

export default Bill;

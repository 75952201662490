import { Avatar, Card, Col, Row, Space, Typography } from 'antd';
import { OfferStatuses } from '../../../../../Constants/OfferStatuses';
import { OffersListItem } from '../../../../../Models/Offer';
import { UserOutlined } from '@ant-design/icons';
import { formatPrice } from '../../../../../Utils/formatPrice';

interface CancelledAcceptedOfferCardProps extends OffersListItem {
  showModal: (organisationId: number) => void;
}

const CancelledAcceptedOfferCard = (props: CancelledAcceptedOfferCardProps) => {
  const { comment, organisationName, fileInfo, price, status, userAvatar, userId, showModal } = props;

  const agreementExistsStatus =
    status === OfferStatuses.AGREEMENT_LOADED ||
    status === OfferStatuses.DONE ||
    status === OfferStatuses.CANCELLED_BY_DELIVERY;

  const cardTitle = (
    <Row justify="space-between" className="offer__card-title">
      <Col>
        <Space align="end" size={12}>
          <Avatar
            size={24}
            shape="square"
            icon={<UserOutlined />}
            src={userAvatar?.link}
            onClick={() => showModal(userId)}
          />
          <Typography.Link onClick={() => showModal(userId)} underline>
            {organisationName}
          </Typography.Link>
        </Space>
      </Col>
    </Row>
  );

  return (
    <>
      {/*{agreementExistsStatus && <FinalAgreementCard file={fileInfo[0]} />}*/}
      <Card title={cardTitle}>
        <Row gutter={[0, 24]}>
          <Space size={16} direction="vertical">
            <Col span={24}>
              <Typography.Text className="offer__info-xxl" strong>
                {`${formatPrice(price)} ₽`}
              </Typography.Text>
              <Typography.Text>&emsp;(с учетом НДС)</Typography.Text>
            </Col>

            {comment && (
              <Col span={24}>
                <Typography className="offer__info-large">{comment}</Typography>
              </Col>
            )}
          </Space>

          {/*<Col span={24}>*/}
          {/*  <Space size={24} direction="vertical">*/}
          {/*    <Space>*/}
          {/*      <FileOutlined className="offer__file-icon" />*/}
          {/*      <Typography.Link className="offer__text" href={fileInfo[0].link}>*/}
          {/*        {fileInfo[0].name}*/}
          {/*      </Typography.Link>*/}
          {/*    </Space>*/}
          {/*  </Space>*/}
          {/*</Col>*/}
        </Row>
      </Card>
    </>
  );
};

export default CancelledAcceptedOfferCard;

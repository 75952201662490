import { Button, Row, Space } from 'antd';

interface ModalTitleProps {
  okText: string;
  cancelText: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  disabled?: boolean;
}

const ModalFooter = (props: ModalTitleProps) => {
  const { okText, cancelText, handleConfirm, handleCancel, disabled } = props;
  return (
    <Row justify="end">
      <Space>
        <Button size="large" onClick={handleCancel} disabled={disabled}>
          {cancelText}
        </Button>
        <Button size="large" type="primary" onClick={handleConfirm} disabled={disabled}>
          {okText}
        </Button>
      </Space>
    </Row>
  );
};

export default ModalFooter;

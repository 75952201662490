import { API } from '../../Services/axios';
import { Alert, Button, Col, Form, Input, Modal, Row, Space, Upload, UploadProps } from 'antd';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { ModalProps } from '../../Models/Modal';
import { ReportStatuses } from '../../Constants/ReportStatuses';
import { beforeUpload } from '../../Utils/beforeUpload';
import { getRequiredFieldRule } from '../../Utils/Rules/order';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ModalTitle from './ModalTitle';

const CancelContractModal = (props: ModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();
  const { open, handleCancel } = props;
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const uploadProps: UploadProps = {
    name: 'act',
    accept: '.pdf,.docx,.xlsx,.txt,.rtf,.png,.jpg,.jpeg,.heic',
    maxCount: 1,
    iconRender: () => <PaperClipOutlined className="form__upload-icon" />,
    beforeUpload: beforeUpload,
    customRequest: ({ file, onSuccess }) =>
      setTimeout(() => {
        onSuccess!('ok');
      }, 0),
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined className="form__remove-icon" />,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    return e?.fileList;
  };

  const onFinish = (values) => {
    setLoading(true);
    const reportData = {
      agreementId: id,
      comment: 'Договор не может быть исполнен' + '; ' + values.comment,
      status: ReportStatuses.CANCELLED,
    };

    const formData = new FormData();

    if (values.file && values.file.length > 0) {
      formData.append('file', values.file['0'].originFileObj);
    }
    formData.append('json', JSON.stringify(reportData));

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    API.post('/api/v1/report', formData, headers)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={
        <ModalTitle level={3} title="Вы уверены, что договор не может быть исполнен?" handleCancel={handleCancel} />
      }
      open={open}
      onCancel={handleCancel}
      className="modal modal__info"
      footer={
        <Row justify="end" align="middle" gutter={[8, 8]}>
          <Col>
            <Button size="large" onClick={handleCancel}>
              Вернуться к договору
            </Button>
          </Col>
          <Col>
            <Button size="large" type="primary" htmlType="submit" form="form" loading={loading}>
              Подтвердить
            </Button>
          </Col>
        </Row>
      }
      centered
      width={645}
      closable={false}
    >
      <Space direction="vertical" size={24}>
        <Alert
          message="Исполнение договора в системе остановится. Такой договор нельзя будет вернуть в работу"
          type="warning"
          showIcon
        />

        <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
          <Form.Item
            label="Укажите причину, по которой договор не может быть исполнен"
            name="comment"
            rules={getRequiredFieldRule('Введите причину, по которой договор не может быть исполнен')}
          >
            <Input.TextArea size="large" maxLength={500} showCount className="form__comment" />
          </Form.Item>
          <Form.Item
            label="Прикрепите соглашение о расторжении договора"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra="Для загрузки выберите файл с расширением .pdf, .docx, .xlsx, .txt, .rtf, .png, .jpg, .jpeg, .heic"
          >
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />} size="large">
                Загрузить
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

export default CancelContractModal;

import { configureStore } from '@reduxjs/toolkit';
import claimIdReducer from './Slices/claimIdSlice';
import customerContractsColsReduser from './Slices/customerContractsColsSlice';
import customerOrdersColsReducer from './Slices/customerOrdersColsSlice';
import orderReducer from './Slices/orderSlice';
import orgContractsColsReducer from './Slices/orgContractsColsSlice';
import orgOrdersColsReducer from './Slices/orgOrdersColsSlice';
import organizationProfileModalReduser from './Slices/organizationProfileModalSlice';
import userReducer from './Slices/userSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    order: orderReducer,
    orgOrdersCols: orgOrdersColsReducer,
    customerOrdersCols: customerOrdersColsReducer,
    orgContractsCols: orgContractsColsReducer,
    customerContractsCols: customerContractsColsReduser,
    organizationProfileModal: organizationProfileModalReduser,
    claimId: claimIdReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'orgOrdersCols/getUpdatedOrgColumns',
          'customerOrdersCols/getUpdatedCustomerColumns',
          'customerContractsCols/getUpdatedCustomerColumns',
          'orgContractsCols/getUpdatedOrgColumns',
        ],
        ignoredPaths: ['orgOrdersCols', 'customerOrdersCols', 'orgContractsCols', 'customerContractsCols'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  TypeSelector,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps';
import React from 'react';

const YMap: React.FC<{ id: string; coordinates: number[]; letter: string }> = ({ id, coordinates, letter }) => {
  return (
    <YMaps
      query={{
        apikey: process.env.REACT_APP_YMAPS_API_KEY,
        suggest_apikey: process.env.REACT_APP_YMAPS_SUGGEST_API_KEY,
        lang: 'ru_RU',
      }}
    >
      <Map
        id={`${id}_ymap`}
        state={{
          center: coordinates,
          zoom: 16,
        }}
        width="100%"
        height="25vh"
      >
        <Placemark
          key={'A'}
          geometry={coordinates}
          options={{
            iconLayout: 'default#image',
            iconImageSize: [50, 50],
            iconImageHref: `https://img.icons8.com/ios-filled/2x/marker-${letter}.png`,
            draggable: false,
          }}
        />
        <GeolocationControl
          options={{
            float: 'left',
          }}
        />
        <ZoomControl />
        <TypeSelector />
        <FullscreenControl
          options={{
            float: 'right',
          }}
        />
      </Map>
    </YMaps>
  );
};

export default YMap;

import { Rate } from '../../../../../../Models/Rate';
import { Table } from 'antd';
import { getRatesTableColumns } from './Component/columns';
import { getRatesTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useState } from 'react';
import DeleteRateModal from '../Modals/DeleteRateModal';
import EditRateModal from '../Modals/EditRateModal';

export interface RatesTableProps {
  data: Rate[];
  loading: boolean;
}

const RatesTable = (props: RatesTableProps) => {
  const { data, loading } = props;

  const [clickedRate, setClickedRate] = useState<Rate>();
  const [openEditRateModal, setOpenEditRateModal] = useState<boolean>(false);
  const handleOpenEditRateModal = () => setOpenEditRateModal(true);
  const handleCancelEditRateModal = () => {
    const f = document.getElementById('formEdit') as HTMLFormElement;
    f.reset();
    setOpenEditRateModal(false);
    setClickedRate(undefined);
  };

  const [openDeleteRateModal, setOpenDeleteRateModal] = useState<boolean>(false);
  const handleOpenDeleteRateModal = () => setOpenDeleteRateModal(true);
  const handleCancelDeleteRateModal = () => setOpenDeleteRateModal(false);

  const { filteredInfo, setFilteredInfo, handleFilter } = getRatesTableFilter();
  const columns = getRatesTableColumns(
    filteredInfo,
    setFilteredInfo,
    setClickedRate,
    setOpenEditRateModal,
    setOpenDeleteRateModal,
  );

  return (
    <div>
      <Table
        bordered
        rowKey={'id'}
        className="table__rates"
        scroll={{ x: 1300 }}
        loading={loading}
        columns={columns}
        dataSource={data}
        locale={tableLocaleSettings()}
        pagination={tablePagingSettings}
        onChange={handleFilter}
      />
      {clickedRate && (
        <EditRateModal open={openEditRateModal} handleCancel={() => handleCancelEditRateModal()} rate={clickedRate} />
      )}
      {clickedRate && (
        <DeleteRateModal
          open={openDeleteRateModal}
          handleCancel={() => handleCancelDeleteRateModal()}
          rate={clickedRate}
        />
      )}
    </div>
  );
};

export default RatesTable;

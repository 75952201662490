import { API } from '../../../../Services/axios';
import { Card, Col, Row, Space, Typography } from 'antd';
import { Contract } from '../../../../Models/Contract';
import { LinkOutlined } from '@ant-design/icons';
import { defaultDateFormat } from '../../../../Constants/DateFormats';
import { formatPrice } from '../../../../Utils/formatPrice';
import { getMaskedPhoneNumberInOrder } from '../../../../Utils/getPhoneMasked';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import Adresses from '../../../../Components/Adresses/Adresses';
import CargoDimensions from '../../../../Components/CargoDimensions/CargoDimensions';
import ContractHeader from './Component/contract-header';
import OrderAttachments from '../../../../Components/Order/OrderAttachments';
import Report from './Component/report';
import dayjs from 'dayjs';

const OperatorContractDetails = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();

  const [data, setData] = useState<Contract>();
  useEffect(() => {
    API.get(`/api/v1/agreement/card/${id}`)
      .then((response) => setData(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  return (data && (
    <Row justify="center" className="contract__container" gutter={[24, 32]}>
      <Col lg={{ span: 24 }} span={24}>
        <ContractHeader title="Информация о договоре" />
      </Col>
      <Col lg={{ span: 10, order: 0 }} span={24} order={1}>
        <Card className="contract__card" title={<Typography.Title level={4}>Информация о договоре</Typography.Title>}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Желаемая дата доставки
                </Typography.Text>
                <Typography.Text className="contract__info-large" strong>
                  {dayjs(data.arrival).format(defaultDateFormat)}
                </Typography.Text>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Стоимость услуги по договору
                </Typography.Text>
                <Typography.Text className="contract__info-large" strong>
                  {`${formatPrice(data.price)} ₽`}
                </Typography.Text>
              </Space>
            </Col>

            {/*<Col span={24}>*/}
            {/*  <Space direction="vertical" size={16}>*/}
            {/*    <Typography.Text className="contract__info" type="secondary" strong>*/}
            {/*      Файл подписанного договора*/}
            {/*    </Typography.Text>*/}
            {/*    <Space size={8} align="center">*/}
            {/*      <FileOutlined className="contract__file-icon" />*/}
            {/*      <Typography.Link className="contract__info-large" href={data.finalAgreementFile[0].link}>*/}
            {/*        {data.finalAgreementFile[0].name}*/}
            {/*      </Typography.Link>*/}
            {/*    </Space>*/}
            {/*    <Typography.Text className="order__contract-text">*/}
            {/*      Договор создан и подписан на основании предложения и проекта договора к заказу&nbsp;*/}
            {/*      <Typography.Link href={`/operator/orders/${data.orderId}`} className="contract__info" underline>*/}
            {/*        {data.orderUserFriendlyId}*/}
            {/*      </Typography.Link>*/}
            {/*    </Typography.Text>*/}
            {/*  </Space>*/}
            {/*</Col>*/}

            <Adresses
              from={data.addressFrom}
              to={data.addressTo}
              pointFrom={data.pointFrom.split(',').map((point) => {
                return +point;
              })}
              pointTo={data.pointTo.split(',').map((point) => {
                return +point;
              })}
            />

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Рассчетное расстояние
                </Typography.Text>
                <Typography className="contract__info-large">{`${data.distance} км`}</Typography>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Заказчик
                </Typography.Text>
                <Typography.Link
                  className="contract__info-large"
                  href={`/operator/organizations/${data.customerOrganisationId}`}
                  underline
                >
                  {data.customerOrganisationName}
                </Typography.Link>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Телефон для уточнения деталей
                </Typography.Text>
                <Typography.Text
                  className="contract__info-large contract__info-copyable"
                  copyable={{ tooltips: false }}
                >
                  {data.customerPhone && getMaskedPhoneNumberInOrder(data.customerPhone)}
                </Typography.Text>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Категория техники
                </Typography.Text>
                <Typography className="contract__info-large">{data.cargoTypeNameWithParentName}</Typography>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Модель техники
                </Typography.Text>
                {data.specificationLink ? (
                  <Space size={12} align="center">
                    <Typography.Link href={data.specificationLink} className="contract__info-large" underline>
                      {data.machineName}
                    </Typography.Link>
                    <Typography.Link href={data.specificationLink}>
                      <LinkOutlined />
                    </Typography.Link>
                  </Space>
                ) : (
                  <Typography.Text className="contract__info-large">{data.machineName}</Typography.Text>
                )}
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Стоимость перевозимой техники
                </Typography.Text>
                <Typography className="contract__info-large">{`${formatPrice(data.machinePrice)} ₽`}</Typography>
              </Space>
            </Col>

            <CargoDimensions height={data.height} weight={data.weight} width={data.width} length={data.length} />

            <OrderAttachments files={data.orderFiles} photos={data.orderPhotos} comment={data.comments} />
          </Row>
        </Card>
      </Col>
      <Col lg={{ span: 14 }} span={24}>
        <Report
          executorOrganisationId={data.executorOrganisationId}
          executorOrganisationName={data.executorOrganisationName}
        />
      </Col>
    </Row>
  ))!;
};

export default OperatorContractDetails;

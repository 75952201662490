import { ColumnsType } from 'antd/lib/table';
import { Roles } from '../../../../../../Constants/Profile';
import { SystemMessage } from '../../../../../../Models/SystemMessage';
import { Typography } from 'antd';

export const getSystemMessagesTableColumns = (handleOpenEditModal: (record: SystemMessage) => void) => {
  const columns: ColumnsType<SystemMessage> = [
    {
      title: 'Роль',
      dataIndex: 'roles',
      key: 'roles',
      width: 229,
      render: (_, record) =>
        record.roles
          .map((item) => {
            switch (item) {
              case Roles.OPERATOR:
                return 'Оператор';
              case Roles.DELIVERY:
                return 'Организатор';
              case Roles.CUSTOMER:
                return 'Заказчик';
              case Roles.ADMIN:
                return 'Администратор';
            }
          })
          .join(', '),
    },
    {
      title: 'Используется',
      dataIndex: 'shortName',
      key: 'shortName',
      width: 212,
    },
    {
      title: 'Текст',
      dataIndex: 'message',
      key: 'message',
      ellipsis: true,
      width: 694,
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 198,
      render: (_, record) => (
        <Typography.Link onClick={() => handleOpenEditModal(record)} underline>
          Редактировать
        </Typography.Link>
      ),
    },
  ];

  return columns;
};

import { API } from '../../../../../Services/axios';
import { Button, Col, Popconfirm, Space, Typography } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import { OfferStatuses } from '../../../../../Constants/OfferStatuses';
import { OffersListItem } from '../../../../../Models/Offer';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useState } from 'react';

interface NewOfferCardFooterProps {
  offerData: OffersListItem;
  currentStatus: OfferStatuses;
}

const NewOfferCardFooter = (props: NewOfferCardFooterProps) => {
  const { showErrorModal } = useErrorModal();
  const { offerData, currentStatus } = props;
  const [isRequested, setIsRequested] = useState(offerData.status === OfferStatuses.AGREEMENT_REQUEST);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDecline, setLoadingDecline] = useState<boolean>(false);

  const requestContractProject = () => {
    setLoading(true);
    API.put(`/api/v1/offer/agreement_request/${offerData.id}`)
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setIsRequested(true);
      });
  };

  const declineOffer = () => {
    setLoadingDecline(true);
    API.put(`/api/v1/offer/decline/${offerData.id}`)
      .then((response) => location.reload())
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoadingDecline(false));
  };

  return (
    <>
      {isRequested && currentStatus !== OfferStatuses.CANCELLED_BY_CUSTOMER ? (
        <Col span={24} className="offer__footer">
          <Space align="center" size={16}>
            <FieldTimeOutlined className="offer__icon-requested" />
            <Typography className="offer__info-large">Ожидаем подтверждение от перевозчика</Typography>
            <Popconfirm
              placement="bottom"
              title={'Вы уверены, что хотите отклонить предложение?'}
              getPopupContainer={(trigger) => trigger.parentElement!}
              onConfirm={declineOffer}
              okText="Подтвердить"
              showCancel={false}
              okButtonProps={{ size: 'middle' }}
              cancelButtonProps={{ size: 'middle' }}
            >
              <Button size="large">Отклонить предложение</Button>
            </Popconfirm>
          </Space>
        </Col>
      ) : currentStatus === OfferStatuses.CANCELLED_BY_CUSTOMER ? (
        <></>
      ) : (
        <Col span={24} className="offer__footer">
          <Space size={16}>
            <Button
              type="primary"
              size="large"
              onClick={requestContractProject}
              loading={loading}
              disabled={offerData.organisationLocked}
            >
              Запросить подтверждение
            </Button>
            <Popconfirm
              placement="bottom"
              title={'Вы уверены, что хотите отклонить предложение?'}
              getPopupContainer={(trigger) => trigger.parentElement!}
              onConfirm={declineOffer}
              okText="Подтвердить"
              cancelText="Отменить"
              okButtonProps={{ size: 'middle' }}
              cancelButtonProps={{ size: 'middle' }}
            >
              <Button size="large" loading={loadingDecline}>
                Отклонить предложение
              </Button>
            </Popconfirm>
          </Space>
        </Col>
      )}
    </>
  );
};

export default NewOfferCardFooter;

import { Col, Row, Tooltip, Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import EditExecutorLinkModal from '../../../../../Components/Modals/EditExecutorLinkModal';

interface ExecutorLinkProps {
  orderId: number;
  link?: string;
  isVerified: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const ExecutorLink = (props: ExecutorLinkProps) => {
  const { link, isVerified, orderId, setReload } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = () => setOpenModal(true);
  const hideModal = () => setOpenModal(false);

  return link ? (
    <Row align="middle" gutter={[0, 12]}>
      <Col span={24}>
        <Typography.Link className="contract__info contract__info-large" onClick={showModal} underline>
          Редактировать внешнюю ссылку или номер заказа
        </Typography.Link>
        <Tooltip
          className="contract__info-icon"
          title="Вы можете отредактировать номер договора в вашей системе или ссылку из внешнего ресурса, этот комментарий не увидят другие пользователи."
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Col>
      <Col span={24}>
        <Typography.Text className="contract__info contract__info-large">{link}</Typography.Text>
      </Col>
      <EditExecutorLinkModal
        open={openModal}
        handleCancel={hideModal}
        orderId={orderId}
        setReload={setReload}
        link={link}
      />
    </Row>
  ) : (
    <Row align="middle">
      <Typography.Link
        className="contract__info contract__info-large"
        underline
        disabled={!isVerified}
        onClick={showModal}
      >
        Добавить внешнюю ссылку или номер заказа
      </Typography.Link>
      <Tooltip
        className="contract__info-icon"
        title="Вы можете добавить номер договора в вашей системе или ссылку из внешнего ресурса, этот комментарий не увидят другие пользователи."
      >
        <QuestionCircleOutlined />
      </Tooltip>
      <EditExecutorLinkModal open={openModal} handleCancel={hideModal} orderId={orderId} setReload={setReload} />
    </Row>
  );
};

export default ExecutorLink;

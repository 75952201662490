import { Col, Row, Tabs, Typography } from 'antd';
import { getClaimsTabsItems } from './Component/tab-items';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

const OperatorClaims = () => {
  const tabsItems = getClaimsTabsItems();

  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab') ?? '1');

  return (
    <div>
      <Row className="toolbar__title">
        <Col span={24}>
          <Typography.Title level={2}>Обращения</Typography.Title>
        </Col>
      </Row>
      <div className="contracts__tabs-table">
        <Tabs
          activeKey={`${tab}`}
          items={tabsItems}
          onTabClick={(key) => {
            setTab(key);
            window.history.replaceState(null, '', `/operator/claims?tab=${key}`);
          }}
          className="tabs"
        />
      </div>
    </div>
  );
};

export default OperatorClaims;

import { API } from '../../Services/axios';
import { Button, Form, Input, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { PatternFormat } from 'react-number-format';
import { getEmailFieldRules, getNameFieldRules, getPhoneFieldRules } from '../../Utils/Rules/registration';
import { getUnrequiredNameFieldRuled } from '../../Utils/Rules/profile';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from './ModalTitle';

const CreateOpratorModal = (props: ModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel } = props;
  const [form] = useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const onCloseModal = () => {
    handleCancel();
    form.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      name: values.name.trim(),
      surname: values.surname.trim(),
      patronymic: values.patronymic && values.patronymic.trim().length > 0 ? values.patronymic.trim() : undefined,
      phone: '+7 ' + values.phone,
      email: values.email.trim(),
    };

    API.post('/api/v1/admin/users/', data)
      .then((response) => {
        onCloseModal();
        Modal.success({
          title: <Typography.Title level={4}>Создан новый оператор в системе</Typography.Title>,
          content: (
            <Typography.Text className="modal__text-large">
              На электронную почту пользователя отправлено письмо с дальнейшими инструкциями для завершения регистрации.
            </Typography.Text>
          ),
          width: 655,
          centered: true,
          onOk: () => location.reload(),
        });
      })
      .catch((error) => {
        if (error.toString().toLowerCase().includes('user with this email already exists')) {
          showErrorModal({
            title: 'Пользователь с указанным адресом электронной почты уже существует',
            message:
              'Данный адрес электронной почты уже используется в системе. В случае, если пользоваель забыл пароль, то он может восстановить его на странице входа в систему.',
          });
        } else showErrorModal({ error });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Создание нового оператора в системе" handleCancel={handleCancel} />}
      open={open}
      onCancel={handleCancel}
      className="modal"
      footer={null}
      centered
      width={645}
      closable={false}
    >
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
          <Form.Item name="surname" label="Фамилия" validateFirst rules={getNameFieldRules('Введите вашу фамилию')}>
            <Input size="large" />
          </Form.Item>
          <Form.Item name="name" label="Имя" validateFirst rules={getNameFieldRules('Введите ваше имя')}>
            <Input size="large" />
          </Form.Item>
          <Form.Item name="patronymic" label="Отчество" validateFirst rules={getUnrequiredNameFieldRuled()}>
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Номер телефона"
            normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}
            rules={getPhoneFieldRules('Введите номер телефона')}
            validateFirst
          >
            <PatternFormat
              valueIsNumericString
              format="(###) ###-##-##"
              customInput={Input}
              prefix="+7"
              size="large"
              mask="_"
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Электронная почта"
            validateFirst
            rules={getEmailFieldRules('Введите электронную почту')}
          >
            <Input size="large" />
          </Form.Item>
        </Form>

        <Row justify="end">
          <Button size="large" onClick={handleCancel} className="modal__cancel-btn">
            Отменить
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Создать пользователя
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default CreateOpratorModal;

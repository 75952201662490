// Checks if legal entity`s ogrn is correct
export const checkOgrn = (value) => {
  const stringOgrn = value.toString();
  const divisionRemainder = Math.floor(Number(stringOgrn.slice(0, -1) % 11));

  if (divisionRemainder >= 10) {
    const lowestRemainderLevel = divisionRemainder % 10;
    return lowestRemainderLevel === Number(stringOgrn.slice('')[12]) ? true : false;
  }
  return divisionRemainder === Number(stringOgrn.slice('')[12]) ? true : false;
};

// Checks if individual entrepreneur`s ogrn is correct
export const checkOgrnip = (value) => {
  const stringOgrnip = value.toString();
  const divisionRemainder = Math.floor(Number(stringOgrnip.slice(0, -1) % 13));

  if (divisionRemainder >= 10) {
    const lowestRemainderLevel = divisionRemainder % 10;
    return lowestRemainderLevel === Number(stringOgrnip.slice('')[14]) ? true : false;
  }
  return divisionRemainder === Number(stringOgrnip.slice('')[14]) ? true : false;
};

import { Button, Col, Row } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ExportStaticticsModal from './Modals/ExportStaticsticsModal';

const Toolbar = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = () => setOpenModal(true);
  const hideModal = () => setOpenModal(false);

  return (
    <Row className="toolbar__admin-title" justify="end">
      <Col className="toolbar__admin-btns">
        <Button size={'large'} type="primary" icon={<ExportOutlined />} onClick={showModal}>
          Выгрузить статистику
        </Button>
      </Col>
      <ExportStaticticsModal open={openModal} handleCancel={hideModal} />
    </Row>
  );
};

export default Toolbar;

import { API } from '../../../../Services/axios';
import { ContractTableCustomerProps } from '../../../../Models/Contract';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { getContractsTabsItems } from './Component/tabs-items';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';
import Toolbar from './Toolbar';

interface CustomerContractsProps extends ContractTableCustomerProps {
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

const Contracts = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ContractTableCustomerProps>({
    all: 0,
    in_process: 0,
    delayed: 0,
    finished: 0,
    agreements: [],
  });
  const [columnsSettings, setColumnsSettings] = useState<unknown>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab')! !== '0' ? searchParams.get('tab')! : 'all');
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'agreementId';

  const restartTable = () => {
    if (tab !== null) {
      API.get(
        `/api/v1/agreement/get-list-with-report-data?filter=${tab}&page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`,
      )
        .then((response) => setData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };
  useEffect(() => {
    setLoading(true);
    restartTable();
    API.get(`/api/v1/table-settings/agreement`)
      .then((response) => setColumnsSettings(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && tab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('tab', tab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
    setLoading(true);
    restartTable();
  }, [tab, size, page, sort, sortField]);

  const contractsProps: CustomerContractsProps = {
    agreements: data.agreements,
    loading: loading,
    columnsSettings: columnsSettings,
    setTab: setTab,
    all: data.all,
    in_process: data.in_process,
    delayed: data.delayed,
    finished: data.finished,
  };

  const tabsItems = getContractsTabsItems(contractsProps);

  return (
    <div>
      <Toolbar columnsSettings={columnsSettings} setColumnsSettings={setColumnsSettings} />
      <div className="contracts__tabs-table">
        <Tabs
          activeKey={tab}
          onTabClick={(key) => {
            setTab(key);
            setSearchParams((params) => {
              params.set('page', '1');
              return searchParams;
            });
            window.history.replaceState(null, '', `/customer/orders?tab=${key}&page=${page}&size=${size}`);
          }}
          items={tabsItems}
          className="tabs"
        />
      </div>
    </div>
  );
};

export default Contracts;

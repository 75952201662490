import { Table } from 'antd';
import { UsersProps } from '../../../../../Models/Profile';
import { UsersTableTabs } from './Component/empty-message';
import { getAdminUsersTableColumns } from './Component/columns';
import { getUsersTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UsersTableProps extends UsersProps {
  tabName: UsersTableTabs;
  totalCount: number;
}

const UsersTable = (props: UsersTableProps) => {
  const { users, loading, tabName, totalCount } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const sizePage = localStorage.getItem('sizePage');

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, handleFilter } = getUsersTableFilter();
  const columns = getAdminUsersTableColumns(filteredInfo, setFilteredInfo);

  useEffect(() => {
    if (paginationInfo?.current) {
      setSearchParams((params) => {
        params.set('page', paginationInfo.current ? paginationInfo.current.toString() : '');
        return searchParams;
      });
    }
    if (paginationInfo?.pageSize) {
      setSearchParams((params) => {
        params.set('size', paginationInfo.pageSize ? paginationInfo.pageSize.toString() : '');
        return searchParams;
      });
    }
  }, [paginationInfo]);
  return (
    <Table
      bordered
      rowKey={'id'}
      className="table__organizations"
      loading={loading}
      scroll={{ x: 1331 }}
      columns={columns}
      dataSource={users}
      locale={tableLocaleSettings(tabName)}
      pagination={tablePagingSettings(totalCount, sizePage)}
      onChange={handleFilter}
    />
  );
};

export default UsersTable;

import { RcFile } from 'antd/lib/upload';

// To be able show file in preview without sending it to server
// when we are creating order
// From Ant Design examples
export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

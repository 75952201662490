import { API } from '../../../../Services/axios';
import { Card, Col, Row, Space, Typography } from 'antd';
import { PaymentData } from '../../../../Models/PaymentData';
import { formatPrice, formatPriceWithDecimals } from '../../../../Utils/formatPrice';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import EditPaymentDataModal from './Modals/EditPaymentDataModal';
import Loader from '../../../../Components/Loader/Loader';
import ProfileItem from '../../../../Components/Profile/ProfileItem';

const PaymentDataCard = () => {
  const { showErrorModal } = useErrorModal();
  const [data, setData] = useState<PaymentData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/admin/settings/payment-details')
      .then((response) => setData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  }, [reload]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const hideModal = () => setOpenModal(false);
  const showModal = () => setOpenModal(true);

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title level={3}>Платежная информация</Typography.Title>
      </Col>
      <Col>
        <Typography.Link onClick={showModal} underline>
          Редактировать
        </Typography.Link>
      </Col>
    </Row>
  );

  if (loading || !data) return <Loader />;

  return (
    data &&
    (
      <Card title={cardTitle} className="settings__card" bordered={false}>
        <Space direction="vertical" size={40} style={{ width: '100%' }}>
          <Typography.Text className="profile__info-large">
            Платежная информация отображается в счете, который организатор перевозок получает для проведения первого
            тестового платежа.
          </Typography.Text>
          <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <ProfileItem title="Название организации" value={data.name} />
            <ProfileItem title="ИНН" value={data.inn} />
            <ProfileItem title="КПП" value={data.kpp} />
            <ProfileItem title="Почтовый адрес" value={data.postalAddress} />
            <ProfileItem title="Расчетный счет" value={data.paymentAccount} />
            <ProfileItem title="Банк" value={data.bank} />
            <ProfileItem title="Корреспондентский счет" value={data.correspondentAccount} />
            <ProfileItem title="БИК" value={data.bic} />
            <ProfileItem title="ОГРН" value={data.ogrn} />
            <ProfileItem title="ОКПО" value={data.okpo} />
            <ProfileItem title="Наименование лицензии/услуги" value={data.licenseOrServiceName} />
            <ProfileItem title="Сумма платежа, ₽" value={formatPriceWithDecimals(data.payment)} />
            <ProfileItem title="Размер НДС, %" value={`${formatPrice(data.vatPercentage)}%`} />
          </Space>
        </Space>
        <EditPaymentDataModal open={openModal} handleCancel={hideModal} data={data} setReload={setReload} />
      </Card>
    )!
  );
};

export default PaymentDataCard;

import { defaultDateTimeFormat } from '../Constants/DateFormats';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

// Converts date & time to Moscow time zone
export const convertToMscTime = (date: Date) => dayjs.utc(date).tz('Europe/Moscow').format(defaultDateTimeFormat);

import { Order } from '../Models/Order';
import { OrderStatus } from '../Constants/Order';

export const getOrderStatus = (record: Order) => {
  const notArchivedStatus = (status) =>
    status !== OrderStatus.DONE &&
    status !== OrderStatus.CANCELLED_BY_CUSTOMER &&
    status !== OrderStatus.CANCELLED_BY_DELIVERY;

  if (
    (record.status === OrderStatus.NEW || record.status === OrderStatus.DELAYED) &&
    record.offersCount === 0 &&
    record.agreementsCount === 0 &&
    !record.acceptedAgreementId
  ) {
    return 'Без предложений';
  } else if (
    notArchivedStatus(record.status) &&
    record.status !== OrderStatus.AGREEMENT_ACCEPTED &&
    record.offersCount > 0 &&
    record.agreementsCount === 0 &&
    !record.acceptedAgreementId
  ) {
    return 'С предложениями';
  } else if (notArchivedStatus(record.status) && record.agreementsCount > 0 && !record.acceptedAgreementId) {
    return 'С проектами договоров';
  } else if (record.status === OrderStatus.AGREEMENT_ACCEPTED && !record.acceptedAgreementId) {
    return 'На подписании';
  } else if (notArchivedStatus(record.status) && record.acceptedAgreementId) {
    return 'Подписан финальный договор';
  } else if (record.status === OrderStatus.DRAFT) {
    return 'Черновик';
  } else if (
    record.status === OrderStatus.DONE ||
    record.status === OrderStatus.CANCELLED_BY_CUSTOMER ||
    record.status === OrderStatus.CANCELLED_BY_DELIVERY
  ) {
    return 'Архивный';
  }
};

import { API } from '../../../../Services/axios';
import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import { OrgTypes } from '../../../../Constants/OrgTypes';
import { PaymentData } from '../../../../Models/PaymentData';
import { Profile } from '../../../../Models/Profile';
import { formatPriceWithDecimals } from '../../../../Utils/formatPrice';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import BillFooter from './bill-footer';
import Loader from '../../../../Components/Loader/Loader';

interface BillCardProps {
  profileData: Profile;
}

const BillCard = (props: BillCardProps) => {
  const { showErrorModal } = useErrorModal();
  const { profileData } = props;

  const [data, setData] = useState<PaymentData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/profile/settings/payment-details')
      .then((response) => setData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  const isLegal = profileData.organisation.type === OrgTypes.LEGAL;

  if (loading || !data || !profileData) return <Loader />;

  return (
    profileData &&
    data && (
      <>
        <Col span={24}>
          <Card>
            <Row gutter={[48, 0]}>
              <Col span={12}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Typography.Title level={4}>Получатель</Typography.Title>
                  </Col>
                  <Col span={24}>
                    <Space size={12} direction="vertical">
                      <Typography.Text>{data.name}</Typography.Text>
                      <Typography.Text>ИНН: {data.inn}</Typography.Text>
                      <Typography.Text>КПП: {data.kpp}</Typography.Text>
                      <Typography.Text>{data.postalAddress}</Typography.Text>
                      <Typography.Text>Расчетный счет: {data.paymentAccount}</Typography.Text>
                      <Typography.Text>Банк: {data.bank}</Typography.Text>
                      <Typography.Text>Корреспондентский счет: {data.correspondentAccount}</Typography.Text>
                      <Typography.Text>БИК: {data.bic}</Typography.Text>
                      <Typography.Text>ОГРН: {data.ogrn}</Typography.Text>
                      <Typography.Text>ОКПО: {data.okpo}</Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Typography.Title level={4}>Плательщик</Typography.Title>
                  </Col>
                  <Col span={24}>
                    <Space size={12} direction="vertical">
                      <Typography.Text>{profileData.organisation.shortName}</Typography.Text>
                      <Typography.Text>ИНН: {profileData.organisation.inn}</Typography.Text>
                      {isLegal && <Typography.Text>КПП: {profileData.organisation.kpp}</Typography.Text>}
                      <Typography.Text>{profileData.organisation.postalAddress}</Typography.Text>
                      <Typography.Text>Расчетный счет: {profileData.organisation.paymentAccount}</Typography.Text>
                      <Typography.Text>Банк: {profileData.organisation.bankName}</Typography.Text>
                      <Typography.Text>
                        Корреспондентский счет: {profileData.organisation.correspondentAccount}
                      </Typography.Text>
                      <Typography.Text>БИК: {profileData.organisation.bic}</Typography.Text>
                      <Typography.Text>
                        {isLegal
                          ? `ОГРН: ${profileData.organisation.ogrn}`
                          : `ОГРНИП: ${profileData.organisation.ogrnip}`}
                      </Typography.Text>
                      <Typography.Text>ОКПО: {profileData.organisation.okpo}</Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <BillFooter
              licenseOrServiceName={data.licenseOrServiceName}
              payment={formatPriceWithDecimals(data.payment)}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Space size={16} direction="vertical">
            <Typography.Title level={4}>Итого: {formatPriceWithDecimals(data.payment)} ₽</Typography.Title>
            <Typography.Title level={4}>
              В том числе НДС {data.vatPercentage}%: {formatPriceWithDecimals(data.vatValue)} ₽
            </Typography.Title>
          </Space>
        </Col>
      </>
    )
  );
};

export default BillCard;

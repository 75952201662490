import { API } from '../../../../Services/axios';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { Profile } from '../../../../Models/Profile';
import { getMaskedPhoneNumberInOrder } from '../../../../Utils/getPhoneMasked';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import DeleteUserModal from './Component/Modals/DeleteUserModal';
import Loader from '../../../../Components/Loader/Loader';
import ProfileItem from '../../../../Components/Profile/ProfileItem';
import React, { useEffect, useState } from 'react';
import UserProfileHeader from './Component/user-profile-header';

const UserProfile = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();

  const [profileData, setProfileData] = useState<Profile>();
  const [loading, setLoading] = useState<boolean>(false);

  const [isOpenDeleteUserModal, setOpenDeleteUserModal] = useState<boolean>(false);

  const handleOpenDeleteUserModal = () => setOpenDeleteUserModal(true);
  const handleCloseDeleteUserModal = () => setOpenDeleteUserModal(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/profile/${id}`)
      .then((response) => setProfileData(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, [id]);

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title level={3}>Данные пользователя</Typography.Title>
      </Col>
      <Col>
        <Button type="primary" size="large" htmlType="button" loading={loading} onClick={handleOpenDeleteUserModal}>
          Удалить пользователя
        </Button>
      </Col>
    </Row>
  );

  if (!profileData || loading) return <Loader />;

  return (
    profileData && (
      <Row gutter={[0, 32]} className="operator operator__full-width">
        <Col span={24}>
          <UserProfileHeader />
        </Col>
        <Col span={24} className="operator__main-col">
          <Card title={cardTitle}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Space direction="vertical" size={16} style={{ width: '100%' }}>
                  <ProfileItem title="Фамилия" value={profileData.surname} />
                  <ProfileItem title="Имя" value={profileData.name} />
                  <ProfileItem title="Отчество" value={profileData.patronymic ?? '—'} />
                  <ProfileItem title="Телефон" value={getMaskedPhoneNumberInOrder(profileData.phone)} />
                  <ProfileItem title="Электронная почта" value={profileData.email} />
                  <Row align="middle" justify={'space-between'}>
                    <Col span={8}>
                      <Typography.Text type="secondary" className="profile__info-large">
                        Организация
                      </Typography.Text>
                    </Col>
                    <Col span={15} offset={1}>
                      <Typography.Link
                        className="profile__info-large"
                        underline
                        href={`/operator/organizations/${profileData.organisation.id}`}
                      >
                        {profileData.organisation.shortName}
                      </Typography.Link>
                    </Col>
                  </Row>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
        {profileData && (
          <DeleteUserModal
            open={isOpenDeleteUserModal}
            id={+profileData.id}
            name={`${profileData.name} ${profileData.surname} (${profileData.email})`}
            handleCancel={handleCloseDeleteUserModal}
          />
        )}
      </Row>
    )
  );
};

export default UserProfile;

import { Card, Space, Typography } from 'antd';
import { ContractReport } from '../../../../../Models/Contract';
import { FileOutlined } from '@ant-design/icons';

interface CompletedWorksActCardProps {
  completedWorksActRecord: ContractReport | undefined;
}

const CompletedWorksActCard = (props: CompletedWorksActCardProps) => {
  const { completedWorksActRecord } = props;
  return (completedWorksActRecord && (
    <Card title={<Typography.Title level={4}>Акт выполненных работ</Typography.Title>}>
      <Space direction="vertical" size={16}>
        <Typography.Text type="secondary" strong>
          Акт выполненных работ
        </Typography.Text>
        <Space size={10}>
          <FileOutlined className="offer__file-icon" />
          <Typography.Link className="offer__text" href={completedWorksActRecord.reportFile[0].link}>
            {completedWorksActRecord.reportFile[0].name}
          </Typography.Link>
        </Space>
      </Space>
    </Card>
  ))!;
};

export default CompletedWorksActCard;

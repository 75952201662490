import { Col, Row, Space, Typography } from 'antd';

interface BillFooterProps {
  licenseOrServiceName: string;
  payment: string;
}

const BillFooter = (props: BillFooterProps) => {
  const { licenseOrServiceName, payment } = props;
  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={4}>Назначение платежа</Typography.Title>
      </Col>
      <Col span={24}>
        <Space direction="vertical">
          <Typography.Text>Наименование лицензии/услуги: {licenseOrServiceName}</Typography.Text>
          <Typography.Text>Кол-во, шт.: 1</Typography.Text>
          <Typography.Text>Сумма, руб.: {payment}</Typography.Text>
        </Space>
      </Col>
    </Row>
  );
};

export default BillFooter;

import { Badge, Typography } from 'antd';
import { OrganizationsProps } from '../../../../../Models/Organizations';
import { OrganizationsTableTabs } from '../Table/Component/empty-message';
import OrganizationsTable from '../Table/OrganizationsTable';

export const getOrganizationsTabsItems = (props: OrganizationsProps) => {
  const { organisations, loading, all, not_confirmed, confirmed, customers } = props;

  const tabsItems = [
    {
      key: 'all',
      label: <Typography.Text className="organizations__info">Все</Typography.Text>,
      children: (
        <OrganizationsTable
          tabName={OrganizationsTableTabs.ALL}
          organizations={organisations}
          loading={loading}
          totalCount={all}
        />
      ),
    },
    {
      key: 'not_confirmed',
      label: (
        <Typography.Text className="organizations__info">
          Неподтвержденные организаторы <Badge className={'organizations__badge'} count={not_confirmed} />
        </Typography.Text>
      ),
      children: (
        <OrganizationsTable
          tabName={OrganizationsTableTabs.UNVERIFIED}
          organizations={organisations}
          loading={loading}
          totalCount={not_confirmed}
        />
      ),
    },
    {
      key: 'confirmed',
      label: <Typography.Text className="organizations__info">Подтвержденные организаторы</Typography.Text>,
      children: (
        <OrganizationsTable
          tabName={OrganizationsTableTabs.VERIFIED}
          organizations={organisations}
          loading={loading}
          totalCount={confirmed}
        />
      ),
    },
    {
      key: 'customers',
      label: <Typography.Text className="organizations__info">Заказчики</Typography.Text>,
      children: (
        <OrganizationsTable
          tabName={OrganizationsTableTabs.CUSTOMERS}
          organizations={organisations}
          loading={loading}
          totalCount={customers}
        />
      ),
    },
  ];

  return tabsItems;
};

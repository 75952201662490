import { Button, Col, Row, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import TableSettingsModal from '../../../../Components/Modals/TableSettingsModal';

interface ToolbarProps {
  columnsSettings: unknown;
  setColumnsSettings: React.Dispatch<React.SetStateAction<unknown>>;
}

const Toolbar = (props: ToolbarProps) => {
  const { columnsSettings, setColumnsSettings } = props;

  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);

  const handleOpen = () => setOpenSettingsModal(true);
  const handleCancel = () => setOpenSettingsModal(false);

  return (
    <Row className="toolbar__title" justify="space-between">
      <Col>
        <Typography.Title level={2}>Договоры</Typography.Title>
      </Col>
      <Col className="toolbar__btns-panel">
        <Button size={'large'} type="primary" icon={<SettingOutlined />} onClick={() => handleOpen()}>
          Настройка колонок
        </Button>
      </Col>
      <TableSettingsModal
        open={openSettingsModal}
        type={'CONTRACT'}
        columnsSettings={columnsSettings}
        setColumnsSettings={setColumnsSettings}
        handleCancel={() => handleCancel()}
      />
    </Row>
  );
};

export default Toolbar;

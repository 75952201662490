import { chain } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumns } from '../InitialStates/CustomerOrdersInitialState';

const preSelectedColKeys = [
  'userFriendlyId',
  'title',
  'cityFrom',
  'cityTo',
  'dateArrival',
  'price',
  'offersCount',
  'agreementsCount',
  'acceptedAgreementUserFriendlyId',
];

const presetColumns = chain(preSelectedColKeys)
  .toPairs()
  .map((i) => initialColumns[i[0]])
  .value();

export const customerOrdersColsSlice = createSlice({
  name: 'customerOrdersCols',
  initialState: presetColumns,
  reducers: {
    getUpdatedCustomerColumns: (state, action) => {
      const columns = action.payload;
      return columns;
    },
  },
});

export const { getUpdatedCustomerColumns } = customerOrdersColsSlice.actions;

export default customerOrdersColsSlice.reducer;

import { API } from '../../../Services/axios';
import { Alert, Button, Card, Col, Divider, Form, InputNumber, Row, Space, Tooltip, Typography } from 'antd';
import { CargoWarning } from '../../Customer/Order/Create/Component/cargo-warning';
import { ExactAddress } from '../../../Models/Address';
import { MAX_HEIGHT, MAX_LENGTH, MAX_WIDTH } from '../../../Constants/OverallDimensions';
import { OrderMarginCalculation } from '../../../Models/Order';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatPrice } from '../../../Utils/formatPrice';
import { getRequiredFieldRule, getSizesRules } from '../../../Utils/Rules/order';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../Components/Modals/ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import CaclFeedBackModal from '../../../Components/Modals/CaclFeedBackModal';
import YMap from '../../Customer/Order/Create/Component/ymaps/ymaps';

interface ProfileOrgCalcCardProps {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  calculatorFeedbackEnabled: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileOrgCalcCard = (props: ProfileOrgCalcCardProps) => {
  const { showErrorModal } = useErrorModal();
  const { setCurrent, calculatorFeedbackEnabled, setReload } = props;
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [calculator, setCalculator] = useState({ price: 0, distance: 0 });
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [warning, setWarning] = useState(false);

  const [orderMarginSettings, setOrderMarginSettings] = useState<OrderMarginCalculation>();
  useEffect(() => {
    API.get(`/api/v1/calculator/for-profile`)
      .then((response) => setOrderMarginSettings(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  const initialValues = orderMarginSettings && {
    desiredProfit: orderMarginSettings.desiredProfit,
    fuelConsumption: orderMarginSettings.fuelConsumption,
    adblueConsumption: orderMarginSettings.adblueConsumption,
    driverSalary: orderMarginSettings.driverSalary,
    driverSalaryPercentage: orderMarginSettings.driverSalaryPercentage,
    driverSalaryDay: orderMarginSettings.driverSalaryDay,
    driverWorkingDay: orderMarginSettings.driverWorkingDay,
    logisticianSalary: orderMarginSettings.logisticianSalary,
    additionalFee: orderMarginSettings.additionalFee,
    insuranceCargoPriceFee: orderMarginSettings.insuranceCargoPriceFee,
    rateSeason: 1,
    rateRegion: 1,
    rateTransport: 1,
  };

  const [feedBackModalOpen, setFeedBackModalOpen] = useState(false);
  const handleFeedBackModalOpen = () => setFeedBackModalOpen(true);
  const handleFeedBackModalCancel = () => setFeedBackModalOpen(false);

  const length = useWatch<number>('length', form);
  const width = useWatch<number>('width', form);
  const height = useWatch<number>('height', form);
  const weight = useWatch<number>('weight', form);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ymaps, setYMaps] = useState<any>();
  const [mapCenter, setMapCenter] = useState([55.76, 37.64]);
  const [mapZoom, setMapZoom] = useState(9);

  const [addressFrom, setAddressFrom] = useState<ExactAddress>({ displayName: '', components: [], coordinates: [] });
  const [addressTo, setAddressTo] = useState<ExactAddress>({ displayName: '', components: [], coordinates: [] });
  const [countrySelect, setCountrySelect] = useState({ countryFromSelect: 'Россия', countryToSelect: 'Россия' });

  useEffect(() => {
    length > MAX_LENGTH || width > MAX_WIDTH || height > MAX_HEIGHT ? setWarning(true) : setWarning(false);
  }, [length, height, width]);

  const [platonPrice, setPlatonPrice] = useState(0);
  const [calculatePlatonLoading, setCaclucatePlatonLoading] = useState<boolean>(false);
  const calculatePlaton = () => {
    setCaclucatePlatonLoading(true);
    setLoading(true);

    const regionFrom = addressFrom.components.filter((component) => component.kind === 'province').pop()?.name ?? null;
    const cityFrom = addressFrom.components.filter((component) => component.kind === 'locality')?.pop()?.name ?? null;
    const regionTo = addressTo.components.filter((component) => component.kind === 'province').pop()?.name ?? null;
    const cityTo = addressTo.components.filter((component) => component.kind === 'locality')?.pop()?.name ?? null;

    API.get(`/api/v1/calculator/platon/${regionFrom}/${cityFrom}/${regionTo}/${cityTo}`)
      .then((response) => {
        form.setFieldValue('platonFee', response.data.price);
        form.validateFields();
        setPlatonPrice(response.data.price);
        // if (response.data.price === 0)
        if (response.data.apiFail === 'null cities') {
          showErrorModal({
            title: 'Не удалось рассчитать стоимость',
            message:
              'Автоматический рассчёт стоимости Платона для данного заказа не удался. Такое бывает в случаях когда адрес указан недостаточно детально или сервис недоступен.',
          });
        } else if (response.data.price === 0) {
          showErrorModal({
            title: 'Не удалось рассчитать стоимость',
            message: 'На данном маршруте отсутствуют обязательства по уплате стоимости Платона.',
          });
        }
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setCaclucatePlatonLoading(false);
        setLoading(false);
      });
  };

  const [orderMarginCalculation, setOrderMarginCalculation] = useState<OrderMarginCalculation | null>(null);
  const onFinish = () => {
    form.validateFields();
    setLoading(true);

    const values = form.getFieldsValue();

    const dto = {
      price: values.price,
      weight: weight,
      length: length,
      height: height,
      width: width,
      addressFrom: addressFrom.displayName,
      addressTo: addressTo.displayName,
      pointFrom: addressFrom.coordinates.toString(),
      pointTo: addressTo.coordinates.toString(),
      cityFrom: addressFrom.components.filter((component) => component.kind === 'locality')?.pop()?.name,
      cityTo: addressTo.components.filter((component) => component.kind === 'locality')?.pop()?.name,
      desiredProfit: values.desiredProfit,
      platonFee: values.platonFee,
      fuelConsumption: values.fuelConsumption,
      adblueConsumption: values.adblueConsumption,
      driverSalary: values.driverSalary,
      driverSalaryPercentage: values.driverSalaryPercentage,
      driverSalaryDay: values.driverSalaryDay,
      driverWorkingDay: values.driverWorkingDay,
      logisticianSalary: values.logisticianSalary,
      additionalFee: values.additionalFee,
      insuranceCargoPriceFee: values.insuranceCargoPriceFee,
      rateSeason: values.rateSeason,
      rateRegion: values.rateRegion,
      rateTransport: values.rateTransport,
    };

    const headers = { headers: { 'Content-Type': 'application/json' } };

    API.post(`/api/v1/calculator/for-profile`, dto, headers)
      .then((response) => {
        setOrderMarginCalculation(response.data);
        if (calculatorFeedbackEnabled) {
          setTimeout(handleFeedBackModalOpen, 7000);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Card title={<Typography.Title level={3}>Расчет стоимости перевозки</Typography.Title>}>
      <Space direction="vertical" size={32} className="operator__full-width">
        {initialValues && (
          <Form
            id="form"
            form={form}
            initialValues={initialValues}
            colon={false}
            layout="vertical"
            className="form"
            onFinish={onFinish}
          >
            <Space direction="vertical" size={30} style={{ width: '100%' }}>
              <Typography.Title level={4}>Информация о перевозке</Typography.Title>
              <Form.Item
                name="price"
                label={<Typography.Text>Стоимость перевозимой техники, ₽</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber
                  controls={false}
                  size="large"
                  decimalSeparator=","
                  min={1}
                  max={999999999999.99}
                  maxLength={15}
                  formatter={(value) => (value ? formatPrice(+value!) : '')}
                />
              </Form.Item>
              <div className="order__dimensions">
                <Space className="order__space-full-width" size={24} align="start">
                  <Form.Item label="Вес, кг" name="weight" rules={getRequiredFieldRule('Введите вес')}>
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      onChange={() => {
                        setCalculator({ price: 0, distance: 0 });
                        setSubmitButtonDisabled(true);
                      }}
                      min={1}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Длина, м"
                    className="input__accent"
                    name="length"
                    rules={getSizesRules('Введите длину', MAX_LENGTH)}
                    status={warning ? 'warning' : 'success'}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      onChange={() => {
                        setCalculator({ price: 0, distance: 0 });
                        setSubmitButtonDisabled(true);
                      }}
                      min={1}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Ширина, м"
                    name="width"
                    className="input__accent"
                    rules={getSizesRules('Введите ширину', MAX_WIDTH)}
                    status={warning ? 'warning' : 'success'}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      onChange={() => {
                        setCalculator({ price: 0, distance: 0 });
                        setSubmitButtonDisabled(true);
                      }}
                      min={1}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Высота, м"
                    name="height"
                    rules={getSizesRules('Введите высоту', MAX_HEIGHT)}
                    status={warning ? 'warning' : 'success'}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      onChange={() => {
                        setCalculator({ price: 0, distance: 0 });
                        setSubmitButtonDisabled(true);
                      }}
                      min={1}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>
                </Space>
              </div>
              {warning && <CargoWarning />}
              <div className="order__map">
                <YMap
                  ymaps={ymaps}
                  form={form}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  addressFrom={addressFrom}
                  addressTo={addressTo}
                  countrySelect={countrySelect}
                  profileOrg={true}
                  setYMaps={setYMaps}
                  setMapCenter={setMapCenter}
                  setMapZoom={setMapZoom}
                  setAddressFrom={setAddressFrom}
                  setAddressTo={setAddressTo}
                  setSubmitButtonDisabled={setSubmitButtonDisabled}
                  setCalculator={setCalculator}
                  setCountrySelect={setCountrySelect}
                />
              </div>
            </Space>
            <Space direction="vertical" size={30} style={{ width: '100%', paddingTop: 30 }}>
              <Typography.Title level={4}>Мои параметры для расчета</Typography.Title>
              <Form.Item
                name="desiredProfit"
                label={<Typography.Text>Планируемая маржинальность, % от оборота</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
              </Form.Item>

              <Row justify="space-between" gutter={[24, 24]} align="bottom">
                <Col flex="auto">
                  <Form.Item
                    name="platonFee"
                    label={<Typography.Text>Стоимость Платона за поездку, ₽</Typography.Text>}
                    rules={getRequiredFieldRule('Поле обязательно для заполнения')}
                  >
                    <InputNumber
                      value={platonPrice}
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      min={0}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
                <Col className="profile__calc-btn" flex="330px">
                  <Button
                    size="large"
                    style={{ width: '100%' }}
                    type="primary"
                    loading={calculatePlatonLoading}
                    disabled={addressFrom.displayName.length === 0 && addressTo.displayName.length === 0}
                    onClick={() => calculatePlaton()}
                  >
                    Рассчитать автоматически
                  </Button>
                </Col>
              </Row>
              <Form.Item
                name="fuelConsumption"
                label={<Typography.Text>Средний расход топлива (на 100 км), л</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber
                  controls={false}
                  size="large"
                  decimalSeparator=","
                  min={0.1}
                  max={9999999.99}
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                name="adblueConsumption"
                label={<Typography.Text>Средний расход мочевины (на 100 км), л</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber
                  controls={false}
                  size="large"
                  decimalSeparator=","
                  min={0.1}
                  max={9999999.99}
                  maxLength={10}
                />
              </Form.Item>
              <Row justify="space-between" gutter={[24, 24]}>
                <Col lg={12} xl={12} xxl={12} span={24}>
                  <Form.Item
                    name="driverSalary"
                    label={<Typography.Text>Заработная плата водителя (за 1 км), ₽</Typography.Text>}
                    rules={getRequiredFieldRule('Поле обязательно для заполнения')}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      min={0}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} xl={12} xxl={12} span={24}>
                  <Form.Item
                    name="driverSalaryPercentage"
                    label={<Typography.Text>Заработная плата водителя, % от оборота</Typography.Text>}
                    rules={getRequiredFieldRule('Поле обязательно для заполнения')}
                  >
                    <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
                  </Form.Item>
                </Col>
              </Row>
              <Alert
                message="Калькулятор может учитывать один или оба параметра расчета заработной платы водителя одновременно. Если Вы используете только один из параметров, оставьте в другом значение 0."
                type="warning"
                showIcon
              />
              <Form.Item
                name="driverSalaryDay"
                label={<Typography.Text>Стоимость суточных водителя, ₽</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber
                  controls={false}
                  size="large"
                  decimalSeparator=","
                  min={0}
                  max={9999999.99}
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                name="driverWorkingDay"
                label={<Typography.Text>Продолжительность рабочего дня водителя, ч</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber controls={false} size="large" decimalSeparator="," min={1} max={24} maxLength={3} />
              </Form.Item>
              <Form.Item
                name="logisticianSalary"
                label={<Typography.Text>Заработная плата логиста, % от оборота</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
              </Form.Item>
              <Form.Item
                name="additionalFee"
                label={<Typography.Text>Дополнительные расходы, % от затрат</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
              </Form.Item>
              <Form.Item
                name="insuranceCargoPriceFee"
                label={<Typography.Text>Страхование груза, % от стоимости груза</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
              </Form.Item>
            </Space>
            <Space direction="vertical" size={24} style={{ width: '100%', paddingTop: 30 }}>
              <Typography.Title level={4}>Коэффициенты</Typography.Title>
              <Row justify="space-between" gutter={[24, 24]}>
                <Col lg={12} xl={12} xxl={12} span={24}>
                  <Form.Item
                    name="rateSeason"
                    label={
                      <Typography.Text>
                        Коэффициент сезона
                        <Tooltip title="Вы можете указать коэффициент сезона. По умолчанию он равен 1. Если Вы укажете значение, большее 1, финальная стоимость увеличится. Если меньше 1, то стоимость уменьшится. Число должно быть больше 0">
                          <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                        </Tooltip>
                      </Typography.Text>
                    }
                    rules={getRequiredFieldRule('Поле обязательно для заполнения')}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      min={0.01}
                      max={99.99}
                      maxLength={5}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} xl={12} xxl={12} span={24}>
                  <Form.Item
                    name="rateRegion"
                    label={
                      <Typography.Text>
                        Коэффициент региона
                        <Tooltip title="Вы можете указать коэффициент региона. По умолчанию он равен 1. Если Вы укажете значение, большее 1, финальная стоимость увеличится. Если меньше 1, то стоимость уменьшится. Число должно быть больше 0">
                          <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                        </Tooltip>
                      </Typography.Text>
                    }
                    rules={getRequiredFieldRule('Поле обязательно для заполнения')}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      min={0.01}
                      max={99.99}
                      maxLength={5}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="rateTransport"
                label={
                  <Typography.Text>
                    Коэффициент используемого транспорта
                    <Tooltip title="Вы можете указать коэффициент используемого транспорта. По умолчанию он равен 1. Если Вы укажете значение, большее 1, финальная стоимость увеличится. Если меньше 1, то стоимость уменьшится. Число должно быть больше 0">
                      <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                    </Tooltip>
                  </Typography.Text>
                }
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber controls={false} size="large" decimalSeparator="," min={0.01} max={99.99} maxLength={5} />
              </Form.Item>
            </Space>
            <Space direction="vertical" size={30} style={{ width: '100%', paddingTop: 30, marginBottom: 40 }}>
              <Typography.Text style={{ fontSize: 16 }}>
                В расчете стоимости перевозки учитываются и другие расходы. Если Вы хотите изменить <br></br>
                больше параметров расчета, перейдите в раздел{' '}
                <b>
                  <u
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      form.resetFields();
                      setCurrent(4);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    Настройки калькулятора
                  </u>
                </b>
                .
              </Typography.Text>
            </Space>
            <div>
              <Form.Item>
                <Row gutter={[24, 24]}>
                  <Col>
                    <Button type="primary" size="large" htmlType="submit" loading={loading}>
                      Рассчитать стоимость
                    </Button>
                    {calculatorFeedbackEnabled && (
                      <CaclFeedBackModal
                        open={feedBackModalOpen}
                        handleCancel={handleFeedBackModalCancel}
                        setReload={setReload}
                      />
                    )}
                  </Col>
                  <Col>
                    <Button
                      size="large"
                      onClick={() => {
                        form.resetFields();
                      }}
                    >
                      Сбросить значения
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
              <Divider />
              {orderMarginCalculation && (
                <Card
                  title={
                    <div>
                      <Typography.Title level={3}>Расчет стоимости перевозки</Typography.Title>
                    </div>
                  }
                >
                  <Row>
                    <Typography.Text className="contract__info" type="secondary">
                      Общая сумма затрат на перевозку{' '}
                      <Typography.Text className="contract__info-price">{`${formatPrice(
                        orderMarginCalculation.resultExpenses,
                      )} ₽`}</Typography.Text>
                    </Typography.Text>
                  </Row>
                  <Row style={{ paddingTop: 10 }}>
                    <Typography.Text className="contract__info" type="secondary">
                      Планируемая маржинальность{' '}
                      <Typography.Text className="contract__info-price">{`${formatPrice(
                        orderMarginCalculation.resultPlannedMarginality,
                      )} ₽`}</Typography.Text>
                    </Typography.Text>
                  </Row>
                  <Row style={{ paddingTop: 10 }}>
                    <Typography.Text className="contract__info" type="secondary">
                      Ставка заказчику, без НДС{' '}
                      <Typography.Text className="contract__info-price">{`${formatPrice(
                        orderMarginCalculation.resultPriceWithoutVat,
                      )} ₽`}</Typography.Text>
                    </Typography.Text>
                  </Row>
                  <Divider />
                  <Typography.Text className="contract__info" type="secondary">
                    {orderMarginCalculation.resultRecommendedPrice === 50000 ? 'Минимальная' : 'Рекомендуемая'}{' '}
                    стоимость для <br></br> этого предложения (с учетом НДС){' '}
                    <Typography.Text
                      className="contract__info__margin-details"
                      ellipsis={{ tooltip: true }}
                      copyable={{ tooltips: false }}
                      style={{ fontWeight: 600, position: 'absolute', right: 35, bottom: 25 }}
                    >{`${formatPrice(orderMarginCalculation.resultRecommendedPrice)} ₽`}</Typography.Text>
                  </Typography.Text>
                </Card>
              )}
            </div>
          </Form>
        )}
      </Space>
    </Card>
  );
};

export default ProfileOrgCalcCard;

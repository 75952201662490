import { Button, Modal, Row, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

interface CancelOrderCreateModalProps {
  open: boolean;
  handleCancel: () => void;
  handleDraftCreate: () => void;
}

const CancelOrderCreateModal = (props: CancelOrderCreateModalProps) => {
  const { open, handleCancel, handleDraftCreate } = props;
  const navigate = useNavigate();

  const handleConfirm = () => navigate('/customer/orders');

  return (
    <Modal
      title=""
      open={open}
      onCancel={handleCancel}
      className="modal"
      footer={null}
      centered
      width={645}
      closable={false}
    >
      <Space direction="vertical" size={32}>
        <Typography.Text className="modal__text-cancel">
          Вы уверены, что хотите отменить создание заказа? Вы можете сохранить заказ как черновик.
        </Typography.Text>
        <Row justify="end">
          <Button size="large" onClick={handleDraftCreate} className="modal__cancel-btn">
            Сохранить как черновик
          </Button>
          <Button type="primary" size="large" onClick={handleConfirm}>
            Отменить создание заказа
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default CancelOrderCreateModal;

import { Alert, Badge, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { OffersListItem } from '../../../../../Models/Offer';
import { OrderStatus } from '../../../../../Constants/Order';
import {
  getCancelledOffersByStatus,
  getNewOffersByStatus,
  getOffersInWorkByStatus,
} from '../../../../../Utils/getOffersData';
import { unionBy } from 'lodash';
import CancelledAcceptedOfferCard from '../Offer/CancelledAcceptedOfferCard';
import OrderTab from '../OrderTab';

export const tabsItems = (
  data: OffersListItem[],
  dataWithAgreements: OffersListItem[],
  orderStatus: OrderStatus,
  agreementId: number | undefined,
  showModal: (organisationId: number) => void,
) => {
  const isOrderCancelled =
    orderStatus === OrderStatus.CANCELLED_BY_CUSTOMER || orderStatus === OrderStatus.CANCELLED_BY_DELIVERY;
  const cancelledOffersWithAgreements = getCancelledOffersByStatus(unionBy(dataWithAgreements, data, 'id'));

  const tabContent = (children) => {
    if (orderStatus === OrderStatus.CANCELLED_BY_CUSTOMER) {
      return (
        <>
          <Alert message={'Вы отменили этот заказ'} type="warning" showIcon />
          {data.length > 0 && children}
        </>
      );
    } else if (agreementId && orderStatus === OrderStatus.CANCELLED_BY_DELIVERY) {
      return (
        <>
          <Alert
            message={
              'Заказ отменен, так как организатор инициировал принудительное завершение исполнения договора в системе'
            }
            type="error"
            showIcon
            icon={<ExclamationCircleFilled />}
          />
          {children}
        </>
      );
    } else if (orderStatus === OrderStatus.CANCELLED_BY_DELIVERY) {
      return (
        <>
          <Alert
            message={
              'Договор не смог быть подписан по инициативе организатора перевозки. Вы можете повторно разместить заказ на платформе.'
            }
            type="error"
            showIcon
            icon={<ExclamationCircleFilled />}
          />
          {children}
        </>
      );
    } else {
      return children;
    }
  };

  return [
    {
      key: '1',
      label: (
        <Typography.Text className="order__info order__info-large">
          Новые <Badge showZero className="order__badge" count={getNewOffersByStatus(data).length} />
        </Typography.Text>
      ),
      children: tabContent(
        !isOrderCancelled ? (
          <OrderTab
            offersData={getNewOffersByStatus(data)}
            infoText={'Вы можете принять одно или несколько заинтересовавших Вас предложений.'}
            emptyDescription={'Здесь будут отображаться предложения организаторов к вашему заказу'}
            showModal={showModal}
          />
        ) : null,
      ),
    },
    {
      key: '2',
      label: (
        <Typography.Text className="order__info order__info-large">
          С подтверждением{' '}
          <Badge showZero className={'order__badge-grey'} count={getOffersInWorkByStatus(dataWithAgreements).length} />
        </Typography.Text>
      ),
      children: tabContent(
        !isOrderCancelled ? (
          <OrderTab
            offersData={getOffersInWorkByStatus(dataWithAgreements)}
            infoText={
              agreementId
                ? 'Вы можете выбрать только одно предложение и от одного организатора на основании полученной заявки. Остальные предложения станут недоступны.'
                : ''
            }
            emptyDescription={
              'Здесь появятся предложения Организаторов перевозок, по которым был получен проект договора'
            }
            showModal={showModal}
          />
        ) : (
          <>
            {getOffersInWorkByStatus(dataWithAgreements).map((offer) => (
              <CancelledAcceptedOfferCard {...offer} key={offer.id} showModal={showModal} />
            ))}
          </>
        ),
      ),
    },
    {
      key: '3',
      label: (
        <Typography.Text className="order__info order__info-large">
          Отклонены <Badge showZero className="order__badge-grey" count={cancelledOffersWithAgreements.length} />
        </Typography.Text>
      ),
      children: tabContent(
        <OrderTab
          showModal={showModal}
          offersData={cancelledOffersWithAgreements}
          infoText={isOrderCancelled ? '' : 'Отклоненные Вами предложения не подлежат восстановлению.'}
          emptyDescription={'Здесь появятся предложения Организаторов перевозок, которые вы отклонили'}
          isOrderCancelled={isOrderCancelled}
        />,
      ),
    },
  ];
};

import { Typography } from 'antd';
import CargoCategories from '../CargoCategories/CargoCategories';
import EmailsTable from '../Emails/Table/EmailsTable';
import OrgCalculatorSettings from '../OrgCalculator/OrgCalculatorSettings';
import PaymentDataCard from '../PaymentData/PaymentDataCard';
import RateList from '../Calculator/RateList/RateList';
import SystemMessagesTable from '../SystemMessages/Table/SystemMessagesTable';

export const getSettingsTabsItems = () => {
  const tabsItems = [
    {
      key: '1',
      label: (
        <Typography.Text id="1" className="order__info">
          Направления и тарифы
        </Typography.Text>
      ),
      children: <RateList />,
    },
    {
      key: '2',
      label: (
        <Typography.Text id="2" className="order__info">
          Калькулятор организатора
        </Typography.Text>
      ),
      children: <OrgCalculatorSettings />,
    },
    {
      key: '3',
      label: (
        <Typography.Text id="3" className="order__info">
          Категории и типы техники
        </Typography.Text>
      ),
      children: <CargoCategories />,
    },
    {
      key: '4',
      label: (
        <Typography.Text id="4" className="order__info">
          Системные сообщения
        </Typography.Text>
      ),
      children: <SystemMessagesTable />,
    },
    {
      key: '5',
      label: (
        <Typography.Text id="5" className="order__info">
          E-mail шаблоны
        </Typography.Text>
      ),
      children: <EmailsTable />,
    },
    {
      key: '6',
      label: (
        <Typography.Text id="6" className="order__info">
          Платежная информация
        </Typography.Text>
      ),
      children: <PaymentDataCard />,
    },
  ];

  return tabsItems;
};

const parsedOrdersWidth = JSON.parse(localStorage.getItem('customerOrders')!);

export const customerOrdersColsWidth = JSON.stringify({
  userFriendlyId: 120,
  title: parsedOrdersWidth ? parsedOrdersWidth.title : 170,
  cityFrom: parsedOrdersWidth ? parsedOrdersWidth.cityFrom : 160,
  cityTo: parsedOrdersWidth ? parsedOrdersWidth.cityTo : 160,
  dateArrival: parsedOrdersWidth ? parsedOrdersWidth.dateArrival : 135,
  price: parsedOrdersWidth ? parsedOrdersWidth.price : 120,
  offersCount: parsedOrdersWidth ? parsedOrdersWidth.offersCount : 169,
  agreementsCount: parsedOrdersWidth ? parsedOrdersWidth.agreementsCount : 150,
  acceptedAgreementUserFriendlyId: parsedOrdersWidth ? parsedOrdersWidth.acceptedAgreementUserFriendlyId : 129,
  cargoTypeNameWithParentName: parsedOrdersWidth ? parsedOrdersWidth.cargoTypeNameWithParentName : 322,
  machineName: parsedOrdersWidth ? parsedOrdersWidth.machineName : 236,
  machinePrice: parsedOrdersWidth ? parsedOrdersWidth.machinePrice : 155,
  status: parsedOrdersWidth ? (parsedOrdersWidth.status ? parsedOrdersWidth.status : 155) : 155,
  dateLoading: parsedOrdersWidth ? (parsedOrdersWidth.dateLoading ? parsedOrdersWidth.dateLoading : 135) : 135,
  weight: parsedOrdersWidth ? parsedOrdersWidth.weight : 104,
  length: parsedOrdersWidth ? parsedOrdersWidth.length : 115,
  width: parsedOrdersWidth ? parsedOrdersWidth.width : 125,
  height: parsedOrdersWidth ? parsedOrdersWidth.height : 125,
  distance: parsedOrdersWidth ? parsedOrdersWidth.distance : 155,
  comment: parsedOrdersWidth ? parsedOrdersWidth.comment : 322,
  createdAt: parsedOrdersWidth ? parsedOrdersWidth.createdAt : 165,
});

const parsedContractsWidth = JSON.parse(localStorage.getItem('customerContracts')!);

export const customerContractsColsWidth = JSON.stringify({
  agreementId: 120,
  cityFrom: parsedContractsWidth ? parsedContractsWidth.cityFrom : 179,
  cityTo: parsedContractsWidth ? parsedContractsWidth.cityTo : 179,
  arrival: parsedContractsWidth ? parsedContractsWidth.arrival : 145,
  price: parsedContractsWidth ? parsedContractsWidth.price : 155,
  organisationName: parsedContractsWidth ? parsedContractsWidth.organisationName : 240,
  lastReportComment: parsedContractsWidth ? parsedContractsWidth.lastReportComment : 200,
  lastReport: parsedContractsWidth ? parsedContractsWidth.lastReport : 174,
  title: parsedContractsWidth ? parsedContractsWidth.title : 182,
  cargoTypeNameWithParentName: parsedContractsWidth ? parsedContractsWidth.cargoTypeNameWithParentName : 322,
  machineName: parsedContractsWidth ? parsedContractsWidth.machineName : 236,
  distance: parsedContractsWidth ? parsedContractsWidth.distance : 155,
  comments: parsedContractsWidth ? parsedContractsWidth.comments : 322,
  machinePrice: parsedContractsWidth ? parsedContractsWidth.machinePrice : 155,
});

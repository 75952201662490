import { API } from '../../../../Services/axios';
import { Col, Row, Table } from 'antd';
import { Profile } from '../../../../Models/Profile';
import { getOperatorsTableColumns } from './Component/columns';
import { getOperatorsTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';
import Toolbar from './Toolbar';

const OperatorsTable = () => {
  const { showErrorModal } = useErrorModal();
  const [data, setData] = useState<{ [key: string]: Profile[] }>({ '0': [] });
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const [count, setCount] = useState([]);

  const restartTable = () => {
    API.get(`/api/v1/admin/operators?page=${page}&size=${size}`)
      .then((response) => {
        // @ts-ignore
        setCount(Object.keys(response.data));
        setData(response.data);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    restartTable();
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
    }
    restartTable();
  }, [page, size]);

  const sizePage = localStorage.getItem('sizePage');

  const columns = getOperatorsTableColumns();

  const { paginationInfo, setPaginationInfo } = getOperatorsTableFilter();

  useEffect(() => {
    if (paginationInfo?.current) {
      setSearchParams((params) => {
        params.set('page', paginationInfo.current ? paginationInfo.current.toString() : '');
        return searchParams;
      });
    }
    if (paginationInfo?.pageSize) {
      setSearchParams((params) => {
        params.set('size', paginationInfo.pageSize ? paginationInfo.pageSize.toString() : '');
        return searchParams;
      });
    }
  }, [paginationInfo]);
  return (
    <div>
      <Row justify="space-between">
        <Col span={24}>
          <Toolbar />
        </Col>
      </Row>
      <div>
        <Table
          bordered
          rowKey={'id'}
          className="table__organizations"
          loading={loading}
          scroll={{ x: 1331 }}
          columns={columns}
          dataSource={data[count[0]]}
          locale={tableLocaleSettings}
          pagination={tablePagingSettings(count[0], sizePage)}
        />
      </div>
    </div>
  );
};

export default OperatorsTable;

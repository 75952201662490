// Mask for phone number
export const getMaskedPhoneNumberInOrder = (value: string) => {
  const clearValue = value.replace(/\(|\)|-|\s/g, '');
  const phoneNumber =
    clearValue.slice(0, 2) +
    ' (' +
    clearValue.slice(2, 5) +
    ') ' +
    clearValue.slice(5, 8) +
    '-' +
    clearValue.slice(8, 10) +
    '-' +
    clearValue.slice(10);
  return phoneNumber;
};

// Mask for phone number without '+7' in the beginning
export const getMaskedPhoneNumberInProfile = (value: string) => {
  const clearValue = value.replace(/\(|\)|-|\s/g, '');
  const phoneNumber =
    '+7 ' +
    ' (' +
    clearValue.slice(0, 3) +
    ') ' +
    clearValue.slice(3, 6) +
    '-' +
    clearValue.slice(6, 8) +
    '-' +
    clearValue.slice(8);
  return phoneNumber;
};

import { DataType } from '../AnalyticalReport';
import { Descriptions, Typography } from 'antd';
import { formatPriceWithDecimals } from '../../../../Utils/formatPrice';

interface ReportCardProps {
  data: DataType;
}

const ReportCard = (props: ReportCardProps) => {
  const { data } = props;

  const colonStyle = { backgroundColor: '#fff', textAlign: 'right' as const };

  return (
    <Descriptions bordered title="" size="middle" column={1}>
      <Descriptions.Item label={<Typography.Text strong>Показатель</Typography.Text>} contentStyle={colonStyle}>
        <Typography.Text strong>Значение</Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label="Общее количество заказчиков в системе" contentStyle={colonStyle}>
        {data['Общее количество заказчиков в системе']}
      </Descriptions.Item>
      <Descriptions.Item label="Количество заказчиков, создавших минимум один заказ" contentStyle={colonStyle}>
        {data['Количество заказчиков, создавших минимум один заказ']}
      </Descriptions.Item>
      <Descriptions.Item label="Общее количество организаторов в системе" contentStyle={colonStyle}>
        {data['Общее количество организаторов в системе']}
      </Descriptions.Item>
      <Descriptions.Item label="Количество организаторов с подтвержденным профилем" contentStyle={colonStyle}>
        {data['Количество организаторов с подтвержденным профилем']}
      </Descriptions.Item>
      <Descriptions.Item label="Общее количество заказов в системе" contentStyle={colonStyle}>
        {data['Общее количество заказов в системе']}
      </Descriptions.Item>
      <Descriptions.Item label="Количество завершенных договоров" contentStyle={colonStyle}>
        {data['Количество завершенных договоров']}
      </Descriptions.Item>
      <Descriptions.Item label="Расчетная сумма по размещенным заказам" contentStyle={colonStyle}>
        {formatPriceWithDecimals(Number(data['Расчетная сумма по размещенным заказам']))} ₽
      </Descriptions.Item>
      <Descriptions.Item label="Расчетная сумма по завершенным договорам" contentStyle={colonStyle}>
        {formatPriceWithDecimals(Number(data['Расчетная сумма по завершенным договорам']))} ₽
      </Descriptions.Item>
      <Descriptions.Item label="Количество заказов без предложений" contentStyle={colonStyle}>
        {data['Количество заказов без предложений']}
      </Descriptions.Item>
      <Descriptions.Item label="Среднее количество предложений на заказ" contentStyle={colonStyle}>
        {data['Среднее количество предложений на заказ']}
      </Descriptions.Item>
      <Descriptions.Item label="Количество полученных обращений" contentStyle={colonStyle}>
        {data['Количество полученных обращений']}
      </Descriptions.Item>
      <Descriptions.Item label="Количество обработанных обращений" contentStyle={colonStyle}>
        {data['Количество обработанных обращений']}
      </Descriptions.Item>
      <Descriptions.Item label="Количество заказчиков, получивших обращение" contentStyle={colonStyle}>
        {data['Количество заказчиков, получивших обращение']}
      </Descriptions.Item>
      <Descriptions.Item label="Количество организаторов, получивших обращение" contentStyle={colonStyle}>
        {data['Количество организаторов, получивших обращение']}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ReportCard;

import { API } from '../../../Services/axios';
import { Alert, Button, Space, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { SystemMessage } from '../../../Models/SystemMessage';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../Components/Modals/ErrorModal';
import { useNavigate } from 'react-router-dom';

interface UnverifiedOrgCardProps {
  areFieldsFilled: boolean;
}

const UnverifiedOrgCard = (props: UnverifiedOrgCardProps) => {
  const { showErrorModal } = useErrorModal();
  const { areFieldsFilled } = props;
  const navigate = useNavigate();

  const goToBill = () => navigate('bill');

  const [systemMessage, setSystemMessage] = useState<SystemMessage>();
  useEffect(() => {
    API.get('/api/v1/messages')
      .then((response) => setSystemMessage(response.data.find((item) => item.shortName === 'Выставление счета')))
      .catch((error) => showErrorModal({ error }));
  }, []);

  const alertContent = (
    <Space direction="vertical" size={16}>
      <Typography.Text>
        Подтвержденные организаторы могут отправлять предложения к заказам и заключать сделки. Для проверки просим Вас
        провести тестовый платеж с расчетного счета организации.
      </Typography.Text>

      <Space direction="vertical">
        <Typography.Text>
          1. Заполните данные об организации и убедитесь, что информация для выставления счета введена корректно
        </Typography.Text>
        <Typography.Text>
          2. Нажмите <strong>Получить счет</strong>
        </Typography.Text>
        <Typography.Text>3. Оплатите полученный счет</Typography.Text>
      </Space>

      <Typography.Text>{systemMessage?.message}</Typography.Text>

      <Button size="large" type="primary" onClick={goToBill} disabled={!areFieldsFilled}>
        Получить счет
      </Button>
    </Space>
  );

  return (
    <Alert
      message={<strong>Подтвердите организацию</strong>}
      description={alertContent}
      type="warning"
      showIcon
      icon={<ExclamationCircleFilled />}
    />
  );
};

export default UnverifiedOrgCard;

import { API } from '../../../../../Services/axios';
import { Alert, Card, Col, Row, Tooltip, Typography } from 'antd';
import { ContractReport } from '../../../../../Models/Contract';
import { ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { ReportStatuses } from '../../../../../Constants/ReportStatuses';
import { reverse } from 'lodash';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import CompletedWorksActCard from './CompletedWorksActCard';
import Loader from '../../../../../Components/Loader/Loader';
import ReportTimeline from '../../../../../Components/Reports/ReportTimeline';

interface ReportProps {
  contractId: number;
}

const Report = (props: ReportProps) => {
  const { showErrorModal } = useErrorModal();
  const { contractId } = props;
  const [data, setData] = useState<ContractReport[]>();
  const [track, setTrack] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/report/getReportsList/${contractId}`)
      .then((response) => {
        setData(reverse(response.data.reportsList));
        setTrack(response.data.trackNo);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  const completedWorksActRecord: ContractReport | undefined = data?.find(
    (item) => item.status === ReportStatuses.WORK_DONE,
  );
  const paidConfirmedRecord: ContractReport | undefined = data?.find((item) => item.status === ReportStatuses.PAID);
  const cancelledContractReport: ContractReport | undefined = data?.find(
    (item) => item.status === ReportStatuses.CANCELLED,
  );

  if (loading) return <Loader />;

  return (
    <Row gutter={[0, 24]} className="contract__report contract__full-width">
      {cancelledContractReport && (
        <Col span={24}>
          <Alert
            message={
              <Typography.Text className="contract__info">
                Организатор перевозки инициировал принудительное завершение исполнения договора в системе, так как он не
                может быть исполнен
              </Typography.Text>
            }
            type="error"
            showIcon
            icon={<ExclamationCircleFilled />}
          />
        </Col>
      )}
      {paidConfirmedRecord && (
        <Col span={24}>
          <Alert
            message={<Typography.Text className="contract__info">Договор успешно завершен</Typography.Text>}
            type="success"
            showIcon
          />
        </Col>
      )}
      {completedWorksActRecord && (
        <Col span={24}>
          <CompletedWorksActCard
            paidConfirmedRecord={paidConfirmedRecord}
            completedWorksActRecord={completedWorksActRecord}
          />
        </Col>
      )}
      <Col span={24}>
        <Card title={<Typography.Title level={4}>Отчет об исполнении</Typography.Title>}>
          {data && <ReportTimeline reportList={data} />}
        </Card>
      </Col>

      {track && (
        <Col span={24}>
          <Card>
            <Row justify="space-between" gutter={[24, 12]}>
              <Col>
                <Typography.Title level={4}>
                  Трек-номер для отслеживания
                  <Tooltip
                    className="contract__info-icon"
                    title="Номер нужен для отслеживания заказа на главной странице"
                  >
                    <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                  </Tooltip>
                </Typography.Title>
              </Col>
              <Col>
                <Typography.Title level={4} className="contract__info" copyable={{ tooltips: true }}>
                  {track}
                </Typography.Title>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default Report;

import { Button, Space, Typography } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import ReplayClaimForm from './replay-form';

interface ClaimInfoModalFooterProps {
  claimId: number;
  operatorComments?: string;
  setReload: Dispatch<SetStateAction<boolean>>;
  handleCancel: () => void;
}

const ClaimInfoModalFooter = (props: ClaimInfoModalFooterProps) => {
  const { claimId, operatorComments, setReload, handleCancel } = props;

  return operatorComments ? (
    <div className="operator__claim-footer">
      <Space direction="vertical" size={10}>
        <Typography.Text type="secondary" className="profile__info-large" strong>
          Решение по обращению
        </Typography.Text>
        <Typography.Text className="profile__info-large">{operatorComments}</Typography.Text>
      </Space>
      <Button type="primary" size="large" onClick={handleCancel}>
        Ок
      </Button>
    </div>
  ) : (
    <ReplayClaimForm setReload={setReload} claimId={claimId} handleCancel={handleCancel} />
  );
};

export default ClaimInfoModalFooter;

import { AdminCalculatorSettingsList } from '../../../../../Models/CalculatorSettings';
import { Card, Col, Row, Space, Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getYearsDeclention } from '../../../../../Utils/yearsDeclention';
import EditCalculatorSettingsModal from '../Modals/EditCalculatorSettingsModal';
import SettingsItem from '../Component/SettingsItem';

interface SettingsListProps {
  data: AdminCalculatorSettingsList;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const SettingsList = (props: SettingsListProps) => {
  const { data, setReload } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const hideModal = () => setOpenModal(false);
  const showModal = () => setOpenModal(true);

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title level={3}>Параметры калькулятора организатора</Typography.Title>
      </Col>
      <Col>
        <Typography.Link onClick={showModal} underline>
          Редактировать
        </Typography.Link>
      </Col>
    </Row>
  );

  return (
    <Card title={cardTitle} className="settings__card" bordered={false}>
      <Row gutter={[0, 40]} style={{ width: '100%' }}>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Расходы на топливо</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Стоимость топлива (за 1 л)" value={`${formatPrice(Number(data.fuelPrice))} ₽`} />
              <SettingsItem
                title="Средний расход топлива (на 100 км)"
                value={`${formatPrice(Number(data.fuelConsumption))} л`}
              />
              <SettingsItem title="Стоимость мочевины (за 1 л)" value={`${formatPrice(Number(data.adbluePrice))} ₽`} />
              <SettingsItem
                title="Средний расход мочевины (на 100 км)"
                value={`${formatPrice(Number(data.adblueConsumption))} л`}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Выплаты водителю</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem
                title="Заработная плата водителя (за 1 км)"
                value={`${formatPrice(Number(data.driverSalary))} ₽`}
              />
              <SettingsItem
                title="Заработная плата водителя (% от оборота)"
                value={`${data.driverSalaryPercentage} %`}
              />
              <SettingsItem title="Страховые взносы" value={`${data.insuranceFee} %`} />
              <SettingsItem
                title="Стоимость суточных водителя"
                value={`${formatPrice(Number(data.driverSalaryDay))} ₽`}
              />
              <SettingsItem title="Продолжительность рабочего дня водителя" value={`${data.driverWorkingDay} ч`} />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Затраты на автошины</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Стоимость комплекта автошин" value={`${formatPrice(Number(data.tyresPrice))} ₽`} />
              <SettingsItem
                title="Интервал замены автошин"
                value={`${formatPrice(Number(data.tyresChangeRange))} км`}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Затраты на ТО и ремонт</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Стоимость ТО" value={`${formatPrice(Number(data.servicePrice))} ₽`} />
              <SettingsItem title="Межсервисный пробег" value={`${formatPrice(Number(data.serviceRange))} км`} />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Стоимость лизинга</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Ежемесячный платеж" value={`${formatPrice(Number(data.leasingPrice))} ₽`} />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Амортизация</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Стоимость автомобиля" value={`${formatPrice(Number(data.carPrice))} ₽`} />
              <SettingsItem title="Период полезного применения" value={getYearsDeclention(data.carUsageAge)} />
              <SettingsItem
                title="Амортизационные отчисления (в год)"
                value={`${formatPrice(Number(data.carUsageFee))} ₽`}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Дополнительные расходы</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Дополнительные расходы, % от затрат" value={`${data.additionalFee} %`} />
              <SettingsItem
                title="Страхование груза, % от стоимости груза "
                value={`${data.insuranceCargoPriceFee} %`}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Налоги</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Транспортный налог в год" value={`${formatPrice(Number(data.transportTaxes))} ₽`} />
              <SettingsItem title="Ставка НДС" value={`${data.vatTaxes} %`} />
              <SettingsItem title="Ставка налога на прибыль" value={`${data.profitTaxes} %`} />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Заработная плата логиста и постоянные расходы</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Заработная плата логиста, % от оборота" value={`${data.logisticianSalary} %`} />
              <SettingsItem title="Постоянные расходы, % от оборота" value={`${data.expenses} %`} />
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} style={{ width: '100%' }}>
          <Col span={24}>
            <Typography.Title level={4}>Планируемая маржинальность</Typography.Title>
          </Col>
          <Col lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 14 }} span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <SettingsItem title="Планируемая маржинальность, % от оборота" value={`${data.desiredProfit} %`} />
            </Space>
          </Col>
        </Row>
      </Row>
      <EditCalculatorSettingsModal open={openModal} handleCancel={hideModal} data={data} setReload={setReload} />
    </Card>
  );
};

export default SettingsList;

import { API } from '../../../Services/axios';
import { Card, Col, Divider, Row, Space, Typography } from 'antd';
import { OrderMarginCalculation } from '../../../Models/Order';
import { formatPrice } from '../../../Utils/formatPrice';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../Components/Modals/ErrorModal';
import CalcItem from './Component/calc-sets-item';
import EditOrgMarginCalcSettingsModal from '../../../Components/Modals/EditOrgMarginCalcSettingsModal';

interface ProfileOrgCalcSettingCardProps {
  examplePrice: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const ProfileOrgCalcSettingCard = (props: ProfileOrgCalcSettingCardProps) => {
  const { showErrorModal } = useErrorModal();
  const { examplePrice, setCurrent } = props;
  const [openEditOrgMarginCalcSettingsModal, setOpenEditOrgMarginCalcSettingsModal] = useState(false);
  const handleOpenEditOrgMarginCalcSettingsModal = () => setOpenEditOrgMarginCalcSettingsModal(true);
  const handleCancelEditOrgMarginCalcSettingsModal = () => setOpenEditOrgMarginCalcSettingsModal(false);

  const [orderMarginCalculation, setOrderMarginCalculation] = useState<OrderMarginCalculation>();
  useEffect(() => {
    API.get(`/api/v1/calculator/for-profile`)
      .then((response) => setOrderMarginCalculation(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <Typography.Title level={3}>Настройки калькулятора</Typography.Title>
          </Col>
          <Col>
            <Typography.Text className="contract__info__margin" onClick={handleOpenEditOrgMarginCalcSettingsModal}>
              Редактировать
            </Typography.Text>
            {orderMarginCalculation && (
              <EditOrgMarginCalcSettingsModal
                open={openEditOrgMarginCalcSettingsModal}
                handleCancel={handleCancelEditOrgMarginCalcSettingsModal}
                orderMarginCalculation={orderMarginCalculation}
                examplePrice={examplePrice}
                setCurrent={setCurrent}
              />
            )}
          </Col>
        </Row>
      }
    >
      {orderMarginCalculation && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={[0, 40]}>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Расходы на топливо</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Стоимость топлива (за 1л)"
                      value={`${formatPrice(orderMarginCalculation.fuelPrice)} ₽`}
                    />
                    <CalcItem
                      title="Средний расход топлива (на 100км)"
                      value={`${orderMarginCalculation.fuelConsumption} л`}
                    />
                    <CalcItem
                      title="Стоимость мочевины (за 1л)"
                      value={`${formatPrice(orderMarginCalculation.adbluePrice)} ₽`}
                    />
                    <CalcItem
                      title="Средний расход мочевины (на 100 км)"
                      value={`${orderMarginCalculation.adblueConsumption} л`}
                    />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Выплаты водителю</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Заработная плата водителя (за 1 км)"
                      value={`${formatPrice(orderMarginCalculation.driverSalary)} ₽`}
                    />
                    <CalcItem
                      title="Заработная плата водителя (% от оборота)"
                      value={`${orderMarginCalculation.driverSalaryPercentage} %`}
                    />
                    <CalcItem title="Страховые взносы" value={`${orderMarginCalculation.insuranceFee} %`} />
                    <CalcItem
                      title="Стоимость суточных водителя"
                      value={`${formatPrice(orderMarginCalculation.driverSalaryDay)} ₽`}
                    />
                    <CalcItem
                      title="Продолжительность рабочего дня водителя"
                      value={`${orderMarginCalculation.driverWorkingDay} ч`}
                    />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Затраты на автошины</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Стоимость комплекта автошин"
                      value={`${formatPrice(orderMarginCalculation.tyresPrice)} ₽`}
                    />
                    <CalcItem title="Интервал замены автошин" value={`${orderMarginCalculation.tyresChangeRange} км`} />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Затраты на ТО и ремонт</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem title="Стоимость ТО" value={`${formatPrice(orderMarginCalculation.servicePrice)} ₽`} />
                    <CalcItem title="Межсервисный пробег" value={`${orderMarginCalculation.serviceRange} км`} />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Стоимость лизинга</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Ежемесячный платеж"
                      value={`${formatPrice(orderMarginCalculation.leasingPrice)} ₽`}
                    />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Амортизация</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Стоимость автомобиля"
                      value={`${formatPrice(orderMarginCalculation.carPrice)} ₽`}
                    />
                    <CalcItem title="Период полезного применения" value={`${orderMarginCalculation.carUsageAge} лет`} />
                    <CalcItem
                      title="Амортизационные отчисления"
                      value={`${formatPrice(orderMarginCalculation.carUsageFee)} ₽`}
                    />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Дополнительные расходы</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Дополнительные расходы, % от затрат"
                      value={`${orderMarginCalculation.additionalFee} %`}
                    />
                    <CalcItem
                      title="Страхование груза, % от стоимости груза"
                      value={`${orderMarginCalculation.insuranceCargoPriceFee} %`}
                    />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Налоги</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Транспортный налог в год"
                      value={`${formatPrice(orderMarginCalculation.transportTaxes)} ₽`}
                    />
                    <CalcItem title="Ставка НДС" value={`${orderMarginCalculation.vatTaxes} %`} />
                    <CalcItem title="Ставка налога на прибыль" value={`${orderMarginCalculation.profitTaxes} %`} />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Заработная плата логиста и постоянные расходы</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Заработная плата логиста, % от оборота"
                      value={`${orderMarginCalculation.logisticianSalary} %`}
                    />
                    <CalcItem title="Постоянные расходы, % от оборота" value={`${orderMarginCalculation.expenses} %`} />
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Планируемая маржинальность</Typography.Title>
                </Col>
                <Col span={24}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <CalcItem
                      title="Планируемая маржинальность, % от оборота"
                      value={`${orderMarginCalculation.desiredProfit} %`}
                    />
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Typography.Text style={{ fontSize: 16 }}>
                При текущих расходах калькулятора перевозка по направлению <b>Москва - Санкт-Петербург</b> составит{' '}
                <b>{`${formatPrice(examplePrice)} ₽`}</b>.<br></br>
                Для расчета по другим направлениям перейдите в раздел{' '}
                <b>
                  <u
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setCurrent(5);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    Расчет стоимости перевозки
                  </u>
                </b>
                .
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default ProfileOrgCalcSettingCard;

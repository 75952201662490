import { API } from '../../../../../../../Services/axios';
import { Button, Form, InputNumber, Modal, Row, Space, Typography } from 'antd';
import { ExactAddress } from '../../../../../../../Models/Address';
import { ModalProps } from '../../../../../../../Models/Modal';
import { getRequiredFieldRule } from '../../../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../../../Components/Modals/ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from '../../../../../../../Components/Modals/ModalTitle';
import YAdresses from './ymaps/yaddresses';

const AddRateModal = (props: ModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel } = props;

  const [formAdd] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [addressFrom, setAddressFrom] = useState<ExactAddress>({ displayName: '', components: [], coordinates: [] });
  const [addressTo, setAddressTo] = useState<ExactAddress>({ displayName: '', components: [], coordinates: [] });
  const price = useWatch('price', formAdd);

  const onFinish = () => {
    formAdd.validateFields();
    setLoading(true);
    const values = formAdd.getFieldsValue();

    const priceMatrixDto = {
      regionFrom: addressFrom.components.filter((component) => component.kind === 'province').pop()?.name ?? null,
      cityFrom: addressFrom.components.filter((component) => component.kind === 'locality')?.pop()?.name ?? null,
      regionTo: addressTo.components.filter((component) => component.kind === 'province').pop()?.name ?? null,
      cityTo: addressTo.components.filter((component) => component.kind === 'locality')?.pop()?.name ?? null,
      price: values.price,
    };

    API.post('/api/v1/price-matrix', priceMatrixDto)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) => {
        if (error.toString().toLowerCase().includes('the specified rule already exists'))
          showErrorModal({
            title: 'Направление уже существует',
            message:
              'Вы пытаетесь добавить направление, которое уже существует. Для изменения тарифа в уже существующем направлении воспользуйтесь редактированием.',
          });
        else showErrorModal({ error });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={
        <ModalTitle
          level={3}
          title="Создание нового направления"
          handleCancel={() => {
            formAdd.setFieldValue('regionFrom', '');
            setAddressFrom({ displayName: '', components: [], coordinates: [] });
            formAdd.setFieldValue('regionTo', '');
            setAddressTo({ displayName: '', components: [], coordinates: [] });
            handleCancel();
          }}
        />
      }
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32}>
        <Row>
          <Typography.Text className="modal__text-large">
            Вы можете ввести субъект Российской Федерации (автономный округ, область, край, республику) или город
          </Typography.Text>
        </Row>
      </Space>
      <br />
      <br />
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form id="formAdd" form={formAdd} colon={false} layout="vertical" className="form">
          <YAdresses
            form={formAdd}
            addressFrom={addressFrom}
            addressTo={addressTo}
            setAddressFrom={setAddressFrom}
            setAddressTo={setAddressTo}
          />
          <Form.Item name="price" label="Тариф за 1 км, ₽" rules={getRequiredFieldRule('Введите цену')}>
            <InputNumber min={0} maxLength={9} size="large" />
          </Form.Item>
        </Form>
        <Space>
          <Row>
            <Button
              size="large"
              className="modal__cancel-btn"
              onClick={() => {
                formAdd.setFieldValue('regionFrom', '');
                setAddressFrom({ displayName: '', components: [], coordinates: [] });
                formAdd.setFieldValue('regionTo', '');
                setAddressTo({ displayName: '', components: [], coordinates: [] });
                handleCancel();
              }}
            >
              Отмена
            </Button>
            <Button
              disabled={addressFrom.displayName === '' || addressTo.displayName === '' || !price}
              type="primary"
              size="large"
              form="form"
              htmlType="submit"
              onClick={onFinish}
              loading={loading}
            >
              Добавить
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddRateModal;

// Using to disable scroll and add gray background when menu is opened
const lockWindow = () => {
  const html = document.querySelector('html');

  if (!html) return;

  html.classList.add('locked');
};

// Using to enable scroll and hide gray background when menu is closed
const unlockWindow = () => {
  const html = document.querySelector('html');

  if (!html) return;

  html.classList.remove('locked');
};

// Using to open any menu
export const openMenu = (menu, locked = true) => {
  if (!menu) return;

  menu.classList.add('active');

  if (locked) {
    lockWindow();
  }
};

// Using to close any menu
export const closeMenu = (menu, locked = false) => {
  if (!menu) return;

  menu.classList.remove('active');

  if (!locked) {
    unlockWindow();
  }
};

// Using to close landing menu on click
export const closeHeaderMenu = () => {
  const headerMenu = document.querySelector('.menu');
  if (!headerMenu || !headerMenu.classList.contains('active')) return;

  const headerMenuToggle = document.querySelector('.header__menu-toggle');
  if (!headerMenuToggle) return;

  closeMenu(headerMenu);
};

// Using to close user menu on click
export const closeUserMenu = () => {
  const userMenu = document.querySelector('.header__user-menu');
  if (!userMenu || !userMenu.classList.contains('active')) return;

  closeMenu(userMenu);
};

// Using to toggle user menu on click
export const toggleUserMenu = (e) => {
  const userMenu = document.querySelector('.header__user-menu');

  e.preventDefault();

  if (e.target.classList.contains('active')) {
    closeMenu(userMenu);
  } else {
    closeUserMenu();
    closeHeaderMenu();
    const locked = window.matchMedia('(max-width: 1279px)').matches;
    openMenu(userMenu, locked);
  }

  e.target.classList.toggle('active');
};

// Using to toggle landing menu on click
export const toggleMenu = (e) => {
  const headerMenu = document.querySelector('.menu');
  const headerMenuToggle = document.querySelector('.header__menu-toggle');

  e.preventDefault();

  if (headerMenuToggle?.classList.contains('active')) {
    closeHeaderMenu();
  } else {
    closeUserMenu();
    closeHeaderMenu();
    const locked = window.matchMedia('(max-width: 1279px)').matches;
    openMenu(headerMenu, locked);
  }

  headerMenuToggle?.classList.toggle('active');
};

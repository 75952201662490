import { API } from '../../../../Services/axios';
import { CargoCategory } from '../../../../Models/CargoCategories';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import CargoCategoriesTable from './Table/CargoCategoriesTable';
import Toolbar from './Toolbar';

const CargoCategories = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CargoCategory[]>([]);
  const [parentCategories, setParentCategories] = useState<CargoCategory[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/dictionary/cargo-categories')
      .then((response) => {
        const categoriesList = response.data;
        const parentCategories = categoriesList.filter((category) => !Object.hasOwn(category, 'parentId'));
        setParentCategories(parentCategories);

        const childCategories = categoriesList.filter((category) => Object.hasOwn(category, 'parentId'));
        const reorganizedCategories = childCategories.map((category) => {
          const parentCategory = parentCategories.find((parent) => parent.id === category.parentId);
          return {
            ...category,
            parentTitle: parentCategory.title,
          };
        });
        const sortedArr = sortBy(
          sortBy(reorganizedCategories, function (c) {
            return c.title.toLowerCase();
          }),
          function (c) {
            return c.parentTitle.toLowerCase();
          },
        );
        setData(sortedArr);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  }, [reload]);

  return (
    <div style={{ background: '#fff' }}>
      <Toolbar parentCategories={parentCategories} setReload={setReload} />
      <CargoCategoriesTable data={data} loading={loading} setReload={setReload} />
    </div>
  );
};

export default CargoCategories;

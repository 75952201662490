export enum OfferStatuses {
  NEW = 'NEW',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  CANCELLED_BY_DELIVERY = 'CANCELLED_BY_DELIVERY',
  CANCELLED = 'CANCELLED',
  AGREEMENT_REQUEST = 'AGREEMENT_REQUEST',
  PRE_AGREEMENT_LOADED = 'PRE_AGREEMENT_LOADED',
  OUT_OF_VALIDITY_DATE = 'OUT_OF_VALIDITY_DATE',
  AGREEMENT_LOADED = 'AGREEMENT_LOADED',
  ACCEPTED = 'ACCEPTED',
  DONE = 'DONE',
}

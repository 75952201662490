import { Rule } from 'antd/lib/form';
import { checkIndividualInn, checkLegalInn } from '../checkInn';
import { getRequiredFieldRule } from './order';

export const getPasswordRule = (): Rule[] => [
  ...getRequiredFieldRule('Введите пароль'),
  {
    validator(_, value) {
      const specialChars = /[_!@#&()–[{}\]:;',%?\\/*~$^+=<>]+/;
      const length = value.length;
      const passMatcher =
        value.match(/[A-Z]/g) && value.match(/[a-z]/g) && value.match(/[0-9]/g) && value.match(specialChars);
      if (passMatcher && length >= 8) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          'Пароль должен быть не меньше восьми символов, содержать минимум одну заглавную и одну строчную латинские буквы, одну цифру и один специальный символ',
        ),
      );
    },
  },
];

export const getConfirmPasswordRule = (): Rule[] => [
  ...getRequiredFieldRule('Подтвердите ваш пароль'),
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Пароли не совпадают'));
    },
  }),
];

export const getEmailFieldRules = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    type: 'email',
    message: 'Убедитесь, что адрес введен без ошибок',
  },
];

export const getPhoneFieldRules = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator: (_, value) => {
      const digitValue = value.replace(/\D/g, '');
      if (digitValue.length === 10) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что телефон введен верно'));
    },
  },
];

export const getNameFieldRules = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    max: 50,
    message: 'Вводимое значение не может быть больше 50 символов',
  },
  {
    validator: (_, value) => {
      if (!value.match(/[0-9]/g) && !value.match(/[._!@#&()[{}\]:;',%?\\/*~$^+=<>|"№]+/)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Вводимое значение не должно содержать цифры и специальные символы'));
    },
  },
  {
    validator: (_, value) => {
      if (!value.trim() === !!'') {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Вводимое значение не может состоять только из пробелов'));
    },
  },
];

export const getOrgNameFieldRules = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    max: 200,
    message: 'Вводимое значение не может быть больше 200 символов',
  },
];

export const getKppFieldsRules = (): Rule[] => [
  ...getRequiredFieldRule('Введите КПП организации'),
  {
    validator: (_, value) => {
      const stringValue = value.toString();
      if (stringValue.length === 9 && /^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(stringValue)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`Убедитесь, что КПП введен правильно`));
    },
  },
];

export const getOrgFieldsRules = (message: string, number: number): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator: (_, value) => {
      if (`${value}`.length === number) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`Количество вводимых символов должно быть равным ${number}`));
    },
  },
];

export const getLegalInnFieldRules = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator: (_, value) => {
      if (`${value}`.length === 10 && checkLegalInn(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что ИНН введен правильно'));
    },
  },
];

export const getIndividualInnFieldRules = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator: (_, value) => {
      if (`${value}`.length === 12 && checkIndividualInn(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что ИНН введен правильно'));
    },
  },
];

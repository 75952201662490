import { Dispatch, SetStateAction, useEffect } from 'react';
import { closeMenu } from '../../Utils/toggleMenu';
import { getUserInfo } from '../../Store/Slices/userSlice';
import { login } from '../../Utils/login';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import GuestHeader from './GuestHeader';
import UserHeader from './UserHeader';

interface AppHeaderProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const AppHeader = (props: AppHeaderProps) => {
  const { setLoading } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);

  const parsedStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')!) : {};
  const parsedStorageLength = Object.keys(parsedStorage).length;

  useEffect(() => {
    if (
      pathname === '/' ||
      (parsedStorageLength === 0 &&
        !pathname.includes('/registration') &&
        !pathname.includes('/operator/set-password') &&
        !pathname.includes('/signin') &&
        !pathname.includes('/newPassword') &&
        !pathname.includes('/resetPassword'))
    ) {
      setLoading(true);
      login(navigate, pathname).then(() => setLoading(false));
    }
  }, [parsedStorageLength, pathname]);

  useEffect(() => {
    dispatch(
      getUserInfo({
        role: parsedStorage.role,
        name: parsedStorage.name && `${parsedStorage.name} ${parsedStorage.surname}`,
        avatarLink: parsedStorage.avatarLink,
        phone: parsedStorage.phone,
        confirmed: parsedStorage.confirmed,
      }),
    );
  }, [parsedStorage]);

  const headerMenuInit = (menu) => {
    if (!menu) return;

    const headerMenuToggle = document.querySelector('.header__menu-toggle');
    window.addEventListener('resize', () => {
      closeMenu(menu);
      headerMenuToggle!.classList.remove('active');
    });
  };

  const userMenuInit = (menu) => {
    if (!menu) return;

    const userMenuToggle = document.querySelector('.header__user-toggle');
    const userMenuIcon = document.querySelector('.header__user-icon');

    if (!userMenuToggle || !userMenuIcon) return;

    window.addEventListener('resize', () => {
      closeMenu(menu);
    });
  };

  const headerMenu = document.querySelector('.menu');
  const userMenu = document.querySelector('.header__user-menu');

  useEffect(() => {
    headerMenuInit(headerMenu);
    userMenuInit(userMenu);
  }, [headerMenu, userMenu]);

  return !role ? <GuestHeader /> : <UserHeader />;
};

export default AppHeader;

import { chain } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumns } from '../InitialStates/CustomerContractsInitialState';

const preSelectedColKeys = [
  'agreementId',
  'cityFrom',
  'cityTo',
  'arrival',
  'price',
  'orderUserFriendlyId',
  'organisationName',
  'lastReportComment',
  'lastReport',
];

const presetColumns = chain(preSelectedColKeys)
  .toPairs()
  .map((i) => initialColumns[i[0]])
  .value();

export const customerContractsColsSlice = createSlice({
  name: 'customerContractsCols',
  initialState: presetColumns,
  reducers: {
    getUpdatedCustomerColumns: (state, action) => {
      const columns = action.payload;
      return columns;
    },
  },
});

export const { getUpdatedCustomerColumns } = customerContractsColsSlice.actions;

export default customerContractsColsSlice.reducer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { API } from '../../Services/axios';
import { Alert, AutoComplete, Button, Form, Input, Row, Select, Space, Typography, notification } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FormInstance, useWatch } from 'antd/lib/form/Form';
import { OrgTypes } from '../../Constants/OrgTypes';
import { PatternFormat } from 'react-number-format';
import { checkIndividualInn, checkLegalInn } from '../../Utils/checkInn';
import {
  getConfirmPasswordRule,
  getEmailFieldRules,
  getIndividualInnFieldRules,
  getKppFieldsRules,
  getLegalInnFieldRules,
  getNameFieldRules,
  getOrgNameFieldRules,
  getPasswordRule,
  getPhoneFieldRules,
} from '../../Utils/Rules/registration';
import { getRequiredFieldRule } from '../../Utils/Rules/order';
import { login } from '../../Utils/login';
import { orgTypeSelectOptions } from '../../Constants/OrgTypesSelectOptions';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../Modals/ErrorModal';
import { useNavigate } from 'react-router-dom';
import LabelWithTooltip from '../Label/LabelWithTooltip';
import RegistrationCheckboxes from './RegistrationCheckboxes';

interface RegistrationFormProps {
  form: FormInstance;
  role: string;
}

const RegistrationForm = (props: RegistrationFormProps) => {
  const { showErrorModal } = useErrorModal();
  const { form, role } = props;
  const navigate = useNavigate();
  const orgTypeValue = useWatch('orgType', form);
  const inn = useWatch('inn', form);
  const kpp = useWatch('kpp', form);

  const [dadataInfo, setDadataInfo] = useState<any>([]);
  const [kppOptions, setKppOptions] = useState([]);
  const [selectedKppOption, setSelectedKppOption] = useState<any>();
  const [isPDPConfirmed, setIsPDPConfirmed] = useState<boolean>(false);
  const [isSubscriptionAllowed, setIsSubscriptionAllowed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const getDadataInfo = () => {
    if (inn && inn.toString().length === 10 && checkLegalInn(inn) && orgTypeValue === OrgTypes.LEGAL) {
      API.get(`/api/v1/data/organisation/${inn}`)
        .then((response) => {
          const kppOptions = response.data.map((item) => ({
            label: item.unrestrictedValue,
            value: item.data.kpp,
          }));
          setDadataInfo(response.data);
          setKppOptions(kppOptions);
        })
        .catch((error) => showErrorModal({ error }));
    } else if (inn && inn.toString().length === 12 && checkIndividualInn(inn) && orgTypeValue === OrgTypes.INDIVIDUAL) {
      API.get(`/api/v1/data/organisation/${inn}`)
        .then((response) => {
          const activeOrgn = response.data.find((item) => (item.data.state.status = 'ACTIVE'));
          if (activeOrgn) {
            setDadataInfo([activeOrgn]);
          }
        })
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getDadataInfo();
    if (!inn) {
      form.resetFields(['kpp', 'orgName']);
    }
  }, [inn]);

  useEffect(() => {
    if (dadataInfo && orgTypeValue === OrgTypes.INDIVIDUAL) {
      form.setFieldValue('orgName', dadataInfo[0].data.name.shortWithOpf);
    } else if (dadataInfo && dadataInfo.length === 1 && orgTypeValue === OrgTypes.LEGAL) {
      form.setFieldValue('kpp', dadataInfo[0].data.kpp);
      form.setFieldValue('orgName', dadataInfo[0].data.name.shortWithOpf);
      setSelectedKppOption(dadataInfo[0].data);
    }
  }, [dadataInfo]);

  const onSelectChange = () => form.resetFields(['inn', 'orgName', 'kpp']);

  const onKppSelect = (kpp: string) => {
    form.resetFields(['orgName']);
    const selectedOption = dadataInfo?.find((info) => info.data.kpp === kpp);
    const mainOffice = dadataInfo?.find((info) => info.data.management);
    if (!selectedOption.data.management) {
      setSelectedKppOption({
        ...selectedOption.data,
        management: mainOffice.data.management,
        ogrnDate: mainOffice.data.ogrnDate,
        okpo: mainOffice.data.okpo,
        okved: mainOffice.data.okved,
      });
    } else {
      setSelectedKppOption(selectedOption.data);
    }
    form.setFieldValue('orgName', selectedOption.data.name.shortWithOpf);
  };

  useEffect(() => {
    if (kpp && kpp.length === 9 && selectedKppOption) {
      form.setFieldValue('orgName', selectedKppOption.name.shortWithOpf);
    }
  }, [kpp, selectedKppOption]);

  const onFinish = (values) => {
    setShowAlert(false);
    setLoading(true);

    let organizationData = {};

    if (orgTypeValue === OrgTypes.LEGAL && selectedKppOption) {
      organizationData = {
        shortName: values.orgName,
        inn: values.inn.toString(),
        kpp: values.kpp.toString(),
        type: values.orgType,
        name: selectedKppOption.name.fullWithOpf,
        businessAddress: selectedKppOption.address?.value,
        managementName: selectedKppOption.management?.name.split(' ')[1],
        managementPatronymic: selectedKppOption.management?.name.split(' ')[2],
        managementSurname: selectedKppOption.management?.name.split(' ')[0],
        managementPost: selectedKppOption.management?.post,
        ogrn: selectedKppOption.ogrn,
        // ogrnDate: selectedKppOption.ogrnDate && dayjs(selectedKppOption.ogrnDate).format(serverDateTimeFormat),
        okpo: selectedKppOption.okpo,
        okved: selectedKppOption.okved,
        postalAddress: selectedKppOption.address?.unrestrictedValue,
      };
    } else if (orgTypeValue === OrgTypes.INDIVIDUAL && dadataInfo[0]) {
      organizationData = {
        shortName: values.orgName,
        inn: values.inn.toString(),
        type: values.orgType,
        ogrnip: dadataInfo[0].data.ogrn,
        name: dadataInfo[0].data.name.fullWithOpf,
        businessAddress: dadataInfo[0].data.address.value,
        managementName: dadataInfo[0].data.fio.name,
        managementPatronymic: dadataInfo[0].data.fio.patronymic,
        managementSurname: dadataInfo[0].data.fio.surname,
        // ogrnDate: dadataInfo[0].data.ogrnDate && dayjs(dadataInfo[0].data.ogrnDate).format(serverDateTimeFormat),
        okpo: dadataInfo[0].data.okpo,
        okved: dadataInfo[0].data.okved,
        postalAddress: dadataInfo[0].data?.address.unrestrictedValue,
      };
    } else {
      organizationData = {
        shortName: values.orgName,
        inn: values.inn.toString(),
        kpp: values.kpp && values.kpp.toString(),
        type: values.orgType,
      };
    }

    if (isPDPConfirmed) {
      const formData = {
        notificationConfirmed: isSubscriptionAllowed,
        name: values.name,
        surname: values.surname,
        email: values.email,
        phone: '+7' + values.phone,
        organisation: organizationData,
        password: values.password,
        type: role,
      };

      API.post('/api/v1/register', formData)
        .then((response) => {
          notification.success({ message: 'Регистрация прошла успешно', placement: 'top' });
          setTimeout(() => {
            setLoading(false);
            login(navigate, '/').then(() => setLoading(false));
          }, 2000);
        })
        .catch((error) => {
          if (error.toString().toLowerCase().includes('user with this email already exists')) {
            showErrorModal({
              title: 'Пользователь с указанным адресом электронной почты уже существует',
              message: 'Если вы забыли пароль, то можете восстановить его на странице входа в систему.',
            });
          } else if (error.toString().toLowerCase().includes('organisation already exists'))
            showErrorModal({
              title: 'Организация с указанным ИНН уже существует',
              message: 'Если вы забыли пароль, то можете восстановить его на странице входа в систему.',
            });
          else showErrorModal({ error });
        })
        .finally(() => setLoading(false));
    }
  };
  const numberValide = (number) => {
    return number.replace(/\D/g, '').replace(/^8|^7/, '');
  };

  return (
    <Form
      form={form}
      layout="vertical"
      colon={false}
      className="form"
      initialValues={{ orgType: orgTypeSelectOptions[0].value }}
      onFinish={onFinish}
      onFinishFailed={() => setShowAlert(true)}
    >
      <Form.Item name="surname" label="Фамилия" validateFirst rules={getNameFieldRules('Введите вашу фамилию')}>
        <Input size="large" />
      </Form.Item>
      <Form.Item name="name" label="Имя" validateFirst rules={getNameFieldRules('Введите ваше имя')}>
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Электронная почта"
        validateFirst
        rules={getEmailFieldRules('Введите электронную почту')}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Номер телефона"
        normalize={(value) => numberValide(value)}
        rules={getPhoneFieldRules('Введите номер телефона')}
        validateFirst
      >
        <PatternFormat
          valueIsNumericString
          format="(###) ###-##-##"
          customInput={Input}
          prefix="+7"
          size="large"
          mask="_"
        />
      </Form.Item>

      <Form.Item
        name="orgType"
        label="Организационно-правовая форма"
        rules={getRequiredFieldRule('Выберите организационно-правовую форму')}
      >
        <Select options={orgTypeSelectOptions} size="large" onChange={onSelectChange} />
      </Form.Item>

      <Form.Item
        name="inn"
        label="ИНН"
        validateFirst
        normalize={(value) => value.replace(/\D/g, '')}
        rules={
          orgTypeValue === OrgTypes.LEGAL
            ? getLegalInnFieldRules('Введите ИНН организации')
            : getIndividualInnFieldRules('Введите ИНН организации')
        }
      >
        <Input size="large" maxLength={orgTypeValue === OrgTypes.LEGAL ? 10 : 12} />
      </Form.Item>

      {orgTypeValue && orgTypeValue === OrgTypes.LEGAL && (
        <Form.Item name="kpp" label="КПП" validateFirst rules={getKppFieldsRules()}>
          <AutoComplete filterOption options={kppOptions} onSelect={onKppSelect} maxLength={9} size="large" />
        </Form.Item>
      )}

      <Form.Item
        name="orgName"
        label={
          <LabelWithTooltip
            label="Краткое наименование"
            message="Это название вашей организации, которое будет отображаться другим пользователям"
          />
        }
        validateFirst
        rules={getOrgNameFieldRules('Введите краткое наименование организации')}
      >
        <Input size="large" />
      </Form.Item>

      <Form.Item name="password" label="Пароль" validateFirst rules={getPasswordRule()}>
        <Input.Password size="large" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Повторите пароль"
        validateFirst
        rules={getConfirmPasswordRule()}
        dependencies={['password']}
      >
        <Input.Password size="large" />
      </Form.Item>

      <RegistrationCheckboxes
        isPDPConfirmed={isPDPConfirmed}
        isSubscriptionAllowed={isSubscriptionAllowed}
        setIsPDPConfirmed={setIsPDPConfirmed}
        setIsSubscriptionAllowed={setIsSubscriptionAllowed}
      />

      <Row justify="center" className="registration__footer">
        <Space direction="vertical" align="center" size={16}>
          {showAlert && (
            <Alert message="Заполните обязательные поля" showIcon icon={<ExclamationCircleFilled />} type="error" />
          )}
          <Form.Item>
            <Button
              disabled={!isPDPConfirmed}
              type="primary"
              htmlType="submit"
              size="large"
              className="registration__button"
              loading={loading}
            >
              Зарегистрироваться
            </Button>
          </Form.Item>

          <Space>
            <Typography.Text>Уже есть аккаунт?</Typography.Text>
            <Typography.Link href="/signin">Войти</Typography.Link>
          </Space>
        </Space>
      </Row>
    </Form>
  );
};

export default RegistrationForm;

import { Card, Col, Row, Tabs, Typography } from 'antd';
import { Helmet } from 'react-helmet-async';
import { Roles } from '../../Constants/Profile';
import { useForm } from 'antd/lib/form/Form';
import { useLocation, useParams } from 'react-router-dom';
import RegistrationForm from './RegistrationForm';

const RegistrationPage = () => {
  const { pathname } = useLocation();
  const { type } = useParams();
  const [form] = useForm();

  const metaTags = () => {
    switch (pathname) {
      case '/registration/customer':
        return (
          <Helmet>
            <title>Регистрация Заказчика в Личном Кабинете Сайта VERUMLOGISTICS | Сервис Доставки С/Х Техники</title>
            <meta
              name="description"
              content="Зарегистрируйтесь в личном кабинете на сайте verumlogistics.ru, чтобы получить полный доступ к заказу услуг транспортировки техники. Аккаунт полностью бесплатен. Регистрация обязательна для заказчиков."
            />
          </Helmet>
        );
      case '/registration/organizer':
        return (
          <Helmet>
            <title>Регистрация Перевозчика в Личном Кабинете Сайта VERUMLOGISTICS | Сервис Доставки С/Х Техники</title>
            <meta
              name="description"
              content="Зарегистрируйтесь в личном кабинете на сайте verumlogistics.ru, чтобы получить полный доступ к размещению своих услуг транспортировки техники. Аккаунт полностью бесплатен. Регистрация обязательна для перевозчиков."
            />
          </Helmet>
        );
      case '/registration/':
        return (
          <Helmet>
            <title>Регистрация в Личном Кабинете Сайта VERUM LOGISTICS | Сервис Доставки С/Х Техники</title>
            <meta
              name="description"
              content="Зарегистрируйтесь в личном кабинете на сайте verumlogistics.ru, чтобы получить полный доступ к логистическому сервису. Аккаунт бесплатен. Если вы ранее регистрировались на verum-agro.ru, используйте этот аккаунт для авторизации."
            />
          </Helmet>
        );
      default:
        return (
          <Helmet>
            <title>Регистрация в Личном Кабинете Сайта VERUM LOGISTICS | Сервис Доставки С/Х Техники</title>
            <meta
              name="description"
              content="Зарегистрируйтесь в личном кабинете на сайте verumlogistics.ru, чтобы получить полный доступ к логистическому сервису. Аккаунт бесплатен. Если вы ранее регистрировались на verum-agro.ru, используйте этот аккаунт для авторизации."
            />
          </Helmet>
        );
    }
  };

  return (
    <Row justify="center" className="registration__container">
      {metaTags()}
      <Col xl={14} lg={18} span={24}>
        <Typography.Title level={1} style={{ fontSize: '30px' }}>
          Регистрация
        </Typography.Title>
      </Col>
      <Col xl={14} lg={18} span={24}>
        <Card className="registration__card">
          <Tabs
            defaultActiveKey={type ?? 'organizer'}
            className="registration__tabs"
            centered
            destroyInactiveTabPane
            onChange={(e) => {
              window.history.replaceState(null, '', `/registration/${e}`);
            }}
            items={[
              {
                label: `Перевозчик`,
                key: 'organizer',
                children: <RegistrationForm form={form} role={Roles.DELIVERY} />,
              },
              {
                label: `Заказчик`,
                key: 'customer',
                children: <RegistrationForm form={form} role={Roles.CUSTOMER} />,
              },
            ]}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default RegistrationPage;

import { API } from '../../../../../Services/axios';
import { AdminCalculatorSettingsList } from '../../../../../Models/CalculatorSettings';
import { Alert, Button, Form, Input, Modal, Row, Space, Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ModalProps } from '../../../../../Models/Modal';
import { NumericFormat } from 'react-number-format';
import { getRequiredFieldRule } from '../../../../../Utils/Rules/order';
import { getRequiredPositiveFieldRules } from '../../../../../Utils/Rules/calculator';
import { snakeCase } from 'lodash';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import LabelWithTooltip from '../../../../../Components/Label/LabelWithTooltip';
import ModalTitle from '../../../../../Components/Modals/ModalTitle';

interface EditCalculatorSettingsModal extends ModalProps {
  data: AdminCalculatorSettingsList;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const clearValue = (value: string | number) =>
  value ? parseFloat(value.toString().replaceAll(' ', '').replace(',', '.')) : undefined;

const EditCalculatorSettingsModal = (props: EditCalculatorSettingsModal) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, data, setReload } = props;
  const [form] = useForm();
  const carPrice = useWatch('carPrice', form);
  const carUsageAge = useWatch('carUsageAge', form);

  const [loading, setLoading] = useState<boolean>(false);

  const carUsageFee = Number(data.carPrice) / Number(data.carUsageAge);

  const initialValues = {
    fuelPrice: data.fuelPrice,
    fuelConsumption: data.fuelConsumption,
    adbluePrice: data.adbluePrice,
    adblueConsumption: data.adblueConsumption,
    driverSalary: data.driverSalary,
    driverSalaryDay: data.driverSalaryDay,
    driverSalaryPercentage: data.driverSalaryPercentage,
    driverWorkingDay: data.driverWorkingDay,
    insuranceFee: data.insuranceFee,
    tyresPrice: data.tyresPrice,
    tyresChangeRange: data.tyresChangeRange,
    servicePrice: data.servicePrice,
    serviceRange: data.serviceRange,
    leasingPrice: data.leasingPrice,
    carPrice: data.carPrice,
    carUsageAge: data.carUsageAge,
    carUsageFee: data.carUsageFee > 0 ? data.carUsageFee : carUsageFee,
    additionalFee: data.additionalFee,
    insuranceCargoPriceFee: data.insuranceCargoPriceFee,
    transportTaxes: data.transportTaxes,
    vatTaxes: data.vatTaxes,
    profitTaxes: data.profitTaxes,
    logisticianSalary: data.logisticianSalary,
    expenses: data.expenses,
    desiredProfit: data.desiredProfit,
  };

  useEffect(() => {
    if (carPrice && carUsageAge) {
      const usageFee = clearValue(carPrice)! / clearValue(carUsageAge)!;
      form.setFieldValue('carUsageFee', usageFee);
    }
  }, [carPrice, carUsageAge]);

  const onCloseModal = () => {
    handleCancel();
    form.resetFields();
    setLoading(false);
  };

  const onFinish = (values) => {
    setLoading(true);

    const mappedValues = Object.keys(values).map((key) => ({ name: snakeCase(key), value: clearValue(values[key]) }));

    API.put('/api/v1/admin/settings', mappedValues)
      .then((response) => {
        setReload(true);
        onCloseModal();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Редактирование параметров калькулятора" handleCancel={onCloseModal} />}
      open={open}
      onCancel={onCloseModal}
      className="modal modal__scrollable"
      footer={
        <Row justify="end">
          <Button size="large" onClick={onCloseModal} className="modal__cancel-btn">
            Отменить
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Сохранить
          </Button>
        </Row>
      }
      centered
      width={645}
      closable={false}
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      <Form
        id="form"
        form={form}
        initialValues={initialValues}
        colon={false}
        layout="vertical"
        className="form"
        onFinish={onFinish}
      >
        <Row gutter={[0, 40]} style={{ width: '100%' }}>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Расходы на топливо</Typography.Title>
            <Form.Item
              name="fuelPrice"
              label={
                <LabelWithTooltip
                  label="Стоимость топлива (за 1 л), ₽"
                  message="Значение стоимости дизельного топлива заполняется автоматически из Росстат"
                />
              }
              validateFirst
              rules={getRequiredFieldRule('Введите стоимость топлива')}
            >
              <Input size="large" disabled />
            </Form.Item>
            <Form.Item
              name="fuelConsumption"
              label="Средний расход топлива (на 100 км), л"
              validateFirst
              rules={getRequiredFieldRule('Введите cредний расход топлива')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={6}
                size="large"
                decimalSeparator=","
                allowedDecimalSeparators={['.']}
                decimalScale={2}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue === undefined || floatValue < 1000;
                }}
              />
            </Form.Item>
            <Form.Item
              name="adbluePrice"
              label="Стоимость мочевины (за 1 л), ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите стоимость мочевины')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={6}
                size="large"
                decimalSeparator=","
                allowedDecimalSeparators={['.']}
                decimalScale={2}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue === undefined || floatValue < 1000;
                }}
              />
            </Form.Item>
            <Form.Item
              name="adblueConsumption"
              label="Средний расход мочевины (на 100 км), л"
              validateFirst
              rules={getRequiredFieldRule('Введите средний расход мочевины')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={6}
                size="large"
                decimalSeparator=","
                allowedDecimalSeparators={['.']}
                decimalScale={2}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue === undefined || floatValue < 1000;
                }}
              />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Выплаты водителю</Typography.Title>
            <Form.Item
              name="driverSalary"
              label="Заработная плата водителя (за 1 км), ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите заработную плату водителя за 1 км')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={6}
                size="large"
                decimalSeparator=","
                allowedDecimalSeparators={['.']}
                decimalScale={2}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue === undefined || floatValue < 1000;
                }}
              />
            </Form.Item>

            <Space direction="vertical" size={16}>
              <Form.Item
                name="driverSalaryPercentage"
                label="Заработная плата водителя, % от оборота"
                validateFirst
                rules={getRequiredFieldRule('Введите заработную платау водителя в % от оборота')}
              >
                <NumericFormat customInput={Input} allowNegative={false} maxLength={2} decimalScale={0} size="large" />
              </Form.Item>
              <Alert
                type="info"
                showIcon
                message="Калькулятор может учитывать один или оба параметра расчета заработной платы водителя одновременно. Если Вы используете только один из параметров, оставьте в другом значение 0."
              />
            </Space>

            <Form.Item
              name="insuranceFee"
              label="Страховые взносы, %"
              validateFirst
              rules={getRequiredFieldRule('Введите страховые взносы в %')}
            >
              <NumericFormat customInput={Input} allowNegative={false} maxLength={2} decimalScale={0} size="large" />
            </Form.Item>
            <Form.Item
              name="driverSalaryDay"
              label="Стоимость суточных водителя, ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите стоимость суточных водителя')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={6}
                size="large"
                decimalScale={0}
                thousandSeparator=" "
              />
            </Form.Item>
            <Form.Item
              name="driverWorkingDay"
              label="Продолжительность рабочего дня водителя, ч"
              validateFirst
              rules={getRequiredPositiveFieldRules('Введите продолжительность рабочего дня водителя')}
            >
              <NumericFormat customInput={Input} allowNegative={false} maxLength={2} decimalScale={0} size="large" />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Затраты на автошины</Typography.Title>
            <Form.Item
              name="tyresPrice"
              label="Стоимость комплекта автошин, ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите стоимость комплекта автошин')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={9}
                decimalScale={0}
                thousandSeparator=" "
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="tyresChangeRange"
              label="Интервал замены автошин, км"
              validateFirst
              rules={getRequiredFieldRule('Введите интервал замены автошин')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={7}
                thousandSeparator=" "
                size="large"
              />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Затраты на ТО и ремонт</Typography.Title>
            <Form.Item
              name="servicePrice"
              label="Стоимость ТО, ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите стоимость ТО')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={7}
                decimalScale={0}
                thousandSeparator=" "
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="serviceRange"
              label="Межсервисный пробег, км"
              validateFirst
              rules={getRequiredFieldRule('Введите межсервисный пробег')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={7}
                decimalScale={0}
                thousandSeparator=" "
                size="large"
              />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Стоимость лизинга</Typography.Title>
            <Form.Item
              name="leasingPrice"
              label="Ежемесячный платеж, ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите ежемесячный платеж')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={10}
                decimalScale={0}
                thousandSeparator=" "
                size="large"
              />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Амортизация</Typography.Title>
            <Form.Item
              name="carPrice"
              label="Стоимость автомобиля, ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите стоимость автомобиля')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={11}
                decimalScale={0}
                thousandSeparator=" "
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="carUsageAge"
              label="Период полезного применения, лет"
              validateFirst
              rules={getRequiredPositiveFieldRules('Введите период полезного применения')}
            >
              <NumericFormat customInput={Input} allowNegative={false} maxLength={2} decimalScale={0} size="large" />
            </Form.Item>
            <Form.Item
              name="carUsageFee"
              label="Амортизационные отчисления (в год), ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите название организации')}
              dependencies={['carPrice', 'carUsageAge']}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={10}
                thousandSeparator=" "
                decimalSeparator=","
                allowedDecimalSeparators={['.']}
                decimalScale={2}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue === undefined || floatValue < 1000000;
                }}
                size="large"
                disabled
              />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Дополнительные расходы</Typography.Title>
            <Form.Item
              name="additionalFee"
              label="Дополнительные расходы, % от затрат"
              validateFirst
              rules={getRequiredFieldRule('Введите % дополнительных расходов')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={5}
                decimalScale={2}
                decimalSeparator=","
                allowedDecimalSeparators={['.']}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue === undefined || floatValue < 100;
                }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="insuranceCargoPriceFee"
              label="Страхование груза, % от стоимости груза"
              validateFirst
              rules={getRequiredFieldRule('Введите % страхования груза')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={5}
                decimalScale={2}
                decimalSeparator=","
                allowedDecimalSeparators={['.']}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue === undefined || floatValue < 100;
                }}
                size="large"
              />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Налоги</Typography.Title>
            <Form.Item
              name="transportTaxes"
              label="Транспортный налог (в год), ₽"
              validateFirst
              rules={getRequiredFieldRule('Введите транспортный налог')}
            >
              <NumericFormat
                customInput={Input}
                allowNegative={false}
                maxLength={7}
                decimalScale={0}
                thousandSeparator=" "
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="vatTaxes"
              label="Ставка НДС, %"
              validateFirst
              rules={getRequiredFieldRule('Введите ставку НДС')}
            >
              <NumericFormat customInput={Input} allowNegative={false} maxLength={2} decimalScale={0} size="large" />
            </Form.Item>
            <Form.Item
              name="profitTaxes"
              label="Ставка налога на прибыль, %"
              validateFirst
              rules={getRequiredFieldRule('Введите ставку налога на прибыль')}
            >
              <NumericFormat customInput={Input} allowNegative={false} maxLength={2} decimalScale={0} size="large" />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Заработная плата логиста и постоянные расходы</Typography.Title>
            <Form.Item
              name="logisticianSalary"
              label="Заработная плата логиста, % от оборота"
              validateFirst
              rules={getRequiredFieldRule('Введите заработную плату логиста в %')}
            >
              <NumericFormat customInput={Input} allowNegative={false} maxLength={2} decimalScale={0} size="large" />
            </Form.Item>
            <Form.Item
              name="expenses"
              label="Постоянные расходы, % от оборота"
              validateFirst
              rules={getRequiredFieldRule('Введите % постоянных расходов')}
            >
              <NumericFormat customInput={Input} allowNegative={false} maxLength={2} decimalScale={0} size="large" />
            </Form.Item>
          </Space>
          <Space size={24} direction="vertical" style={{ width: '100%' }}>
            <Typography.Title level={4}>Планируемая маржинальность</Typography.Title>
            <Form.Item
              name="desiredProfit"
              label="Планируемая маржинальность, % от оборота"
              validateFirst
              rules={getRequiredFieldRule('Введите планируемую маржинальность')}
            >
              <NumericFormat customInput={Input} allowNegative={false} maxLength={3} decimalScale={0} size="large" />
            </Form.Item>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditCalculatorSettingsModal;

import { API } from '../../../../Services/axios';
import { Claim } from '../../../../Models/Claims';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ModalProps } from '../../../../Models/Modal';
import { useAppSelector } from '../../../../Store/hooks';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import ClaimInfo from './Component/claim-info';
import ClaimInfoModalFooter from './Component/claim-info-modal-footer';
import Loader from '../../../../Components/Loader/Loader';
import ModalTitle from '../../../../Components/Modals/ModalTitle';

interface ClaimModalProps extends ModalProps {
  reload: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const ClaimModal = (props: ClaimModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, reload, setReload } = props;

  const claimId = useAppSelector((state) => state.claimId.claimId);

  const [data, setData] = useState<Claim>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (claimId) {
      API.get(`/api/v1/claim/${claimId}/operator`)
        .then((response) => setData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => {
          setLoading(false);
          setReload(false);
        });
    }
  }, [claimId, reload]);

  const title = data?.userFriendlyId ? `Обращение ${data.userFriendlyId}` : 'Обращение';

  return (
    <Modal
      title={<ModalTitle level={3} title={title} handleCancel={handleCancel} />}
      open={open}
      onCancel={handleCancel}
      className="modal modal__scrollable operator__claim"
      footer={
        data && (
          <ClaimInfoModalFooter
            claimId={data.id}
            setReload={setReload}
            handleCancel={handleCancel}
            operatorComments={data.operatorComments}
          />
        )
      }
      centered
      width={645}
      closable={false}
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      {loading || !data ? <Loader /> : <ClaimInfo data={data} />}
    </Modal>
  );
};

export default ClaimModal;

import { Button, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

export const enum CustomerTableTabs {
  ALL = 'ALL',
  NEW = 'NEW',
  WITH_OFFERS = 'WITH_OFFERS',
  WITH_AGREEMENTS = 'WITH_AGREEMENTS',
  AT_SIGNING = 'AT_SIGNING',
  WITH_FINAL_AGREEMENT = 'WITH_FINAL_AGREEMENT',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
}

interface EmptyMessageProps {
  tabName: CustomerTableTabs;
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { tabName } = props;
  const navigate = useNavigate();
  const createOrder = () => navigate('/customer/orders/create');

  const emptyText = () => {
    switch (tabName) {
      case CustomerTableTabs.ALL:
        return 'Для начала работы в сервисе необходимо разместить заказ для организаторов перевозки';
      case CustomerTableTabs.NEW:
        return 'Здесь будут отображаться Ваши заказы, на которые еще не откликнулись организаторы';
      case CustomerTableTabs.WITH_OFFERS:
        return 'Здесь будут отображаться Ваши заказы, на которые откликнулись организаторы';
      case CustomerTableTabs.WITH_AGREEMENTS:
        return 'Здесь будут отображаться Ваши заказы, по которым Организаторы прислали проекты договоров';
      case CustomerTableTabs.AT_SIGNING:
        return 'Здесь будут отображаться Ваши заказы на подписании';
      case CustomerTableTabs.WITH_FINAL_AGREEMENT:
        return 'Здесь будут отображаться Ваши заказы, по которым подписан финальный договор';
      case CustomerTableTabs.DRAFT:
        return 'Здесь будут отображаться черновики';
      case CustomerTableTabs.ARCHIVED:
        return 'Здесь будут отображаться отмененные заказы и заверешенные заказы';
    }
  };

  return tabName === CustomerTableTabs.ALL ? (
    <Space direction="vertical" size={24} className="table__orders-btn">
      <Typography.Text>{emptyText()}</Typography.Text>
      <Button size="large" type="primary" onClick={createOrder}>
        Создать заказ
      </Button>
    </Space>
  ) : (
    <Typography.Text>{emptyText()}</Typography.Text>
  );
};

export default EmptyMessage;

import 'dayjs/locale/ru';
import { API } from '../../../../Services/axios';
import {
  BackTop,
  Breadcrumb,
  Button,
  Card,
  Cascader,
  Col,
  Divider,
  Empty,
  Form,
  Grid,
  Input,
  InputNumber,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  notification,
} from 'antd';
import { CargoWarning } from './Component/cargo-warning';
import { CascaderOptionType, Category } from '../../../../Models/Order';
import { ExactAddress } from '../../../../Models/Address';
import { MAX_HEIGHT, MAX_LENGTH, MAX_WIDTH } from '../../../../Constants/OverallDimensions';
import { PatternFormat } from 'react-number-format';
import { PhoneOutlined, UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { defaultDateFormat } from '../../../../Constants/DateFormats';
import { disablePastDate } from '../../../../Utils/disablePastDates';
import { formatPrice } from '../../../../Utils/formatPrice';
import { getBase64 } from '../../../../Utils/getBase64';
import {
  getLinkFieldRules,
  getMaxLengthRule,
  getPhoneSizeRule,
  getRequiredFieldRule,
  getSizesRules,
  getTextFieldRules,
} from '../../../../Utils/Rules/order';
import { getParentId } from '../../../../Utils/getParentId';
import { reorganizeCategories } from '../../../../Utils/reorganizeCategories';
import { uploadFilesProps } from './Component/upload-files-props';
import { uploadPhotosProps } from './Component/upload-photos-props';
import { useAppSelector } from '../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useWatch } from 'antd/lib/form/Form';
import CancelOrderCreateModal from '../../../../Components/Modals/CancelOrderCreateModal';
import CreateDraftModal from '../../../../Components/Modals/CreateDraftModal';
import DatePicker from '../../../../Components/DatePicker/DatePicker';
import PreviewPhotoModal from '../../../../Components/Modals/PreviewPhotoModal';
import YMap from './Component/ymaps/ymaps';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import trailer_icon from '../../../../Assets/Images/trailer-grey.png';
import trailer_icon_green from '../../../../Assets/Images/trailer-green.png';
import truck_icon from '../../../../Assets/Images/truck-grey.png';
import truck_icon_green from '../../../../Assets/Images/truck-green.png';

dayjs().locale('ru');

const { useBreakpoint } = Grid;

const CreateOrder = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();
  const hrefDraft = window.location.pathname === `/customer/draft/${id}`;
  const hrefDublicate = window.location.pathname === `/customer/dublicate/${id}`;
  const phone = useAppSelector((state) => state.user.phone);
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const smallScreens = (screens.xs || screens.sm) && !screens.md && !screens.lg;

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [orderData, setOrderData] = useState({});
  const [machineCategory, setMachineCategory] = useState<Category[]>();
  const [machineCategoryId, setMachineCategoryId] = useState<string | number | undefined>();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isDraftModalOpened, setDraftIsModalOpened] = useState<boolean>(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [options, setOptions] = useState<CascaderOptionType[]>();

  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCatalog, setLoadingCatalog] = useState<boolean>(false);
  const [loadingCalulator, setLoadingCalculator] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ymaps, setYMaps] = useState<any>();
  const [mapCenter, setMapCenter] = useState([55.76, 37.64]);
  const [mapZoom, setMapZoom] = useState(9);

  const [addressFrom, setAddressFrom] = useState<ExactAddress>({ displayName: '', components: [], coordinates: [] });
  const [addressTo, setAddressTo] = useState<ExactAddress>({ displayName: '', components: [], coordinates: [] });

  const [countrySelect, setCountrySelect] = useState({ countryFromSelect: 'Россия', countryToSelect: 'Россия' });

  const [form] = Form.useForm();
  const length = useWatch<number>('length', form);
  const width = useWatch<number>('width', form);
  const height = useWatch<number>('height', form);
  const weight = useWatch<number>('weight', form);
  const dateArrival = useWatch<Date>('dateArrival', form);
  const dateLoading = useWatch<Date>('dateLoading', form);
  const [transport, setTransport] = useState('TRUCK');
  const files = useWatch('files', form);
  const photos = useWatch('photos', form);

  const [calculator, setCalculator] = useState({ price: 0, distance: 0 });
  const [calculationId, setCalculationId] = useState(0);

  useEffect(() => {
    setLoadingCatalog(true);
    setOrderData({ ...orderData, phone: phone.replace('+7', '').replace(/\(|\)|-|\s/g, '') });
    if (hrefDraft) {
      API.get(`/api/v1/order/${id}?draft=true`)
        .then((response) => {
          form.setFieldsValue({
            ...response.data,
            dateArrival: dayjs(response.data.dateArrival),
            dateLoading: dayjs(response.data.dateLoading),
            phone: response.data.phone.replace('+7', '').replace(/\(|\)|-|\s/g, ''),
          });
          setOrderData({
            ...response.data,
            dateArrival: dayjs(response.data.dateArrival),
            dateLoading: dayjs(response.data.dateLoading),
            phone: response.data.phone.replace('+7', '').replace(/\(|\)|-|\s/g, ''),
          });
          setCalculator({ price: response.data.price, distance: response.data.distance });
          setMachineCategoryId(response.data.machineCategoryId);
        })
        .catch((error) => showErrorModal({ error }));
    }
    if (hrefDublicate) {
      API.get(`/api/v1/order/${id}`)
        .then((response) => {
          form.setFieldsValue({
            ...response.data,
            dateArrival: dayjs(response.data.dateArrival),
            dateLoading: dayjs(response.data.dateLoading),
            phone: response.data.phone.replace('+7', '').replace(/\(|\)|-|\s/g, ''),
            id: '',
          });
          setOrderData({
            ...response.data,
            dateArrival: dayjs(response.data.dateArrival),
            dateLoading: dayjs(response.data.dateLoading),
            phone: response.data.phone.replace('+7', '').replace(/\(|\)|-|\s/g, ''),
            id: '',
          });
          setCalculator({ price: response.data.price, distance: response.data.distance });
          setMachineCategoryId(response.data.machineCategoryId);
          setAddressFrom({
            displayName: response.data.addressFrom,
            components: [],
            coordinates: response.data.pointFrom.split(','),
          });
          setAddressTo({
            displayName: response.data.addressTo,
            components: [],
            coordinates: response.data.pointTo.split(','),
          });
        })
        .catch((error) => showErrorModal({ error }));
    }
    API.get('/api/v1/dictionary/cargo-categories')
      .then((responce) => {
        setMachineCategory(responce.data);
        return responce.data;
      })
      .then((data) => reorganizeCategories(data))
      .then((options) => setOptions(options))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoadingCatalog(false));
  }, []);

  useEffect(() => {
    length > MAX_LENGTH || width > MAX_WIDTH || height > MAX_HEIGHT ? setWarning(true) : setWarning(false);
  }, [length, height, width]);

  useEffect(() => {
    if (typeof machineCategoryId !== 'undefined') {
      form.setFieldValue('machineCategoryId', getParentId(machineCategory, machineCategoryId));
    }
  }, [machineCategory, machineCategoryId]);

  useEffect(() => {
    if (warning || weight > 20000) {
      setTransport('TRAILER');
      form.setFieldValue('transport', 'TRAILER');
    }
  }, [warning, weight]);

  const handlePreview = async (file: UploadFile) => {
    file.preview = await getBase64(file.originFileObj as RcFile);
    setPreviewImage(file.preview as string);
    setPreviewOpen(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    return e?.fileList;
  };

  const handleCancel = () => setIsModalOpened(false);
  const handleDraftCancel = () => setDraftIsModalOpened(false);

  const handleDraftCreate = async () => {
    setLoading(true);

    const orderDataDraft = {
      cityFrom: addressFrom.components.filter((component) => component.kind === 'locality')?.pop()?.name,
      pointFrom: addressFrom.coordinates.toString(),
      addressTo: form.getFieldValue('addressTo'),
      addressFrom: form.getFieldValue('addressFrom'),
      cityTo: addressTo.components.filter((component) => component.kind === 'locality')?.pop()?.name,
      pointTo: addressTo.coordinates.toString(),
      dateArrival: dayjs(new Date(form.getFieldValue('dateArrival'))),
      dateLoading: dayjs(new Date(form.getFieldValue('dateLoading'))),
      phone: '+7 ' + form.getFieldValue('phone'),
      comment: form.getFieldValue('comment'),
      calculationId: calculationId,
    };

    const jsonData = Object.assign(orderData, orderDataDraft);

    const formData = new FormData();

    await files?.forEach((file) => {
      formData.append('files', file.originFileObj);
    });

    await photos?.forEach((photo) => {
      formData.append('photos', photo.originFileObj);
    });

    formData.append('json', JSON.stringify(jsonData));

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    API.post('/api/v1/order?draft=true', formData, headers)
      .then((response) => {
        notification.success({ message: 'Черновик успешно создан', placement: 'top' });
        setTimeout(() => {
          navigate(`/customer/draft/${response.data.id}`);
        }, 2000);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  const onFinish = async (values) => {
    setLoading(true);

    const orderData = {
      title: values.title || values.machineName,
      machineCategoryId: values.machineCategoryId['1'],
      machineName: values.machineName,
      machinePrice: values.machinePrice,
      specificationLink: values.specificationLink,
      weight: values.weight,
      width: values.width,
      length: values.length,
      height: values.height,
      addressFrom: values.addressFrom,
      cityFrom: addressFrom.components.filter((component) => component.kind === 'locality')?.pop()?.name,
      pointFrom: addressFrom.coordinates.toString(),
      addressTo: values.addressTo,
      cityTo: addressTo.components.filter((component) => component.kind === 'locality')?.pop()?.name,
      pointTo: addressTo.coordinates.toString(),
      dateArrival: dayjs(new Date(values.dateArrival)).toISOString(),
      dateLoading: dayjs(new Date(values.dateLoading)).toISOString(),
      phone: '+7 ' + values.phone,
      comment: values.comment,
      calculationId: calculationId,
    };

    const formData = new FormData();

    await values.files?.forEach((file) => {
      formData.append('files', file.originFileObj);
    });

    await values.photos?.forEach((photo) => {
      formData.append('photos', photo.originFileObj);
    });

    formData.append('json', JSON.stringify(orderData));

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    API.post('/api/v1/order', formData, headers)
      .then((response) => {
        notification.success({ message: 'Заказ успешно создан', placement: 'top' });
        setTimeout(() => {
          navigate(`/customer/orders/${response.data.id}`);
        }, 2000);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  const onCalculatePrice = () => {
    const inverseSolution = ymaps.coordSystem.geo.solveInverseProblem(addressFrom.coordinates, addressTo.coordinates);
    const halfWayDirectSolution = ymaps.coordSystem.geo.solveDirectProblem(
      addressFrom.coordinates,
      inverseSolution.startDirection,
      inverseSolution.distance / 2,
    );
    setMapCenter(halfWayDirectSolution.endPoint);
    const zoom = inverseSolution.distance / 100000;
    if (zoom < 0.01) {
      setMapZoom(14);
    } else if (zoom < 0.05) {
      setMapZoom(13);
    } else if (zoom < 0.1) {
      setMapZoom(12);
    } else if (zoom < 0.2) {
      setMapZoom(10);
    } else if (zoom < 0.4) {
      setMapZoom(9);
    } else if (zoom < 0.8) {
      setMapZoom(8);
    } else if (zoom < 1.6) {
      setMapZoom(7);
    } else if (zoom < 3.2) {
      setMapZoom(6);
    } else if (zoom < 6.4) {
      setMapZoom(5);
    } else if (zoom < 12.8) {
      setMapZoom(4);
    } else if (zoom < 25.6) {
      setMapZoom(3);
    } else if (zoom < 51.2) {
      setMapZoom(2);
    } else setMapZoom(1);

    setLoadingCalculator(true);

    const startRegion =
      addressFrom.components.filter((component) => component.kind === 'province').pop()?.name ?? 'null';
    const startCity = addressFrom.components.filter((component) => component.kind === 'locality').pop()?.name ?? 'null';
    const startPoint = addressFrom.coordinates.toString();

    const finishRegion =
      addressTo.components.filter((component) => component.kind === 'province').pop()?.name ?? 'null';
    const finishCity = addressTo.components.filter((component) => component.kind === 'locality').pop()?.name ?? 'null';
    const finishPoint = addressTo.coordinates.toString();

    API.get(
      `/api/v1/order/route/${startRegion}/${startCity}/${startPoint}/${finishRegion}/${finishCity}/${finishPoint}/?length=${length}&width=${width}&height=${height}&weight=${weight}&transport=${transport}`,
    )
      .then((response) => {
        setCalculator({ price: response.data.price, distance: response.data.distance });
        setCalculationId(response.data.id);
        setOrderData({
          ...orderData,
          price: response.data.price,
          distance: response.data.distance,
          calculationId: response.data.id,
        });
        setSubmitButtonDisabled(false);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoadingCalculator(false);
      });
  };

  return (
    <div className="order">
      <Row justify="center" className="order__container" gutter={[0, 24]}>
        <Col xxl={12} xl={14} lg={18} span={22}>
          <BackTop />
          <Space direction="vertical" size={24}>
            <Breadcrumb className="order__breadcrumb" separator=">">
              <Breadcrumb.Item href="/">Главная страница</Breadcrumb.Item>
              <Breadcrumb.Item href="/customer/orders">Заказы</Breadcrumb.Item>
              <Breadcrumb.Item>{hrefDraft ? 'Черновик' : 'Новый заказ'}</Breadcrumb.Item>
            </Breadcrumb>
            <Typography.Title level={2}>{hrefDraft ? 'Черновик' : 'Новый заказ'}</Typography.Title>
          </Space>
        </Col>

        <Col xxl={12} xl={14} lg={18} span={22}>
          <Form layout="vertical" form={form} size="large" className="order__form" onFinish={onFinish}>
            <Card className="order__wrapper">
              <Typography.Title level={3}>Информация о грузе</Typography.Title>

              <Form.Item label="Название заказа" name="title" rules={getMaxLengthRule(100)}>
                <Input
                  placeholder="Например, перевозка телескопического погрузчика Bobcat"
                  size="large"
                  onChange={(e) => {
                    setOrderData({
                      ...orderData,
                      title: e.target.value,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Тип техники"
                name="machineCategoryId"
                rules={getRequiredFieldRule('Выберите тип техники')}
              >
                <Cascader
                  loading={loadingCatalog}
                  notFoundContent={<Empty description={false} />}
                  showSearch
                  size="large"
                  options={options}
                  dropdownMatchSelectWidth
                  getPopupContainer={(trigger) => trigger.parentElement!}
                  onChange={(e) => {
                    setOrderData({
                      ...orderData,
                      machineCategoryId: e[1],
                    });
                  }}
                />
              </Form.Item>

              <Row gutter={[24, 0]} justify="space-between">
                <Col xxl={12} span={24}>
                  <Form.Item
                    label="Модель техники"
                    name="machineName"
                    rules={getTextFieldRules('Введите модель техники', 100)}
                  >
                    <Input
                      placeholder="Например, Bobcat TL30.60"
                      size="large"
                      onChange={(e) => {
                        setOrderData({
                          ...orderData,
                          machineName: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xxl={12} span={24}>
                  <Form.Item
                    label="Стоимость перевозимой техники, ₽"
                    name="machinePrice"
                    rules={getRequiredFieldRule('Введите стоимость техники')}
                  >
                    <InputNumber
                      size="large"
                      defaultValue={''}
                      maxLength={11}
                      controls={false}
                      formatter={(value) => formatPrice(+value!)}
                      onChange={(value) => {
                        setOrderData({
                          ...orderData,
                          machinePrice: value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Ссылка на спецификацию" name="specificationLink" rules={getLinkFieldRules()}>
                <Input
                  size="large"
                  onChange={(e) => {
                    setOrderData({
                      ...orderData,
                      specificationLink: e.target.value,
                    });
                  }}
                />
              </Form.Item>

              <Typography.Title level={4} className="order__subtitle">
                Транспортировочные габариты
              </Typography.Title>
              <div className="order__dimensions">
                <Space className="order__space-full-width" size={24} align="start">
                  <Form.Item label="Вес, кг" name="weight" rules={getRequiredFieldRule('Введите вес')}>
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      onChange={(value) => {
                        setCalculator({ price: 0, distance: 0 });
                        setSubmitButtonDisabled(true);
                        setOrderData({
                          ...orderData,
                          weight: value,
                        });
                      }}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Длина, м"
                    className="input__accent"
                    name="length"
                    rules={getSizesRules('Введите длину', MAX_LENGTH)}
                    status={warning ? 'warning' : 'success'}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      onChange={(value) => {
                        setCalculator({ price: 0, distance: 0 });
                        setSubmitButtonDisabled(true);
                        setOrderData({
                          ...orderData,
                          length: value,
                        });
                      }}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Ширина, м"
                    name="width"
                    className="input__accent"
                    rules={getSizesRules('Введите ширину', MAX_WIDTH)}
                    status={warning ? 'warning' : 'success'}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      onChange={(value) => {
                        setCalculator({ price: 0, distance: 0 });
                        setSubmitButtonDisabled(true);
                        setOrderData({
                          ...orderData,
                          width: value,
                        });
                      }}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Высота, м"
                    name="height"
                    rules={getSizesRules('Введите высоту', MAX_HEIGHT)}
                    status={warning ? 'warning' : 'success'}
                  >
                    <InputNumber
                      controls={false}
                      size="large"
                      decimalSeparator=","
                      onChange={(value) => {
                        setCalculator({ price: 0, distance: 0 });
                        setSubmitButtonDisabled(true);
                        setOrderData({
                          ...orderData,
                          height: value,
                        });
                      }}
                      max={9999999.99}
                      maxLength={10}
                    />
                  </Form.Item>
                </Space>
              </div>

              {warning && <CargoWarning />}
            </Card>

            <Card className="order__wrapper">
              <Typography.Title level={3}>Тип техники для транспортировки</Typography.Title>
              <Typography.Text>
                Выбор машины, на которой будет осуществляться перевозка груза, важен для предварительного расчета
                стоимости доставки. Финально тип техники определит организатор перевозки с учетом габаритов и
                особенностей погрузочных работ.<br></br>
              </Typography.Text>
              <Form.Item label="" className="input__accent" name="transport" initialValue={'TRUCK'}>
                <Radio.Group
                  disabled={warning || weight > 20000}
                  onChange={(e) => {
                    setCalculator({ price: 0, distance: 0 });
                    setSubmitButtonDisabled(true);
                  }}
                  className="order__transport"
                >
                  <Row style={{ paddingTop: 32 }} gutter={[18, 8]}>
                    <Col>
                      <Row>
                        <Col span={24} className="order__transport-wrapper">
                          <img
                            src={truck_icon}
                            className={
                              transport === 'TRUCK'
                                ? 'order__transport-truck order__transport-hidden'
                                : 'order__transport-truck'
                            }
                          />
                          <img
                            src={truck_icon_green}
                            className={
                              transport === 'TRUCK'
                                ? 'order__transport-truck'
                                : 'order__transport-truck order__transport-hidden'
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Radio
                          value={'TRUCK'}
                          onClick={() => {
                            setTransport('TRUCK');
                            setOrderData({
                              ...orderData,
                              transport: 'TRUCK',
                            });
                          }}
                        >
                          Фура
                        </Radio>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="end">
                        <Col span={24} className="order__transport-wrapper">
                          <img
                            src={trailer_icon}
                            className={
                              transport === 'TRAILER'
                                ? 'order__transport-trailer order__transport-hidden'
                                : 'order__transport-trailer'
                            }
                          />
                          <img
                            src={trailer_icon_green}
                            className={
                              transport === 'TRAILER'
                                ? 'order__transport-trailer'
                                : 'order__transport-trailer order__transport-hidden'
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Radio
                            value={'TRAILER'}
                            onClick={() => {
                              setTransport('TRAILER');
                              setOrderData({
                                ...orderData,
                                transport: 'TRAILER',
                              });
                            }}
                          >
                            Трал
                          </Radio>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Card>

            <Card className="order__wrapper">
              <Typography.Title level={3} className="order__subtitle">
                Детали транспортировки и предварительный расчет
              </Typography.Title>
              <Form.Item
                label="Телефон для уточнения деталей заказа"
                name="phone"
                validateFirst
                normalize={(value) => value.replace('+7', '').replace(/\D/g, '').slice(0, 10)}
                rules={getPhoneSizeRule('Введите номер телефона')}
                initialValue={phone.replace('+7', '').replace(/\(|\)|-|\s/g, '')}
              >
                <PatternFormat
                  className="input__phone"
                  suffix={<PhoneOutlined />}
                  valueIsNumericString
                  format="(###) ###-##-##"
                  customInput={Input}
                  prefix="+7"
                  size="large"
                  mask="_"
                  onChange={(e) =>
                    setOrderData({
                      ...orderData,
                      phone: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Space
                className="order__space-full-width order__two-column"
                size={smallScreens ? 0 : 24}
                wrap={smallScreens}
              >
                <Form.Item
                  name="dateArrival"
                  label="Желаемая дата получения груза"
                  rules={getRequiredFieldRule('Выберите дату')}
                >
                  <DatePicker
                    format={defaultDateFormat}
                    size="large"
                    placeholder=""
                    disabledDate={(current) => {
                      return current < dayjs(dateLoading, 'YYYY-MM-DD') || disablePastDate(current);
                    }}
                    getPopupContainer={(trigger) => trigger.parentElement!}
                    locale={locale}
                    onChange={(date) => {
                      setOrderData({
                        ...orderData,
                        dateArrival: date?.date,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="dateLoading"
                  label="Дата погрузки техники"
                  rules={getRequiredFieldRule('Выберите дату')}
                >
                  <DatePicker
                    format={defaultDateFormat}
                    size="large"
                    placeholder=""
                    disabledDate={(current) => {
                      if (dateArrival) {
                        return (
                          (!(dayjs(dateArrival, 'YYYY-MM-DD').diff(current, 'day') === 0) &&
                            dayjs(dateArrival, 'YYYY-MM-DD').diff(current, 'day') < 0) ||
                          disablePastDate(current)
                        );
                      } else {
                        return disablePastDate(current);
                      }
                    }}
                    getPopupContainer={(trigger) => trigger.parentElement!}
                    locale={locale}
                    onChange={(date) => {
                      setOrderData({
                        ...orderData,
                        dateLoading: date?.date,
                      });
                    }}
                  />
                </Form.Item>
              </Space>

              <div className="order__map">
                <YMap
                  ymaps={ymaps}
                  form={form}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  addressFrom={addressFrom}
                  addressTo={addressTo}
                  countrySelect={countrySelect}
                  setYMaps={setYMaps}
                  setMapCenter={setMapCenter}
                  setMapZoom={setMapZoom}
                  setAddressFrom={setAddressFrom}
                  setAddressTo={setAddressTo}
                  setSubmitButtonDisabled={setSubmitButtonDisabled}
                  setCalculator={setCalculator}
                  setCountrySelect={setCountrySelect}
                  profileOrg={false}
                />
              </div>

              <div className="order__cost">
                <Row justify="center" gutter={[0, 32]}>
                  <Col span={24} className="order__actions">
                    <Button
                      type="primary"
                      disabled={
                        addressFrom.coordinates.length === 0 ||
                        addressTo.coordinates.length === 0 ||
                        !length ||
                        !width ||
                        !height ||
                        !weight ||
                        !dateLoading ||
                        !dateArrival
                      }
                      loading={loadingCalulator}
                      onClick={() => onCalculatePrice()}
                    >
                      Рассчитать стоимость
                    </Button>
                  </Col>
                  <Col
                    span={24}
                    className={`order__total ${
                      addressFrom.coordinates.length === 0 ||
                      addressTo.coordinates.length === 0 ||
                      !length ||
                      !width ||
                      !height ||
                      !weight
                        ? 'order__total-disabled'
                        : ''
                    }`}
                  >
                    <Row justify="space-between" className="order__total-distance">
                      <Col>
                        <Typography.Text strong>Расстояние по маршруту</Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text strong>{calculator.distance} км</Typography.Text>
                      </Col>
                    </Row>
                    <Row justify="space-between" style={{ width: '100%' }}>
                      <Col>
                        <Typography.Text strong>Предварительная стоимость доставки</Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text strong>{formatPrice(calculator.price) || 0} ₽</Typography.Text>
                      </Col>
                      <Col span={24}>
                        <Divider />
                        <div className="order__actions-description">
                          Расчет стоимости предварительный с учетом НДС, точная сумма будет указана в предложениях
                          организаторов перевозок
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>

            <Card className="order__wrapper">
              <Typography.Title level={3}>Дополнительные сведения</Typography.Title>

              <Form.Item
                label="Дополнительные файлы"
                name="files"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                className="order__attachments"
                extra="Для загрузки выберите файл с расширением .pdf, .docx, .xlsx, .txt, .rtf"
              >
                <Upload {...uploadFilesProps}>
                  <Button icon={<UploadOutlined />} size="large" disabled={files && files.length === 5}>
                    Загрузить
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Фотографии техники"
                name="photos"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                className="order__upload-photo"
                extra="Для загрузки выберите файл с расширением .png, .jpg, .jpeg, .heic"
              >
                <Upload {...uploadPhotosProps} onPreview={handlePreview}>
                  <Button
                    type="default"
                    disabled={photos && photos.length === 5}
                    style={{ height: '49px', width: '100%' }}
                    icon={<UploadOutlined />}
                  >
                    Загрузить
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item
                name="comment"
                label="Комментарий"
                rules={getMaxLengthRule(500)}
                className="order__textarea-container"
                extra="Количество машин для транспортировки, способ загрузки, требуемая грузоподъемность, тип кузова и т.д."
              >
                <Input.TextArea
                  className="order__textarea"
                  rows={4}
                  onChange={(e) =>
                    setOrderData({
                      ...orderData,
                      comment: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Card>

            <div className="order__bottom-btns">
              <Form.Item>
                <div className="order__btns-wrapper-left">
                  <Button size="large" className="order__btns-cancel" onClick={() => setDraftIsModalOpened(true)}>
                    Отменить
                  </Button>
                </div>
                <div className="order__btns-wrapper-middle">
                  <Button type="primary" size="large" onClick={() => handleDraftCreate()} loading={loading}>
                    Сохранить черновик
                  </Button>
                </div>
                <div className="order__btns-wrapper-rigth">
                  {submitButtonDisabled ? (
                    <Tooltip title="Для публикации заказа необходимо заполнить обязательные поля и рассчитать стоимость">
                      <Button type="primary" disabled size="large" htmlType="submit" loading={loading}>
                        Опубликовать
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button type="primary" size="large" htmlType="submit" loading={loading}>
                      Опубликовать
                    </Button>
                  )}
                </div>
              </Form.Item>
            </div>
          </Form>
        </Col>
        <PreviewPhotoModal previewImage={previewImage} open={previewOpen} handleCancel={() => setPreviewOpen(false)} />
        <CancelOrderCreateModal open={isModalOpened} handleCancel={() => handleCancel()} />
        <CreateDraftModal
          open={isDraftModalOpened}
          handleCancel={() => handleDraftCancel()}
          handleDraftCreate={() => handleDraftCreate()}
        />
      </Row>
    </div>
  );
};

export default CreateOrder;

import { API } from '../../../../../../../Services/axios';
import { Button, Modal, Row } from 'antd';
import { ModalProps } from '../../../../../../../Models/Modal';
import { useErrorModal } from '../../../../../../../Components/Modals/ErrorModal';
import { useState } from 'react';
import ModalTitle from '../../../../../../../Components/Modals/ModalTitle';

interface DeleteSavedFilterModalProps extends ModalProps {
  id: number;
  name: string;
}

const DeleteSavedFilterModal = (props: DeleteSavedFilterModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, id, name, handleCancel } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = () => {
    setLoading(true);

    API.delete(`/api/v1/filter/delete/${id}`)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={
        <ModalTitle
          level={3}
          title={`Вы действительно хотите удалить вкладку "${name}"?`}
          handleCancel={handleCancel}
        />
      }
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Row>
        <Button size="large" className="modal__cancel-btn" onClick={handleCancel}>
          Отмена
        </Button>
        <Button type="primary" size="large" form="form" htmlType="submit" onClick={onFinish} loading={loading}>
          Удалить
        </Button>
      </Row>
    </Modal>
  );
};

export default DeleteSavedFilterModal;

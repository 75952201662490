import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { FilterOutlined } from '@ant-design/icons';
import { InputRef, Typography } from 'antd';
import { Profile } from '../../../../../../Models/Profile';
import { Roles } from '../../../../../../Constants/Profile';
import { StringFilter, filterString } from '../../../../../../Components/Filters/StringFilter';
import { UserPropertyType, getUserPropertyType } from '../../../../../../Utils/getUserPropertyType';
import { defaultDateFormat } from '../../../../../../Constants/DateFormats';
import { isUndefined } from 'lodash';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';

export const getAdminUsersTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
) => {
  type DataIndex = keyof UserPropertyType;
  const columns: ColumnsType<Profile> = [
    {
      title: 'ФИО пользователя',
      dataIndex: 'surname',
      width: 230,
      ellipsis: true,
      sorter: (a, b) => `${a.surname} ${a.name}`.localeCompare(`${b.surname} ${b.name}`),
      key: 'surname',
      render: (_, record) => (
        <Typography.Link href={`/admin/users/${record.id}`} underline>
          {record.patronymic
            ? `${record.surname} ${record.name} ${record.patronymic}`
            : `${record.surname} ${record.name}`}
        </Typography.Link>
      ),
    },
    {
      title: 'Тип пользователя',
      dataIndex: 'type',
      width: 212,
      ellipsis: true,
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (_, record) => (record.type === Roles.CUSTOMER ? 'Заказчик' : 'Организатор'),
      key: 'type',
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      width: 230,
      ellipsis: true,
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      key: 'createdAt',
      render: (text) => dayjs(text).format(defaultDateFormat),
    },
    {
      title: 'Статус',
      dataIndex: 'enabled',
      width: 195,
      ellipsis: true,
      sorter: (a, b) => {
        if (isUndefined(b.enabled)) {
          return +1;
        }
        if (isUndefined(a.enabled)) {
          return -1;
        }
        return Number(b.enabled) - Number(a.enabled);
      },
      key: 'enabled',
      render: (_, record) => (record.enabled || isUndefined(record.enabled) ? 'Активен' : 'Заблокирован'),
    },
    {
      title: 'Электронная почта',
      dataIndex: 'email',
      width: 207,
      ellipsis: true,
      key: 'email',
    },
    {
      title: 'Организация',
      dataIndex: 'organisationName',
      width: 'auto',
      ellipsis: true,
      key: 'organisationName',
      render: (_, record) => <Typography.Text>{record.organisation.shortName}</Typography.Text>,
    },
  ];

  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys.toString());
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
  };

  const searchInput = useRef<InputRef>(null);

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Profile> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (_, record) => {
      let result = true;
      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] !== null) {
          if (key === 'surname') {
            const name = record.patronymic
              ? `${record.surname} ${record.name} ${record.patronymic}`
              : `${record.surname} ${record.name}`;
            result &&= filterString(name, filteredInfo[key]![0]);
          } else if (key === 'type') {
            const typeValue = record.type === Roles.CUSTOMER ? 'Заказчик' : 'Организатор';
            result &&= filterString(typeValue, filteredInfo[key]![0]);
          } else if (key === 'locked') {
            const lockedValue = record.organisation.locked ? 'Заблокирован' : 'Активен';
            result &&= filterString(lockedValue, filteredInfo[key]![0]);
          } else if (key === 'organisationName')
            result &&= filterString(record.organisation.shortName, filteredInfo[key]![0]);
          else if (`${getUserPropertyType(key)}` === 'string')
            result &&= filterString(record[key], filteredInfo[key]![0]);
        }
      });
      return result;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<Profile> = columns.map((col, index) => {
    const dataIndex = col.key! as keyof Omit<Profile, 'avatar' | 'organisation'>;
    if (dataIndex === 'createdAt' || dataIndex === 'enabled') {
      return {
        ...col,
      };
    }
    return {
      ...col,
      ...getColumnSearchProps(dataIndex),
      render: col.render,
    };
  });

  return filterableColumns;
};

import { Typography } from 'antd';

export const enum OrganizerTableTabs {
  NEW = 'NEW',
  WITH_OFFER = 'WITH_OFFER',
  WAITING_FOR_AGREEMENT = 'WAITING_FOR_AGREEMENT',
  WITH_AGREEMENT = 'WITH_AGREEMENT',
  AT_SIGNING = 'AT_SIGNING',
  WITH_FINAL_AGREEMENT = 'WITH_FINAL_AGREEMENT',
  ARCHIVED = 'ARCHIVED',
}

interface EmptyMessageProps {
  tabName: OrganizerTableTabs;
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { tabName } = props;

  const emptyText = () => {
    switch (tabName) {
      case OrganizerTableTabs.NEW:
        return 'Здесь будут отображаться заказы из каталога, которые Вы возьмете в работу';
      case OrganizerTableTabs.WITH_OFFER:
        return 'Здесь будут отображаться заказы, по которым Вы выслали предложение';
      case OrganizerTableTabs.WAITING_FOR_AGREEMENT:
        return 'Здесь будут отображаться заказы, по которым заказчик запросил проект договора';
      case OrganizerTableTabs.WITH_AGREEMENT:
        return 'Здесь будут отображаться заказы, по которым Вы выслали проект договора';
      case OrganizerTableTabs.AT_SIGNING:
        return 'Здесь будут отображаться заказы на подписании';
      case OrganizerTableTabs.WITH_FINAL_AGREEMENT:
        return 'Здесь будут отображаться заказы, для которых подписан договор';
      case OrganizerTableTabs.ARCHIVED:
        return 'Здесь будут отображаться отмененные заказы и заверешенные заказы';
    }
  };

  return <Typography.Text>{emptyText()}</Typography.Text>;
};

export default EmptyMessage;

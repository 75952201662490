import { Card, Col, Row, Space, Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { Profile } from '../../Models/Profile';
import { Roles } from '../../Constants/Profile';
import { getMaskedPhoneNumberInOrder } from '../../Utils/getPhoneMasked';
import { useAppSelector } from '../../Store/hooks';
import ChangePassword from './ChangePassword';
import EditUserProfileModal from '../Modals/EditUserProfileModal';
import ProfileItem from './ProfileItem';

interface PersonalInfoCardProps {
  profileData: Profile;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const SpecialUserProfile = (props: PersonalInfoCardProps) => {
  const { profileData, setReload } = props;
  const role = useAppSelector((state) => state.user.role);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const hideModal = () => setOpenModal(false);
  const showModal = () => setOpenModal(true);

  const isAdmin = role === Roles.ADMIN;

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title level={3}>Личные данные</Typography.Title>
      </Col>
      {!isAdmin && (
        <Col>
          <Typography.Link onClick={showModal} underline>
            Редактировать
          </Typography.Link>
        </Col>
      )}
    </Row>
  );

  return (
    <Row gutter={[24, 40]} justify="space-between">
      <Col lg={16} span={24}>
        <Card title={cardTitle} style={{ width: '100%' }}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <ProfileItem title="Фамилия" value={profileData.surname} />
                <ProfileItem title="Имя" value={profileData.name} />
                <ProfileItem title="Отчество" value={profileData.patronymic ?? '—'} />
                <ProfileItem title="Телефон" value={getMaskedPhoneNumberInOrder(profileData.phone)} />
                <ProfileItem title="Электронная почта" value={profileData.email} />
              </Space>
            </Col>
          </Row>
          <EditUserProfileModal
            open={openModal}
            handleCancel={hideModal}
            profileData={profileData}
            setReload={setReload}
          />
        </Card>
      </Col>
      <Col lg={8} span={24}>
        <ChangePassword />
      </Col>
    </Row>
  );
};

export default SpecialUserProfile;

import { Button, Form, Modal, Row } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { ReportFormType } from '../../Models/Form';
import { useForm } from 'antd/lib/form/Form';
import ModalTitle from './ModalTitle';
import TextArea from 'antd/lib/input/TextArea';

interface ReportStatusModalProps extends ModalProps {
  loading: boolean;
  submitForm: (values: ReportFormType) => void;
}

const ReportStatusModal = (props: ReportStatusModalProps) => {
  const { open, handleCancel, loading, submitForm } = props;
  const [form] = useForm<ReportFormType>();

  return (
    <Modal
      title={<ModalTitle level={3} title="Отчет об исполнении" handleCancel={handleCancel} />}
      open={open}
      onCancel={handleCancel}
      className="modal"
      footer={null}
      centered
      width={645}
      closable={false}
    >
      <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={submitForm}>
        <Form.Item label="Расскажите о текущем статусе" name="statusText">
          <TextArea size="large" maxLength={100} showCount className="form__comment" />
        </Form.Item>
      </Form>

      <Row justify="end">
        <Button size="large" onClick={handleCancel} className="modal__cancel-btn">
          Отменить
        </Button>
        <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
          Отправить
        </Button>
      </Row>
    </Modal>
  );
};

export default ReportStatusModal;

import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Row, Space } from 'antd';
import { ModalProps } from '../../Models/Modal';

interface PreviewPhotoModalProps extends ModalProps {
  previewImage: string;
}

const PreviewPhotoModal = (props: PreviewPhotoModalProps) => {
  const { open, handleCancel, previewImage } = props;
  return (
    <Modal open={open} title="" footer={null} onCancel={handleCancel} closable={false} maskClosable={true}>
      <Space direction="vertical" size={24}>
        <Row justify="end" align="middle" wrap={false}>
          <CloseCircleOutlined className="modal__close-icon" onClick={handleCancel} />
        </Row>
        <img alt="" style={{ width: '100%' }} src={previewImage} />
      </Space>
    </Modal>
  );
};

export default PreviewPhotoModal;

import { Button, InputRef, Tooltip } from 'antd';
import { DateFilter, filterDate } from '../../../../../../Components/Filters/DateFilter';
import { FilterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { NumberFilter, filterNumber } from '../../../../../../Components/Filters/NumberFilter';
import { Order } from '../../../../../../Models/Order';
import { ResizeCallbackData } from 'react-resizable';
import { StringFilter, filterString } from '../../../../../../Components/Filters/StringFilter';
import { initialColumns as allColumns } from '../../../../../../Store/InitialStates/CustomerOrdersInitialState';
import { getOrderPropertyType } from '../../../../../../Utils/getOrderPropertyType';
import { getUpdatedCustomerColumns } from '../../../../../../Store/Slices/customerOrdersColsSlice';
import { updateColsWidth } from '../../../../../../Utils/updateColsWidth';
import { useAppDispatch, useAppSelector } from '../../../../../../Store/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';

export const getCustomerOrdersTableColumns = (
  columnsSettings: unknown,
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
) => {
  type DataIndex = keyof Order;

  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState(allColumns);
  const initialColsState = useAppSelector((state) => state.customerOrdersCols);
  const [initialColumns, setInit] = useState(initialColsState);

  useMemo(() => {
    const updatedAllCols = allColumns
      .filter((allColumnsItem) => !initialColsState.find((item) => allColumnsItem['key'] === item['key']))
      .concat(initialColsState);
    setColumns(updatedAllCols);
  }, [initialColsState]);

  useEffect(() => {
    if (columnsSettings) {
      setInit(
        columns
          .filter((column) => Object.keys(columnsSettings).includes(column.key as unknown as string))
          .sort((a, b) => columnsSettings[a.key as unknown as string] - columnsSettings[b.key as unknown as string]),
      );
    }
  }, [initialColsState, columnsSettings]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
  };

  const searchInput = useRef<InputRef>(null);

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Order> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const [showSegment, setShowSegment] = useState(false);
      const [selectedFilter, setSelectedFilter] = useState('eq');
      const handleFilterChange = (operator: string) => {
        setSelectedKeys([]);
        setShowSegment(operator === 'seg');
        setSelectedFilter(operator);
      };
      return `${getOrderPropertyType(dataIndex)}` === 'string' || dataIndex === 'userFriendlyId' ? (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      ) : `${getOrderPropertyType(dataIndex)}` === 'number' ? (
        <NumberFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleFilterChange={handleFilterChange}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          showSegment={showSegment}
          selectedFilter={selectedFilter}
        />
      ) : `${getOrderPropertyType(dataIndex)}` === 'object' ? (
        <DateFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleFilterChange={handleFilterChange}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          showSegment={showSegment}
          selectedFilter={selectedFilter}
        />
      ) : (
        <></>
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (_, record) => {
      let result = true;
      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] !== null) {
          if (`${getOrderPropertyType(key)}` === 'string' || key === 'userFriendlyId') {
            result &&= filterString(record[key], filteredInfo[key]![0]);
          } else if (`${getOrderPropertyType(key)}` === 'number') {
            result &&= filterNumber(record[key], filteredInfo[key]!);
          } else if (`${getOrderPropertyType(key)}` === 'object') {
            result &&= filterDate(record[key], filteredInfo[key]!);
          }
        }
      });
      return result;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...initialColumns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      updateColsWidth('customerOrders', newColumns, columns);
      dispatch(getUpdatedCustomerColumns(newColumns));
    };

  const resizableColumns: ColumnsType<Order> = initialColumns.map((col, index) => {
    const dataIndex = col.key! as keyof Order;
    if (col.key! === 'userFriendlyId') {
      return {
        ...col,
        ...getColumnSearchProps('userFriendlyId'),
        render: (_, record) => (
          <Tooltip title={`Заказ номер ${record.userFriendlyId}`} zIndex={5}>
            {record.status === 'DRAFT' ? (
              <Link to={`/customer/draft/${record.id}`} target="_blank">
                <Button type="default">{record.userFriendlyId}</Button>
              </Link>
            ) : (
              <Link to={`/customer/orders/${record.id}`} target="_blank">
                <Button type="default">{record.userFriendlyId}</Button>
              </Link>
            )}
          </Tooltip>
        ),
      };
    }
    return {
      ...col,
      onHeaderCell: (column) => ({
        width: (column as ColumnType<Order>)!.width,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onResize: handleResize(index) as React.ReactEventHandler<any>,
      }),
      ...getColumnSearchProps(dataIndex),
      render: col.render,
    };
  });

  return resizableColumns;
};

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExactAddress } from '../../../../../../../../Models/Address';
import { FormInstance } from 'rc-field-form';
import { YMaps } from '@pbe/react-yandex-maps';
import React from 'react';
import YSuggest from './yaddress-suggest';

const YAdresses: React.FC<{
  form: FormInstance;
  addressFrom: ExactAddress;
  addressTo: ExactAddress;
  setAddressFrom: React.Dispatch<React.SetStateAction<ExactAddress>>;
  setAddressTo: React.Dispatch<React.SetStateAction<ExactAddress>>;
}> = ({ form, addressFrom, addressTo, setAddressFrom, setAddressTo }) => {
  return (
    <YMaps
      query={{
        apikey: process.env.REACT_APP_YMAPS_API_KEY,
        suggest_apikey: process.env.REACT_APP_YMAPS_SUGGEST_API_KEY,
        lang: 'ru_RU',
      }}
    >
      <YSuggest
        form={form}
        label={'Регион отправки'}
        name={'regionFrom'}
        id={'suggestFrom'}
        addressFrom={addressFrom}
        addressTo={addressTo}
        setAddressFrom={setAddressFrom}
        setAddressTo={setAddressTo}
      />
      <YSuggest
        form={form}
        label={'Регион получения'}
        name={'regionTo'}
        id={'suggestTo'}
        addressFrom={addressFrom}
        addressTo={addressTo}
        setAddressFrom={setAddressFrom}
        setAddressTo={setAddressTo}
      />
    </YMaps>
  );
};

export default YAdresses;

import { API } from '../../Services/axios';
import { Button, Col, Divider, Form, Input, InputRef, Modal, Row, Select, Space } from 'antd';
import { CargoCategory, SelectOption } from '../../Models/CargoCategories';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ModalProps } from '../../Models/Modal';
import { getRequiredFieldRule } from '../../Utils/Rules/order';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import ModalTitle from './ModalTitle';

interface CreateCategoryModalProps extends ModalProps {
  parentCategories: CargoCategory[];
  setReload: Dispatch<SetStateAction<boolean>>;
}

const CreateCategoryModal = (props: CreateCategoryModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, parentCategories, setReload } = props;
  const [form] = useForm();
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);
  const [newCategoryId, setNewCategoryId] = useState<number>(-1);

  const [selectItems, setSelectItems] = useState<SelectOption[]>([]);
  const initialSelectOptions = parentCategories.map((category) => ({ label: category.title, value: category.id }));

  useEffect(() => {
    setSelectItems(initialSelectOptions);
  }, [parentCategories]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addNewCategory = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const title = { title: name };

    API.post('/api/v1/dictionary/cargo-categories', title)
      .then((response) => {
        setNewCategoryId(response.data.id);
        setReload(true);
        setName('');
        setTimeout(() => {
          inputRef.current?.blur();
        }, 0);
      })
      .catch((error) => showErrorModal({ error }));
  };

  useEffect(() => {
    if (open) {
      const newCategory = initialSelectOptions.find((item) => item.value === newCategoryId);
      form.setFieldValue('parent', newCategory?.value);
    }
  }, [parentCategories, newCategoryId]);

  const [loading, setLoading] = useState<boolean>(false);

  const onCloseModal = () => {
    setLoading(false);
    handleCancel();
    form.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      parentId: values.parent,
      title: values.title,
    };

    API.post('/api/v1/dictionary/cargo-categories', formData)
      .then((response) => {
        setLoading(false);
        setReload(true);
        onCloseModal();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Добавление типа техники" handleCancel={onCloseModal} />}
      open={open}
      onCancel={onCloseModal}
      className="modal modal__scrollable"
      footer={
        <Row justify="end">
          <Button size="large" onClick={onCloseModal} className="modal__cancel-btn">
            Отменить
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Добавить тип техники
          </Button>
        </Row>
      }
      centered
      width={645}
      closable={false}
      getContainer={false}
    >
      <Form
        id="form"
        form={form}
        colon={false}
        layout="vertical"
        className="form"
        onFinish={onFinish}
        initialValues={{ reason: 'actions' }}
      >
        <Space direction="vertical" size={24} className="modal__full-width">
          <Form.Item
            name="parent"
            label="Выберите категорию техники"
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <Select
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              notFoundContent={null}
              showSearch={true}
              dropdownRender={(menu) => (
                <>
                  <Row style={{ padding: '4px 8px', width: '100%' }} justify="space-between">
                    <Col span={12}>
                      <Input
                        ref={inputRef}
                        placeholder="Введите название новой категории"
                        value={name}
                        onChange={onNameChange}
                        maxLength={200}
                      />
                    </Col>
                    <Col>
                      <Button type="primary" onClick={addNewCategory} disabled={!name}>
                        Добавить категорию
                      </Button>
                    </Col>
                  </Row>
                  <Divider style={{ margin: '8px 0' }} />
                  {menu}
                </>
              )}
              size="large"
              getPopupContainer={(trigger) => trigger.parentElement!}
              options={selectItems}
            />
          </Form.Item>
          <Form.Item
            name="title"
            label="Укажите название типа техники"
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <Input size="large" maxLength={200} />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default CreateCategoryModal;

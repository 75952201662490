import { API } from '../../../../../Services/axios';
import { Button, Card, Checkbox, Col, Form, Space, Typography, notification } from 'antd';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import React, { useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';

interface IOrgAccreditationProps {
  executorId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IAccreditationData {
  id: number;
  name: string;
  active: boolean;
  created: string;
  requiredAccreditation: boolean;
  currentAccreditation?: boolean;
  comment?: string;
}

const OrgAccreditation = (props: IOrgAccreditationProps) => {
  const { executorId, setLoading } = props;
  const { showErrorModal } = useErrorModal();

  const [accreditationData, setAccreditationData] = useState<IAccreditationData>({
    id: 1,
    name: 'АО Росагролизинг',
    active: true,
    created: '14923847-39-23',
    requiredAccreditation: true,
  });
  const [currentAccreditation, setCurrentAccreditation] = useState(false);
  const [commentAccreditation, setCommentAccreditation] = useState<string>();
  const [newAccreditation, setNewAccreditation] = useState(false);

  const reloadAccreditaion = () => {
    API.get(`/api/v1/external-customers?executorId=${executorId}`)
      .then((response) => {
        if (response.data[0].id) {
          setAccreditationData(response.data[0]);
          setCurrentAccreditation(response.data[0].currentAccreditation);
          setNewAccreditation(true);
        }
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };
  useEffect(() => reloadAccreditaion(), []);

  const handleClick = () => {
    const requestData = {
      executorId: executorId,
      customerId: accreditationData.id,
      status: currentAccreditation ? 'ACTIVE' : 'INACTIVE',
    };
    if (newAccreditation) {
      API.put('api/v1/accreditations', {
        ...requestData,
        comment: currentAccreditation ? '' : commentAccreditation,
      })
        .then((_) => {
          notification.success({ message: 'Аккредитация обновлена успешно', placement: 'top' });
          reloadAccreditaion();
        })
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    } else {
      API.post('api/v1/accreditations', { ...requestData })
        .then((_) => {
          notification.success({ message: 'Аккредитация сохранена успешно', placement: 'top' });
          reloadAccreditaion();
        })
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Card title={<Typography.Title level={4}>Аккредитация</Typography.Title>}>
      <Space direction="vertical" size={16} className="operator__full-width">
        <Form.Item>
          <Checkbox
            checked={currentAccreditation}
            onChange={(e) => {
              setCurrentAccreditation(e.target.checked);
            }}
          >
            {accreditationData.name}
          </Checkbox>
        </Form.Item>

        {!currentAccreditation && accreditationData.comment && (
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text className="order__info" type="secondary" strong>
                Комментарий
              </Typography.Text>
              <Typography.Paragraph className="order__info-large" ellipsis={{ rows: 3, tooltip: true }}>
                {accreditationData.comment}
              </Typography.Paragraph>
            </Space>
          </Col>
        )}
        {!currentAccreditation && newAccreditation && !accreditationData.comment && (
          <Form.Item>
            <TextArea
              onChange={(e) => {
                setCommentAccreditation(e.target.value);
              }}
            />
          </Form.Item>
        )}
        <Button type="primary" size="large" onClick={handleClick}>
          Сохранить
        </Button>
      </Space>
    </Card>
  );
};

export default OrgAccreditation;

import { Claim } from '../../../../../Models/Claims';
import { Col, Row, Space, Typography } from 'antd';
import { defaultDateFormat } from '../../../../../Constants/DateFormats';
import dayjs from 'dayjs';

interface ClaimInfoProps {
  data: Claim;
}

const ClaimInfo = (props: ClaimInfoProps) => {
  const { data } = props;
  return (
    <>
      {data && (
        <Space direction="vertical" size={16} className="modal__full-width">
          <Row align="middle" justify={'space-between'}>
            <Col span={8}>
              <Typography.Text type="secondary" className="profile__info-large">
                Дата создания
              </Typography.Text>
            </Col>
            <Col span={15} offset={1}>
              <Typography.Text className="profile__info-large">
                {dayjs(data.created).format(defaultDateFormat)}
              </Typography.Text>
            </Col>
          </Row>
          <Row align="middle" justify={'space-between'}>
            <Col span={8}>
              <Typography.Text type="secondary" className="profile__info-large">
                Инициатор
              </Typography.Text>
            </Col>
            <Col span={15} offset={1}>
              <Typography.Link
                href={`/operator/organizations/${data.creatorOrganisationId}`}
                target="_blank"
                className="profile__info-large"
                underline
              >
                {data.creator}
              </Typography.Link>
            </Col>
          </Row>
          {data.agreementId && (
            <Row align="middle" justify={'space-between'}>
              <Col span={8}>
                <Typography.Text type="secondary" className="profile__info-large">
                  Кому адресовано
                </Typography.Text>
              </Col>
              <Col span={15} offset={1}>
                <Typography.Link
                  href={`/operator/organizations/${data.organisationId}`}
                  target="_blank"
                  className="profile__info-large"
                  underline
                >
                  {data.organisation}
                </Typography.Link>
              </Col>
            </Row>
          )}
          <Row align="middle" justify={'space-between'}>
            <Col span={8}>
              <Typography.Text type="secondary" className="profile__info-large">
                Причина обращения
              </Typography.Text>
            </Col>
            <Col span={15} offset={1}>
              <Typography.Text className="profile__info-large">{data.reason ? data.reason : '—'}</Typography.Text>
            </Col>
          </Row>
          <Row align="middle" justify={'space-between'}>
            <Col span={8}>
              <Typography.Text type="secondary" className="profile__info-large">
                Проблема
              </Typography.Text>
            </Col>
            <Col span={15} offset={1}>
              <Typography.Text className="profile__info-large">{data.issue ? data.issue : '—'}</Typography.Text>
            </Col>
          </Row>
          {data.comments && (
            <Row align="middle" justify={'space-between'}>
              <Col span={8}>
                <Typography.Text type="secondary" className="profile__info-large">
                  Описание проблемы
                </Typography.Text>
              </Col>
              <Col span={15} offset={1}>
                <Typography.Text className="profile__info-large">{data.comments}</Typography.Text>
              </Col>
            </Row>
          )}
          {data.agreementId && (
            <Row align="middle" justify={'space-between'}>
              <Col span={8}>
                <Typography.Text type="secondary" className="profile__info-large">
                  № Договора в системе
                </Typography.Text>
              </Col>
              <Col span={15} offset={1}>
                <Typography.Link
                  href={`/operator/contracts/${data.agreementId}`}
                  target="_blank"
                  className="profile__info-large"
                  underline
                >
                  {data.agreementUserFriendlyId ? data.agreementUserFriendlyId : '—'}
                </Typography.Link>
              </Col>
            </Row>
          )}
        </Space>
      )}
    </>
  );
};

export default ClaimInfo;

import { Rule } from 'antd/lib/form';
import { checkOgrn, checkOgrnip } from '../checkOgrn';
import { getRequiredFieldRule } from './order';

export const getUnrequiredNameFieldRuled = (): Rule[] => [
  {
    max: 50,
    message: 'Вводимое значение не может быть больше 50 символов',
  },
  {
    validator: (_, value) => {
      if (!value || (!value.match(/[0-9]/g) && !value.match(/[._!@#&()[{}\]:;',%?\\/*~$^+=<>|"№]/g))) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Вводимое значение не должно содержать цифры и специальные символы'));
    },
  },
  {
    validator: (_, value) => {
      if (!value || !value.trim() === !!'') {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Вводимое значение не может состоять только из пробелов'));
    },
  },
];

export const getUnrequiredPostFieldRuled = (): Rule[] => [
  {
    max: 200,
    message: 'Вводимое значение не может быть больше 200 символов',
  },
  {
    validator: (_, value) => {
      if (!value || !value.trim() === !!'') {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Вводимое значение не может состоять только из пробелов'));
    },
  },
];

export const getUnrequiredEmailFieldRule = (): Rule[] => [
  {
    type: 'email',
    message: 'Убедитесь, что адрес введен без ошибок',
  },
];

export const getUrequiredPhoneFieldRule = (): Rule[] => [
  {
    validator: (_, value) => {
      if (!value) {
        return Promise.resolve();
      } else if (value && value.replace(/\D/g, '').length === 10) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что телефон введен верно'));
    },
  },
];

export const getUnrequiredOrgNameFieldRule = (): Rule[] => [
  {
    max: 200,
    message: 'Вводимое значение не может быть больше 200 символов',
  },
];

export const getRequiredOrgFieldRule = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    max: 200,
    message: 'Вводимое значение не может быть больше 200 символов',
  },
];

export const getUnrequiredBankFieldRule = (): Rule[] => [
  {
    max: 100,
    message: 'Вводимое значение не может быть больше 100 символов',
  },
];

export const getRequiredBankFieldRule = (): Rule[] => [
  ...getRequiredFieldRule('Введите название банка'),
  {
    max: 100,
    message: 'Вводимое значение не может быть больше 100 символов',
  },
];

export const getUnrequiredNumericFieldRule = (number: number): Rule[] => [
  {
    validator: (_, value) => {
      if (!value || `${value}`.length === number) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`Количество вводимых символов должно быть равным ${number}`));
    },
  },
];

export const getRequiredNumericFieldRule = (message: string, number: number): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator: (_, value) => {
      if (!value || `${value}`.length === number) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`Количество вводимых символов должно быть равным ${number}`));
    },
  },
];

export const getOgrnFieldRules = (): Rule[] => [
  {
    validator: (_, value) => {
      if (!value || (`${value}`.length === 13 && checkOgrn(value))) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что ОГРН введен правильно'));
    },
  },
];

export const getRequiredOgrnFieldRules = (): Rule[] => [
  ...getRequiredFieldRule('Введите ОГРН организации'),
  {
    validator: (_, value) => {
      if (!value || (`${value}`.length === 13 && checkOgrn(value))) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что ОГРН введен правильно'));
    },
  },
];

export const getOgrnipFieldRules = (): Rule[] => [
  {
    validator: (_, value) => {
      if (!value || (`${value}`.length === 15 && checkOgrnip(value))) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что ОГРНИП введен правильно'));
    },
  },
];

export const getOkvedFieldRules = (): Rule[] => [
  {
    validator: (_, value) => {
      if (!value || (value.length <= 8 && value.match(/^[0-9]{2}$/)) || value.match(/^[0-9]{2}(\.[0-9]{1,2}){1,2}$/)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Убедитесь, что ОКВЭД введен правильно'));
    },
  },
];

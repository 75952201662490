import { Card, Col, Row, Space, Typography } from 'antd';
import { ChangeHistoryEvents } from '../../../../../Constants/Operator';
import { HistoryItem } from '../../../../../Models/Operator';
import { defaultDateFormat } from '../../../../../Constants/DateFormats';
import dayjs from 'dayjs';

interface ChangeHistoryProps {
  history: HistoryItem[];
}

const ChangeHistory = (props: ChangeHistoryProps) => {
  const { history } = props;

  const filteredHistory = history.filter((item) => item.event !== ChangeHistoryEvents.CREATED);

  const message = (event: ChangeHistoryEvents) => {
    switch (event) {
      case ChangeHistoryEvents.BLOCKED:
        return 'Организация заблокирована';
      case ChangeHistoryEvents.UNBLOCKED:
        return 'Организация разблокирована';
      case ChangeHistoryEvents.VERIFIED:
        return 'Организация подтверждена в системе';
      case ChangeHistoryEvents.UNVERIFIED:
        return '';
      default:
        return '';
    }
  };

  const comment = (json) => JSON.parse(json).comment;

  const changeHistoryItem = (item: HistoryItem) => (
    <Space direction="vertical" size={4} className="operator__full-width">
      <Row gutter={[4, 0]}>
        <Col span={8}>
          <Typography.Text strong>{dayjs(item.created).format(defaultDateFormat)}</Typography.Text>
        </Col>
        <Col span={16}>
          <Typography.Text>{message(item.event)}</Typography.Text>
        </Col>
      </Row>
      {comment(item.description) && (
        <Row gutter={[4, 0]}>
          <Col span={8}>
            <Typography.Text strong>Причина</Typography.Text>
          </Col>
          <Col span={16}>
            <Typography.Text>{comment(item.description)}</Typography.Text>
          </Col>
        </Row>
      )}
    </Space>
  );

  return (
    <Card title={<Typography.Title level={4}>История изменений</Typography.Title>}>
      <Space direction="vertical" size={16}>
        {filteredHistory.length > 0 ? (
          filteredHistory.map((item) => changeHistoryItem(item))
        ) : (
          <Typography.Text>У этой организации еще нет истории изменений</Typography.Text>
        )}
      </Space>
    </Card>
  );
};

export default ChangeHistory;

import { Typography } from 'antd';

export const enum CustomerTableTabs {
  ALL = 'ALL',
  IN_PROCESS = 'IN_PROCESS',
  DELAYED = 'DELAYED',
  COMPLETED = 'COMPLETED',
}

interface EmptyMessageProps {
  tabName: CustomerTableTabs;
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { tabName } = props;

  const emptyText = () => {
    switch (tabName) {
      case CustomerTableTabs.ALL:
        return 'Здесь будут отображаться все ваши договоры с организаторами перевозки';
      case CustomerTableTabs.IN_PROCESS:
        return 'Здесь будут отображаться ваши договоры, по которым происходит исполнение';
      case CustomerTableTabs.DELAYED:
        return 'Здесь будут отображаться ваши договоры, для которых наступила указанная вами желаемая дата доставки, но не закончено исполнение';
      case CustomerTableTabs.COMPLETED:
        return 'Здесь будут отображаться выполненные договоры';
    }
  };

  return <Typography.Text>{emptyText()}</Typography.Text>;
};

export default EmptyMessage;

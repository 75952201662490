import { ContractsTableProps } from '../../../../../Models/Contract';
import { OrgTableTabs } from './Component/empty-message';
import { ResizableTitle } from './Component/resizable-title';
import { Table } from 'antd';
import { getOrganizerContractsTableColumns } from './Component/columns';
import { getOrganizerContractsTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { updateOrganisationId } from '../../../../../Store/Slices/organizationProfileModalSlice';
import { useAppDispatch, useAppSelector } from '../../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrganizaionInfoModal from '../../../../../Components/Modals/OrganizationInfoModal';

interface OrgTableProps extends ContractsTableProps {
  columnsSettings: unknown;
  tabName: OrgTableTabs;
  totalCount: number;
}

const ContractsTable = (props: OrgTableProps) => {
  const { data, rowSelection, loading, columnsSettings, tabName, totalCount } = props;
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = (organisationId: number) => {
    dispatch(updateOrganisationId(organisationId));
    setOpenModal(true);
  };
  const hideModal = () => setOpenModal(false);

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, sortInfo, setSortInfo, handleFilter } =
    getOrganizerContractsTableFilter();
  const columns = getOrganizerContractsTableColumns(columnsSettings, filteredInfo, setFilteredInfo, showModal);
  const [searchParams, setSearchParams] = useSearchParams();
  const sizePage = localStorage.getItem('sizePage');

  useEffect(() => {
    const handlers = document.querySelectorAll('.react-resizable .react-resizable-handle');
    handlers.forEach((handler) =>
      handler.addEventListener('click', () => {
        return false;
      }),
    );
  });

  useEffect(() => {
    if (paginationInfo?.current) {
      setSearchParams((params) => {
        params.set('page', paginationInfo.current ? paginationInfo.current.toString() : '');
        return searchParams;
      });
    }
    if (paginationInfo?.pageSize) {
      setSearchParams((params) => {
        params.set('size', paginationInfo.pageSize ? paginationInfo.pageSize.toString() : '');
        return searchParams;
      });
    }
    if (sortInfo?.sortOrder) {
      setSearchParams((params) => {
        params.set('sort', sortInfo.sortOrder ? sortInfo.sortOrder.toString() : '');
        return searchParams;
      });
    }
    if (sortInfo?.sortField) {
      setSearchParams((params) => {
        params.set('sortField', sortInfo.sortField ? sortInfo.sortField.toString() : '');
        return searchParams;
      });
    }
  }, [paginationInfo, sortInfo]);

  return (
    <>
      <Table
        bordered
        rowKey={'agreementId'}
        className="table__contracts"
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        scroll={{ x: 1300 }}
        loading={loading}
        rowSelection={isVerified ? rowSelection : undefined}
        columns={columns}
        dataSource={data}
        locale={tableLocaleSettings(tabName)}
        pagination={tablePagingSettings(totalCount, sizePage)}
        onChange={handleFilter}
      />
      <OrganizaionInfoModal title="Данные о заказчике" open={openModal} handleCancel={hideModal} />
    </>
  );
};

export default ContractsTable;

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExactAddress } from '../../../../../../Models/Address';
import { FormInstance } from 'rc-field-form';
import {
  FullscreenControl,
  GeolocationControl,
  Map,
  Placemark,
  TypeSelector,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps';
import React from 'react';
import YSuggest from './yaddress-suggest';

const YMap: React.FC<{
  ymaps: any;
  form: FormInstance;
  mapCenter: number[];
  mapZoom: number;
  addressFrom: ExactAddress;
  addressTo: ExactAddress;
  countrySelect: {
    countryFromSelect: string;
    countryToSelect: string;
  };
  profileOrg: boolean;
  setYMaps: React.Dispatch<any>;
  setMapCenter: React.Dispatch<React.SetStateAction<number[]>>;
  setMapZoom: React.Dispatch<React.SetStateAction<number>>;
  setAddressFrom: React.Dispatch<React.SetStateAction<ExactAddress>>;
  setAddressTo: React.Dispatch<React.SetStateAction<ExactAddress>>;
  setSubmitButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setCalculator: React.Dispatch<
    React.SetStateAction<{
      price: number;
      distance: number;
    }>
  >;
  setCountrySelect: React.Dispatch<
    React.SetStateAction<{
      countryFromSelect: string;
      countryToSelect: string;
    }>
  >;
}> = ({
  ymaps,
  form,
  mapCenter,
  mapZoom,
  addressFrom,
  addressTo,
  countrySelect,
  profileOrg,
  setYMaps,
  setAddressFrom,
  setAddressTo,
  setMapCenter,
  setMapZoom,
  setSubmitButtonDisabled,
  setCalculator,
  setCountrySelect,
}) => {
  const initMap = (ymaps) => {
    setYMaps(ymaps);

    // код для удаления промо элементов
    // не включать пока не разберёмся с условиями использования лицензии
    // setTimeout 0 необходим для того чтобы отодвинуть вызов в eventloop
    // setTimeout(() => {
    //   const promo = document.querySelector('ymaps[class*="map-copyrights-promo"]');
    //   promo!.remove();
    // }, 0);
  };

  const setAddressFromOnMapClick = (coords: number[]) => {
    const geo = ymaps.geocode(coords, { results: 1 });
    geo.then((res) => {
      const geoObj = res.geoObjects.get(0);
      const address = geoObj.properties._data.text;
      setAddressFrom({
        displayName: address.replace('Украина', 'Россия'),
        coordinates: coords,
        components: geoObj.properties._data.metaDataProperty.GeocoderMetaData.Address.Components,
      });
      form.setFieldValue('addressFrom', address);
    });
    setCalculator({ price: 0, distance: 0 });
    setSubmitButtonDisabled(true);
  };

  const setAddressToOnMapClick = (coords: number[]) => {
    const geo = ymaps.geocode(coords, { results: 1 });
    geo.then((res) => {
      const geoObj = res.geoObjects.get(0);
      const address = geoObj.properties._data.text;
      setAddressTo({
        displayName: address.replace('Украина', 'Россия'),
        coordinates: coords,
        components: geoObj.properties._data.metaDataProperty.GeocoderMetaData.Address.Components,
      });
      form.setFieldValue('addressTo', address);
    });
    setCalculator({ price: 0, distance: 0 });
    setSubmitButtonDisabled(true);
  };

  const onMapClick = (e) => {
    const coords = e.get('coords');
    if (addressFrom.displayName === '') {
      setAddressFromOnMapClick(coords);
    } else if (addressTo.displayName === '') {
      setAddressToOnMapClick(coords);
    }
    setCalculator({ price: 0, distance: 0 });
    setSubmitButtonDisabled(true);
  };

  const onDragA = (e) => {
    const coords = e.originalEvent.target.geometry._coordinates;
    setAddressFromOnMapClick(coords);
  };

  const onDragB = (e) => {
    const coords = e.originalEvent.target.geometry._coordinates;
    setAddressToOnMapClick(coords);
  };

  return (
    <YMaps
      query={{
        apikey: process.env.REACT_APP_YMAPS_API_KEY,
        suggest_apikey: process.env.REACT_APP_YMAPS_SUGGEST_API_KEY,
        lang: 'ru_RU',
      }}
    >
      <YSuggest
        form={form}
        label={'Адрес отправки'}
        name={'addressFrom'}
        id={'suggestFrom'}
        value={addressFrom.displayName}
        nameSelect={'countryFromSelect'}
        nameCheckbox={'countryFromCheckbox'}
        idSelect={'suggestFromSelect'}
        idCheckbox={'suggestFromCheckbox'}
        idSpace={'suggestFromSpace'}
        countrySelect={countrySelect}
        profileOrg={profileOrg}
        setAddressFrom={setAddressFrom}
        setAddressTo={setAddressTo}
        setMapCenter={setMapCenter}
        setMapZoom={setMapZoom}
        setSubmitButtonDisabled={setSubmitButtonDisabled}
        setCalculator={setCalculator}
        setCountrySelect={setCountrySelect}
      />
      <YSuggest
        form={form}
        label={'Адрес получения'}
        name={'addressTo'}
        id={'suggestTo'}
        value={addressTo.displayName}
        nameSelect={'countryToSelect'}
        nameCheckbox={'countryToCheckbox'}
        idSelect={'suggestToSelect'}
        idCheckbox={'suggestToCheckbox'}
        idSpace={'suggestToSpace'}
        countrySelect={countrySelect}
        profileOrg={profileOrg}
        setAddressFrom={setAddressFrom}
        setAddressTo={setAddressTo}
        setMapCenter={setMapCenter}
        setMapZoom={setMapZoom}
        setSubmitButtonDisabled={setSubmitButtonDisabled}
        setCalculator={setCalculator}
        setCountrySelect={setCountrySelect}
      />
      <Map
        id="ymap"
        onLoad={(ymaps) => initMap(ymaps)}
        state={{
          center: mapCenter,
          zoom: mapZoom,
        }}
        width="100%"
        height="50vh"
        onClick={onMapClick}
      >
        {addressFrom.coordinates.length > 0 && (
          <Placemark
            key={'A'}
            geometry={addressFrom.coordinates}
            options={{
              iconLayout: 'default#image',
              iconImageSize: [50, 50],
              iconImageHref: `https://img.icons8.com/ios-filled/2x/marker-a.png`,
              draggable: true,
            }}
            onDragEnd={onDragA}
          />
        )}
        {addressTo.coordinates.length > 0 && (
          <Placemark
            key={'B'}
            geometry={addressTo.coordinates}
            options={{
              iconLayout: 'default#image',
              iconImageSize: [50, 50],
              iconImageHref: `https://img.icons8.com/ios-filled/2x/marker-b.png`,
              draggable: true,
            }}
            onDragEnd={onDragB}
          />
        )}
        <GeolocationControl
          options={{
            float: 'left',
          }}
        />
        <ZoomControl />
        <TypeSelector />
        <FullscreenControl
          options={{
            float: 'right',
          }}
        />
      </Map>
    </YMaps>
  );
};

export default YMap;

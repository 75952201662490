import { Rule } from 'antd/lib/form';
import { getRequiredFieldRule } from './order';

export const getRequiredPositiveFieldRules = (message: string): Rule[] => [
  ...getRequiredFieldRule(message),
  {
    validator(_, value) {
      const numberValue = Number(`${value}`.replaceAll(' ', ''));
      if (!value || +numberValue >= 1) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Вводимое значение должно быть больше 0'));
    },
  },
];

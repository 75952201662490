import { API } from '../../Services/axios';
import { Alert, Avatar, Col, Modal, Row, Space, Typography } from 'antd';
import { ExclamationCircleFilled, UserOutlined } from '@ant-design/icons';
import { ModalProps } from '../../Models/Modal';
import { OrgTypes } from '../../Constants/OrgTypes';
import { Profile } from '../../Models/Profile';
import { Roles } from '../../Constants/Profile';
import { getMaskedPhoneNumberInProfile } from '../../Utils/getPhoneMasked';
import { useAppSelector } from '../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from './ErrorModal';
import Loader from '../Loader/Loader';
import ModalTitle from './ModalTitle';
import ProfileItem from '../Profile/ProfileItem';

interface OrganizaionInfoModalProps extends ModalProps {
  title: string;
}

const OrganizaionInfoModal = (props: OrganizaionInfoModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, title } = props;
  const organisationId = useAppSelector((state) => state.organizationProfileModal.organisationId);

  const [profileData, setProfileData] = useState<Profile>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (organisationId) {
      API.get(`/api/v1/profile/${organisationId}`)
        .then((response) => setProfileData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  }, [organisationId]);

  const isUserLegal = profileData?.organisation.type === OrgTypes.LEGAL ? true : false;

  const managementName = profileData?.organisation.managementPatronymic
    ? `${profileData?.organisation.managementSurname} ${profileData?.organisation.managementName} ${profileData.organisation.managementPatronymic}`
    : `${profileData?.organisation.managementSurname} ${profileData?.organisation.managementName}`;
  const orgRole = profileData?.type === Roles.DELIVERY;

  const isThereAdditionalInfo =
    profileData?.organisation.taxationSystem ||
    profileData?.organisation.phone ||
    profileData?.organisation.email ||
    profileData?.organisation.description;
  const isThereBankInfo =
    profileData?.organisation.bankName ||
    profileData?.organisation.bic ||
    profileData?.organisation.correspondentAccount ||
    profileData?.organisation.paymentAccount;

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title={title} handleCancel={handleCancel} />}
      width={860}
      closable={false}
      footer={null}
      centered
      className="modal modal__org-info"
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      {loading ? (
        <Loader />
      ) : (
        profileData && (
          <Row gutter={[0, 40]}>
            {!profileData.enabled && (
              <Row className="profile__blocked-alert">
                <Col span={24}>
                  <Alert
                    message="Пользователь данной организации был заблокирован"
                    showIcon
                    icon={<ExclamationCircleFilled />}
                    type="error"
                  />
                </Col>
              </Row>
            )}
            {profileData.organisation.locked && (
              <Row className="profile__blocked-alert">
                <Col span={24}>
                  <Alert
                    message="Организация временно заблокирована"
                    showIcon
                    icon={<ExclamationCircleFilled />}
                    type="error"
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[24, 32]} style={{ width: '100%' }}>
              <Col span={24}>
                <Typography.Title level={4}>Основная информация</Typography.Title>
              </Col>
              <Col span={20}>
                <Space direction="vertical" size={16} style={{ width: '100%' }}>
                  <ProfileItem
                    title="Организационно-правовая форма"
                    value={isUserLegal ? 'Юридическое лицо' : 'Индивидуальный предприниматель'}
                  />
                  <ProfileItem title="ИНН" value={profileData!.organisation.inn} />
                  {isUserLegal && <ProfileItem title="КПП" value={profileData!.organisation.kpp} />}
                  <ProfileItem title="Краткое наименование" value={profileData.organisation.shortName} />
                  {profileData.organisation.name && (
                    <ProfileItem title="Полное наименование" value={profileData.organisation.name} />
                  )}
                  {/* <ProfileItem
                    title="Дата внесения в реестр"
                    value={dayjs(profileData.organisation.ogrnDate).format(defaultDateFormat) ?? '—'}
                  /> */}
                  {isUserLegal ? (
                    <>
                      {profileData.organisation.businessAddress && (
                        <ProfileItem title="Юридический адрес" value={profileData.organisation.businessAddress} />
                      )}
                      {profileData.organisation.postalAddress && (
                        <ProfileItem title="Почтовый адрес" value={profileData.organisation.postalAddress} />
                      )}
                    </>
                  ) : (
                    profileData.organisation.businessAddress && (
                      <ProfileItem title="Адрес регистрации" value={profileData.organisation.businessAddress} />
                    )
                  )}

                  {(profileData.organisation.ogrn || profileData.organisation.ogrnip) && (
                    <ProfileItem
                      title={isUserLegal ? 'ОГРН' : 'ОГРНИП'}
                      value={isUserLegal ? profileData.organisation.ogrn : profileData.organisation.ogrnip}
                    />
                  )}
                  {profileData.organisation.okpo && <ProfileItem title="ОКПО" value={profileData.organisation.okpo} />}
                  {profileData.organisation.okved && (
                    <ProfileItem title="ОКВЭД" value={profileData.organisation.okved} />
                  )}
                </Space>
              </Col>
              <Col span={'92px'}>
                <Avatar shape="square" icon={<UserOutlined />} src={profileData.avatar?.link} size={92} />
              </Col>
            </Row>
            {isThereBankInfo && (
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Платежная информация</Typography.Title>
                </Col>
                <Col span={20}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    {profileData.organisation.bankName && (
                      <ProfileItem title="Банк" value={profileData.organisation.bankName ?? '—'} />
                    )}
                    {profileData.organisation.paymentAccount && (
                      <ProfileItem title="Расчетный счет" value={profileData.organisation.paymentAccount} />
                    )}
                    {profileData.organisation.correspondentAccount && (
                      <ProfileItem
                        title="Корреспондентский счет"
                        value={profileData.organisation.correspondentAccount}
                      />
                    )}
                    {profileData.organisation.bic && <ProfileItem title="БИК" value={profileData.organisation.bic} />}
                  </Space>
                </Col>
              </Row>
            )}
            {profileData.organisation.managementName && (
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Данные о руководителе</Typography.Title>
                </Col>
                <Col span={20}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <ProfileItem title="ФИО" value={profileData.organisation.managementName ? managementName : '—'} />
                    <ProfileItem title={'Склонённое ФИО'} value={profileData.organisation.fioPDF} />
                    {isUserLegal && (
                      <ProfileItem title="Должность" value={profileData.organisation.managementPost ?? '—'} />
                    )}
                  </Space>
                </Col>
              </Row>
            )}
            {isThereAdditionalInfo && (
              <Row gutter={[24, 32]} style={{ width: '100%' }}>
                <Col span={24}>
                  <Typography.Title level={4}>Дополнительные сведения</Typography.Title>
                </Col>
                <Col span={20}>
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    {profileData.organisation.taxationSystem && (
                      <ProfileItem title="Система налогообложения" value={profileData.organisation.taxationSystem} />
                    )}
                    {profileData.organisation.email && (
                      <ProfileItem title="Электронная почта" value={profileData.organisation.email} />
                    )}
                    {profileData.organisation.phone && (
                      <ProfileItem
                        title="Телефон организации"
                        value={getMaskedPhoneNumberInProfile(profileData.organisation.phone)}
                      />
                    )}
                    {orgRole && profileData.organisation.description && (
                      <ProfileItem title="Краткое описание" value={profileData.organisation.description} />
                    )}
                  </Space>
                </Col>
              </Row>
            )}
          </Row>
        )
      )}
    </Modal>
  );
};

export default OrganizaionInfoModal;

import { Badge, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import calcIcon from '../Assets/Images/calculator-icon.png';

export const landingUrl = 'https://verumlogistics.ru/';

export const orgMenuItems = (ordersCount: number) => [
  {
    label: <Link to="/organizer/orders">Каталог заказов</Link>,
    key: '/organizer/orders',
  },
  {
    label: (
      <Link to="/organizer/orders/in-progress">
        Заказы в работе {ordersCount > 0 && <Badge className="order__badge" count={ordersCount} />}
      </Link>
    ),
    key: '/organizer/orders/in-progress',
  },
  {
    label: <Link to="/organizer/contracts">Договоры</Link>,
    key: '/organizer/contracts',
  },
  {
    label: <Link to="/organizer/support">Обращения</Link>,
    key: '/organizer/support',
  },
  {
    label: (
      <Link to="/profile?tab=5">
        <div className="header__nav-icon">
          <img src={calcIcon} height={22} width="auto" />
        </div>
        <Typography.Text>Расчет стоимости</Typography.Text>
      </Link>
    ),
    key: '/profile?tab=5',
  },
];

export const customerMenuItems = (ordersCount: number, contractsCount: number) => [
  {
    label: (
      <Link to="/customer/orders">
        Мои заказы {ordersCount > 0 && <Badge className="order__badge" count={ordersCount} />}
      </Link>
    ),
    key: '/customer/orders',
  },
  {
    label: (
      <Link to="/customer/contracts">
        Договоры {contractsCount > 0 && <Badge className="order__badge" count={contractsCount} />}
      </Link>
    ),
    key: '/customer/contracts',
  },
  {
    label: <Link to="/customer/support">Обращения</Link>,
    key: '/customer/support',
  },
  {
    label: (
      <Link to="/customer/orders/create">
        <div className="header__nav-icon">
          <PlusOutlined style={{ color: '#00bc41' }} />
        </div>
        <Typography.Text>Создать заказ</Typography.Text>
      </Link>
    ),
    key: '/customer/orders/create',
  },
];

export const operatorMenuItems = (orgsCounter: number, claimsCounter: number) => [
  {
    label: <Link to="/operator/organizations">Организации {orgsCounter > 0 && <Badge count={orgsCounter} />}</Link>,
    key: '/operator/organizations',
  },
  {
    label: <Link to="/operator/users">Пользователи</Link>,
    key: '/operator/users',
  },
  {
    label: <Link to="/operator/claims">Обращения {claimsCounter > 0 && <Badge count={claimsCounter} />}</Link>,
    key: '/operator/claims',
  },
  {
    label: <Link to="/operator/orders">Заказы</Link>,
    key: '/operator/orders',
  },
  {
    label: <Link to="/operator/contracts">Договоры</Link>,
    key: '/operator/contracts',
  },
];

export const adminMenuItems = [
  {
    label: <Link to="/admin/users">Пользователи</Link>,
    key: '/admin/users',
  },
  {
    label: <Link to="/admin/operators">Операторы</Link>,
    key: '/admin/operators',
  },
  {
    label: <Link to="/admin/analitics">Аналитика</Link>,
    key: '/admin/analitics',
  },
  {
    label: <Link to="/admin/settings">Настройки системы</Link>,
    key: '/admin/settings',
  },
];

export const landingItems = [
  {
    label: 'Владельцам грузов',
    link: `${landingUrl}vladelcam-gruzov/`,
  },
  {
    label: 'Организаторам перевозок',
    link: `${landingUrl}organizatoram-perevozok/`,
  },
  {
    label: 'Калькулятор',
    link: `${landingUrl}calculator/`,
  },
  {
    label: 'Заказы',
    link: `${landingUrl}zakazy-na-perevozku/`,
  },
  {
    label: 'Новости',
    link: `${landingUrl}novosti/`,
  },
  {
    label: 'Контакты',
    link: `${landingUrl}kontakty/`,
  },
];

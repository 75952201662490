// Checks if legal entity`s inn is correct
export const checkLegalInn = (value) => {
  const innNumbers = value.toString().split('');

  const divisionRemainder =
    (2 * innNumbers[0] +
      4 * innNumbers[1] +
      10 * innNumbers[2] +
      3 * innNumbers[3] +
      5 * innNumbers[4] +
      9 * innNumbers[5] +
      4 * innNumbers[6] +
      6 * innNumbers[7] +
      8 * innNumbers[8] +
      0 * innNumbers[9]) %
    11;

  if (divisionRemainder > 9) {
    const remainder = divisionRemainder % 10;
    return Number(innNumbers[9]) === remainder ? true : false;
  } else {
    return Number(innNumbers[9]) === divisionRemainder ? true : false;
  }
};

// Checks if individual entrepreneur`s inn is correct
export const checkIndividualInn = (value) => {
  const innNumbers = value.toString().split('');

  let checkSumOne =
    (7 * innNumbers[0] +
      2 * innNumbers[1] +
      4 * innNumbers[2] +
      10 * innNumbers[3] +
      3 * innNumbers[4] +
      5 * innNumbers[5] +
      9 * innNumbers[6] +
      4 * innNumbers[7] +
      6 * innNumbers[8] +
      8 * innNumbers[9]) %
    11;

  if (checkSumOne > 9) checkSumOne = checkSumOne % 10;

  let checkSumTwo =
    (3 * innNumbers[0] +
      7 * innNumbers[1] +
      2 * innNumbers[2] +
      4 * innNumbers[3] +
      10 * innNumbers[4] +
      3 * innNumbers[5] +
      5 * innNumbers[6] +
      9 * innNumbers[7] +
      4 * innNumbers[8] +
      6 * innNumbers[9] +
      8 * innNumbers[10]) %
    11;

  if (checkSumTwo > 9) checkSumTwo = checkSumTwo % 10;

  return checkSumOne === Number(innNumbers[10]) && checkSumTwo === Number(innNumbers[11]) ? true : false;
};

import { API } from '../../../../Services/axios';
import { Col, Row, Tabs, Typography } from 'antd';
import { UsersTabProps } from '../../../../Models/Profile';
import { getUsersTabsItems } from './Component/tab-items';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';

const Users = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    users: [],
    all: 0,
    deliveries: 0,
    customers: 0,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab') ?? 'all');
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';

  const restartTable = () => {
    if (tab !== null) {
      setLoading(true);
      API.get(`/api/v1/operator/profiles?filter=${tab}&page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`)
        .then((response) => setData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    restartTable();
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && tab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('tab', tab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
    setLoading(true);
    restartTable();
  }, [tab, page, size, sort, sortField]);

  const usersProps: UsersTabProps = {
    users: data.users,
    loading: loading,
    all: data.all,
    deliveries: data.deliveries,
    customers: data.customers,
  };

  const tabsItems = getUsersTabsItems(usersProps);

  return (
    <div>
      <Row className="toolbar__title" justify="space-between">
        <Col>
          <Typography.Title level={2}>Пользователи</Typography.Title>
        </Col>
      </Row>
      <div className="organizations__tabs-table">
        <Tabs
          activeKey={tab}
          onTabClick={(key) => {
            setTab(key);
            setSearchParams((params) => {
              params.set('page', '1');
              return searchParams;
            });
            window.history.replaceState(null, '', `/operator/users?tab=${key}&page=${page}&size=${size}`);
          }}
          items={tabsItems}
          className="tabs"
        />
      </div>
    </div>
  );
};

export default Users;

import { Profile } from '../Models/Profile';
import { Roles } from '../Constants/Profile';

export interface UserPropertyType extends Omit<Profile, 'avatar' | 'organisation'> {
  organisationName: string;
  locked: string;
}

// Using to show users table filter depending on the type of cell`s data
export const getUserPropertyType = (propName: string) => {
  const u: UserPropertyType = {
    createdAt: new Date(),
    locked: '',
    organisationName: '',
    email: '',
    id: 0,
    name: '',
    notificationConfirmed: false,
    notificationConfirmedAt: '',
    patronymic: '',
    phone: '',
    processingDataConfirmed: false,
    processingDataConfirmedAt: '',
    systemNotificationConfirmed: true,
    surname: '',
    type: Roles.CUSTOMER,
    verified: false,
    enabled: true,
    calculatorFeedbackEnabled: true,
  };
  return typeof u[propName];
};

import { API } from '../../Services/axios';
import { Button, Form, Input, Modal, Row, Space } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { ModalProps } from '../../Models/Modal';
import { PatternFormat } from 'react-number-format';
import { Profile } from '../../Models/Profile';
import { getEmailFieldRules, getNameFieldRules, getPhoneFieldRules } from '../../Utils/Rules/registration';
import { getUnrequiredNameFieldRuled } from '../../Utils/Rules/profile';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import ModalTitle from './ModalTitle';

interface EditUserProfileModalProps extends ModalProps {
  profileData: Profile;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const EditUserProfileModal = (props: EditUserProfileModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, profileData, setReload } = props;
  const [form] = useForm();

  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    name: profileData.name,
    surname: profileData.surname,
    patronymic: profileData.patronymic,
    phone: profileData.phone.replace(/\D/g, '').slice(1),
    email: profileData.email,
  };

  const onCloseModal = () => {
    handleCancel();
    form.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);
    const updatedData = {
      ...profileData,
      name: values.name.trim(),
      surname: values.surname.trim(),
      patronymic: values.patronymic && values.patronymic.trim().length > 0 ? values.patronymic.trim() : undefined,
      phone: '+7 ' + values.phone,
    };

    API.put('/api/v1/profile', updatedData)
      .then((response) => {
        setReload(true);
        onCloseModal();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Редактирование личных данных" handleCancel={onCloseModal} />}
      open={open}
      onCancel={onCloseModal}
      className="modal"
      footer={null}
      centered
      width={645}
      closable={false}
    >
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form
          id="form"
          form={form}
          initialValues={initialValues}
          colon={false}
          layout="vertical"
          className="form"
          onFinish={onFinish}
        >
          <Form.Item name="surname" label="Фамилия" validateFirst rules={getNameFieldRules('Введите вашу фамилию')}>
            <Input size="large" />
          </Form.Item>
          <Form.Item name="name" label="Имя" validateFirst rules={getNameFieldRules('Введите ваше имя')}>
            <Input size="large" />
          </Form.Item>
          <Form.Item name="patronymic" label="Отчество" validateFirst rules={getUnrequiredNameFieldRuled()}>
            <Input size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Электронная почта"
            validateFirst
            rules={getEmailFieldRules('Введите электронную почту')}
          >
            <Input size="large" disabled />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Номер телефона"
            normalize={(value) => value.replace(/\D/g, '').slice(0, 10)}
            rules={getPhoneFieldRules('Введите номер телефона')}
            validateFirst
          >
            <PatternFormat
              valueIsNumericString
              format="(###) ###-##-##"
              customInput={Input}
              prefix="+7"
              size="large"
              mask="_"
            />
          </Form.Item>
        </Form>

        <Row justify="end">
          <Button size="large" onClick={onCloseModal} className="modal__cancel-btn">
            Отменить
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Сохранить
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default EditUserProfileModal;

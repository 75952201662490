import { API } from '../../../../../Services/axios';
import { Agreement } from '../../../../../Models/Agreement';
import { Button, Col, Grid, Modal, Popconfirm, Space, Typography } from 'antd';
import { OfferStatuses } from '../../../../../Constants/OfferStatuses';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import SigningContractModal from '../../../../../Components/Modals/SigningContractModal';

interface AgreementOfferCardFooterProps {
  isLocked: boolean;
  offerId: number;
  currentStatus: OfferStatuses;
}

const { useBreakpoint } = Grid;

const AgreementOfferCardFooter = (props: AgreementOfferCardFooterProps) => {
  const { showErrorModal } = useErrorModal();
  const { isLocked, offerId, currentStatus } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const screens = useBreakpoint();
  // const smallScreens = (screens.xs || screens.sm) && !screens.md && !screens.lg;

  const [agreements, setAgreements] = useState<Agreement[]>([]);
  useEffect(() => {
    API.get(`/api/v1/agreement/${offerId}/list`)
      .then((response) => setAgreements(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);
  const handleConfirm = () => {
    setLoading(true);
    API.put(`/api/v1/offer/accept/${offerId}`)
      .then((response) => {
        Modal.success({
          title: <Typography.Title level={4}>Предложение принято и перевозчик выбран</Typography.Title>,
          content: <></>,
          width: 655,
          centered: true,
          onOk: () => location.reload(),
        });
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        handleCancel();
        setLoading(false);
      });
  };

  const declineOffer = () => {
    API.put(`/api/v1/offer/decline/${offerId}`)
      .then((response) => location.reload())
      .catch((error) => showErrorModal({ error }));
  };

  return (
    <Col span={24}>
      <Space size={24} direction="vertical" className="offer__full-width">
        {/*{agreements &&*/}
        {/*  agreements.map((agreement) => {*/}
        {/*    return (*/}
        {/*      <Space key={agreement.id} size={16} direction="vertical">*/}
        {/*        <Space size={12} direction="vertical">*/}
        {/*          /!*<Space size={smallScreens ? 12 : 24} direction={smallScreens ? 'vertical' : 'horizontal'}>*!/*/}
        {/*          /!*<Space size={10}>*!/*/}
        {/*          /!*  <FileOutlined className="offer__file-icon" />*!/*/}
        {/*          /!*  <Typography.Link className="offer__text" href={agreement.fileInfo[0].link}>*!/*/}
        {/*          /!*    {agreement.fileInfo[0].name}*!/*/}
        {/*          /!*  </Typography.Link>*!/*/}
        {/*          /!*</Space>*!/*/}
        {/*          /!*{`${convertToMscTime(agreement.created)} мск`}*!/*/}
        {/*          /!*</Space>*!/*/}
        {/*          {agreement.comment && <Typography className="offer__info-large">{agreement.comment}</Typography>}*/}
        {/*        </Space>*/}
        {/*        {agreement !== agreements[agreements.length - 1] && <Divider />}*/}
        {/*      </Space>*/}
        {/*    );*/}
        {/*  })}*/}

        {currentStatus !== OfferStatuses.ACCEPTED && (
          <div className="offer__footer">
            <Space size={16}>
              <Button type="primary" size="large" onClick={showModal} disabled={isLocked}>
                Выбрать перевозчика
              </Button>
              <Popconfirm
                placement="bottom"
                title={'Вы уверены, что хотите отказаться от предложения перевозчика?'}
                getPopupContainer={(trigger) => trigger.parentElement!}
                onConfirm={declineOffer}
                okText="Подтвердить"
                cancelText="Отменить"
                okButtonProps={{ size: 'middle' }}
                cancelButtonProps={{ size: 'middle' }}
              >
                <Button size="large" style={{ whiteSpace: 'break-spaces', height: 'auto' }}>
                  Отказаться от предложения
                </Button>
              </Popconfirm>
            </Space>
          </div>
        )}

        {currentStatus === OfferStatuses.ACCEPTED && (
          <Space size={12}>
            <Typography.Text className="offer__info-xxl">
              <strong>Предложение принято и перевозчик выбран</strong>
            </Typography.Text>
            {/*<Tooltip*/}
            {/*  className="order__info-icon"*/}
            {/*  title="Организатор подготовит договор и свяжется с вами для подписания. Как только подписанные документы будут загружены в систему, здесь отобразится файл финального договора."*/}
            {/*>*/}
            {/*  <QuestionCircleOutlined />*/}
            {/*</Tooltip>*/}
          </Space>
        )}
      </Space>
      <SigningContractModal
        open={isModalOpen}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        loading={loading}
      />
    </Col>
  );
};

export default AgreementOfferCardFooter;

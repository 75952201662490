// Declines word 'год' depending on its quantity
export const getYearsDeclention = (value: number) => {
  let points = 'лет';
  const number = Number(value);

  const rounded = Math.abs(number) % 100;
  const amount = rounded % 10;
  if (amount === 1) {
    points = 'год';
  } else if (amount > 1 && amount < 5) {
    points = 'года';
  } else if (number === 0) {
    return 'лет';
  }
  return `${number} ${points}`;
};

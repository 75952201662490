import { BackTop, Breadcrumb, Button, Col, Row, Tooltip, Typography } from 'antd';
import { ContractStatus } from '../../Constants/Contract';
import { ExportOutlined, PrinterOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../Store/hooks';

interface ContractHeaderProps {
  title: string;
  role: string;
  contractId: number;
  contractNumber: string;
  contractStatus?: ContractStatus;
  showModal?: () => void;
}

const ContractHeader = (props: ContractHeaderProps) => {
  const { title, role, contractId, contractNumber, contractStatus, showModal } = props;

  const isVerified = useAppSelector((state) => state.user.confirmed);
  const isOrganizer = role === 'organizer';
  const isCancelAllowedByStatus = contractStatus !== ContractStatus.PAID && contractStatus !== ContractStatus.CANCELLED;

  return (
    <Row justify="space-between" align="bottom" gutter={[10, 16]} className="contract__header">
      <Col span={24}>
        <Row justify="space-between" align="bottom" gutter={[0, 16]}>
          <Col>
            <BackTop />
            <Breadcrumb className="order__breadcrumb" separator=">">
              <Breadcrumb.Item href="/">Главная страница</Breadcrumb.Item>
              <Breadcrumb.Item href={`/${role}/contracts`}>Договоры</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Typography.Text ellipsis>{title}</Typography.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col>
            <Typography.Text className="order__info">Номер договора:</Typography.Text>
            <Typography.Text className="order__info" strong>
              {contractNumber}
            </Typography.Text>
            <Tooltip className="order__info-icon" title="Номер договора нужен для обращения в поддержку">
              <QuestionCircleOutlined />
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col xxl={isOrganizer ? 16 : 24} xl={isOrganizer ? 13 : 24} lg={isOrganizer ? 16 : 24} span={24}>
        <Typography.Title level={2} ellipsis={{ tooltip: false }}>
          {title}
        </Typography.Title>
      </Col>
      {isOrganizer && (
        <Col span="auto">
          <Button
            size="large"
            icon={<ExportOutlined />}
            style={{ marginRight: 10 }}
            disabled={!isVerified}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_API_URL}/api/v1/agreement/agreements-csv/?agreementsIds=${contractId}`,
              );
            }}
          >
            Экспорт
          </Button>
          <Button
            size="large"
            icon={<PrinterOutlined />}
            disabled={!isVerified}
            onClick={() => {
              window.open(`${process.env.REACT_APP_API_URL}/api/v1/agreement/${contractId}/pdf/`);
            }}
          >
            Печать
          </Button>
        </Col>
      )}
      {isOrganizer && isCancelAllowedByStatus && (
        <Col span="auto">
          <Button size="large" onClick={showModal}>
            Договор не может быть исполнен
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default ContractHeader;

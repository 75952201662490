/* eslint-disable @typescript-eslint/no-explicit-any */
import { Resizable, ResizeCallbackData } from 'react-resizable';
import React from 'react';

export const ResizableTitle = (
  props: React.HTMLAttributes<any> & {
    onResize: React.ReactEventHandler<any> & ((e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void);
    onClick: () => void;
    width: number;
  },
) => {
  const { onResize, width, onClick, ...restProps } = props;

  if (!width) {
    return (
      <th style={restProps.style} className={restProps.className}>
        <div onClick={(...args) => onClick(...args)} style={{ background: 'inherit' }} {...restProps}></div>
      </th>
    );
  }

  let resizing = false;

  return (
    <Resizable
      width={width}
      height={0}
      onResizeStart={() => (resizing = true)}
      onResizeStop={() => {
        setTimeout(() => {
          resizing = false;
        }, 500);
      }}
      axis="x"
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      minConstraints={[100, 0]}
      maxConstraints={[700, 0]}
    >
      <th>
        <div
          onClick={(...args) => {
            if (!resizing && onClick) {
              onClick(...args);
            }
          }}
          {...restProps}
        ></div>
      </th>
    </Resizable>
  );
};

import { Col, Row, Typography } from 'antd';

interface SettingsItemProps {
  title: string;
  value: string | number;
}

const SettingsItem = (props: SettingsItemProps) => {
  const { title, value } = props;
  return (
    <div className="settings__item">
      <Row align="middle" justify="space-between">
        <Col sm={{ span: 15 }} lg={{ span: 15 }} xl={{ span: 15 }} xxl={{ span: 15 }} span={24}>
          <Typography.Text type="secondary" className="profile__info-large">
            {title}
          </Typography.Text>
        </Col>
        <Col
          sm={{ span: 8, offset: 1 }}
          lg={{ span: 8, offset: 1 }}
          xl={{ span: 8, offset: 1 }}
          xxl={{ span: 8, offset: 1 }}
          span={24}
        >
          <Typography.Text className="profile__info-large">{value}</Typography.Text>
        </Col>
      </Row>
    </div>
  );
};

export default SettingsItem;

import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Contract } from '../../Models/Contract';
import { Link } from 'react-router-dom';
import { defaultDateFormat } from '../../Constants/DateFormats';
import { formatPrice } from '../../Utils/formatPrice';
import dayjs from 'dayjs';

const parsedWidth = JSON.parse(localStorage.getItem('orgContracts')!);

export const initialColumns: ColumnsType<Contract> = [
  {
    key: 'agreementId',
    title: '№',
    dataIndex: 'agreementId',
    fixed: 'left',
    sorter: true,
    width: parsedWidth ? parsedWidth.agreementId : 120,
  },
  {
    key: 'cityFrom',
    title: 'Город отправки',
    dataIndex: 'cityFrom',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.cityFrom : 174,
  },
  {
    key: 'cityTo',
    title: 'Город доставки',
    dataIndex: 'cityTo',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.cityTo : 174,
  },
  {
    key: 'arrival',
    title: 'Дата доставки',
    dataIndex: 'arrival',
    ellipsis: true,
    sorter: true,
    render: (text) => dayjs(text).format(defaultDateFormat),
    width: parsedWidth ? parsedWidth.arrival : 165,
  },
  {
    key: 'price',
    title: 'Стоимость',
    dataIndex: 'price',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${formatPrice(+record.price)} ₽`,
    width: parsedWidth ? parsedWidth.price : 148,
  },
  {
    key: 'orderUserFriendlyId',
    title: 'Заказ',
    dataIndex: 'orderUserFriendlyId',
    sorter: true,
    render: (_, record) => (
      <Link to={`/organizer/orders/${record.orderId}`} target="_blank">
        <Button type="default">{record.orderUserFriendlyId}</Button>
      </Link>
    ),
    width: parsedWidth && parsedWidth.orderUserFriendlyId ? parsedWidth.orderUserFriendlyId : 125,
  },
  {
    key: 'cargoTypeNameWithParentName',
    title: 'Категория техники',
    dataIndex: 'cargoTypeNameWithParentName',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.cargoTypeNameWithParentName : 237,
  },
  {
    key: 'lastReportComment',
    title: 'Текст последнего отчета',
    dataIndex: 'lastReportComment',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.lastReportComment : 322,
  },
  {
    key: 'lastReport',
    title: 'Последний отчёт',
    dataIndex: 'lastReport',
    ellipsis: true,
    sorter: true,
    render: (text) => dayjs(text).format(defaultDateFormat),
    width: parsedWidth ? parsedWidth.lastReport : 170,
  },
  {
    key: 'title',
    title: 'Название заказа',
    dataIndex: 'title',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.title : 187,
  },
  {
    key: 'distance',
    title: 'Расстояние',
    dataIndex: 'distance',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (text) => `${text} км`,
    width: parsedWidth ? parsedWidth.distance : 155,
  },
  {
    key: 'organisationName',
    title: 'Заказчик',
    dataIndex: 'organisationName',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.organisationName : 232,
  },
  {
    key: 'machineName',
    title: 'Модель техники',
    ellipsis: true,
    dataIndex: 'machineName',
    sorter: true,
    width: parsedWidth ? parsedWidth.machineName : 235,
  },
  {
    key: 'machinePrice',
    title: 'Стоимость техники',
    dataIndex: 'machinePrice',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${formatPrice(+record.machinePrice)} ₽`,
    width: parsedWidth ? parsedWidth.machinePrice : 155,
  },
  {
    key: 'comments',
    title: 'Комментарий',
    dataIndex: 'comments',
    ellipsis: true,
    sorter: true,
    render: (text) => (text ? text : '—'),
    width: parsedWidth ? parsedWidth.comments : 322,
  },
  {
    key: 'weight',
    title: 'Вес',
    dataIndex: 'weight',
    align: 'right',
    sorter: true,
    render: (_, record) => `${record.weight} кг`,
    width: parsedWidth ? parsedWidth.weight : 104,
  },
  {
    key: 'length',
    title: 'Длина',
    dataIndex: 'length',
    align: 'right',
    sorter: true,
    render: (_, record) => `${record.length} м`,
    width: parsedWidth ? parsedWidth.length : 110,
  },
  {
    key: 'width',
    title: 'Ширина',
    dataIndex: 'width',
    align: 'right',
    sorter: true,
    render: (_, record) => `${record.width} м`,
    width: parsedWidth ? parsedWidth.width : 121,
  },
  {
    key: 'height',
    title: 'Высота',
    dataIndex: 'height',
    align: 'right',
    sorter: true,
    render: (_, record) => `${record.height} м`,
    width: parsedWidth ? parsedWidth.height : 113,
  },
];

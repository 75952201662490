import { API } from '../../Services/axios';
import { Alert, Button, Checkbox, Col, Divider, Form, InputNumber, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { OrderMarginCalculation } from '../../Models/Order';
import { formatPrice } from '../../Utils/formatPrice';
import { getRequiredFieldRule } from '../../Utils/Rules/order';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from './ModalTitle';

interface EditOrgMarginCalcSettingsModalProps extends ModalProps {
  orderMarginCalculation: OrderMarginCalculation;
  examplePrice: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const EditOrgMarginCalcSettingsModal = (props: EditOrgMarginCalcSettingsModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, orderMarginCalculation, examplePrice, setCurrent } = props;

  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    fuelPrice: orderMarginCalculation.fuelPrice,
    subscribeToFuelPrice: orderMarginCalculation.subscribeToFuelPrice,
    fuelConsumption: orderMarginCalculation.fuelConsumption,
    adbluePrice: orderMarginCalculation.adbluePrice,
    adblueConsumption: orderMarginCalculation.adblueConsumption,
    driverSalary: orderMarginCalculation.driverSalary,
    driverSalaryPercentage: orderMarginCalculation.driverSalaryPercentage,
    insuranceFee: orderMarginCalculation.insuranceFee,
    driverSalaryDay: orderMarginCalculation.driverSalaryDay,
    driverWorkingDay: orderMarginCalculation.driverWorkingDay,
    tyresPrice: orderMarginCalculation.tyresPrice,
    tyresChangeRange: orderMarginCalculation.tyresChangeRange,
    servicePrice: orderMarginCalculation.servicePrice,
    serviceRange: orderMarginCalculation.serviceRange,
    leasingPrice: orderMarginCalculation.leasingPrice,
    carPrice: orderMarginCalculation.carPrice,
    carUsageAge: orderMarginCalculation.carUsageAge,
    carUsageFee: orderMarginCalculation.carUsageFee,
    additionalFee: orderMarginCalculation.additionalFee,
    insuranceCargoPriceFee: orderMarginCalculation.insuranceCargoPriceFee,
    transportTaxes: orderMarginCalculation.transportTaxes,
    vatTaxes: orderMarginCalculation.vatTaxes,
    profitTaxes: orderMarginCalculation.profitTaxes,
    logisticianSalary: orderMarginCalculation.logisticianSalary,
    expenses: orderMarginCalculation.expenses,
    desiredProfit: orderMarginCalculation.desiredProfit,
  };

  const [fuelPrice, setFuelPrice] = useState(orderMarginCalculation.fuelPrice);
  const [updateFuelPriceLoading, setUpdateFuelPriceLoading] = useState<boolean>(false);
  const updateFuelPrice = () => {
    setUpdateFuelPriceLoading(true);
    setLoading(true);

    API.get(`/api/v1/profile/settings/update-fuel-price`)
      .then((response) => {
        form.setFieldValue('fuelPrice', response.data);
        form.validateFields();
        setFuelPrice(response.data);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setUpdateFuelPriceLoading(false);
        setLoading(false);
      });
  };

  const onCloseModal = () => {
    handleCancel();
    form.resetFields();
  };

  const onFinish = () => {
    form.validateFields();
    setLoading(true);

    const values = form.getFieldsValue();
    const settings = [
      {
        name: 'fuel_price',
        value: `${values.fuelPrice}`,
      },
      {
        name: 'subscribe_to_fuel_price ',
        value: `${(document.getElementById('subscribeToFuelPrice') as HTMLInputElement).checked}`,
      },
      {
        name: 'fuel_consumption',
        value: `${values.fuelConsumption}`,
      },
      {
        name: 'adblue_price',
        value: `${values.adbluePrice}`,
      },
      {
        name: 'adblue_consumption',
        value: `${values.adblueConsumption}`,
      },
      {
        name: 'driver_salary',
        value: `${values.driverSalary}`,
      },
      {
        name: 'driver_salary_percentage',
        value: `${values.driverSalaryPercentage}`,
      },
      {
        name: 'insurance_fee',
        value: `${values.insuranceFee}`,
      },
      {
        name: 'driver_salary_day',
        value: `${values.driverSalaryDay}`,
      },
      {
        name: 'driver_working_day',
        value: `${values.driverWorkingDay}`,
      },
      {
        name: 'tyres_price',
        value: `${values.tyresPrice}`,
      },
      {
        name: 'tyres_change_range',
        value: `${values.tyresChangeRange}`,
      },
      {
        name: 'service_price',
        value: `${values.servicePrice}`,
      },
      {
        name: 'service_range',
        value: `${values.serviceRange}`,
      },
      {
        name: 'leasing_price',
        value: `${values.leasingPrice}`,
      },
      {
        name: 'car_price',
        value: `${values.carPrice}`,
      },
      {
        name: 'car_usage_age',
        value: `${values.carUsageAge}`,
      },
      {
        name: 'car_usage_fee',
        value: `${values.carUsageFee}`,
      },
      {
        name: 'additional_fee',
        value: `${values.additionalFee}`,
      },
      {
        name: 'insurance_cargo_price_fee',
        value: `${values.insuranceCargoPriceFee}`,
      },
      {
        name: 'transport_taxes',
        value: `${values.transportTaxes}`,
      },
      {
        name: 'vat_taxes',
        value: `${values.vatTaxes}`,
      },
      {
        name: 'profit_taxes',
        value: `${values.profitTaxes}`,
      },
      {
        name: 'logistician_salary',
        value: `${values.logisticianSalary}`,
      },
      {
        name: 'expenses',
        value: `${values.expenses}`,
      },
      {
        name: 'desired_profit',
        value: `${values.desiredProfit}`,
      },
    ];

    const headers = { headers: { 'Content-Type': 'application/json' } };

    API.put(`/api/v1/profile/settings`, JSON.stringify(settings), headers)
      .then((response) => {
        handleCancel();
        window.location.search += window.location.href.includes('tab') ? '' : '?tab=4';
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Редактирование настроек калькулятора" handleCancel={onCloseModal} />}
      open={open}
      onCancel={onCloseModal}
      className="modal modal__scrollable"
      footer={
        <Row justify="end">
          <Button size="large" onClick={onCloseModal} className="modal__cancel-btn">
            Отмена
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Сохранить
          </Button>
        </Row>
      }
      centered
      width={645}
      closable={false}
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      <Form
        id="form"
        form={form}
        initialValues={initialValues}
        colon={false}
        layout="vertical"
        className="form"
        onFinish={onFinish}
      >
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4}>Расходы на топливо</Typography.Title>
          <Row gutter={[24, 24]} justify="space-between" align="bottom">
            <Col lg={12} span={24}>
              <Form.Item
                name="fuelPrice"
                label={<Typography.Text>Стоимость топлива (за 1 л), ₽</Typography.Text>}
                rules={getRequiredFieldRule('Поле обязательно для заполнения')}
              >
                <InputNumber
                  value={fuelPrice}
                  style={{ width: '100%' }}
                  controls={false}
                  size="large"
                  decimalSeparator=","
                  min={0}
                  max={9999999.99}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col lg={12} span={24} className="modal__action-btn">
              <Button
                size="large"
                style={{ width: '100%' }}
                type="primary"
                loading={updateFuelPriceLoading}
                onClick={() => updateFuelPrice()}
              >
                Заполнить из Росстат
              </Button>
            </Col>
            <Form.Item name="subscribeToFuelPrice">
              <Checkbox id="subscribeToFuelPrice" defaultChecked={orderMarginCalculation.subscribeToFuelPrice}>
                Всегда автоматически обновлять стоимость топлива из данных Росстат
              </Checkbox>
            </Form.Item>
          </Row>
          <Form.Item
            name="fuelConsumption"
            label={<Typography.Text>Средний расход топлива (на 100 км), л</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0.1} max={9999999.99} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="adbluePrice"
            label={<Typography.Text>Стоимость мочевины (за 1 л), ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="adblueConsumption"
            label={<Typography.Text>Средний расход мочевины (на 100 км), л</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0.1} max={9999999.99} maxLength={10} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Выплаты водителю
          </Typography.Title>
          <Form.Item
            name="driverSalary"
            label={<Typography.Text>Заработная плата водителя (за 1 км), ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="driverSalaryPercentage"
            label={<Typography.Text>Заработная плата водителя, % от оборота</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
          <Alert
            message="Калькулятор может учитывать один или оба параметра расчета заработной платы водителя одновременно. Если Вы используете только один из параметров, оставьте в другом значение 0."
            type="warning"
            showIcon
          />
          <Form.Item
            name="insuranceFee"
            label={<Typography.Text>Страховые взносы, %</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
          <Form.Item
            name="driverSalaryDay"
            label={<Typography.Text>Стоимость суточных водителя, ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="driverWorkingDay"
            label={<Typography.Text>Продолжительность рабочего дня водителя, ч</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={1} max={24} maxLength={3} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Затраты на автошины
          </Typography.Title>
          <Form.Item
            name="tyresPrice"
            label={<Typography.Text>Стоимость комплекта автошин, ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="tyresChangeRange"
            label={<Typography.Text>Интервал замены автошин, км*</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Затраты на ТО и ремонт
          </Typography.Title>
          <Form.Item
            name="servicePrice"
            label={<Typography.Text>Стоимость ТО, ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="serviceRange"
            label={<Typography.Text>Межсервисный пробег, км</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Стоимость лизинга
          </Typography.Title>
          <Form.Item
            name="leasingPrice"
            label={<Typography.Text>Ежемесячный платеж, ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Амортизация
          </Typography.Title>
          <Form.Item
            name="carPrice"
            label={<Typography.Text>Стоимость автомобиля, ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber
              controls={false}
              size="large"
              decimalSeparator=","
              min={0}
              max={99999999999.99}
              maxLength={14}
            />
          </Form.Item>
          <Form.Item
            name="carUsageAge"
            label={<Typography.Text>Период полезного применения, лет</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={100} maxLength={4} />
          </Form.Item>
          <Form.Item
            name="carUsageFee"
            label={<Typography.Text>Амортизационные отчисления (в год), ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Дополнительные расходы
          </Typography.Title>
          <Form.Item
            name="additionalFee"
            label={<Typography.Text>Дополнительные расходы, % от затрат</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
          <Form.Item
            name="insuranceCargoPriceFee"
            label={<Typography.Text>Страхование груза, % от стоимости груза</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Налоги
          </Typography.Title>
          <Form.Item
            name="transportTaxes"
            label={<Typography.Text>Транспортный налог (в год), ₽</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={9999999.99} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="vatTaxes"
            label={<Typography.Text>Ставка НДС, %</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
          <Form.Item
            name="profitTaxes"
            label={<Typography.Text>Ставка налога на прибыль, %</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Заработная плата логиста и постоянные расходы
          </Typography.Title>
          <Form.Item
            name="logisticianSalary"
            label={<Typography.Text>Заработная плата логиста, % от оборота</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
          <Form.Item
            name="expenses"
            label={<Typography.Text>Постоянные расходы, % от оборота</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
        </Space>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Планируемая маржинальность
          </Typography.Title>
          <Form.Item
            name="desiredProfit"
            label={<Typography.Text>Планируемая маржинальность, % от оборота</Typography.Text>}
            rules={getRequiredFieldRule('Поле обязательно для заполнения')}
          >
            <InputNumber controls={false} size="large" decimalSeparator="," min={0} max={99.99} maxLength={5} />
          </Form.Item>
        </Space>
        <Divider />
        <Typography.Text style={{ fontSize: 16 }}>
          При текущих расходах калькулятора перевозка по направлению <b>Москва - Санкт-Петербург</b> составит{' '}
          <b>{`${formatPrice(examplePrice)} ₽`}</b>.<br></br>
          Для расчета по другим направлениям перейдите в раздел{' '}
          <b>
            <u
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrent(5);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Расчет стоимости перевозки
            </u>
          </b>
          .
        </Typography.Text>
      </Form>
    </Modal>
  );
};

export default EditOrgMarginCalcSettingsModal;

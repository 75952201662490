import { Alert, Col, Row, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

const UnverifiedOrgAlert = () => {
  const message = (
    <Row justify="space-between">
      <Col flex="auto">
        <Typography.Text>
          Для получения доступа к полному функционалу системы Вам необходимо пройти процедуру подтверждения Организации.
        </Typography.Text>
      </Col>
      <Col flex="none">
        <Typography.Link href="/profile" underline strong>
          Подтвердить организацию в личном кабинете
        </Typography.Link>
      </Col>
    </Row>
  );

  return <Alert message={message} type="warning" showIcon icon={<ExclamationCircleFilled />} />;
};

export default UnverifiedOrgAlert;

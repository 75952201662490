import { ExclamationCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

const BlockedIcon = () => {
  return (
    <Tooltip title="Организация временно заблокирована">
      <ExclamationCircleFilled className="profile__blocked-icon" />
    </Tooltip>
  );
};

export default BlockedIcon;

import { ColumnsType } from 'antd/lib/table';
import { EmailTemplate } from '../../../../../../Models/EmailTemplate';
import { Typography } from 'antd';

export const getSystemMessagesTableColumns = (handleOpenEditModal: (record: EmailTemplate) => void) => {
  const columns: ColumnsType<EmailTemplate> = [
    {
      title: 'Шаблон',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 229,
    },
    {
      title: 'Тема письма',
      dataIndex: 'subject',
      key: 'subject',
      ellipsis: true,
      width: 212,
    },
    {
      title: 'Текст письма',
      dataIndex: 'body',
      key: 'body',
      ellipsis: true,
      width: 694,
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 198,
      render: (_, record) => (
        <Typography.Link onClick={() => handleOpenEditModal(record)} underline>
          Редактировать
        </Typography.Link>
      ),
    },
  ];

  return columns;
};

import { Card, Col, Row, Space, Typography } from 'antd';
import { Roles } from '../../../../../Constants/Profile';

interface ActiveProjectsCardProps {
  role: Roles;
  activeAgreementsCount: number;
  activeOrdersCount: number;
}

const ActiveProjectsCard = (props: ActiveProjectsCardProps) => {
  const { role, activeAgreementsCount, activeOrdersCount } = props;

  return (
    <Card title={<Typography.Title level={4}>Активные проекты</Typography.Title>} className="operator__full-width">
      <Space size={16} direction="vertical">
        {role === Roles.CUSTOMER && (
          <Row justify="space-between" className="operator__full-width">
            <Col>Созданные заказы</Col>
            <Col>{activeOrdersCount}</Col>
          </Row>
        )}
        <Row justify="space-between" className="operator__full-width">
          <Col>Активные договоры</Col>
          <Col>{activeAgreementsCount}</Col>
        </Row>
      </Space>
    </Card>
  );
};

export default ActiveProjectsCard;

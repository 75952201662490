import { Button, Card, Space, Typography } from 'antd';
import { ChangeHistoryEvents } from '../../../../../Constants/Operator';
import { Roles } from '../../../../../Constants/Profile';
import { useState } from 'react';
import OrgActionsModal from '../../../../../Components/Modals/OrgProfileActionsModal';

interface OrgActionsProps {
  role: Roles;
  locked: boolean;
  confirmed: boolean;
}

const OrgActions = (props: OrgActionsProps) => {
  const { role, confirmed, locked } = props;
  const [action, setAction] = useState<ChangeHistoryEvents>();
  const [open, setOpen] = useState<boolean>(false);

  const showModal = (action: ChangeHistoryEvents) => {
    setAction(action);
    setOpen(true);
  };
  const hideModal = () => setOpen(false);

  return (
    <Card title={<Typography.Title level={4}>Управление организацией</Typography.Title>}>
      <Space direction="vertical" size={16} className="operator__full-width">
        {role === Roles.DELIVERY && !confirmed && (
          <Button type="primary" size="large" onClick={() => showModal(ChangeHistoryEvents.VERIFIED)}>
            Подтвердить организацию
          </Button>
        )}
        {locked ? (
          <Button type="primary" size="large" onClick={() => showModal(ChangeHistoryEvents.UNBLOCKED)}>
            Разблокировать организацию
          </Button>
        ) : (
          <Button type="primary" size="large" onClick={() => showModal(ChangeHistoryEvents.BLOCKED)}>
            Блокировать организацию
          </Button>
        )}
      </Space>
      <OrgActionsModal action={action} open={open} handleCancel={hideModal} />
    </Card>
  );
};

export default OrgActions;

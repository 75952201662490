import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { FilterOutlined } from '@ant-design/icons';
import { InputRef, Typography } from 'antd';
import { OrgTypes } from '../../../../../../Constants/OrgTypes';
import { Organization } from '../../../../../../Models/Organizations';
import { Roles } from '../../../../../../Constants/Profile';
import { StringFilter, filterString } from '../../../../../../Components/Filters/StringFilter';
import { getOrganizationPropertyType } from '../../../../../../Utils/getOrganizationPropertyType';
import { useRef, useState } from 'react';

export const getOrganizationsTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
) => {
  type DataIndex = keyof Organization;

  const confirmedColValue = (record) =>
    record.role === Roles.CUSTOMER ? 'Не требуется' : record.confirmed ? 'Подтверждена' : 'Не подтверждена';

  const columns: ColumnsType<Organization> = [
    {
      title: 'Организация',
      dataIndex: 'shortName',
      sorter: (a, b) => a.shortName.localeCompare(b.shortName),
      key: 'shortName',
      width: 260,
      ellipsis: true,
      render: (_, record) => (
        <Typography.Link href={`/operator/organizations/${record.id}`} underline>
          {record.shortName}
        </Typography.Link>
      ),
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      width: 116,
      ellipsis: true,
      sorter: (a, b) => a.inn.localeCompare(b.inn),
      key: 'inn',
    },
    {
      title: 'Тип организации',
      dataIndex: 'type',
      width: 185,
      ellipsis: true,
      sorter: (a, b) => a.type.localeCompare(b.type),
      key: 'type',
      render: (_, record) => (record.type === OrgTypes.LEGAL ? 'Юридическое лицо' : 'ИП'),
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      width: 153,
      ellipsis: true,
      sorter: (a, b) => a.role.localeCompare(b.role),
      key: 'role',
      render: (_, record) => {
        if (record.role.includes(',')) {
          const roles = record.role.split(', ');
          return roles.map((role) => (role === Roles.DELIVERY ? 'Организатор' : 'Заказчик')).join(', ');
        } else {
          return record.role === Roles.DELIVERY ? 'Организатор' : 'Заказчик';
        }
      },
    },
    {
      title: 'Подтверждение',
      dataIndex: 'confirmed',
      width: 193,
      ellipsis: true,
      sorter: (a, b) => {
        const statusA = confirmedColValue(a);
        const statusB = confirmedColValue(b);
        return statusA.localeCompare(statusB);
      },
      key: 'confirmed',
      render: (_, record) => confirmedColValue(record),
    },
    {
      title: 'Статус',
      dataIndex: 'locked',
      width: 185,
      ellipsis: true,
      sorter: (a, b) => {
        if (!b.locked) {
          return +1;
        }
        if (!a.locked) {
          return -1;
        }
        return Number(b.locked) - Number(a.locked);
      },
      key: 'locked',
      render: (_, record) => (record.locked ? 'Заблокирована' : 'Активна'),
    },
    {
      title: 'Эл. почта',
      dataIndex: 'email',
      width: 'auto',
      ellipsis: true,
      sorter: (a, b) => {
        if (!a.email) {
          return +1;
        }
        if (!b.email) {
          return -1;
        }
        return a.email.localeCompare(b.email);
      },
      key: 'email',
      render: (text) => (text ? text : '—'),
    },
  ];
  const [searchText, setSearchText] = useState<string>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys.toString());
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
  };

  const searchInput = useRef<InputRef>(null);

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Organization> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (_, record) => {
      let result = true;
      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] !== null) {
          if (key === 'type') {
            const typeValue = record.type === OrgTypes.LEGAL ? 'Юридическое лицо' : 'ИП';
            result &&= filterString(typeValue, filteredInfo[key]![0]);
          } else if (key === 'locked') {
            const lockedValue = record.locked ? 'Заблокирована' : 'Активена';
            result &&= filterString(lockedValue, filteredInfo[key]![0]);
          } else if (key === 'confirmed') {
            const confirmedValue = confirmedColValue(record);
            result &&= filterString(confirmedValue, filteredInfo[key]![0]);
          } else if (key === 'role') {
            const typeValue = () => {
              if (record.role.includes(',')) {
                const roles = record.role.split(', ');
                return roles.map((role) => (role === Roles.DELIVERY ? 'Организатор' : 'Заказчик')).join(', ');
              } else {
                return record.role === Roles.DELIVERY ? 'Организатор' : 'Заказчик';
              }
            };
            result &&= filterString(typeValue(), searchText);
          } else if (`${getOrganizationPropertyType(key)}` === 'string')
            result &&= filterString(record[key], filteredInfo[key]![0]);
        }
      });
      return result;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<Organization> = columns.map((col, index) => {
    const dataIndex = col.key! as DataIndex;
    return {
      ...col,
      ...getColumnSearchProps(dataIndex),
      render: col.render,
    };
  });

  return filterableColumns;
};

import { API } from '../../Services/axios';
import { Card, Col, Row, Space, Typography } from 'antd';
import { ChangeHistoryEvents } from '../../Constants/Operator';
import { CheckCircleOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { HistoryItem } from '../../Models/Operator';
import { convertToMscTime } from '../../Utils/convertToMscTime';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../Modals/ErrorModal';
import Loader from '../Loader/Loader';

const message = (event: ChangeHistoryEvents) => {
  switch (event) {
    case ChangeHistoryEvents.BLOCKED:
      return 'Ваша организация временно заблокирована';
    case ChangeHistoryEvents.UNBLOCKED:
      return 'Ваша организация разблокирована';
    case ChangeHistoryEvents.VERIFIED:
      return 'Ваша организация подтверждена в системе';
    case ChangeHistoryEvents.UNVERIFIED:
      return '';
    default:
      return '';
  }
};

const icon = (event: ChangeHistoryEvents) => {
  switch (event) {
    case ChangeHistoryEvents.BLOCKED:
      return <ExclamationCircleOutlined className="profile__history-icon" />;
    case ChangeHistoryEvents.UNBLOCKED:
      return <CheckCircleOutlined className="profile__history-icon" />;
    case ChangeHistoryEvents.VERIFIED:
      return <CheckCircleOutlined className="profile__history-icon" />;
    case ChangeHistoryEvents.UNVERIFIED:
      return <ExclamationCircleOutlined className="profile__history-icon" />;
    default:
      return <InfoCircleOutlined className="profile__history-icon" />;
  }
};

const ProfileHistoryCard = () => {
  const { showErrorModal } = useErrorModal();
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/profile/history')
      .then((response) => {
        setHistory(response.data);
        setLoading(false);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  const filteredHistory = history.filter((item) => item.event !== ChangeHistoryEvents.CREATED);

  const comment = (json) => JSON.parse(json).comment;

  const changeHistoryItem = (item: HistoryItem) => (
    <Space direction="vertical" size={4} className="operator__full-width" key={item.id}>
      <Row gutter={[4, 8]} justify="space-between">
        <Col span={2}>{icon(item.event)}</Col>
        <Col sm={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }} xxl={{ span: 16 }} span={20}>
          <Typography.Text strong className="profile__info-large">
            {message(item.event)}
          </Typography.Text>
        </Col>
        <Col sm={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} span={24}>
          <Typography.Text className="profile__info-large"> {`${convertToMscTime(item.created)} мск`}</Typography.Text>
        </Col>
      </Row>
      {comment(item.description) && (
        <Row gutter={[4, 0]}>
          <Col span={22} offset={2}>
            <Typography.Text className="profile__info-large">Причина: {comment(item.description)}</Typography.Text>
          </Col>
        </Row>
      )}
    </Space>
  );

  if (loading || !history) return <Loader />;

  return (
    <Card title={<Typography.Title level={3}>История изменений</Typography.Title>}>
      <Space direction="vertical" size={32} className="operator__full-width">
        {filteredHistory.length > 0 ? (
          filteredHistory.map((item) => changeHistoryItem(item))
        ) : (
          <Typography.Text>У вашей организации еще нет истории изменений</Typography.Text>
        )}
      </Space>
    </Card>
  );
};

export default ProfileHistoryCard;

import { chain } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { initialColumns } from '../InitialStates/OrgOrdersInitialState';

const preSelectedColKeys = [
  'userFriendlyId',
  'cargoTypeNameWithParentName',
  'cityFrom',
  'cityTo',
  'distance',
  'dateArrival',
  'price',
];

const presetColumns = chain(preSelectedColKeys)
  .toPairs()
  .map((i) => initialColumns[i[0]])
  .value();

export const orgOrdersColsSlice = createSlice({
  name: 'orgOrdersCols',
  initialState: presetColumns,
  reducers: {
    getUpdatedOrgColumns: (state, action) => {
      const columns = action.payload;
      return columns;
    },
  },
});

export const { getUpdatedOrgColumns } = orgOrdersColsSlice.actions;

export default orgOrdersColsSlice.reducer;

import { API } from '../../../../Services/axios';
import { Col, Row, Tabs, Typography } from 'antd';
import { OfferStatuses } from '../../../../Constants/OfferStatuses';
import { OffersListItem } from '../../../../Models/Offer';
import { OrderStatus } from '../../../../Constants/Order';
import { formatPrice } from '../../../../Utils/formatPrice';
import { tabsItems } from './Component/tabs-items';
import { updateOrganisationId } from '../../../../Store/Slices/organizationProfileModalSlice';
import { useAppDispatch } from '../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import Loader from '../../../../Components/Loader/Loader';
import OrganizaionInfoModal from '../../../../Components/Modals/OrganizationInfoModal';

interface OffersProps {
  orderId: number;
  agreementId: number | undefined;
  orderStatus: OrderStatus;
  price: number;
}

const Offers = (props: OffersProps) => {
  const { showErrorModal } = useErrorModal();
  const { orderId, orderStatus, price, agreementId } = props;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<OffersListItem[]>([]);
  const [dataWithAgreements, setDataWithAgreements] = useState<OffersListItem[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = (organisationId: number) => {
    dispatch(updateOrganisationId(organisationId));
    setOpenModal(true);
  };
  const hideModal = () => setOpenModal(false);

  useEffect(() => {
    if (orderId) {
      setLoading(true);

      API.get(`/api/v1/offer/get-offers-list-for-customer/${orderId}`)
        .then((response) => setData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
      API.get(`/api/v1/offer/get-offers-with-agreements-list/${orderId}`)
        .then((response) => setDataWithAgreements(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  }, [orderId]);

  const dataWithAgreementsCounter = dataWithAgreements.filter(
    (offer) =>
      offer.status !== OfferStatuses.CANCELLED &&
      offer.status !== OfferStatuses.CANCELLED_BY_CUSTOMER &&
      offer.status !== OfferStatuses.CANCELLED_BY_DELIVERY &&
      offer.status !== OfferStatuses.OUT_OF_VALIDITY_DATE,
  ).length;

  const dataWithAgreementsLoaded = dataWithAgreements.filter(
    (offer) => offer.status === OfferStatuses.AGREEMENT_LOADED,
  );

  return (
    <Row justify="space-between">
      <Col className="order__subtitle">
        <Typography.Title level={3}>Предложения организаторов</Typography.Title>
      </Col>
      <Col className="order__subtitle">
        <Typography.Text className="order__info order__info-large">
          {dataWithAgreementsLoaded[0] ? 'Стоимость перевозки:' : 'Рассчетная стоимость:'}
        </Typography.Text>
        <Typography.Text className="order__info-large" strong>
          {dataWithAgreementsLoaded[0]
            ? `${formatPrice(dataWithAgreementsLoaded[0].price)} ₽`
            : `${formatPrice(price)} ₽`}
        </Typography.Text>
      </Col>

      <Col span={24}>
        {loading ? (
          <Loader />
        ) : (
          <Tabs
            defaultActiveKey={dataWithAgreementsCounter > 0 ? '2' : '1'}
            items={tabsItems(data, dataWithAgreements, orderStatus, agreementId, showModal)}
            className="order__tabs"
          />
        )}
      </Col>
      <OrganizaionInfoModal title="Данные об организаторе перевозки" open={openModal} handleCancel={hideModal} />
    </Row>
  );
};

export default Offers;

import { API } from '../../../../Services/axios';
import { Col, Row, Table, Typography } from 'antd';
import { Contract } from '../../../../Models/Contract';
import { getContractsTableColumns } from './Component/columns';
import { getContractsTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';

const ContractsTable = () => {
  const { showErrorModal } = useErrorModal();
  const [data, setData] = useState<{ [key: string]: Contract[] }>({ '0': [] });
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';
  const [count, setCount] = useState([]);

  const restartTable = () => {
    setLoading(true);
    API.get(`/api/v1/operator/agreements-list?page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`)
      .then((response) => {
        // @ts-ignore
        setCount(Object.keys(response.data));
        setData(response.data);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setLoading(true);
    restartTable();
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
    restartTable();
  }, [page, size, sort, sortField]);

  const sizePage = localStorage.getItem('sizePage');

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, sortInfo, setSortInfo, handleFilter } =
    getContractsTableFilter();
  const columns = getContractsTableColumns(filteredInfo, setFilteredInfo);

  useEffect(() => {
    if (paginationInfo?.current) {
      setSearchParams((params) => {
        params.set('page', paginationInfo.current ? paginationInfo.current.toString() : '');
        return searchParams;
      });
    }
    if (paginationInfo?.pageSize) {
      setSearchParams((params) => {
        params.set('size', paginationInfo.pageSize ? paginationInfo.pageSize.toString() : '');
        return searchParams;
      });
    }
    if (sortInfo?.sortOrder) {
      setSearchParams((params) => {
        params.set('sort', sortInfo.sortOrder ? sortInfo.sortOrder.toString() : '');
        return searchParams;
      });
    }
    if (sortInfo?.sortField) {
      setSearchParams((params) => {
        params.set('sortField', sortInfo.sortField ? sortInfo.sortField.toString() : '');
        return searchParams;
      });
    }
  }, [paginationInfo, sortInfo]);
  return (
    <div>
      <Row className="toolbar__title" justify="space-between">
        <Col>
          <Typography.Title level={2}>Договоры</Typography.Title>
        </Col>
      </Row>
      <div>
        <Table
          bordered
          rowKey={'agreementId'}
          className="table__organizations"
          scroll={{ x: 1331 }}
          loading={loading}
          columns={columns}
          dataSource={data[count[0]]}
          locale={tableLocaleSettings}
          pagination={tablePagingSettings(count[0], sizePage)}
          onChange={handleFilter}
        />
      </div>
    </div>
  );
};

export default ContractsTable;

import { API } from '../../Services/axios';
import { Button, Checkbox, Form, Modal, Rate, Row, Space } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from './ModalTitle';
import TextArea from 'antd/lib/input/TextArea';

interface CaclFeedBackModalProps extends ModalProps {
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const CaclFeedBackModal = (props: CaclFeedBackModalProps) => {
  const { open, handleCancel, setReload } = props;
  const { showErrorModal } = useErrorModal();

  const [formFeedBack] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const desc = ['Ужасно', 'Плохо', 'Нормально', 'Хорошо', 'Отлично'];
  const [value, setValue] = useState(0);

  const onFinish = () => {
    setLoading(true);
    const values = formFeedBack.getFieldsValue();

    const dto = {
      comments: values.comments,
      feedback: values.feedback ?? 0,
      disableFeedback: (document.getElementById('disableFeedback') as HTMLInputElement).checked,
    };

    const headers = { headers: { 'Content-Type': 'application/json' } };

    API.post(`/api/v1/calculator/feedback`, dto, headers)
      .then((response) => {
        formFeedBack.resetFields();
        handleCancel();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Оцените расчет калькулятора" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form id="formEditDefault" form={formFeedBack} colon={false} layout="vertical" className="form">
          <Form.Item name="feedback" label="На сколько точным оказался расчёт калькулятора?">
            <Rate tooltips={desc} allowClear={false} onChange={setValue} value={value} style={{ fontSize: 60 }} />
          </Form.Item>
          <Form.Item name="comments" label="Поделитесь впечатлением">
            <TextArea size="large" maxLength={1000} showCount className="form__comment"></TextArea>
          </Form.Item>
          <Form.Item name="disableFeedback">
            <Checkbox id="disableFeedback">Больше не показывать это сообщение</Checkbox>
          </Form.Item>
        </Form>
        <Space>
          <Row>
            <Button size="large" className="modal__cancel-btn" onClick={handleCancel}>
              Отмена
            </Button>
            <Button type="primary" size="large" form="form" onClick={onFinish} loading={loading}>
              Отправить
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default CaclFeedBackModal;

import { createSlice } from '@reduxjs/toolkit';

export const claimIdSlice = createSlice({
  name: 'claimId',
  initialState: {
    claimId: null,
  },
  reducers: {
    updateClaimId: (state, action) => {
      return {
        ...state,
        claimId: action.payload,
      };
    },
  },
});

export const { updateClaimId } = claimIdSlice.actions;

export default claimIdSlice.reducer;

import { API } from '../../Services/axios';
import { Card, Checkbox, Col, Row, Space, Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { Profile } from '../../Models/Profile';
import { Roles } from '../../Constants/Profile';
import { getMaskedPhoneNumberInOrder } from '../../Utils/getPhoneMasked';
import { useAppSelector } from '../../Store/hooks';
import { useErrorModal } from '../Modals/ErrorModal';
import EditUserProfileModal from '../Modals/EditUserProfileModal';
import ProfileItem from './ProfileItem';

interface PersonalInfoCardProps {
  profileData: Profile;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const PersonalInfoCard = (props: PersonalInfoCardProps) => {
  const { showErrorModal } = useErrorModal();
  const { profileData, setReload } = props;
  const role = useAppSelector((state) => state.user.role);

  const [areMessagesChecked, setAreMessagesChecked] = useState<boolean>(profileData.notificationConfirmed);
  const handleMessageCheckbox = () => {
    if (areMessagesChecked) {
      API.put('/api/v1/profile/unsubscribe')
        .then((response) => setAreMessagesChecked(false))
        .catch((error) => showErrorModal({ error }));
    } else {
      API.put('/api/v1/profile/subscribe')
        .then((response) => setAreMessagesChecked(true))
        .catch((error) => showErrorModal({ error }));
    }
  };

  const [areSystemMessagesChecked, setAreSystemMessagesChecked] = useState<boolean>(
    profileData.systemNotificationConfirmed,
  );
  const handleSystemMessageCheckbox = () => {
    if (areSystemMessagesChecked) {
      API.put('/api/v1/profile/unsubscribe/system-messages')
        .then((response) => setAreSystemMessagesChecked(false))
        .catch((error) => showErrorModal({ error }));
    } else {
      API.put('/api/v1/profile/subscribe/system-messages')
        .then((response) => setAreSystemMessagesChecked(true))
        .catch((error) => showErrorModal({ error }));
    }
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const hideModal = () => setOpenModal(false);
  const showModal = () => setOpenModal(true);

  const isAdmin = role === Roles.ADMIN;
  const isAdminOrOperator = isAdmin || role === Roles.OPERATOR;

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title level={3}>Личные данные</Typography.Title>
      </Col>
      {!isAdmin && (
        <Col>
          <Typography.Link onClick={showModal} underline>
            Редактировать
          </Typography.Link>
        </Col>
      )}
    </Row>
  );
  return (
    <Row gutter={[0, 40]}>
      <Card title={cardTitle} style={{ width: '100%' }}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <ProfileItem title="Фамилия" value={profileData.surname} />
              <ProfileItem title="Имя" value={profileData.name} />
              <ProfileItem title="Отчество" value={profileData.patronymic ?? '—'} />
              <ProfileItem title="Телефон" value={getMaskedPhoneNumberInOrder(profileData.phone)} />
              <ProfileItem title="Электронная почта" value={profileData.email} />
            </Space>
          </Col>
        </Row>
        <EditUserProfileModal
          open={openModal}
          handleCancel={hideModal}
          profileData={profileData}
          setReload={setReload}
        />
      </Card>
      {!isAdminOrOperator && (
        <Card title={<Typography.Title level={3}>E-mail уведомления</Typography.Title>} style={{ width: '100%' }}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Typography.Text type="secondary" className="profile__info-large">
                Мы хотим, чтобы Вы получали только полезные письма.
              </Typography.Text>
              <br />
              <Typography.Text type="secondary" className="profile__info-large">
                Вы можете управлять рассылками, которые приходят на электронную почту, которую Вы указали при
                регистрации в системе.
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Space direction="vertical" size={18} style={{ width: '100%' }}>
                <Space size={12}>
                  <Checkbox checked={areMessagesChecked} onChange={handleMessageCheckbox} />
                  <Typography.Text className="profile__info-large">
                    Согласен на получение информационных сообщений на email
                  </Typography.Text>
                </Space>
                <Space size={12}>
                  <Checkbox checked={areSystemMessagesChecked} onChange={handleSystemMessageCheckbox} />
                  <Typography.Text className="profile__info-large">
                    Согласен на получение системных уведомлений на email
                  </Typography.Text>
                </Space>
              </Space>
            </Col>
          </Row>
        </Card>
      )}
    </Row>
  );
};

export default PersonalInfoCard;

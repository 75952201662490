import { API } from '../../../../Services/axios';
import { Card, Col, Row, Space, Typography } from 'antd';
import { Contract } from '../../../../Models/Contract';
import { LinkOutlined } from '@ant-design/icons';
import { defaultDateFormat } from '../../../../Constants/DateFormats';
import { formatPrice } from '../../../../Utils/formatPrice';
import { updateOrganisationId } from '../../../../Store/Slices/organizationProfileModalSlice';
import { useAppDispatch } from '../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import Adresses from '../../../../Components/Adresses/Adresses';
import BlockedRibbon from '../../../../Components/Blocked/BlockedRibbon';
import CargoDimensions from '../../../../Components/CargoDimensions/CargoDimensions';
import ContractHeader from '../../../../Components/Contract/ContractHeader';
import OrderAttachments from '../../../../Components/Order/OrderAttachments';
import OrganizaionInfoModal from '../../../../Components/Modals/OrganizationInfoModal';
import Report from './Report/Report';
import dayjs from 'dayjs';

const ContractDetails = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = (organisationId: number) => {
    dispatch(updateOrganisationId(organisationId));
    setOpenModal(true);
  };
  const hideModal = () => setOpenModal(false);

  const [data, setData] = useState<Contract>();
  useEffect(() => {
    API.get(`/api/v1/agreement/card/${id}`)
      .then((response) => setData(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  const organisation = data && (
    <Space direction="vertical">
      <Typography.Text className="order__info" type="secondary" strong>
        Организатор
      </Typography.Text>
      <Typography.Link className="order__info-large" onClick={() => showModal(data.userId)} underline>
        {data.organisationName}
      </Typography.Link>
    </Space>
  );

  return (data && (
    <Row justify="center" className="contract contract__container" gutter={[24, 32]}>
      <Col lg={{ span: 24 }} span={24}>
        <ContractHeader
          title={data.title}
          role="customer"
          contractId={data.agreementId}
          contractNumber={data.agreementUserFriendlyId}
        />
      </Col>
      <Col lg={{ span: 10, order: 0 }} span={24} order={1}>
        <Card className="contract__card" title={<Typography.Title level={4}>Информация о заказе</Typography.Title>}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Планируемая дата доставки
                </Typography.Text>
                <Typography.Text className="order__info-large" strong>
                  {dayjs(data.arrival).format(defaultDateFormat)}
                </Typography.Text>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Стоимость услуги по договору
                </Typography.Text>
                <Typography.Text className="order__info-large" strong>
                  {`${formatPrice(data.price)} ₽`}
                </Typography.Text>
              </Space>
            </Col>

            {/*<Col span={24}>*/}
            {/*  <Space direction="vertical" size={16}>*/}
            {/*    <Typography.Text className="order__info" type="secondary" strong>*/}
            {/*      Файл подписанного договора*/}
            {/*    </Typography.Text>*/}
            {/*    <Space size={10}>*/}
            {/*      <FileOutlined className="offer__file-icon" />*/}
            {/*      <Typography.Link className="order__info-large" href={data.finalAgreementFile[0].link}>*/}
            {/*        {data.finalAgreementFile[0].name}*/}
            {/*      </Typography.Link>*/}
            {/*    </Space>*/}
            {/*    <Typography.Text className="order__contract-text">*/}
            {/*      Договор создан и подписан на основании предложения и проекта договора к заказу&nbsp;*/}
            {/*      <Typography.Link href={`/customer/orders/${data.orderId}`} className="order__info" underline>*/}
            {/*        {data.title}*/}
            {/*      </Typography.Link>*/}
            {/*    </Typography.Text>*/}
            {/*  </Space>*/}
            {/*</Col>*/}

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Категория техники
                </Typography.Text>
                <Typography className="order__info-large">{data.cargoTypeNameWithParentName}</Typography>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Модель техники
                </Typography.Text>
                {data.specificationLink ? (
                  <Space size={12} align="center">
                    <Typography.Link href={data.specificationLink} className="contract__info-large" underline>
                      {data.machineName}
                    </Typography.Link>
                    <Typography.Link href={data.specificationLink}>
                      <LinkOutlined />
                    </Typography.Link>
                  </Space>
                ) : (
                  <Typography.Text className="contract__info-large">{data.machineName}</Typography.Text>
                )}
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Стоимость перевозимой техники
                </Typography.Text>
                <Typography className="order__info-large">{`${formatPrice(data.machinePrice)} ₽`}</Typography>
              </Space>
            </Col>

            <CargoDimensions height={data.height} weight={data.weight} width={data.width} length={data.length} />

            <Adresses
              from={data.addressFrom}
              to={data.addressTo}
              pointFrom={data.pointFrom.split(',').map((point) => {
                return +point;
              })}
              pointTo={data.pointTo.split(',').map((point) => {
                return +point;
              })}
            />

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Рассчетное расстояние
                </Typography.Text>
                <Typography className="order__info-large">{`${data.distance} км`}</Typography>
              </Space>
            </Col>

            <Col span={24}>
              {data.customerOrganisationLocked ? (
                <BlockedRibbon isOffer={false}>{organisation}</BlockedRibbon>
              ) : (
                organisation
              )}
            </Col>

            <OrderAttachments files={data.orderFiles} photos={data.orderPhotos} comment={data.comments} />
          </Row>
        </Card>
        <OrganizaionInfoModal title="Данные об организаторе перевозки" open={openModal} handleCancel={hideModal} />
      </Col>
      <Col lg={{ span: 14 }} span={24}>
        <Report contractId={+id!} />
      </Col>
    </Row>
  ))!;
};

export default ContractDetails;

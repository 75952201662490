import { API } from '../../../../../Services/axios';
import { SystemMessage } from '../../../../../Models/SystemMessage';
import { Table } from 'antd';
import { getSystemMessagesTableColumns } from './Component/columns';
import { sortBy } from 'lodash';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import EditSystemMessageModal from '../Modals/EditSystemMessageModal';

const SystemMessagesTable = () => {
  const { showErrorModal } = useErrorModal();
  const [data, setData] = useState<SystemMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/messages')
      .then((response) => setData(sortBy(response.data, 'id')))
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  }, [reload]);

  const initialRecord = { id: 0, roles: ['', ''], shortName: '', message: '' };

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [record, setRecord] = useState<SystemMessage>(initialRecord);
  const handleOpenEditModal = (record: SystemMessage) => {
    setOpenEditModal(true);
    setRecord(record);
  };
  const handleHideDeleteModal = () => {
    setOpenEditModal(false);
    setRecord(initialRecord);
  };

  const columns = getSystemMessagesTableColumns(handleOpenEditModal);

  return (
    <div style={{ background: '#fff' }}>
      <div>
        <Table
          bordered
          rowKey={'id'}
          className="table__rates"
          scroll={{ x: 1300 }}
          loading={loading}
          columns={columns}
          dataSource={data}
          locale={tableLocaleSettings()}
          pagination={tablePagingSettings}
        />
        <EditSystemMessageModal
          open={openEditModal}
          handleCancel={handleHideDeleteModal}
          record={record}
          setReload={setReload}
        />
      </div>
    </div>
  );
};

export default SystemMessagesTable;

import { Typography } from 'antd';

export const enum OrganizationsTableTabs {
  ALL = 'ALL',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  CUSTOMERS = 'CUSTOMERS',
}

interface EmptyMessageProps {
  tabName: OrganizationsTableTabs;
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { tabName } = props;

  const emptyText = () => {
    switch (tabName) {
      case OrganizationsTableTabs.ALL:
        return 'Здесь будут отображаться все Организации, которые зарегестрированы на платформе Верум Логистика';
      case OrganizationsTableTabs.UNVERIFIED:
        return 'Здесь будут отображаться неподтвержденные Организации организаторов, зарегистрированных на платформе Верум Логистика';
      case OrganizationsTableTabs.VERIFIED:
        return 'Здесь будут отображаться подтвержденные Организации организаторов, зарегистрированных на платформе Верум Логистика';
      case OrganizationsTableTabs.CUSTOMERS:
        return 'Здесь будут отображаться Организации заказчиков, зарегистрированных на платформе Верум Логистика';
    }
  };

  return <Typography.Text>{emptyText()}</Typography.Text>;
};

export default EmptyMessage;

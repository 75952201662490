import { CascaderOptionType, Category } from '../Models/Order';
import { remove, sortBy } from 'lodash';

// Reorganizes categories by combining child categories
// and adding them as children of the corresponding parent category
// Child category 'прочее' is always adding to the end of chils categories array
export const reorganizeCategories = (categoriesList: Category[]) => {
  const parentCategories = categoriesList.filter((category) => !Object.hasOwn(category, 'parentId'));

  const reorganizedCategories: CascaderOptionType[] = parentCategories.map((parentCategory) => {
    const childCategories = sortBy(
      categoriesList.filter((category) => category.parentId === parentCategory.id),
      'title',
    );

    const othersType = childCategories.find((category) => category.title.toLowerCase() === 'прочее'.toLowerCase());

    const reorderedChildCategories = othersType
      ? remove(childCategories, (item) => item.title.toLowerCase() !== 'прочее'.toLowerCase()).concat(othersType)
      : childCategories;

    const mappedChildren = reorderedChildCategories.map((child) => ({
      value: child.id,
      label: child.title,
    }));

    return {
      value: parentCategory.id,
      label: parentCategory.title,
      children: mappedChildren,
    };
  });

  return reorganizedCategories.filter((category) => category.children && category.children.length > 0);
};

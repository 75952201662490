import { API } from '../../Services/axios';
import { Avatar, Button, Popconfirm, Space, Upload } from 'antd';
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Dispatch, SetStateAction } from 'react';
import { beforeUploadAvatar } from '../../Utils/beforeUpload';
import { useErrorModal } from '../Modals/ErrorModal';
import ImgCrop from 'antd-img-crop';
import type { UploadProps } from 'antd/lib/upload/interface';

interface ProfileAvatarProps {
  avatarLink: string | undefined;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const ProfileAvatar = (props: ProfileAvatarProps) => {
  const { showErrorModal } = useErrorModal();
  const { avatarLink, setReload } = props;

  const uploadProps: UploadProps = {
    name: 'avatar',
    accept: '.png,.jpg,.jpeg,.heic',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    customRequest: ({ file, onSuccess }) =>
      setTimeout(() => {
        onSuccess!('ok');
      }, 0),
  };

  const onFinish = (value) => {
    const formData = new FormData();
    formData.append('file', value);

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    API.post('/api/v1/profile/avatar', formData, headers)
      .then((response) => setReload(true))
      .catch((error) => showErrorModal({ error }));
  };

  const deleteAvatar = () => {
    API.delete('/api/v1/profile/avatar')
      .then((response) => setReload(true))
      .catch((error) => showErrorModal({ error }));
  };

  return (
    <Space direction="vertical" size={8} className="profile__avatar">
      <Avatar shape="square" icon={<UserOutlined />} src={avatarLink} size={140} />
      <Space.Compact block style={{ width: '100%' }}>
        <ImgCrop
          modalClassName="avatar"
          rotate
          modalOk="Сохранить"
          modalCancel="Отменить"
          modalTitle="Изменить аватар"
          onModalOk={onFinish}
          beforeCrop={beforeUploadAvatar}
          modalWidth={645}
          minZoom={-1}
          cropperProps={{ aspect: 1, restrictPosition: false }}
        >
          <Upload {...uploadProps}>
            <Button icon={<EditOutlined />} size="large" />
          </Upload>
        </ImgCrop>
        {avatarLink && (
          <Popconfirm
            placement="bottom"
            title={'Удалить аватар?'}
            getPopupContainer={(trigger) => trigger.parentElement!}
            onConfirm={deleteAvatar}
            okText="Подтвердить"
            showCancel={false}
            okButtonProps={{ size: 'middle' }}
            cancelButtonProps={{ size: 'middle' }}
          >
            <Button icon={<DeleteOutlined />} size="large" />
          </Popconfirm>
        )}
      </Space.Compact>
    </Space>
  );
};

export default ProfileAvatar;

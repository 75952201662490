import { API } from '../../../../../Services/axios';
import { FilterValue } from 'antd/lib/table/interface';
import { OrderTableProps, OrdersProps } from '../../../../../Models/Order';
import { Tabs } from 'antd';
import { getOrdersTabsItems } from './Component/tabs-items';
import { useAppSelector } from '../../../../../Store/hooks';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';
import DeleteSavedFilterModal from './Component/Modals/DeleteSavedFilterModal';
import React, { useEffect, useState } from 'react';
import Toolbar from './Toolbar';
import UnverifiedOrgAlert from '../../Detail/Component/unverified-alert';

const Orders = () => {
  const { showErrorModal } = useErrorModal();
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrderTableProps>({
    all: 0,
    orders: [],
  });
  const [savedTabs, setSavedTabs] = useState<unknown>(null);
  const [savedFiltersCount, setSavedFiltersCount] = useState(0);
  const [columnsSettings, setColumnsSettings] = useState<unknown>(null);

  const [showActions, setShowActions] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tabsItems, setTabsItems] = useState<any[]>([]);
  const [filterInfo, setFilterInfo] = useState<Record<string, FilterValue | null>>({});
  const [showFilterActions, setShowFilterActions] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [openDeleteSavedFilterModal, setOpenDeleteSavedFilterModal] = useState<boolean>(false);
  const [modalFilterId, setModalFilterId] = useState(-1);
  const [modalFilterName, setModalFilterName] = useState('');
  const handleOpenDeleteSavedFilterModal = (id: number, name: string) => {
    setModalFilterId(id);
    setModalFilterName(name);
    setOpenDeleteSavedFilterModal(true);
  };
  const handleCancelDeleteSavedFilterModal = () => setOpenDeleteSavedFilterModal(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(
    +searchParams.get('tab')! > 0 && !!savedTabs ? +searchParams.get('tab')! : 1,
  );
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';
  const restartTable = () => {
    if (activeTab !== null) {
      API.get(
        `/api/v1/order/list-of-associated-orders?filter=all&page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`,
      )
        .then((response) => setData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && activeTab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('tab', activeTab.toString());
        return searchParams;
      });
    }
    setLoading(true);
    restartTable();
    API.get(`/api/v1/table-settings/order`)
      .then((response) => setColumnsSettings(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
    restartTable();
  }, [activeTab, page, size, sort, sortField]);

  useEffect(() => {
    setLoading(true);
    restartTable();
    API.get(`/api/v1/filter`)
      .then((response) => {
        setSavedTabs(response.data.sort((a, b) => b.id - a.id));
        setSavedFiltersCount(response.data.length);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
    API.get(`/api/v1/table-settings/order`)
      .then((response) => setColumnsSettings(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  const ordersProps: OrdersProps = {
    orders: data.orders,
    columnsSettings: columnsSettings,
    loading: loading,
    all: data.all,
  };

  useEffect(() => {
    setTabsItems(
      getOrdersTabsItems(
        ordersProps,
        savedTabs,
        setShowActions,
        setShowFilterActions,
        setFilterInfo,
        selectedRowKeys,
        setSelectedRowKeys,
        handleOpenDeleteSavedFilterModal,
      ),
    );
  }, [
    savedTabs,
    data.orders.filter((item) => (item.executorOffersCount ? item.executorOffersCount < 3 : true)),
    columnsSettings,
    selectedRowKeys,
  ]);

  return (
    <div>
      {!isVerified && (
        <div className="profile__unverified">
          <UnverifiedOrgAlert />
        </div>
      )}
      <Toolbar
        selectedRowKeys={selectedRowKeys}
        showActions={showActions}
        savedFiltersCount={savedFiltersCount}
        showFilterActions={showFilterActions && activeTab === 1}
        filterInfo={filterInfo}
        columnsSettings={columnsSettings}
        setColumnsSettings={setColumnsSettings}
      />
      {tabsItems.length > 0 && (
        <div className="orders__tabs-table">
          <Tabs
            activeKey={`${activeTab}`}
            items={tabsItems}
            className="tabs"
            onChange={(e) => {
              setShowActions(false);
              setSelectedRowKeys([]);
              setActiveTab(+e);
              setSearchParams((params) => {
                params.set('page', '1');
                return searchParams;
              });
              window.history.replaceState(null, '', `/organizer/orders?tab=${e}&page=${page}&size=${size}`);
            }}
          />
        </div>
      )}
      <DeleteSavedFilterModal
        open={openDeleteSavedFilterModal}
        id={modalFilterId}
        name={modalFilterName}
        handleCancel={() => handleCancelDeleteSavedFilterModal()}
      />
    </div>
  );
};

export default Orders;

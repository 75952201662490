import { API } from '../../../../../Services/axios';
import { Button, Card, Col, Form, Input, Modal, Row, Space, Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { EmailTemplate } from '../../../../../Models/EmailTemplate';
import { ModalProps } from '../../../../../Models/Modal';
import { getRequiredFieldRule } from '../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import ModalTitle from '../../../../../Components/Modals/ModalTitle';

interface EditSystemMessageModalProps extends ModalProps {
  record: EmailTemplate;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const EditEmailModal = (props: EditSystemMessageModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, record, setReload } = props;
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open)
      form.setFieldsValue({
        subject: record.subject,
        body: record.body,
      });
  }, [record.id]);

  const onClose = () => {
    handleCancel();
    form.resetFields();
    setLoading(false);
  };

  const onFinish = (values) => {
    if (values.subject && values.body) {
      setLoading(true);
      const formData = {
        subject: values.subject,
        body: values.body,
      };

      API.put(`/api/v1/admin/email/${record.id}`, formData)
        .then((response) => onClose())
        .catch((error) => showErrorModal({ error }))
        .finally(() => {
          setReload(true);
          setLoading(false);
        });
    }
  };

  const capitalizeFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Редактирование шаблона" handleCancel={onClose} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        {record.placeholders.length > 0 && (
          <div className="form__info">
            <Typography.Text strong>Переменные, используемые в теме/тексте письма</Typography.Text>
            <Card>
              <Row gutter={[0, 16]} align="middle">
                {record.placeholders.map((placeholder) => (
                  <Col span={24} key={placeholder.id}>
                    <Row justify="space-between">
                      <Col span={15}>
                        <Typography.Text className="form__info-text">
                          {capitalizeFirstLetter(placeholder.description)}
                        </Typography.Text>
                      </Col>
                      <Col span={9} className="form__align-right">
                        <Typography.Text
                          className="form__info"
                          copyable={{ tooltips: false, text: '${' + placeholder.name + '}' }}
                          strong
                        >
                          &#36;&#123;{placeholder.name}&#125;
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Card>
          </div>
        )}

        <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
          <Form.Item name="subject" label="Тема письма" rules={getRequiredFieldRule('Введите тему письма')}>
            <Input size="large" maxLength={60} />
          </Form.Item>

          <Form.Item name="body" label="Текст письма" rules={getRequiredFieldRule('Введите текст письма')}>
            <Input.TextArea size="large" maxLength={1000} showCount className="form__comment" autoSize />
          </Form.Item>
        </Form>

        <Row justify="end">
          <Button size="large" className="modal__cancel-btn" onClick={onClose}>
            Отмена
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" onClick={onFinish} loading={loading}>
            Сохранить
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default EditEmailModal;

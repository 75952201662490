import { API } from '../../../../Services/axios';
import { Col, Row, Table, Typography } from 'antd';
import { Order } from '../../../../Models/Order';
import { getOrdersTableColumns } from './Component/columns';
import { getOrdersTableFilter } from './Component/filters';
import { sortBy } from 'lodash';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';

const OrdersTable = () => {
  const { showErrorModal } = useErrorModal();
  const [data, setData] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/order/order-list-for-operator/')
      .then((response) => setData(sortBy(response.data, 'id').reverse()))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  const { filteredInfo, setFilteredInfo, handleFilter } = getOrdersTableFilter();
  const columns = getOrdersTableColumns(filteredInfo, setFilteredInfo);

  return (
    <div>
      <Row className="toolbar__title" justify="space-between">
        <Col>
          <Typography.Title level={2}>Заказы</Typography.Title>
        </Col>
      </Row>
      <div>
        <Table
          bordered
          rowKey={'id'}
          className="table__organizations"
          loading={loading}
          columns={columns}
          dataSource={data}
          scroll={{ x: 1331 }}
          locale={tableLocaleSettings}
          pagination={tablePagingSettings}
          onChange={handleFilter}
        />
      </div>
    </div>
  );
};

export default OrdersTable;

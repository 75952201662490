import { Card, Empty, Space, Typography } from 'antd';
import { OffersListItem } from '../../../../Models/Offer';
import OfferCard from './Offer/OfferCard';

interface OrderTabProps {
  offersData: OffersListItem[];
  infoText: string;
  emptyDescription: string;
  isOrderCancelled?: boolean;
  showModal: (organisationId: number) => void;
}

const OrderTab = (props: OrderTabProps) => {
  const { offersData, infoText, emptyDescription, isOrderCancelled, showModal } = props;

  return (
    <>
      <Typography.Text className="order__info">{infoText}</Typography.Text>
      {offersData.length > 0 ? (
        <Space direction="vertical" size={16} className="offer">
          {offersData.map((offer) => (
            <OfferCard {...offer} key={offer.id} showModal={showModal} />
          ))}
        </Space>
      ) : isOrderCancelled ? null : (
        <Card>
          <Empty description={emptyDescription} className="order__info-empty" />
        </Card>
      )}
    </>
  );
};

export default OrderTab;

import { API } from '../../../../../Services/axios';
import { Button, Card, Col, Row, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { OfferType } from '../../../../../Models/Offer';
import { clickDownloadLink } from '../../../../../Utils/clickDownloadLink';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import FinalAgreementOffer from './FinalAgreementOffer';
import Loader from '../../../../../Components/Loader/Loader';
import dayjs from 'dayjs';

interface FinalAgreementInfoProps {
  id: number;
  offerData: OfferType;
}

const FinalAgreementInformation = (props: FinalAgreementInfoProps) => {
  const { id, offerData } = props;
  const { showErrorModal } = useErrorModal();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const [fioCheck, setFioCheck] = useState(false);

  useEffect(() => {
    API.get(`/api/v1/profile/checkFIO/offer?offerId=${offerData.id}&type=DELIVERY`)
      .then((response) => {
        setFioCheck(response.data);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  const handleClick = () => {
    setLoading(true);
    API.post(
      `/api/v1/fillAgreement?offerId=${offerData.id}`,
      {},
      {
        responseType: 'blob',
      },
    )
      .then((response) => {
        setFile(response.data);
        setLoading(false);
      })
      .catch((error) => {
        showErrorModal({ error });
        setLoading(false);
      });
  };

  const handleDownload = () => {
    if (file) {
      clickDownloadLink(file, `Договор${dayjs().format('YYYY_MM_DD')}.pdf`);
    }
  };

  return (
    <Row gutter={[0, 64]}>
      <Col span={24}>
        <Card className="offer__full-width">
          <Row>
            <Col span={24} className="offer__title">
              <Typography.Title level={4}>Финальный договор</Typography.Title>
            </Col>
            {/*<Col span={24}>*/}
            {/*  <Space direction="vertical" size={12}>*/}
            {/*    <Typography.Text type="secondary" strong>*/}
            {/*      Файл подписанного договора*/}
            {/*    </Typography.Text>*/}
            {/*    <Space size={10}>*/}
            {/*      <FileOutlined className="offer__file-icon" />*/}
            {/*      <Typography.Link className="offer__text" href={file.link}>*/}
            {/*        {file.name}*/}
            {/*      </Typography.Link>*/}
            {/*    </Space>*/}
            {/*  </Space>*/}
            {/*</Col>*/}

            {loading ? (
              <Loader />
            ) : (
              <Col span={24} className="offer__actions">
                <Button
                  size="large"
                  style={{ whiteSpace: 'break-spaces', height: 'auto', textAlign: 'center', marginRight: '10px' }}
                  type={'primary'}
                  onClick={file ? handleDownload : handleClick}
                >
                  {file ? 'Скачать договор' : 'Сформировать договор'}
                </Button>
                <Link to={`/organizer/contracts/${id}`}>
                  <Button
                    size="large"
                    style={{ whiteSpace: 'break-spaces', height: 'auto', textAlign: 'center' }}
                    type={'primary'}
                  >
                    Перейти к отслеживанию договора
                  </Button>
                </Link>
              </Col>
            )}
          </Row>
          {!fioCheck && (
            <Row>
              <Col span={24}>
                <div className="order__warning-message">
                  <Typography.Title level={5}>
                    <ExclamationCircleFilled />
                    <Typography.Link href="/profile" underline>
                      Заполните
                    </Typography.Link>
                    &nbsp;поле ФИО в родительном падеже.
                  </Typography.Title>
                </div>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
      <Col span={24}>
        <FinalAgreementOffer offerData={offerData} />
      </Col>
    </Row>
  );
};

export default FinalAgreementInformation;

import {
  CalculatorOutlined,
  ContainerOutlined,
  HistoryOutlined,
  KeyOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';

export const profileItems = [
  {
    title: 'Данные об организации',
    icon: <ContainerOutlined />,
    key: 1,
  },
  {
    title: 'Личные данные',
    icon: <UserOutlined />,
    key: 2,
  },
  {
    title: 'История изменений',
    icon: <HistoryOutlined />,
    key: 3,
  },
  {
    title: 'Настройки калькулятора',
    icon: <CalculatorOutlined />,
    key: 4,
  },
  {
    title: 'Расчет стоимости перевозки',
    icon: <WalletOutlined />,
    key: 5,
  },
  {
    title: 'Смена пароля',
    icon: <KeyOutlined />,
    key: 6,
  },
];

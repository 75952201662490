import { Typography } from 'antd';

export const enum UsersTableTabs {
  ALL = 'ALL',
  ORGS = 'ORGS',
  CUSTOMERS = 'CUSTOMERS',
}

interface EmptyMessageProps {
  tabName: UsersTableTabs;
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { tabName } = props;

  const emptyText = () => {
    switch (tabName) {
      case UsersTableTabs.ALL:
        return 'Здесь будут отображаться все пользователи, которые зарегестрированы на платформе Верум Логистика';
      case UsersTableTabs.ORGS:
        return 'Здесь будут отображаться организаторы, зарегистрированные на платформе Верум Логистика';
      case UsersTableTabs.CUSTOMERS:
        return 'Здесь будут отображаться заказчики, зарегистрированные на платформе Верум Логистика';
    }
  };

  return <Typography.Text>{emptyText()}</Typography.Text>;
};

export default EmptyMessage;

import { API } from '../../../../../Services/axios';
import { Button, Modal, Row } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { ModalProps } from '../../../../../Models/Modal';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import ModalTitle from '../../../../../Components/Modals/ModalTitle';

interface DeleteCategoryModalProps extends ModalProps {
  id: number;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const DeleteCategoryModal = (props: DeleteCategoryModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { id, setReload, open, handleCancel } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const handleDeleteCategory = () => {
    setLoading(true);
    API.delete(`/api/v1/dictionary/cargo-categories/${id}`)
      .then((response) => {
        setReload(true);
        handleCancel();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={
        <ModalTitle
          level={3}
          title={`Вы действительно хотите удалить тип этот тип техники?`}
          handleCancel={handleCancel}
        />
      }
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Row justify="end">
        <Button size="large" className="modal__cancel-btn" onClick={handleCancel}>
          Отменить
        </Button>
        <Button type="primary" size="large" form="form" onClick={() => handleDeleteCategory()} loading={loading}>
          Удалить
        </Button>
      </Row>
    </Modal>
  );
};

export default DeleteCategoryModal;

import { API } from '../../../../../Services/axios';
import { Modal } from 'antd';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import ModalFooter from '../../../../../Components/Modals/ModalFooter';
import ModalTitle from '../../../../../Components/Modals/ModalTitle';

interface CancelOfferModalProps {
  open: boolean;
  offerId: number | null;
  handleCancel: () => void;
}

const CancelOfferModal = (props: CancelOfferModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, offerId, handleCancel } = props;

  const handleConfirm = () => {
    API.put(`/api/v1/offer/decline/${offerId}`)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) => showErrorModal({ error }));
  };

  return (
    <Modal
      title={
        <ModalTitle
          level={4}
          title="Вы уверены, что хотите отозвать отправленное предложение?"
          handleCancel={handleCancel}
        />
      }
      open={open}
      onCancel={handleCancel}
      className="modal modal__info"
      centered
      width={645}
      closable={false}
      footer={
        <ModalFooter
          okText="Подтвердить"
          cancelText="Вернуться к заказу"
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />
      }
    >
      {/*<Space direction="vertical" align="center" size={24} className="modal__content">*/}
      {/*  <Typography.Text>*/}
      {/*    Вы не сможете сделать предложение к этому заказу повторно, поэтому заказ автоматически переместится в архив.*/}
      {/*  </Typography.Text>*/}
      {/*</Space>*/}
    </Modal>
  );
};

export default CancelOfferModal;

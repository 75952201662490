import { API } from '../../Services/axios';
import { Alert, Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { CheckCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { getEmailFieldRules } from '../../Utils/Rules/registration';
import { useErrorModal } from '../Modals/ErrorModal';
import { useState } from 'react';

const ChangePasswordPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { showErrorModal } = useErrorModal();
  const [checkEmail, setCheckEmail] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);

  const onFinish = (values) => {
    setLoading(true);
    API.post(`/api/v1/auth/reset-password?email=${values.email}`)
      .then((response) => {
        if (response.status === 200) {
          setCheckEmail(true);
          setErrorEmail(false);
        }
      })
      .catch((error) => {
        showErrorModal({ error });
        setErrorEmail(true);
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="login">
      <div className="login__card">
        <Col span={24}>
          <div className="login__card__header">
            <Typography.Title level={1} style={{ fontSize: '24px' }}>
              Забыли пароль?
            </Typography.Title>
          </div>
          {errorEmail && (
            <Alert
              message={
                <Typography.Text className="contract__info">
                  Данный email не зарегистрирован в системе. Вы можете зарегистрироваться по{' '}
                  <Typography.Link href="/registration">ссылке.</Typography.Link>
                </Typography.Text>
              }
              type="error"
              showIcon
              icon={<ExclamationCircleFilled />}
            />
          )}
          {checkEmail && (
            <Alert
              message={<Typography.Text className="contract__info">Письмо отправлено на ваш email.</Typography.Text>}
              type="success"
              showIcon
              icon={<CheckCircleOutlined />}
            />
          )}

          <Form layout="vertical" className="form" onFinish={onFinish}>
            <Form.Item
              name="email"
              label="Электронная почта"
              validateFirst
              rules={getEmailFieldRules('Введите электронную почту')}
            >
              <Input size="large" />
            </Form.Item>

            <div className="login__card__settings">
              <Space className="login__card__link">
                <Typography.Link href="/signin">« Назад</Typography.Link>
              </Space>
            </div>
            <Row justify="center" className="login__card__footer">
              <Space direction="vertical" align="center" size={16}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="login__card__button"
                    loading={loading}
                  >
                    Отправить
                  </Button>
                </Form.Item>
                <Space style={{ textAlign: 'center' }}>
                  <Typography.Text>
                    Введите свою электронную почту и мы отправим Вам инструкцию как создать новый пароль.
                  </Typography.Text>
                </Space>
              </Space>
            </Row>
          </Form>
        </Col>
      </div>
    </div>
  );
};

export default ChangePasswordPage;

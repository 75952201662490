import { API } from '../../Services/axios';
import { Button, Form, Input, Modal, Row } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ModalProps } from '../../Models/Modal';
import { ReportFormType } from '../../Models/Form';
import { getUnrequiredOrgNameFieldRule } from '../../Utils/Rules/profile';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import ModalTitle from './ModalTitle';

interface EditExecutorLinkModalProps extends ModalProps {
  orderId: number;
  link?: string;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const EditExecutorLinkModal = (props: EditExecutorLinkModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, link, orderId, setReload } = props;
  const [form] = useForm<ReportFormType>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldValue('link', link);
  }, [link]);

  const onClose = () => {
    handleCancel();
    form.resetFields();
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      orderId: orderId,
      link: values.link,
    };
    API.post('/api/v1/order-external-link', formData)
      .then((response) => {
        setReload(true);
        onClose();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={
        <ModalTitle
          level={3}
          title={link ? 'Редактирование ссылки или номера заказа' : 'Добавление ссылки или номера заказа'}
          handleCancel={onClose}
        />
      }
      open={open}
      onCancel={onClose}
      className="modal form__edit-link"
      footer={null}
      centered
      width={645}
      closable={false}
    >
      <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
        <Form.Item label="Внешняя ссылка или номер заказа" name="link" rules={getUnrequiredOrgNameFieldRule()}>
          <Input size="large" />
        </Form.Item>
      </Form>

      <Row justify="end">
        <Button size="large" onClick={handleCancel} className="modal__cancel-btn">
          Отменить
        </Button>
        <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
          Добавить
        </Button>
      </Row>
    </Modal>
  );
};

export default EditExecutorLinkModal;

import { API } from '../../../../../../Services/axios';
import { Button, Form, Input, InputNumber, Modal, Row, Space } from 'antd';
import { ModalProps } from '../../../../../../Models/Modal';
import { Rate } from '../../../../../../Models/Rate';
import { getRequiredFieldRule } from '../../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../../Components/Modals/ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from '../../../../../../Components/Modals/ModalTitle';

interface EditRateModalProps extends ModalProps {
  rate: Rate;
}

const EditRateModal = (props: EditRateModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { rate, open, handleCancel } = props;

  const [formEdit] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const price = useWatch('price', formEdit);

  const [initialValues] = useState({
    from: `${rate.regionFrom}${rate.cityFrom ? `, ${rate.cityFrom}` : ``}`,
    to: `${rate.regionTo}${rate.cityTo ? `, ${rate.cityTo}` : ``}`,
    price: rate.price,
  });

  const onFinish = () => {
    formEdit.validateFields();
    setLoading(true);
    const values = formEdit.getFieldsValue();

    const priceMatrixDto = {
      regionFrom: values.from.split(',')[0],
      cityFrom: values.from.split(',')[1] ?? '',
      regionTo: values.to.split(',')[0],
      cityTo: values.to.split(',')[1] ?? '',
      price: values.price,
    };

    API.put(`/api/v1/price-matrix/${rate.id}`, priceMatrixDto)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Редактирование тарифа по направлению" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form
          id="formEdit"
          form={formEdit}
          initialValues={initialValues}
          colon={false}
          layout="vertical"
          className="form"
        >
          <Form.Item name="from" label="Регион отправки">
            <Input disabled size="large" />
          </Form.Item>
          <Form.Item name="to" label="Регион отправки">
            <Input disabled size="large" />
          </Form.Item>
          <Form.Item name="price" label="Тариф за 1 км, ₽" rules={getRequiredFieldRule('Введите цену')}>
            <InputNumber min={0} maxLength={9} size="large" />
          </Form.Item>
        </Form>
        <Space>
          <Row>
            <Button size="large" className="modal__cancel-btn" onClick={handleCancel}>
              Отмена
            </Button>
            <Button
              disabled={!price}
              type="primary"
              size="large"
              form="form"
              htmlType="submit"
              onClick={onFinish}
              loading={loading}
            >
              Cохранить
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditRateModal;

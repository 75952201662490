/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Input, InputRef, Space } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { FilterOutlined } from '@ant-design/icons';

interface StringFilterProps {
  searchInput: React.RefObject<InputRef>;
  selectedKeys: string[];
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  handleSearch: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => void;
  handleReset: (clearFilters: () => void) => void;
  confirm: (param?: FilterConfirmProps | undefined) => void;
  dataIndex: any;
  clearFilters: (() => void) | undefined;
  setSearchText: (value: React.SetStateAction<string>) => void;
  setSearchedColumn: (value: React.SetStateAction<string>) => void;
}

const StringFilter = (props: StringFilterProps) => {
  const {
    searchInput,
    selectedKeys,
    setSelectedKeys,
    handleSearch,
    handleReset,
    confirm,
    dataIndex,
    clearFilters,
    setSearchText,
    setSearchedColumn,
  } = props;
  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Начните вводить для поиска`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch([selectedKeys.toString()], confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Сброс
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<FilterOutlined />}
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys.toString());
            setSearchedColumn(dataIndex);
          }}
        >
          Фильтровать
        </Button>
      </Space>
    </div>
  );
};

const filterString = (value, filter) => {
  const definedValue = value ?? '—';
  return definedValue.toString().toLowerCase().includes(filter.toLowerCase());
};

export { StringFilter, filterString };

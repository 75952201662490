import { API } from '../../../../../Services/axios';
import { Alert, Button, Card, Col, Row, Tooltip, Typography } from 'antd';
import { ContractReport } from '../../../../../Models/Contract';
import { ContractStatus } from '../../../../../Constants/Contract';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ReportFormType } from '../../../../../Models/Form';
import { ReportStatuses } from '../../../../../Constants/ReportStatuses';
import { reverse } from 'lodash';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import CancelContractModal from '../../../../../Components/Modals/CancelContractModal';
import CompletedWorksActCard from './CompletedWorksActCard';
import ConfirmPaymentCard from './ConfirmPaymentCard';
import Loader from '../../../../../Components/Loader/Loader';
import ReportStatusModal from '../../../../../Components/Modals/ReportStatusModal';
import ReportTimeline from '../../../../../Components/Reports/ReportTimeline';

interface ReportProps {
  contractId: number;
  contractStatus: ContractStatus;
  isContractCancelModalOpen: boolean;
  closeCancelContractModal: () => void;
}

const Report = (props: ReportProps) => {
  const { showErrorModal } = useErrorModal();
  const { contractId, isContractCancelModalOpen, closeCancelContractModal } = props;
  const [data, setData] = useState<ContractReport[]>();
  const [track, setTrack] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);

  const handleCancel = () => setIsModalOpen(false);

  const submitForm = (values: ReportFormType) => {
    setLoading(true);
    const reportData = {
      agreementId: contractId,
      status: ReportStatuses.NEW,
      comment: values.statusText,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(reportData));

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    API.post('/api/v1/report', formData, headers)
      .then((response) => {
        setReload(true);
        setIsModalOpen(false);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoadingList(true);
    API.get(`/api/v1/report/getReportsList/${contractId}`)
      .then((response) => {
        setData(reverse(response.data.reportsList));
        setTrack(response.data.trackNo);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoadingList(false);
        setReload(false);
      });
  }, [reload]);

  const completedWorksActRecord: ContractReport | undefined = data?.find(
    (item) => item.status === ReportStatuses.WORK_DONE,
  );
  const paidConfirmedRecord: ContractReport | undefined = data?.find((item) => item.status === ReportStatuses.PAID);
  const cancelledContractReport: ContractReport | undefined = data?.find(
    (item) => item.status === ReportStatuses.CANCELLED,
  );

  if (loadingList) return <Loader />;

  return (
    <Row gutter={[0, 24]} className="contract__report contract__full-width">
      {cancelledContractReport && (
        <Col span={24}>
          <Alert
            message={
              <Typography.Text className="contract__info">
                Вы инициировали принудительное завершение исполнения договора в системе, так как он не может быть
                исполнен
              </Typography.Text>
            }
            type="warning"
            showIcon
          />
        </Col>
      )}
      {completedWorksActRecord && !cancelledContractReport && (
        <Col span={24}>
          <ConfirmPaymentCard contractId={contractId} paidConfirmedRecord={paidConfirmedRecord} setReload={setReload} />
        </Col>
      )}
      <Col span={24}>
        <CompletedWorksActCard
          contractId={contractId}
          setReload={setReload}
          cancelledContractReport={cancelledContractReport}
          completedWorksActRecord={completedWorksActRecord}
        />
      </Col>

      <Col span={24}>
        <Card title={<Typography.Title level={4}>Отчет об исполнении</Typography.Title>}>
          <Row gutter={[0, 24]}>
            {!completedWorksActRecord && !cancelledContractReport && (
              <Col span={24}>
                <Typography.Text>
                  Дополняйте отчет статусами о нахождении груза. Это поможет заказчику отслеживать информацию об
                  исполнении условий договора.
                </Typography.Text>
              </Col>
            )}
            <Col span={24}>{data && <ReportTimeline reportList={data} />}</Col>
            {!completedWorksActRecord && !cancelledContractReport && (
              <Col span={24}>
                <Button size="large" type="primary" onClick={() => showModal()}>
                  Дополнить отчет
                </Button>
              </Col>
            )}
          </Row>
          <ReportStatusModal open={isModalOpen} handleCancel={handleCancel} submitForm={submitForm} loading={loading} />
          <CancelContractModal open={isContractCancelModalOpen} handleCancel={closeCancelContractModal} />
        </Card>
      </Col>
      {track && (
        <Col span={24}>
          <Card>
            <Row justify="space-between" gutter={[24, 12]}>
              <Col>
                <Typography.Title level={4}>
                  Трек-номер для отслеживания
                  <Tooltip
                    className="contract__info-icon"
                    title="Номер нужен для отслеживания заказа на главной странице"
                  >
                    <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                  </Tooltip>
                </Typography.Title>
              </Col>
              <Col>
                <Typography.Title level={4} className="contract__info" copyable={{ tooltips: true }}>
                  {track}
                </Typography.Title>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default Report;

export const tablePagingSettings = (totalCount: number, sizePage) => {
  return {
    showSizeChanger: true,
    showQuickJumper: false,
    total: totalCount,
    showTotal: (total) => `Всего: ${total}.`,
    pageSizeOptions: [20, 30, 50, 100],
    defaultPageSize: sizePage ? sizePage : 20,
    locale: {
      items_per_page: 'на странице',
      jump_to: 'Перейти на',
      page: 'страницу',
    },
  };
};

import { OrganizationsTableProps } from '../../../../../Models/Organizations';
import { Table } from 'antd';
import { getOrganizationsTableColumns } from './Component/columns';
import { getOrganizationsTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const OrganizationsTable = (props: OrganizationsTableProps) => {
  const { organizations, loading, tabName, totalCount } = props;

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, sortInfo, setSortInfo, handleFilter } =
    getOrganizationsTableFilter();
  const columns = getOrganizationsTableColumns(filteredInfo, setFilteredInfo);

  const [searchParams, setSearchParams] = useSearchParams();
  const sizePage = localStorage.getItem('sizePage');
  useEffect(() => {
    if (paginationInfo?.current) {
      setSearchParams((params) => {
        params.set('page', paginationInfo.current ? paginationInfo.current.toString() : '');
        return searchParams;
      });
    }
    if (paginationInfo?.pageSize) {
      setSearchParams((params) => {
        params.set('size', paginationInfo.pageSize ? paginationInfo.pageSize.toString() : '');
        return searchParams;
      });
    }
    if (sortInfo?.sortOrder) {
      setSearchParams((params) => {
        params.set('sort', sortInfo.sortOrder ? sortInfo.sortOrder.toString() : '');
        return searchParams;
      });
    }
    if (sortInfo?.sortField) {
      setSearchParams((params) => {
        params.set('sortField', sortInfo.sortField ? sortInfo.sortField.toString() : '');
        return searchParams;
      });
    }
  }, [paginationInfo, sortInfo]);
  return (
    <Table
      bordered
      rowKey={'id'}
      className="table__organizations"
      loading={loading}
      columns={columns}
      scroll={{ x: 1331 }}
      dataSource={organizations}
      locale={tableLocaleSettings(tabName)}
      pagination={tablePagingSettings(totalCount, sizePage)}
      onChange={handleFilter}
    />
  );
};

export default OrganizationsTable;

import { Button, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import ModalTitle from './ModalTitle';

interface SigningContractModalProps extends ModalProps {
  handleConfirm: () => void;
  loading: boolean;
}

const SigningContractModal = (props: SigningContractModalProps) => {
  const { open, handleCancel, handleConfirm, loading } = props;
  return (
    <Modal
      open={open}
      title={<ModalTitle level={4} title="Вы действительно готовы выбрать перевозчика?" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32}>
        <Typography.Text className="modal__text-large">
          Выбор перевозчика означает, что Вы согласны с его условиями перевозки и выбираете его исполнителем данного
          заказа.
        </Typography.Text>
        {/*<Typography.Text className="modal__text-large">*/}
        {/*  Подписание договора на оказание услуг возможно через систему электронного документооборота или через обмен*/}
        {/*  бумажными оригиналами. Когда договор будет подписан обеими сторонами, Вы сможете отслеживать статус его*/}
        {/*  исполнения.*/}
        {/*</Typography.Text>*/}
        <div className="modal__footer">
          <Row justify="end">
            <Button size="large" onClick={handleCancel} className="modal__cancel-btn">
              Отменить
            </Button>
            <Button type="primary" size="large" onClick={handleConfirm} loading={loading}>
              Подтвердить
            </Button>
          </Row>
        </div>
      </Space>
    </Modal>
  );
};

export default SigningContractModal;

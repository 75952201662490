import { API } from '../../Services/axios';
import { Button, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { useErrorModal } from './ErrorModal';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ModalTitle from './ModalTitle';

const CancelOrderModal = (props: ModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();
  const { open, handleCancel } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirm = () => {
    setLoading(true);

    API.put(`/api/v1/order/cancel-by-delivery/${id}`)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={
        <ModalTitle level={4} title="Вы уверены, что договор не может быть подписан?" handleCancel={handleCancel} />
      }
      open={open}
      onCancel={handleCancel}
      className="modal modal__info"
      footer={null}
      centered
      width={645}
      closable={false}
    >
      <Space direction="vertical" size={32}>
        <Typography.Text className="modal__text-large">
          В этом случае Ваше предложение и проект договора аннулируются, а заказ перейдет в “Архив”. Это действие нельзя
          будет отменить.
        </Typography.Text>
        <Row justify="end">
          <Button size="large" onClick={handleCancel} className="modal__cancel-btn">
            Вернуться к заказу
          </Button>
          <Button type="primary" size="large" onClick={handleConfirm} loading={loading}>
            Подтвердить
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default CancelOrderModal;

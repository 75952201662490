import { BackTop, Breadcrumb, Col, Row, Typography } from 'antd';

const ProfileHeader = () => {
  return (
    <Row justify="space-between" align="bottom" gutter={[0, 16]} className="profile__header">
      <Col span={24}>
        <Row justify="space-between" align="bottom" gutter={[0, 16]}>
          <Col>
            <BackTop />
            <Breadcrumb className="order__breadcrumb" separator=">">
              <Breadcrumb.Item href="/">Главная страница</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Typography.Text ellipsis>Профиль</Typography.Text>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Col>
      <Col span={17}>
        <Typography.Title level={2}>Профиль</Typography.Title>
      </Col>
    </Row>
  );
};

export default ProfileHeader;

import { API } from '../../Services/axios';
import { AutocompleteClaimItem } from '../../Models/Claims';
import { Button, Empty, Form, Input, Modal, Radio, Row, Select, Space, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { Roles } from '../../Constants/Profile';
import { SystemMessage } from '../../Models/SystemMessage';
import {
  customerActionsClaim,
  deliveryActionsClaim,
  systemProblemCustomerClaim,
  systemProblemOrgClaim,
} from '../../Constants/ClaimSelectOptions';
import { getRequiredClaimFieldRules } from '../../Utils/Rules/claims';
import { getRequiredFieldRule } from '../../Utils/Rules/order';
import { useAppSelector } from '../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from './ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import ClaimAgreementInfo from '../Claims/Component/agreement-info';
import Loader from '../Loader/Loader';
import ModalTitle from './ModalTitle';

const CreateClaimModal = (props: ModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel } = props;
  const role = useAppSelector((state) => state.user.role);
  const [form] = useForm();
  const reason = useWatch('reason', form);

  const [systemMessage, setSystemMessage] = useState<SystemMessage>();
  useEffect(() => {
    API.get('/api/v1/messages')
      .then((response) => {
        const message = response.data.find((item) => item.shortName === 'Обработка обращений');
        setSystemMessage(message);
      })
      .catch((error) => showErrorModal({ error }));
  }, []);

  const isDelivery = role === Roles.DELIVERY;
  const selectOptions =
    reason === 'actions'
      ? isDelivery
        ? customerActionsClaim
        : deliveryActionsClaim
      : isDelivery
      ? systemProblemOrgClaim
      : systemProblemCustomerClaim;

  const [autocompleteAgreements, setAutocompleteAgreements] = useState<AutocompleteClaimItem[]>([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState<AutocompleteClaimItem | null>();

  const clearForm = () => {
    setAutocompleteOptions([]);
    setAutocompleteAgreements([]);
    setSelectedAgreement(null);
    form.resetFields(['agreementUserFriendlyId', 'issue', 'comments']);
  };

  useEffect(() => {
    clearForm();
  }, [reason]);

  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const onAgreementSearch = (value: string) => {
    setSearchLoading(true);
    if (!value) {
      setSearchLoading(false);
    } else if (value && value.length >= 5) {
      API.get(`/api/v1/claim/suggest/${value}`)
        .then((response) => {
          const options = response.data.map((item) => ({
            label: item.userFriendlyId,
            value: item.agreementId.toString(),
          }));
          setAutocompleteOptions(options);
          setAutocompleteAgreements(response.data);
        })
        .catch((error) => showErrorModal({ error }))
        .finally(() => {
          setSearchLoading(false);
        });
    } else {
      setAutocompleteOptions([]);
      setAutocompleteAgreements([]);
      setSelectedAgreement(null);
    }
  };

  const onAgreementSelect = (agreementId: string) => {
    const chosenAgreement = autocompleteAgreements.find((agreement) => agreement.agreementId === +agreementId);
    chosenAgreement && setSelectedAgreement(chosenAgreement);
  };

  const onSelectBlur = () => {
    if (!selectedAgreement) {
      form.resetFields(['agreementUserFriendlyId']);
    }
  };

  const [loading, setLoading] = useState<boolean>(false);

  const onCloseModal = () => {
    handleCancel();
    clearForm();
    form.resetFields(['reason']);
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      agreementId: selectedAgreement?.agreementId,
      organisationId: selectedAgreement?.organisationId,
      reason:
        values.reason === 'actions'
          ? isDelivery
            ? 'Исполнение договора заказчиком перевозки'
            : 'Исполнение договора организатором перевозки'
          : 'Работа в системе',
      issue: values.issue,
      comments: values.comments,
    };

    API.post('/api/v1/claim', formData)
      .then((response) => {
        onCloseModal();
        Modal.success({
          title: <Typography.Title level={4}>Обращение создано</Typography.Title>,
          content: <Typography.Text className="modal__text-large">{systemMessage?.message}</Typography.Text>,
          width: 655,
          centered: true,
          onOk: () => location.reload(),
        });
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Создание обращения" handleCancel={onCloseModal} />}
      open={open}
      className="modal modal__scrollable"
      footer={
        <Row justify="end">
          <Button size="large" onClick={onCloseModal} className="modal__cancel-btn">
            Отменить
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" loading={loading}>
            Создать обращение
          </Button>
        </Row>
      }
      centered
      width={645}
      closable={false}
    >
      <Form
        id="form"
        form={form}
        colon={false}
        layout="vertical"
        className="form"
        onFinish={onFinish}
        initialValues={{ reason: 'actions' }}
      >
        <Space direction="vertical" size={56} className="modal__full-width">
          <Space direction="vertical" size={24} className="modal__full-width">
            <Form.Item name="reason" label="Укажите причину обращения">
              <Radio.Group onChange={() => form.resetFields(['issue'])}>
                <Space direction="vertical">
                  <Radio value="actions">
                    {isDelivery
                      ? 'Исполнение договора заказчиком перевозки'
                      : 'Исполнение договора организатором перевозки'}
                  </Radio>
                  <Radio value="system">Работа в системе</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {reason === 'actions' && (
              <Space direction="vertical" size={8} className="form__full-width form__info">
                <Form.Item
                  name="agreementUserFriendlyId"
                  label="Укажите номер договора в системе"
                  rules={getRequiredClaimFieldRules(100)}
                >
                  <Select
                    loading={searchLoading}
                    filterOption={false}
                    notFoundContent={searchLoading ? <Loader /> : <Empty description="" />}
                    showSearch
                    options={autocompleteOptions}
                    onSearch={onAgreementSearch}
                    onSelect={onAgreementSelect}
                    onBlur={onSelectBlur}
                    size="large"
                    getPopupContainer={(trigger) => trigger.parentElement!}
                  />
                </Form.Item>

                {selectedAgreement && (
                  <ClaimAgreementInfo
                    organisationTitle={isDelivery ? 'Заказчик' : 'Организатор перевозки'}
                    organisationName={selectedAgreement.organisationName}
                    addressFrom={selectedAgreement.addressFrom}
                    addressTo={selectedAgreement.addressTo}
                  />
                )}
              </Space>
            )}
            <Form.Item
              name="issue"
              label="Укажите проблему"
              rules={getRequiredFieldRule('Поле обязательно для заполнения')}
            >
              <Select
                size="large"
                placement="bottomLeft"
                options={selectOptions}
                getPopupContainer={(trigger) => trigger.parentElement!}
              />
            </Form.Item>
            <Form.Item name="comments" label="Опишите проблему">
              <Input.TextArea size="large" maxLength={200} showCount className="form__comment" />
            </Form.Item>
          </Space>
        </Space>
      </Form>
    </Modal>
  );
};

export default CreateClaimModal;

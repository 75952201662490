import { createSlice } from '@reduxjs/toolkit';

export const ordersSlice = createSlice({
  name: 'order',
  initialState: {
    agreementId: null,
  },
  reducers: {
    updateAgreementId: (state, action) => {
      return {
        ...state,
        agreementId: action.payload,
      };
    },
  },
});

export const { updateAgreementId } = ordersSlice.actions;

export default ordersSlice.reducer;

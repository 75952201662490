import { CustomerTableTabs } from './Component/empty-message';
import { OrdersTableProps } from '../../../../../Models/Order';
import { ResizableTitle } from './Component/resizable-title';
import { Table } from 'antd';
import { getCustomerOrdersTableColumns } from './Component/columns';
import { getCustomerOrdersTableFilter } from './Component/filters';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface CustomerTableProps extends Omit<OrdersTableProps, 'rowSelection'> {
  columnsSettings: unknown;
  tabName: CustomerTableTabs;
  totalCount: number;
}

const OrdersTable = (props: CustomerTableProps) => {
  const { data, loading, columnsSettings, tabName, totalCount } = props;

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, sortInfo, setSortInfo, handleFilter } =
    getCustomerOrdersTableFilter();
  const columns = getCustomerOrdersTableColumns(columnsSettings, filteredInfo, setFilteredInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const sizePage = localStorage.getItem('sizePage');

  useEffect(() => {
    const handlers = document.querySelectorAll('.react-resizable .react-resizable-handle');
    handlers.forEach((handler) =>
      handler.addEventListener('click', () => {
        return false;
      }),
    );
  });

  useEffect(() => {
    if (paginationInfo?.current) {
      setSearchParams((params) => {
        params.set('page', paginationInfo.current ? paginationInfo.current.toString() : '');
        return searchParams;
      });
    }
    if (paginationInfo?.pageSize) {
      setSearchParams((params) => {
        params.set('size', paginationInfo.pageSize ? paginationInfo.pageSize.toString() : '');
        return searchParams;
      });
    }
    if (sortInfo?.sortOrder) {
      setSearchParams((params) => {
        params.set('sort', sortInfo.sortOrder ? sortInfo.sortOrder.toString() : '');
        return searchParams;
      });
    }
    if (sortInfo?.sortField) {
      setSearchParams((params) => {
        params.set('sortField', sortInfo.sortField ? sortInfo.sortField.toString() : '');
        return searchParams;
      });
    }
  }, [paginationInfo, sortInfo]);

  return (
    <Table
      bordered
      rowKey={'id'}
      className="table__orders"
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      scroll={{ x: 1300 }}
      loading={loading}
      columns={columns}
      dataSource={data}
      locale={tableLocaleSettings(tabName)}
      pagination={tablePagingSettings(totalCount, sizePage)}
      onChange={handleFilter}
    />
  );
};

export default OrdersTable;

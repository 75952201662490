/* eslint-disable react/prop-types */
import { Checkbox, Form, Input, Select, Space, Typography } from 'antd';
import { ExactAddress } from '../../../../../../Models/Address';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FormInstance } from 'rc-field-form';
import { getRequiredFieldRule } from '../../../../../../Utils/Rules/order';
import { withYMaps } from '@pbe/react-yandex-maps';
import React, { useState } from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const YSuggest: React.FC<{
  form: FormInstance;
  label: string;
  name: string;
  id: string;
  value: string;
  nameSelect: string;
  nameCheckbox: string;
  idSelect: string;
  idCheckbox: string;
  idSpace: string;
  countrySelect: {
    countryFromSelect: string;
    countryToSelect: string;
  };
  profileOrg: boolean;
  setAddressFrom: React.Dispatch<React.SetStateAction<ExactAddress>>;
  setAddressTo: React.Dispatch<React.SetStateAction<ExactAddress>>;
  setMapCenter: React.Dispatch<React.SetStateAction<number[]>>;
  setMapZoom: React.Dispatch<React.SetStateAction<number>>;
  setSubmitButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setCalculator: React.Dispatch<
    React.SetStateAction<{
      price: number;
      distance: number;
    }>
  >;
  setCountrySelect: React.Dispatch<
    React.SetStateAction<{
      countryFromSelect: string;
      countryToSelect: string;
    }>
  >;
}> = ({
  form,
  label,
  name,
  id,
  value,
  nameSelect,
  nameCheckbox,
  idSelect,
  idCheckbox,
  idSpace,
  countrySelect,
  profileOrg,
  setAddressFrom,
  setAddressTo,
  setMapCenter,
  setMapZoom,
  setSubmitButtonDisabled,
  setCalculator,
  setCountrySelect,
}) => {
  function MapSuggest(props) {
    const { ymaps } = props;

    const [countrySelectNew, setCountrySelectNew] = useState(countrySelect);
    const newAreas = countrySelectNew[nameSelect] !== 'Россия' && countrySelectNew[nameSelect] !== 'Беларусь';
    const [warningCountry, setWarningCountry] = useState(false);
    const handleChangeCountry = (value: string) => {
      if (name === 'addressFrom') {
        setCountrySelect({
          ...countrySelect,
          countryFromSelect: value,
        });
        setCountrySelectNew({
          ...countrySelect,
          countryFromSelect: value,
        });
      } else {
        setCountrySelect({
          ...countrySelect,
          countryToSelect: value,
        });
        setCountrySelectNew({
          ...countrySelect,
          countryToSelect: value,
        });
      }
    };

    React.useEffect(() => {
      let params = {};
      if (newAreas) {
        params = {
          boundedBy: [
            [46.640368, 32.614812],
            [48.573891, 39.307578],
          ],
          results: 3,
        };
      }
      const suggestView = new ymaps.SuggestView(id, {
        provider: {
          suggest: (request, options) => ymaps.suggest(`${countrySelectNew[nameSelect]}, ${request}`, { ...params }),
        },
      });
      setWarningCountry(false);
      if (countrySelectNew[nameSelect] !== 'Беларусь') {
        const containerSpace = document.getElementById(idSpace)!;
        const containerInput = containerSpace.getElementsByClassName('ant-form-item-control-input-content');
        const removeYMap = containerInput[2].getElementsByTagName('ymaps')!;
        if (removeYMap.length > 1) {
          containerInput[2].removeChild(removeYMap[0]);
        }
      }
      suggestView.events.add('select', (e) => {
        const address = e.get('item').value;
        form.setFieldValue(name, address);
        const geo = ymaps.geocode(address, { results: 1 });
        geo.then((res) => {
          const coords = res.geoObjects.get(0).geometry._coordinates;
          if (name === 'addressFrom') {
            setAddressFrom({
              displayName: address,
              coordinates: coords,
              components: res.geoObjects.get(0).properties._data.metaDataProperty.GeocoderMetaData.Address.Components,
            });
          } else
            setAddressTo({
              displayName: address,
              coordinates: coords,
              components: res.geoObjects.get(0).properties._data.metaDataProperty.GeocoderMetaData.Address.Components,
            });
          setMapCenter(coords);
          setMapZoom(16);
        });
        setCalculator({ price: 0, distance: 0 });
        setSubmitButtonDisabled(true);
      });
    }, [ymaps.SuggestView, countrySelectNew]);

    const moveToIfExist = (name) => {
      const address = form.getFieldValue(name);
      if (address) {
        if (name === 'addressFrom') {
          setAddressFrom((addressFrom) => {
            setMapCenter(addressFrom.coordinates);
            setMapZoom(16);
            return addressFrom;
          });
        } else if (name === 'addressTo') {
          setAddressTo((addressTo) => {
            setMapCenter(addressTo.coordinates);
            setMapZoom(16);
            return addressTo;
          });
        }
      }
    };

    const screens = useBreakpoint();
    const smallScreens = (screens.xs || screens.sm) && !screens.md && !screens.lg;

    return (
      <div id={idSpace}>
        <Space className="order__space-full-width order__two-column" size={smallScreens ? 0 : 24} wrap={smallScreens}>
          <Form.Item name={nameSelect} label={label}>
            <Select
              size="large"
              id={idSelect}
              defaultValue="Россия"
              style={{ marginBottom: profileOrg ? '24px' : undefined }}
              options={[
                { value: 'Россия', label: 'Россия' },
                { value: 'Беларусь', label: 'Беларусь' },
              ]}
              onChange={(value) => {
                handleChangeCountry(value);
              }}
            />
          </Form.Item>
          <div>
            {countrySelectNew[nameSelect] !== 'Беларусь' && (
              <Form.Item name={nameCheckbox}>
                <Checkbox
                  id={idCheckbox}
                  checked={countrySelectNew[nameSelect] !== 'Беларусь' && countrySelectNew[nameSelect] !== 'Россия'}
                  onChange={(e) => (e.target.checked ? handleChangeCountry('') : handleChangeCountry('Россия'))}
                >
                  Новые регионы
                </Checkbox>
              </Form.Item>
            )}
          </div>
        </Space>
        <Form.Item name={name} rules={getRequiredFieldRule(`${label} является обязательным`)}>
          <Input
            id={id}
            value={value}
            size="large"
            onClick={() => moveToIfExist(name)}
            style={{ marginBottom: profileOrg ? '24px' : undefined }}
          />
        </Form.Item>
        {warningCountry && (
          <div className="order__warning-message">
            <Typography.Title level={5}>
              <ExclamationCircleFilled />
              Введите корректный адрес
            </Typography.Title>
          </div>
        )}
      </div>
    );
  }

  const Suggest = React.useMemo(() => {
    return withYMaps(MapSuggest, true, ['SuggestView', 'suggest', 'geocode', 'coordSystem.geo']);
  }, [form, label, name, id, value, nameSelect]);

  return <Suggest />;
};

export default YSuggest;

import { Checkbox, Space, Typography } from 'antd';
import { Dispatch, SetStateAction } from 'react';

interface CheckboxesProps {
  isPDPConfirmed: boolean;
  isSubscriptionAllowed: boolean;
  setIsPDPConfirmed: Dispatch<SetStateAction<boolean>>;
  setIsSubscriptionAllowed: Dispatch<SetStateAction<boolean>>;
}

const RegistrationCheckboxes = (props: CheckboxesProps) => {
  const { isPDPConfirmed, isSubscriptionAllowed, setIsPDPConfirmed, setIsSubscriptionAllowed } = props;
  return (
    <>
      <Space size={8} align="start" className="registration__checkbox">
        <Checkbox defaultChecked={isSubscriptionAllowed} onChange={(e) => setIsSubscriptionAllowed(e.target.checked)} />
        <Typography.Text>Я согласен на получение информационных сообщений</Typography.Text>
      </Space>

      <Space size={8} align="start" className="registration__checkbox">
        <Checkbox defaultChecked={isPDPConfirmed} onChange={(e) => setIsPDPConfirmed(e.target.checked)} />
        <Typography.Text>
          Я согласен на обработку персональных данных в соответствии с{' '}
          <Typography.Link href="https://verumlogistics.ru/politika-konfidencialnosti/" target="_blank">
            политикой конфиденциальности
          </Typography.Link>{' '}
          и{' '}
          <Typography.Link href="https://verumlogistics.ru/offer/" target="_blank">
            пользовательским соглашением
          </Typography.Link>
        </Typography.Text>
      </Space>
    </>
  );
};

export default RegistrationCheckboxes;

import { API } from '../../../../../Services/axios';
import { AdminActions as AdminActionsEnum } from '../../../../../Constants/Admin';
import { Button, Card, Modal, Space, Typography } from 'antd';
import { Profile } from '../../../../../Models/Profile';
import { Roles } from '../../../../../Constants/Profile';
import { isUndefined } from 'lodash';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useState } from 'react';
import ProfileActionsModal from '../../../../../Components/Modals/ProfileActionsModal';

interface AdminActionsProps {
  profileData: Profile;
  enabled: boolean;
  isOperatorLogged?: boolean;
}

const AdminActions = (props: AdminActionsProps) => {
  const { showErrorModal } = useErrorModal();
  const { enabled, profileData, isOperatorLogged } = props;
  const [action, setAction] = useState<AdminActionsEnum>();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const showModal = (action: AdminActionsEnum) => {
    setAction(action);
    setOpen(true);
  };
  const hideModal = () => setOpen(false);

  const resendLink = () => {
    setLoading(true);
    API.put('/api/v1/admin/operators/update-registration-link', profileData)
      .then((response) => {
        Modal.success({
          title: <Typography.Title level={4}>Приглашение отправлено повторно</Typography.Title>,
          content: (
            <Typography.Text className="modal__text-large">
              На электронную почту пользователя отправлено письмо с дальнейшими инструкциями.
            </Typography.Text>
          ),
          width: 655,
          centered: true,
        });
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  const isOperator = profileData.type === Roles.OPERATOR;

  return (
    <Card className="operator__full-width" title={<Typography.Title level={4}>Управление профилем</Typography.Title>}>
      <Space direction="vertical" size={16} className="operator__full-width">
        {isOperator && !isOperatorLogged && (
          <Button type="primary" size="large" onClick={resendLink} loading={loading}>
            Отправить приглашение повторно
          </Button>
        )}
        {enabled || isUndefined(enabled) ? (
          <Button type="primary" size="large" onClick={() => showModal(AdminActionsEnum.BLOCK)}>
            Заблокировать {isOperator ? 'оператора' : 'пользователя'}
          </Button>
        ) : (
          <Button type="primary" size="large" onClick={() => showModal(AdminActionsEnum.UNBLOCK)}>
            Разблокировать {isOperator ? 'оператора' : 'пользователя'}
          </Button>
        )}
      </Space>
      <ProfileActionsModal action={action} open={open} handleCancel={hideModal} />
    </Card>
  );
};

export default AdminActions;

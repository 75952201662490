export enum TaxationSystem {
  OSN = 'ОСН',
  USN = 'УСН',
  ESHN = 'ЕСХН',
}

export const taxationSysmemSelectOptions = [
  {
    value: TaxationSystem.OSN,
    label: 'Основная система налогообложения (ОСН) ',
  },
  {
    value: TaxationSystem.USN,
    label: 'Упрощенная система налогообложения (УСН)',
  },
  {
    value: TaxationSystem.ESHN,
    label: 'Единый сельскохозяйственный налог (ЕСХН)',
  },
];

import { Avatar, Card, Col, Row, Space, Typography } from 'antd';
import { OrgTypes } from '../../../../../Constants/OrgTypes';
import { OrganisationProfile } from '../../../../../Models/Operator';
import { Roles } from '../../../../../Constants/Profile';
import { UserOutlined } from '@ant-design/icons';
import { getMaskedPhoneNumberInProfile } from '../../../../../Utils/getPhoneMasked';
import ProfileItem from '../../../../../Components/Profile/ProfileItem';

interface ProfileCardProps {
  profileData: OrganisationProfile;
}

const ProfileCard = (props: ProfileCardProps) => {
  const { profileData } = props;

  const isUserLegal = profileData.type === OrgTypes.LEGAL ? true : false;

  const managementName = profileData.managementPatronymic
    ? `${profileData.managementSurname} ${profileData.managementName} ${profileData.managementPatronymic}`
    : `${profileData.managementSurname} ${profileData.managementName}`;

  const replaceEmptyString = (value) => (value || (value && value.length !== 0) ? value : '—');

  return (
    <Row gutter={[0, 16]}>
      <Card title={<Typography.Title level={3}>Данные об организации</Typography.Title>}>
        <Row gutter={[0, 40]} className="operator__full-width">
          <Col span={24}>
            <Row>
              <Typography.Title level={4}>
                Роль в системе: {profileData.users[0].role === Roles.CUSTOMER ? 'Заказчик' : 'Организатор перевозки'}
              </Typography.Title>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" gutter={[0, 32]}>
              <Col span={24}>
                <Typography.Title level={4}>Основная информация</Typography.Title>
              </Col>
              <Col span={24}>
                <Row justify="space-between" gutter={[24, 32]}>
                  <Col
                    lg={{ span: 20, order: 0 }}
                    xl={{ span: 20, order: 0 }}
                    xxl={{ span: 20, order: 0 }}
                    span={24}
                    order={1}
                  >
                    <Space direction="vertical" size={16} className="operator__full-width">
                      <ProfileItem
                        title="Организационно-правовая форма"
                        value={isUserLegal ? 'Юридическое лицо' : 'Индивидуальный предприниматель'}
                      />
                      <ProfileItem title="ИНН" value={profileData.inn} />
                      {isUserLegal && <ProfileItem title="КПП" value={profileData.kpp} />}
                      <ProfileItem title="Краткое наименование" value={replaceEmptyString([profileData.shortName])} />
                      <ProfileItem title="Полное наименование" value={replaceEmptyString(profileData.name)} />
                      {/* <ProfileItem
                      title="Дата внесения в реестр"
                      value={dayjs(profileData.organisation.ogrnDate).format(defaultDateFormat) ?? '—'}
                    /> */}
                      {isUserLegal ? (
                        <>
                          <ProfileItem
                            title="Юридический адрес"
                            value={replaceEmptyString(profileData.businessAddress)}
                          />
                          <ProfileItem title="Почтовый адрес" value={replaceEmptyString(profileData.postalAddress)} />
                        </>
                      ) : (
                        <ProfileItem
                          title="Адрес регистрации"
                          value={replaceEmptyString(profileData.businessAddress)}
                        />
                      )}
                      <ProfileItem
                        title={isUserLegal ? 'ОГРН' : 'ОГРНИП'}
                        value={
                          isUserLegal ? replaceEmptyString(profileData.ogrn) : replaceEmptyString(profileData.ogrnip)
                        }
                      />
                      <ProfileItem title="ОКПО" value={replaceEmptyString(profileData.okpo)} />
                      <ProfileItem title="ОКВЭД" value={replaceEmptyString(profileData.okved)} />
                    </Space>
                  </Col>
                  <Col span={'92px'}>
                    <Avatar
                      shape="square"
                      icon={<UserOutlined />}
                      src={profileData.users[0].userAvatar && profileData.users[0].userAvatar[0].link}
                      size={92}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: 20 }} xl={{ span: 20 }} xxl={{ span: 20 }} span={24}>
            <Row gutter={[24, 32]}>
              <Col span={24}>
                <Typography.Title level={4}>Платежная информация</Typography.Title>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={16} className="operator__full-width">
                  <ProfileItem title="Банк" value={replaceEmptyString(profileData.bankName)} />
                  <ProfileItem title="Расчетный счет" value={replaceEmptyString(profileData.paymentAccount)} />
                  <ProfileItem
                    title="Корреспондентский счет"
                    value={replaceEmptyString(profileData.correspondentAccount)}
                  />
                  <ProfileItem title="БИК" value={replaceEmptyString(profileData.bic)} />
                </Space>
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: 20 }} xl={{ span: 20 }} xxl={{ span: 20 }} span={24}>
            <Row gutter={[24, 32]}>
              <Col span={24}>
                <Typography.Title level={4}>Данные о руководителе</Typography.Title>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={16} className="operator__full-width">
                  <ProfileItem
                    title="ФИО"
                    value={
                      profileData.managementName ||
                      (profileData.managementName && profileData.managementName.length !== 0)
                        ? managementName
                        : '—'
                    }
                  />
                  {isUserLegal && (
                    <ProfileItem title="Должность" value={replaceEmptyString(profileData.managementPost)} />
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
          <Col lg={{ span: 20 }} xl={{ span: 20 }} xxl={{ span: 20 }} span={24}>
            <Row gutter={[24, 32]}>
              <Col span={24}>
                <Typography.Title level={4}>Дополнительные сведения</Typography.Title>
              </Col>
              <Col span={24}>
                <Space direction="vertical" size={16} className="operator__full-width">
                  <ProfileItem title="Система налогообложения" value={replaceEmptyString(profileData.taxationSystem)} />
                  <ProfileItem title="Электронная почта" value={replaceEmptyString(profileData.email)} />
                  <ProfileItem
                    title="Телефон организации"
                    value={
                      profileData.phone || (profileData.phone && profileData.phone.length !== 0)
                        ? getMaskedPhoneNumberInProfile(profileData.phone)
                        : '—'
                    }
                  />
                  {profileData.role === Roles.DELIVERY && (
                    <ProfileItem title="Краткое описание" value={replaceEmptyString(profileData.description)} />
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default ProfileCard;

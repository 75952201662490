import { API } from '../../../../../Services/axios';
import { Alert, Button, Space, Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ContractReport } from '../../../../../Models/Contract';
import { ReportStatuses } from '../../../../../Constants/ReportStatuses';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useState } from 'react';
import ConfirmPaymentModal from '../../../../../Components/Modals/ConfirmPaymentModal';

interface ConfirmPaymentCardProps {
  contractId: number;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  paidConfirmedRecord: ContractReport | undefined;
}

const ConfirmPaymentCard = (props: ConfirmPaymentCardProps) => {
  const { showErrorModal } = useErrorModal();
  const { contractId, paidConfirmedRecord, setReload } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onConfirmPayment = () => {
    setLoading(true);
    const reportData = {
      agreementId: contractId,
      status: ReportStatuses.PAID,
      comment: 'Получена оплата по договору',
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(reportData));

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    API.post('/api/v1/report', formData, headers)
      .then((response) => {
        setReload(true);
        setOpenModal(false);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  const content = (
    <Space direction="vertical" size={16}>
      <Typography.Text className="contract__info-large">
        Чтобы завершить исполнение договора, подтвердите получение оплаты по договору
      </Typography.Text>
      <Button size="large" type="primary" onClick={() => setOpenModal(true)}>
        Подтвердить получение оплаты
      </Button>
    </Space>
  );

  return (
    <>
      {!paidConfirmedRecord && (
        <>
          <Alert message={content} type="warning" />
          <ConfirmPaymentModal
            loading={loading}
            open={openModal}
            handleConfirm={onConfirmPayment}
            handleCancel={() => setOpenModal(false)}
          />
        </>
      )}
      {paidConfirmedRecord && (
        <Alert
          message={<Typography.Text className="contract__info">Договор успешно завершен</Typography.Text>}
          type="success"
          showIcon
          icon={<CheckCircleOutlined />}
        />
      )}
    </>
  );
};

export default ConfirmPaymentCard;

import { API } from '../../../../Services/axios';
import { Card, Col, Row, Space, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { Order } from '../../../../Models/Order';
import { defaultDateFormat } from '../../../../Constants/DateFormats';
import { formatPrice } from '../../../../Utils/formatPrice';
import { getMaskedPhoneNumberInOrder } from '../../../../Utils/getPhoneMasked';
import { updateAgreementId } from '../../../../Store/Slices/orderSlice';
import { useAppDispatch } from '../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router';
import Adresses from '../../../../Components/Adresses/Adresses';
import CancelOrderModal from '../../../../Components/Modals/CancelOrderByCustomerModal';
import CargoDimensions from '../../../../Components/CargoDimensions/CargoDimensions';
import Offers from './Offers';
import OrderAttachments from '../../../../Components/Order/OrderAttachments';
import OrderHeader from './Component/order-header';
import dayjs from 'dayjs';

const OrderDetails = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [openCancelOrderModal, setOpenCancelOrderModal] = useState<boolean>(false);
  const showCancelOrderModal = () => setOpenCancelOrderModal(true);
  const hideCancelOrderModal = () => setOpenCancelOrderModal(false);

  const [data, setData] = useState<Order>();
  useEffect(() => {
    API.get(`/api/v1/order/${id}`)
      .then((response) => {
        setData(response.data);
        dispatch(updateAgreementId(response.data.acceptedAgreementId));
      })
      .catch((error) => showErrorModal({ error }));
  }, []);

  return (data && (
    <Row justify="center" className="order__container" gutter={[24, 32]}>
      <Col lg={{ span: 24 }} span={24}>
        <OrderHeader
          title={`${data.title ?? data.machineName}`}
          orderNumber={data.userFriendlyId.toString()}
          orderStatus={data.status}
          createdAt={data.createdAt}
          finalAgreementId={data.acceptedAgreementId}
          showCancelOrderModal={showCancelOrderModal}
          orderId={data.id}
        />
      </Col>
      <Col lg={{ span: 10, order: 0 }} span={24} order={1}>
        <Card className="order__card" title={<Typography.Title level={4}>Информация о заказе</Typography.Title>}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Категория техники
                </Typography.Text>
                <Typography className="order__info-large">{data.cargoTypeNameWithParentName}</Typography>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="contract__info" type="secondary" strong>
                  Модель техники
                </Typography.Text>
                {data.specificationLink ? (
                  <Space size={12} align="center">
                    <Typography.Link href={data.specificationLink} className="contract__info-large" underline>
                      {data.machineName}
                    </Typography.Link>
                    <Typography.Link href={data.specificationLink}>
                      <LinkOutlined />
                    </Typography.Link>
                  </Space>
                ) : (
                  <Typography.Text className="contract__info-large">{data.machineName}</Typography.Text>
                )}
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Стоимость перевозимой техники
                </Typography.Text>
                <Typography className="order__info-large">{`${formatPrice(data.machinePrice)} ₽`}</Typography>
              </Space>
            </Col>

            <CargoDimensions height={data.height} weight={data.weight} width={data.width} length={data.length} />
            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Тип техники для транспортировки
                </Typography.Text>
                <Typography.Text className="order__info-large">
                  {data.transport === 'TRAILER' ? 'Трал' : 'Фура'}
                </Typography.Text>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Желаемая дата получения груза
                </Typography.Text>
                <Typography.Text className="order__info-large">
                  {dayjs(data.dateArrival).format(defaultDateFormat)}
                </Typography.Text>
              </Space>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Дата погрузки техники
                </Typography.Text>
                <Typography.Text className="order__info-large">
                  {dayjs(data.dateLoading).format(defaultDateFormat)}
                </Typography.Text>
              </Space>
            </Col>

            <Adresses
              from={data.addressFrom}
              to={data.addressTo}
              pointFrom={data.pointFrom.split(',').map((point) => {
                return +point;
              })}
              pointTo={data.pointTo.split(',').map((point) => {
                return +point;
              })}
            />

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Рассчетное расстояние
                </Typography.Text>
                <Typography className="order__info-large">{`${data.distance} км`}</Typography>
              </Space>
            </Col>

            <Col span={24}>
              <Space direction="vertical">
                <Typography.Text className="order__info" type="secondary" strong>
                  Телефон для уточнения деталей
                </Typography.Text>
                <Typography.Text className="order__info-large">
                  {getMaskedPhoneNumberInOrder(data.phone)}
                </Typography.Text>
              </Space>
            </Col>

            <OrderAttachments files={data.files} photos={data.photos} comment={data.comment} />
          </Row>
        </Card>
      </Col>
      <Col lg={{ span: 14 }} span={24}>
        <Offers orderId={+id!} price={data.price} orderStatus={data.status} agreementId={data.acceptedAgreementId} />
      </Col>
      <CancelOrderModal open={openCancelOrderModal} handleCancel={hideCancelOrderModal} />
    </Row>
  ))!;
};

export default OrderDetails;

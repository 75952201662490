import { Typography } from 'antd';

export const enum OrgTableTabs {
  ALL = 'ALL',
  IN_PROCESS = 'IN_PROCESS',
  AWAITS_PAYMENT = 'AWAITS_PAYMENT',
  COMPLETED = 'COMPLETED',
}

interface EmptyMessageProps {
  tabName: OrgTableTabs;
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { tabName } = props;

  const emptyText = () => {
    switch (tabName) {
      case OrgTableTabs.ALL:
        return 'Здесь будут отображаться все ваши договоры с заказчиком перевозки';
      case OrgTableTabs.IN_PROCESS:
        return 'Здесь будут отображаться ваши договоры, по которым происходит исполнение';
      case OrgTableTabs.AWAITS_PAYMENT:
        return 'Здесь будут отображаться ваши договоры, по которым ожидается поступление оплаты';
      case OrgTableTabs.COMPLETED:
        return 'Здесь будут отображаться ваши договоры, которые завершены';
    }
  };

  return <Typography.Text>{emptyText()}</Typography.Text>;
};

export default EmptyMessage;

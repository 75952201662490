import { API } from '../../../../../Services/axios';
import { Badge, Typography } from 'antd';
import { Claim } from '../../../../../Models/Claims';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import ClaimsTable from '../Table/ClaimsTable';

export const getClaimsTabsItems = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [unansweredClaims, setUnansweredClaims] = useState<Claim[]>([]);
  const [answeredClaims, setAnsweredClaims] = useState<Claim[]>([]);

  useEffect(() => {
    setLoading(true);
    API.get('/api/v1/claim/list-closed')
      .then((response) => setAnsweredClaims(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
    API.get('/api/v1/claim/list-unanswered')
      .then((response) => setUnansweredClaims(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        setLoading(false);
        setReload(false);
      });
  }, [reload]);

  const unansweredClaimsCounter = unansweredClaims.length;

  const tabsItems = [
    {
      key: '1',
      label: (
        <Typography.Text className="contract__info">
          Новые <Badge className={'organizations__badge'} count={unansweredClaimsCounter} />
        </Typography.Text>
      ),
      children: <ClaimsTable claims={unansweredClaims} loading={loading} reload={reload} setReload={setReload} />,
    },
    {
      key: '2',
      label: <Typography.Text className="contract__info">Закрытые</Typography.Text>,
      children: <ClaimsTable claims={answeredClaims} loading={loading} reload={reload} setReload={setReload} />,
    },
  ];

  return tabsItems;
};

import { API } from '../../Services/axios';
import { AdminActions } from '../../Constants/Admin';
import { Button, Form, Input, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ModalTitle from './ModalTitle';

interface ProfileActionsModalProps extends ModalProps {
  action?: AdminActions;
}

const ProfileActionsModal = (props: ProfileActionsModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { action, open, handleCancel } = props;
  const { id } = useParams();
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const title = (event?: AdminActions) => {
    switch (event) {
      case AdminActions.BLOCK:
        return 'Вы действительно хотите заблокировать профиль?';
      case AdminActions.UNBLOCK:
        return 'Вы действительно хотите разблокировать профиль?';
      default:
        return '';
    }
  };

  const message = (event?: AdminActions) => {
    switch (event) {
      case AdminActions.BLOCK:
        return 'Пользователь больше не сможет войти в систему используя свои логин и пароль.';
      case AdminActions.UNBLOCK:
        return 'Пользователь снова сможет входить в систему используя свои логин и пароль.';
      default:
        return '';
    }
  };

  const buttonText = (event?: AdminActions) => {
    switch (event) {
      case AdminActions.BLOCK:
        return 'Заблокировать';
      case AdminActions.UNBLOCK:
        return 'Разблокировать';
      default:
        return '';
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      comment: values.comment,
    };
    if (action === AdminActions.BLOCK) {
      API.put(`/api/v1/operator/user/${id}/block`, formData)
        .then((response) => location.reload())
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    } else if (action === AdminActions.UNBLOCK) {
      API.put(`/api/v1/operator/user/${id}/unblock`, formData)
        .then((response) => location.reload())
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      title={<ModalTitle level={4} title={title(action)} handleCancel={handleCancel} />}
      open={open}
      onCancel={handleCancel}
      className="modal modal__info"
      footer={
        <Row justify="end" align="middle" wrap={false}>
          <Space>
            <Button size="large" onClick={handleCancel}>
              Отменить
            </Button>
            <Button size="large" type="primary" htmlType="submit" form="form" loading={loading}>
              {buttonText(action)}
            </Button>
          </Space>
        </Row>
      }
      centered
      width={645}
      closable={false}
    >
      <Space direction="vertical" size={24}>
        <Typography.Text>{message(action)}</Typography.Text>

        <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea size="large" maxLength={100} showCount className="form__comment" />
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

export default ProfileActionsModal;

export const tablePagingSettings = {
  showSizeChanger: true,
  showQuickJumper: false,
  showTotal: (total) => `Всего: ${total}.`,
  locale: {
    items_per_page: 'на странице',
    jump_to: 'Перейти на',
    page: 'страницу',
  },
};

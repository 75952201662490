import { Button, Col, Row, Space, Typography } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Rate } from '../../../../../Models/Rate';
import { TransportCoefficients } from '../../../../../Models/TransportCoefficients';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { useState } from 'react';
import AddRateModal from '../../Calculator/RateList/Modals/AddRateModal/AddRateModal';
import EditDefaultRateModal from '../../Calculator/RateList/Modals/EditDefaultRateModal';
import EditTransportCoefficientsModal from './Modals/EditTransportCoefficientsModal';

interface RateToolbarProps {
  transportCoefficients: TransportCoefficients;
  defaultRateTruck: Rate;
  defaultRateTrailer: Rate;
  loading: boolean;
}

const Toolbar = (props: RateToolbarProps) => {
  const { transportCoefficients, defaultRateTruck, defaultRateTrailer, loading } = props;

  const [openAddRateModal, setOpenAddRateModal] = useState<boolean>(false);
  const handleOpenAddRateModal = () => setOpenAddRateModal(true);
  const handleCancelAddRateModal = () => {
    setOpenAddRateModal(false);
  };

  const [openEditDefaultRateTruckModal, setOpenEditDefaultRateTruckModal] = useState<boolean>(false);
  const [openEditDefaultRateTrailerModal, setOpenEditDefaultRateTrailerModal] = useState<boolean>(false);
  const handleOpenEditDefaultRateTruckModal = () => setOpenEditDefaultRateTruckModal(true);
  const handleOpenEditDefaultRateTrailerModal = () => setOpenEditDefaultRateTrailerModal(true);

  const handleCancelEditRateTruckModal = () => {
    const f = document.getElementById('formEditDefault') as HTMLFormElement;
    f.reset();
    setOpenEditDefaultRateTruckModal(false);
  };
  const handleCancelEditRateTrailerModal = () => {
    const f = document.getElementById('formEditDefault') as HTMLFormElement;
    f.reset();
    setOpenEditDefaultRateTrailerModal(false);
  };

  const [openEditTransportCoefficientsModal, setOpenEditTransportCoefficientsModal] = useState(false);
  const handleOpenEditTransportCoefficientsModal = () => setOpenEditTransportCoefficientsModal(true);
  const handleCancelEditTransportCoefficientsModal = () => {
    const f = document.getElementById('formEditTransportCoefficients') as HTMLFormElement;
    f.reset();
    setOpenEditTransportCoefficientsModal(false);
  };

  return (
    <Row className="toolbar__admin-title" justify="space-between">
      <Col className="toolbar__admin-subtitle">
        <Space>
          <Typography.Text className="rate__info" type="secondary" strong>
            Стоимость 1км по умолчанию для фуры
          </Typography.Text>
          <Typography.Text className="rate__info-large" strong>
            {`${formatPrice(defaultRateTruck.price)} ₽`}
            <Button
              icon={<EditOutlined />}
              size="large"
              type="text"
              loading={loading}
              onClick={() => handleOpenEditDefaultRateTruckModal()}
            />
          </Typography.Text>
        </Space>
        <Space>
          <Typography.Text className="rate__info" type="secondary" strong>
            Стоимость 1км по умолчанию для трала
          </Typography.Text>
          <Typography.Text className="rate__info-large" strong>
            {`${formatPrice(defaultRateTrailer.price)} ₽`}
            <Button
              icon={<EditOutlined />}
              size="large"
              type="text"
              loading={loading}
              onClick={() => handleOpenEditDefaultRateTrailerModal()}
            />
          </Typography.Text>
        </Space>
      </Col>
      <Col className="toolbar__admin-btns">
        <Button
          size={'large'}
          type="primary"
          loading={loading}
          onClick={() => handleOpenEditTransportCoefficientsModal()}
        >
          Транспортные коэффициенты
        </Button>
        <Button
          size={'large'}
          type="primary"
          icon={<PlusOutlined />}
          loading={loading}
          onClick={() => handleOpenAddRateModal()}
        >
          Добавить направление
        </Button>
      </Col>
      <EditTransportCoefficientsModal
        transportCoefficients={transportCoefficients}
        open={openEditTransportCoefficientsModal}
        handleCancel={() => handleCancelEditTransportCoefficientsModal()}
      />
      <AddRateModal open={openAddRateModal} handleCancel={() => handleCancelAddRateModal()} />
      <EditDefaultRateModal
        open={openEditDefaultRateTruckModal}
        handleCancel={() => handleCancelEditRateTruckModal()}
        rate={defaultRateTruck}
      />
      <EditDefaultRateModal
        open={openEditDefaultRateTrailerModal}
        handleCancel={() => handleCancelEditRateTrailerModal()}
        rate={defaultRateTrailer}
      />
    </Row>
  );
};

export default Toolbar;

import { API } from '../../../../../../../Services/axios';
import { Button, Form, Input, Modal, Row, Space } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { ModalProps } from '../../../../../../../Models/Modal';
import { getRequiredFieldRule } from '../../../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../../../Components/Modals/ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from '../../../../../../../Components/Modals/ModalTitle';

interface SaveFilterModalProps extends ModalProps {
  filterInfo: Record<string, FilterValue | null>;
}

const SaveFilterModal = (props: SaveFilterModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, filterInfo, handleCancel } = props;

  const [formSaveFilter] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const name = useWatch<string>('name', formSaveFilter);

  const onFinish = () => {
    formSaveFilter.validateFields();
    setLoading(true);
    const values = formSaveFilter.getFieldsValue();
    const filterDto = {
      name: values.name,
      ...filterInfo,
    };
    API.post(`/api/v1/filter`, filterDto)
      .then((response) => {
        handleCancel();
        location.reload();
      })
      .catch((error) =>
        showErrorModal({
          title: 'Такой фильтр уже существует',
          message: 'Фильтр с таким именем уже существует. Назовите новый фильтр так, чтобы имя отличалось.',
        }),
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Название новой вкладки каталога заказов" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form id="formSaveFilter" form={formSaveFilter} colon={false} layout="vertical" className="form">
          <Form.Item name="name" rules={getRequiredFieldRule('Введите название')}>
            <Input size="large" maxLength={30} />
          </Form.Item>
        </Form>
        <Space>
          <Row justify="end" align="middle" wrap={false}>
            <Button size="large" className="modal__cancel-btn" onClick={handleCancel}>
              Отмена
            </Button>
            <Button
              disabled={!name}
              type="primary"
              size="large"
              form="form"
              htmlType="submit"
              onClick={onFinish}
              loading={loading}
            >
              Сохранить
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default SaveFilterModal;

import { OfferStatuses } from '../Constants/OfferStatuses';
import { OffersListItem } from '../Models/Offer';

// Filters offers that were cancelled
export const getCancelledOffersByStatus = (data: OffersListItem[]) => {
  const filtered = data.filter(
    (item) =>
      item.status === OfferStatuses.CANCELLED ||
      item.status === OfferStatuses.CANCELLED_BY_CUSTOMER ||
      item.status === OfferStatuses.CANCELLED_BY_DELIVERY ||
      item.status === OfferStatuses.OUT_OF_VALIDITY_DATE,
  );

  return filtered ? filtered : [];
};

// Filters offers that doea not have agreement project file
export const getNewOffersByStatus = (data: OffersListItem[]) => {
  return data.filter((offer) => offer.status === OfferStatuses.NEW || offer.status === OfferStatuses.AGREEMENT_REQUEST);
};

// Filters offers that are taken in work or taken in work and finished
export const getOffersInWorkByStatus = (data: OffersListItem[]) => {
  return data.filter(
    (offer) =>
      offer.status === OfferStatuses.PRE_AGREEMENT_LOADED ||
      offer.status === OfferStatuses.AGREEMENT_LOADED ||
      offer.status === OfferStatuses.ACCEPTED ||
      offer.status === OfferStatuses.DONE,
  );
};

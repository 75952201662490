import { Card, Col, Row, Typography } from 'antd';
import { Roles } from '../../../../../Constants/Profile';
import { getMaskedPhoneNumberInOrder } from '../../../../../Utils/getPhoneMasked';

interface UserProfileProps {
  role: Roles;
  userName: string;
  phone: string;
  email: string;
}

const UserProfile = (props: UserProfileProps) => {
  const { role, userName, phone, email } = props;
  return (
    <Card title={<Typography.Title level={3}>Пользователи от организации</Typography.Title>}>
      <Row justify="space-between" gutter={[0, 24]}>
        <Col xl={{ span: 5 }} sm={12} span={24}>
          {role === Roles.CUSTOMER ? 'Заказчик' : 'Организатор'}
        </Col>
        <Col xl={{ span: 6 }} sm={12} span={24}>
          {userName}
        </Col>
        <Col xl={{ span: 5 }} sm={12} span={24}>
          {getMaskedPhoneNumberInOrder(phone)}
        </Col>
        <Col xl={{ span: 6 }} sm={12} span={24}>
          {email}
        </Col>
      </Row>
    </Card>
  );
};

export default UserProfile;

import { BackTop, Breadcrumb, Col, Row, Typography } from 'antd';

const OperatorProfileHeader = () => {
  return (
    <Row justify="space-between" align="middle" gutter={[0, 16]} className="operator__header">
      <Col>
        <BackTop />
        <Breadcrumb className="operator__breadcrumb" separator=">">
          <Breadcrumb.Item href="/">Главная страница</Breadcrumb.Item>
          <Breadcrumb.Item href="/admin/operators">Операторы</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Typography.Text ellipsis>Профиль оператора</Typography.Text>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col span={24}>
        <Typography.Title level={3} ellipsis={{ tooltip: false }}>
          Профиль оператора
        </Typography.Title>
      </Col>
    </Row>
  );
};

export default OperatorProfileHeader;

import { API } from '../../../../../Services/axios';
import { Button, Col, Form, Row, Typography, Upload, UploadProps } from 'antd';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { OfferType } from '../../../../../Models/Offer';
import { beforeUploadAgreement } from '../../../../../Utils/beforeUpload';
import { getRequiredFieldRule } from '../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import FinalAgreementOffer from './FinalAgreementOffer';

interface FinalAgreementFormProps {
  offerId: number;
  offerData: OfferType;
}

const FinalAgreementForm = (props: FinalAgreementFormProps) => {
  const { showErrorModal } = useErrorModal();
  const { offerId, offerData } = props;
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const uploadProps: UploadProps = {
    name: 'finalAgreement',
    accept: '.pdf,.png,.jpg,.jpeg',
    maxCount: 1,
    iconRender: () => <PaperClipOutlined className="form__upload-icon" />,
    beforeUpload: beforeUploadAgreement,
    customRequest: ({ file, onSuccess }) =>
      setTimeout(() => {
        onSuccess!('ok');
      }, 0),
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined className="form__remove-icon" />,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    return e?.fileList;
  };

  const onFinish = (values) => {
    setLoading(true);
    const agreementData = {
      offerId: offerId,
    };

    const formData = new FormData();
    formData.append('file', values.finalAgreement['0'].originFileObj);
    formData.append('json', JSON.stringify(agreementData));

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    API.put('/api/v1/final-agreement', formData, headers)
      .then((response) => {
        location.reload();
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Row gutter={[0, 64]}>
      <Col span={24}>
        <Row gutter={[0, 32]}>
          <Col span={22} offset={1}>
            Чтобы взять договор на исполнение и в работу, загрузите финальную версию документа, подписанную обеими
            сторонами.
          </Col>
          <Col span={22} offset={1}>
            <Typography.Title level={4}>Финальный договор</Typography.Title>
          </Col>
          <Col span={22} offset={1}>
            <Form form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
              <Form.Item
                label="Файл финального договора"
                name="finalAgreement"
                rules={getRequiredFieldRule('Прикрепите финальный договор')}
                extra={
                  <Typography.Text type="secondary" className="form__help-text">
                    Для загрузки выберите файл с расширением .pdf, .png, .jpg, .jpeg.
                  </Typography.Text>
                }
                className="form__upload-file"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />} size="large">
                    Загрузить
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item className="offer__bottom-btns">
                <Button type="primary" size="large" htmlType="submit" loading={loading}>
                  Отправить
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <FinalAgreementOffer offerData={offerData} />
      </Col>
    </Row>
  );
};

export default FinalAgreementForm;

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';

export const CargoWarning = () => {
  return (
    <div className="order__warning-message">
      <Typography.Title level={5}>
        <ExclamationCircleFilled />
        Ваш груз является негабаритным
      </Typography.Title>
      Перевозка негабаритных грузов включает ряд особенностей, влияющих на стоимость перевозки: чертежи и спецификацию
      груза, выбор и согласование маршрута перевозки, подбор трала, оформление специального разрешения на перевозку,
      погрузка и крепление груза, такелажные работы.
    </div>
  );
};

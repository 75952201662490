import { RcFile } from 'antd/lib/upload';
import { Upload, message } from 'antd';

// Check if uploading files are accepted (by size & type)

export const beforeUploadAvatar = (file: RcFile) => {
  const isImg =
    file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/heic';
  if (!isImg) {
    message.error('Аватар может быть одного из следующих расширений: .jpeg, .jpg, .png, .heic');
    return false;
  }
  const isLt10M = file.size / 1024 / 1024 <= 10;
  if (!isLt10M) {
    message.error('Загружаемый файл не должен быть больше 10мб');
    return false;
  }
  return isImg && isLt10M;
};

export const beforeUploadImage = (file: RcFile) => {
  const isImg =
    file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/heic';
  if (!isImg) {
    message.error('Изображение может быть одного из следующих расширений: .jpeg, .jpg, .png, .heic');
  }
  const isLt10M = file.size / 1024 / 1024 <= 10;
  if (!isLt10M) {
    message.error('Загружаемый файл не должен быть больше 10мб');
  }
  return (isImg && isLt10M) || Upload.LIST_IGNORE;
};

export const beforeUpload = (file: RcFile) => {
  const isFile =
    file.type === 'application/pdf' ||
    file.type === 'text/plain' ||
    file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/rtf' ||
    file.type === 'application/msword' ||
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg' ||
    file.type === 'image/heic';
  if (!isFile) {
    message.error(
      'Файл может быть одного из следующих расширений: .txt, .pdf, .docx, .rtf, .xlsx, .png, .jpeg, .jpg, .heic',
    );
  }
  const isLt10M = file.size / 1024 / 1024 <= 10;
  if (!isLt10M) {
    message.error('Загружаемый файл не должен быть больше 10мб');
  }
  return (isFile && isLt10M) || Upload.LIST_IGNORE;
};

export const beforeUploadFile = (file: RcFile) => {
  const isFile =
    file.type === 'application/pdf' ||
    file.type === 'text/plain' ||
    file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/rtf' ||
    file.type === 'application/msword';
  if (!isFile) {
    message.error('Файл может быть одного из следующих расширений: .txt, .pdf, .docx, .rtf, .xlsx');
  }
  const isLt10M = file.size / 1024 / 1024 <= 10;
  if (!isLt10M) {
    message.error('Загружаемый файл не должен быть больше 10мб');
  }
  return (isFile && isLt10M) || Upload.LIST_IGNORE;
};

export const beforeUploadAgreement = (file: RcFile) => {
  const isImg =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg' ||
    file.type === 'application/pdf';
  if (!isImg) {
    message.error('Файл может быть одного из следующих расширений: .jpeg, .jpg, .png, .pdf');
  }
  const isLt10M = file.size / 1024 / 1024 <= 10;
  if (!isLt10M) {
    message.error('Загружаемый файл не должен быть больше 10мб');
  }
  return (isImg && isLt10M) || Upload.LIST_IGNORE;
};

import { Contract } from '../../../../../../Models/Contract';
import { TablePaginationConfig, TableProps } from 'antd';
import { useState } from 'react';
import type { FilterValue } from 'antd/lib/table/interface';

export const getOrganizerContractsTableFilter = () => {
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>();
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortInfo, setSortInfo] = useState<any>({
    sortOrder: 'descend',
    sortField: 'agreementId',
  });
  const handleFilter: TableProps<Contract>['onChange'] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setPaginationInfo(pagination);
    setSortInfo({
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });
  };

  return {
    filteredInfo,
    setFilteredInfo,
    paginationInfo,
    setPaginationInfo,
    handleFilter,
    sortInfo,
    setSortInfo,
  };
};

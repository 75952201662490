import { Navigate, useLocation } from 'react-router-dom';
import { Roles } from '../../Constants/Profile';
import { useAppSelector } from '../../Store/hooks';
import NoAccessPage from '../ResultPage/NoAccessPage';

interface ProtectedRouteUnterface {
  loading: boolean;
  children: JSX.Element;
  routeRole: Roles;
}

const ProtectedRoute = (props: ProtectedRouteUnterface) => {
  const { loading, children, routeRole } = props;
  const role = useAppSelector((state) => state.user.role);
  const location = useLocation();

  if (loading && !role) {
    return <Navigate to={'/signin'} state={{ from: location.pathname }} />;
  } else if (routeRole !== role) {
    return <NoAccessPage />;
  }
  return children;
};

export default ProtectedRoute;

import { API } from '../../../../../Services/axios';
import { Button, Form, Input, Modal, Row, Space } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ModalProps } from '../../../../../Models/Modal';
import { SystemMessage } from '../../../../../Models/SystemMessage';
import { getRequiredFieldRule } from '../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import ModalTitle from '../../../../../Components/Modals/ModalTitle';

interface EditSystemMessageModalProps extends ModalProps {
  record: SystemMessage;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const EditSystemMessageModal = (props: EditSystemMessageModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, handleCancel, record, setReload } = props;
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open)
      form.setFieldsValue({
        message: record.message,
      });
  }, [record.message]);

  const onClose = () => {
    handleCancel();
    form.resetFields();
    setLoading(false);
  };

  const onFinish = (values) => {
    if (values.message) {
      setLoading(true);
      const formData = {
        ...record,
        message: values.message,
      };

      API.put('/api/v1/message', formData)
        .then((response) => onClose())
        .catch((error) => showErrorModal({ error }))
        .finally(() => {
          setLoading(false);
          setReload(true);
        });
    }
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Редактирование сообщения" handleCancel={onClose} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
          <Form.Item name="message" label="Текст сообщения" rules={getRequiredFieldRule('Введите сообщение')}>
            <Input.TextArea size="large" maxLength={200} showCount className="form__comment" />
          </Form.Item>
        </Form>

        <Row justify="end">
          <Button size="large" className="modal__cancel-btn" onClick={onClose}>
            Отменить
          </Button>
          <Button type="primary" size="large" form="form" htmlType="submit" onClick={onFinish} loading={loading}>
            Сохранить
          </Button>
        </Row>
      </Space>
    </Modal>
  );
};

export default EditSystemMessageModal;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { ExactAddress } from '../../../../../../../../Models/Address';
import { Form, Input } from 'antd';
import { FormInstance } from 'rc-field-form';
import { getRequiredFieldRule } from '../../../../../../../../Utils/Rules/order';
import { withYMaps } from '@pbe/react-yandex-maps';
import React from 'react';

const YSuggest: React.FC<{
  form: FormInstance;
  label: string;
  name: string;
  id: string;
  addressFrom: ExactAddress;
  addressTo: ExactAddress;
  setAddressFrom: React.Dispatch<React.SetStateAction<ExactAddress>>;
  setAddressTo: React.Dispatch<React.SetStateAction<ExactAddress>>;
}> = ({ form, label, name, id, addressFrom, addressTo, setAddressFrom, setAddressTo }) => {
  function MapSuggest(props) {
    const { ymaps } = props;

    React.useEffect(() => {
      const suggestView = new ymaps.SuggestView(id, {
        results: 2,
        provider: {
          suggest: function (request, options) {
            return ymaps.suggest(`${request}`);
          },
        },
      });
      suggestView.events.add('select', async (e) => {
        const address = e.get('item').value;
        form.setFieldValue(name, address);
        const geo = ymaps.geocode(address, { results: 1 });
        geo.then((res) => {
          const coords = res.geoObjects.get(0).geometry._coordinates;
          if (name === 'regionFrom') {
            setAddressFrom({
              displayName: address,
              coordinates: coords,
              components: res.geoObjects.get(0).properties._data.metaDataProperty.GeocoderMetaData.Address.Components,
            });
            const city = res.geoObjects
              .get(0)
              .properties._data.metaDataProperty.GeocoderMetaData.Address.Components.filter(
                (component) => component.kind === 'locality',
              )
              ?.pop()?.name;
            const region = res.geoObjects
              .get(0)
              .properties._data.metaDataProperty.GeocoderMetaData.Address.Components.filter(
                (component) => component.kind === 'province',
              )
              .pop()?.name;
          } else {
            setAddressTo({
              displayName: address,
              coordinates: coords,
              components: res.geoObjects.get(0).properties._data.metaDataProperty.GeocoderMetaData.Address.Components,
            });
            const city = res.geoObjects
              .get(0)
              .properties._data.metaDataProperty.GeocoderMetaData.Address.Components.filter(
                (component) => component.kind === 'locality',
              )
              ?.pop()?.name;
            const region = res.geoObjects
              .get(0)
              .properties._data.metaDataProperty.GeocoderMetaData.Address.Components.filter(
                (component) => component.kind === 'province',
              )
              .pop()?.name;
          }
        });
      });
    }, [ymaps.SuggestView]);

    return (
      <Form.Item label={label} name={name} rules={getRequiredFieldRule(`${label} является обязательным`)}>
        <Input id={id} size="large" />
      </Form.Item>
    );
  }

  const Suggest = React.useMemo(() => {
    return withYMaps(MapSuggest, true, ['SuggestView', 'geocode', 'suggest', 'package.search', 'coordSystem.geo']);
  }, [form, label, name, id]);

  return <Suggest />;
};

export default YSuggest;

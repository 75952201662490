export enum OrderStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  AGREEMENT_REQUEST = 'AGREEMENT_REQUEST',
  AGREEMENT_ACCEPTED = 'AGREEMENT_ACCEPTED',
  DONE = 'DONE',
  CANCELLED_BY_CUSTOMER = 'CANCELLED_BY_CUSTOMER',
  CANCELLED_BY_DELIVERY = 'CANCELLED_BY_DELIVERY',
  DELAYED = 'DELAYED',
  DRAFT = 'DRAFT',
}

export const OrderMarginCalculationInitialValue = {
  apiFail: null,
  adblueConsumption: 0,
  adbluePrice: 0,
  additionalFee: 0,
  addressFrom: '',
  addressTo: '',
  carPrice: 0,
  carUsageAge: 0,
  carUsageFee: 0,
  cityFrom: '',
  cityTo: '',
  created: '',
  customExpenses: 0,
  customPlannedMarginality: 0,
  customPriceWithoutVat: 0,
  customProfit: 0,
  customCost: 0,
  customCalculationUpdated: '',
  desiredProfit: 0,
  driverSalary: 0,
  driverSalaryDay: 0,
  driverSalaryPercentage: 0,
  driverWorkingDay: 0,
  expenses: 0,
  fuelConsumption: 0,
  fuelPrice: 0,
  height: 0,
  insuranceCargoPriceFee: 0,
  insuranceFee: 0,
  leasingPrice: 0,
  length: 0,
  logisticianSalary: 0,
  orderId: 0,
  platonFee: 0,
  pointFrom: '',
  pointTo: '',
  price: 0,
  profitTaxes: 0,
  rateRegion: 0,
  rateSeason: 0,
  rateTransport: 0,
  regionFrom: '',
  regionTo: '',
  resultExpenses: 0,
  resultPlannedMarginality: 0,
  resultPriceWithoutVat: 0,
  resultProfit: 0,
  resultRecommendedPrice: 0,
  servicePrice: 0,
  serviceRange: 0,
  subscribeToFuelPrice: false,
  orderPrice: 0,
  transportTaxes: 0,
  tyresChangeRange: 0,
  tyresPrice: 0,
  vatTaxes: 0,
  weight: 0,
  width: 0,
};

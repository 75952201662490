import { API } from '../../../../../../Services/axios';
import { Button, Form, InputNumber, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../../../../../Models/Modal';
import { TransportCoefficients } from '../../../../../../Models/TransportCoefficients';
import { getRequiredFieldRule } from '../../../../../../Utils/Rules/order';
import { useErrorModal } from '../../../../../../Components/Modals/ErrorModal';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useState } from 'react';
import ModalTitle from '../../../../../../Components/Modals/ModalTitle';

interface EditTransportCoefficientsModalProps extends ModalProps {
  transportCoefficients: TransportCoefficients;
}

const EditTransportCoefficientsModal = (props: EditTransportCoefficientsModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { transportCoefficients, open, handleCancel } = props;

  const [formEditTransportCoefficients] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const truck = useWatch('truckCoeff', formEditTransportCoefficients);
  const trailer = useWatch('trailerCoeff', formEditTransportCoefficients);

  const initialValues = {
    truckCoeff: transportCoefficients.truckCoeff,
    trailerCoeff: transportCoefficients.trailerCoeff,
  };

  const onFinish = async () => {
    formEditTransportCoefficients.validateFields();
    setLoading(true);
    const values = formEditTransportCoefficients.getFieldsValue();

    const settings = [
      {
        name: 'truck_multiplicator',
        value: `${values.truckCoeff}`,
      },
      {
        name: 'trailer_multiplicator',
        value: values.trailerCoeff,
      },
    ];

    const headers = { headers: { 'Content-Type': 'application/json' } };

    API.put(`/api/v1/admin/settings`, JSON.stringify(settings), headers)
      .then((response) => {
        handleCancel();
        window.location.search += window.location.href.includes('tab') ? '' : '?tab=4';
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      title={<ModalTitle level={3} title="Транспортные коэффициенты" handleCancel={handleCancel} />}
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32}>
        <Row>
          <Typography.Text className="modal__text-large">
            Транспортировочные коэффициенты учитываются при расчете стоимости перевозки заказчиком. Они влияют на
            ценообразование в зависимости от того, каким транспортом планируется доставлять груз.
          </Typography.Text>
        </Row>
      </Space>
      <br />
      <br />
      <Space direction="vertical" size={32} className="modal__full-width">
        <Form
          id="formEditTransportCoefficients"
          form={formEditTransportCoefficients}
          initialValues={initialValues}
          colon={false}
          layout="vertical"
          className="form"
        >
          <Form.Item name="truckCoeff" label="Коэффициент для фуры" rules={getRequiredFieldRule('Введите коэффициент')}>
            <InputNumber min={0} maxLength={9} controls={false} decimalSeparator="," size="large" />
          </Form.Item>
          <Form.Item
            name="trailerCoeff"
            label="Коэффициент для трала"
            rules={getRequiredFieldRule('Введите коэффициент')}
          >
            <InputNumber min={0} maxLength={9} controls={false} decimalSeparator="," size="large" />
          </Form.Item>
        </Form>
        <Space>
          <Row>
            <Button size="large" className="modal__cancel-btn" onClick={handleCancel}>
              Отмена
            </Button>
            <Button
              disabled={!truck || !trailer}
              type="primary"
              size="large"
              form="form"
              htmlType="submit"
              onClick={onFinish}
              loading={loading}
            >
              Сохранить
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditTransportCoefficientsModal;

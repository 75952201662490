import { API } from '../../../../Services/axios';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { Order } from '../../../../Models/Order';
import { defaultDateFormat } from '../../../../Constants/DateFormats';
import { formatPrice } from '../../../../Utils/formatPrice';
import { getMaskedPhoneNumberInOrder } from '../../../../Utils/getPhoneMasked';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useParams } from 'react-router-dom';
import Adresses from '../../../../Components/Adresses/Adresses';
import CargoDimensions from '../../../../Components/CargoDimensions/CargoDimensions';
import DeleteOrderModal from './Component/Modals/DeleteOrderModal';
import FinalAgreement from './Component/final-agreement';
import OrderAttachments from '../../../../Components/Order/OrderAttachments';
import OrderHeader from './Component/order-header';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const OperatorOrder = () => {
  const { showErrorModal } = useErrorModal();
  const { id } = useParams();
  const [orderData, setOrderorderData] = useState<Order>();

  const [isOpenDeleteOrderModal, setOpenDeleteOrderModal] = useState<boolean>(false);

  const handleOpenDeleteOrderModal = () => setOpenDeleteOrderModal(true);
  const handleCloseDeleteOrderModal = () => setOpenDeleteOrderModal(false);

  useEffect(() => {
    API.get(`/api/v1/order/${id}`)
      .then((response) => setOrderorderData(response.data))
      .catch((error) => showErrorModal({ error }));
  }, [id]);

  if (!orderData) return <Row justify="center" className="order__container" gutter={[24, 24]} />;

  return (
    <Row>
      <Row
        justify="space-between"
        align="bottom"
        gutter={[0, 24]}
        className="order__container"
        style={{ width: '100%' }}
      >
        <Col lg={{ span: 12 }} span={12}>
          <OrderHeader title={`${orderData.userFriendlyId} ${orderData.machineName}`} />
        </Col>
        <Col>
          <Button type="primary" size="large" htmlType="button" onClick={handleOpenDeleteOrderModal}>
            Удалить заказ
          </Button>
        </Col>
      </Row>
      <Row justify="center" className="order__container" gutter={[24, 24]}>
        <Col lg={{ span: 10, order: 0 }} span={24} order={1}>
          <Card className="order__card" title={<Typography.Title level={4}>Информация о заказе</Typography.Title>}>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Text className="order__info" type="secondary" strong>
                    Предварительная оценочная стоимость
                  </Typography.Text>
                  <Typography.Text className="order__info-large" strong>
                    {`${formatPrice(orderData.price)} ₽`}
                  </Typography.Text>
                </Space>
              </Col>

              <Col span={12}>
                <Space direction="vertical">
                  <Typography.Text className="order__info" type="secondary" strong>
                    Желаемая дата получения груза
                  </Typography.Text>
                  <Typography.Text className="order__info-large" strong>
                    {dayjs(orderData.dateArrival).format(defaultDateFormat)}
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical">
                  <Typography.Text className="order__info" type="secondary" strong>
                    Дата погрузки техники
                  </Typography.Text>
                  <Typography.Text className="order__info-large" strong>
                    {dayjs(orderData.dateLoading).format(defaultDateFormat)}
                  </Typography.Text>
                </Space>
              </Col>

              <Adresses
                from={orderData.addressFrom}
                to={orderData.addressTo}
                pointFrom={orderData.pointFrom.split(',').map((point) => {
                  return +point;
                })}
                pointTo={orderData.pointTo.split(',').map((point) => {
                  return +point;
                })}
              />

              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Text className="order__info" type="secondary" strong>
                    Расчетное расстояние
                  </Typography.Text>
                  <Typography className="order__info-large">{orderData.distance} км</Typography>
                </Space>
              </Col>

              <CargoDimensions
                height={orderData.height}
                weight={orderData.weight}
                width={orderData.width}
                length={orderData.length}
              />

              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Text className="order__info" type="secondary" strong>
                    Заказчик
                  </Typography.Text>
                  <Typography.Link
                    href={`/operator/organizations/${orderData.organisationId}`}
                    className="order__info-large"
                    underline
                  >
                    {orderData.organisationName}
                  </Typography.Link>
                </Space>
              </Col>

              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Text className="order__info" type="secondary" strong>
                    Телефон для уточнения деталей
                  </Typography.Text>
                  <Typography.Text className="order__info-large">
                    {getMaskedPhoneNumberInOrder(orderData.phone)}
                  </Typography.Text>
                </Space>
              </Col>

              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Text className="order__info" type="secondary" strong>
                    Категория техники
                  </Typography.Text>
                  <Typography className="order__info-large">{orderData.cargoTypeNameWithParentName}</Typography>
                </Space>
              </Col>

              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Text className="contract__info" type="secondary" strong>
                    Модель техники
                  </Typography.Text>
                  {orderData.specificationLink ? (
                    <Space size={12} align="center">
                      <Typography.Link href={orderData.specificationLink} className="contract__info-large" underline>
                        {orderData.machineName}
                      </Typography.Link>
                      <Typography.Link href={orderData.specificationLink}>
                        <LinkOutlined />
                      </Typography.Link>
                    </Space>
                  ) : (
                    <Typography.Text className="contract__info-large">{orderData.machineName}</Typography.Text>
                  )}
                </Space>
              </Col>

              <Col span={24}>
                <Space direction="vertical">
                  <Typography.Text className="order__info" type="secondary" strong>
                    Стоимость перевозимой техники
                  </Typography.Text>
                  <Typography className="order__info-large">{`${formatPrice(orderData.machinePrice)} ₽`}</Typography>
                </Space>
              </Col>

              <OrderAttachments files={orderData.files} photos={orderData.photos} comment={orderData.comment} />
            </Row>
          </Card>
        </Col>
        <Col lg={{ span: 14 }} span={24}>
          <FinalAgreement />
        </Col>
      </Row>

      <DeleteOrderModal
        open={isOpenDeleteOrderModal}
        id={id ? +id : 0}
        name={`${orderData.userFriendlyId} ${orderData.machineName}`}
        handleCancel={handleCloseDeleteOrderModal}
      />
    </Row>
  );
};

export default OperatorOrder;

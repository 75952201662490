import { API } from '../../Services/axios';
import { Button, Form, Input, Modal, Row, Space, Typography } from 'antd';
import { ChangeHistoryEvents } from '../../Constants/Operator';
import { ModalProps } from '../../Models/Modal';
import { useErrorModal } from './ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ModalTitle from './ModalTitle';

interface OrgActionsModalProps extends ModalProps {
  action?: ChangeHistoryEvents;
}

const OrgActionsModal = (props: OrgActionsModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { action, open, handleCancel } = props;
  const { id } = useParams();
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const title = (event?: ChangeHistoryEvents) => {
    switch (event) {
      case ChangeHistoryEvents.VERIFIED:
        return 'Вы действительно хотите подтвердить организацию?';
      case ChangeHistoryEvents.BLOCKED:
        return 'Вы действительно хотите заблокировать организацию?';
      case ChangeHistoryEvents.UNBLOCKED:
        return 'Вы действительно хотите разблокировать организацию?';
      default:
        return '';
    }
  };

  const message = (event?: ChangeHistoryEvents) => {
    switch (event) {
      case ChangeHistoryEvents.VERIFIED:
        return 'Эта организация получит статус подтвержденной в системе, а также все пользователи, состоящие в ней.';
      case ChangeHistoryEvents.BLOCKED:
        return 'Это действие заблокирует вход в систему всем пользователям, входящим в неё. Если у организации есть активные проекты, то работа по ним будет приостановлена.';
      case ChangeHistoryEvents.UNBLOCKED:
        return 'Это действие разблокирует вход в систему всем пользователям, входящим в неё. Если у организации есть активные проекты, то работа по ним будет возобновлена.';
      default:
        return '';
    }
  };

  const buttonText = (event?: ChangeHistoryEvents) => {
    switch (event) {
      case ChangeHistoryEvents.VERIFIED:
        return 'Подтвердить';
      case ChangeHistoryEvents.BLOCKED:
        return 'Заблокировать';
      case ChangeHistoryEvents.UNBLOCKED:
        return 'Разблокировать';
      default:
        return '';
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = {
      comment: values.comment,
    };
    if (action === ChangeHistoryEvents.VERIFIED) {
      API.put(`/api/v1/operator/organisation/${id}/verify`, formData)
        .then((response) => location.reload())
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    } else if (action === ChangeHistoryEvents.BLOCKED) {
      API.put(`/api/v1/operator/organisation/${id}/block`, formData)
        .then((response) => location.reload())
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    } else if (action === ChangeHistoryEvents.UNBLOCKED) {
      API.put(`/api/v1/operator/organisation/${id}/unblock`, formData)
        .then((response) => location.reload())
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      title={<ModalTitle level={4} title={title(action)} handleCancel={handleCancel} />}
      open={open}
      onCancel={handleCancel}
      className="modal modal__info"
      footer={
        <Row justify="end" align="middle" wrap={false}>
          <Space>
            <Button size="large" onClick={handleCancel}>
              Отменить
            </Button>
            <Button size="large" type="primary" htmlType="submit" form="form" loading={loading}>
              {buttonText(action)}
            </Button>
          </Space>
        </Row>
      }
      centered
      width={645}
      closable={false}
    >
      <Space direction="vertical" size={24}>
        <Typography.Text>{message(action)}</Typography.Text>

        <Form id="form" form={form} colon={false} layout="vertical" className="form" onFinish={onFinish}>
          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea size="large" maxLength={100} showCount className="form__comment" />
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};

export default OrgActionsModal;

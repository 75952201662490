import { Button, Modal, Row, Space, Typography } from 'antd';
import { ModalProps } from '../../Models/Modal';
import ModalTitle from './ModalTitle';

interface ConfirmPaymentModalProps extends ModalProps {
  handleConfirm: () => void;
  loading: boolean;
}

const ConfirmPaymentModal = (props: ConfirmPaymentModalProps) => {
  const { open, handleCancel, handleConfirm, loading } = props;
  return (
    <Modal
      open={open}
      title={
        <ModalTitle level={4} title="Вы действительно подтверждаете получение оплаты?" handleCancel={handleCancel} />
      }
      width={655}
      closable={false}
      footer={null}
      centered
      className="modal"
    >
      <Space direction="vertical" size={32}>
        <Typography.Text className="modal__text-large">
          В этом случае исполнение заказа будет завершено и он автоматически переместится в архив
        </Typography.Text>
        <div className="modal__footer">
          <Row justify="end">
            <Button size="large" onClick={handleCancel} className="modal__cancel-btn">
              Отменить
            </Button>
            <Button type="primary" size="large" onClick={handleConfirm} loading={loading}>
              Подтвердить
            </Button>
          </Row>
        </div>
      </Space>
    </Modal>
  );
};

export default ConfirmPaymentModal;

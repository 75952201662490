import { Typography } from 'antd';

export const enum CatalogTableTabs {
  ALL = 'ALL',
}

interface EmptyMessageProps {
  tabName: CatalogTableTabs;
}

const EmptyMessage = (props: EmptyMessageProps) => {
  const { tabName } = props;

  const emptyText = () => {
    switch (tabName) {
      case CatalogTableTabs.ALL:
        return 'Здесь будут отображаться все заказы из каталога';
    }
  };

  return <Typography.Text>{emptyText()}</Typography.Text>;
};

export default EmptyMessage;

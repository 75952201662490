import { API } from '../../../../../Services/axios';
import { OrderTableProps } from '../../../../../Models/Order';
import { Tabs } from 'antd';
import { getOrdersTabsItems } from './Component/tabs-items';
import { useAppSelector } from '../../../../../Store/hooks';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Toolbar from './Toolbar';
import UnverifiedOrgAlert from '../../Detail/Component/unverified-alert';

const Orders = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [data, setData] = useState<OrderTableProps>({
    awaits: 0,
    offered: 0,
    awaits_agreement: 0,
    pre_agreement_loaded: 0,
    accepted: 0,
    signed: 0,
    archive: 0,
    orders: [],
  });
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [columnsSettings, setColumnsSettings] = useState<unknown>(null);
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get('tab')! !== '' ? searchParams.get('tab')! : 'awaits',
  );
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';
  const restartTable = () => {
    if (activeTab !== null) {
      API.get(
        `/api/v1/order/list-of-associated-orders?filter=${activeTab}&page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`,
      )
        .then((response) => setData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setLoading(true);
    restartTable();
    API.get(`/api/v1/table-settings/order`)
      .then((response) => setColumnsSettings(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && activeTab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('tab', activeTab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
    setLoading(true);
    restartTable();
  }, [activeTab, page, size, sort, sortField]);

  const ordersProps = {
    columnsSettings: columnsSettings,
    setShowActions: setShowActions,
    selectedRowKeys: selectedRowKeys,
    setSelectedRowKeys: setSelectedRowKeys,
    setActiveTab: setActiveTab,
    loading: loading,
    orders: data.orders,
    awaits: data.awaits,
    offered: data.offered,
    awaits_agreement: data.awaits_agreement,
    pre_agreement_loaded: data.pre_agreement_loaded,
    accepted: data.accepted,
    signed: data.signed,
    archive: data.archive,
  };

  const tabsItems = getOrdersTabsItems(ordersProps);

  return (
    <div>
      {!isVerified && (
        <div className="profile__unverified">
          <UnverifiedOrgAlert />
        </div>
      )}
      <Toolbar
        activeTab={activeTab}
        selectedRowKeys={selectedRowKeys}
        showActions={showActions}
        columnsSettings={columnsSettings}
        setColumnsSettings={setColumnsSettings}
      />
      <div className="orders__tabs-table">
        <Tabs
          activeKey={`${activeTab}`}
          items={tabsItems}
          className="tabs"
          onChange={(e) => {
            setShowActions(false);
            setSelectedRowKeys([]);
            setActiveTab(e);
            setSearchParams((params) => {
              params.set('page', '1');
              return searchParams;
            });
            window.history.replaceState(null, '', `/organizer/orders/in-progress?tab=${e}&page=${page}&size=${size}`);
          }}
        />
      </div>
    </div>
  );
};

export default Orders;

import { Empty, Typography } from 'antd';

export const tableLocaleSettings = {
  triggerDesc: 'Сортировать по убыванию',
  triggerAsc: 'Сортировать по возрастанию',
  cancelSort: 'Отменить сортировку',
  emptyText: (
    <Empty
      description={
        <Typography.Text>Здесь будут отображаться все заказы, размещенные на платформе Верум Логистика</Typography.Text>
      }
    />
  ),
};

import { Badge, Typography } from 'antd';
import { CustomerTableTabs } from '../Table/Component/empty-message';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { OrderCustomerTableProps } from '../../../../../Models/Order';
import { useSearchParams } from 'react-router-dom';
import OrdersTable from '../Table/OrdersTable';

interface CustomerOrdersProps extends OrderCustomerTableProps {
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

export const getOrdersTabsItems = (props: CustomerOrdersProps) => {
  const {
    orders,
    columnsSettings,
    setTab,
    all,
    on_signing,
    signed,
    with_draft_agreements,
    with_offers,
    without_offers,
    drafts,
    archive,
    loading,
  } = props;

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (+searchParams.get('tab')! <= 0) {
      if (with_draft_agreements > 0) {
        setTab('with_draft_agreements');
      } else if (without_offers > 0) {
        setTab('without_offers');
        // } else if (ordersAtSigning.length > 0) {
        //   setTab('signed');
      } else if (with_offers > 0) {
        setTab('with_offers');
      } else if (drafts > 0) {
        setTab('drafts');
      } else if (signed > 0) {
        setTab('signed');
      } else setTab('ALL');
    }
  }, [orders]);

  const tabsItems = [
    {
      key: 'ALL',
      label: (
        <Typography.Text className="order__info">
          Все <Badge showZero className={'order__badge-grey'} count={all} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          loading={loading}
          tabName={CustomerTableTabs.ALL}
          totalCount={all}
        />
      ),
    },
    {
      key: 'without_offers',
      label: (
        <Typography.Text className="order__info">
          Без предложений <Badge showZero className={'order__badge-grey'} count={without_offers} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          loading={loading}
          tabName={CustomerTableTabs.NEW}
          totalCount={without_offers}
        />
      ),
    },
    {
      key: 'with_offers',
      label: (
        <Typography.Text className="order__info">
          С предложениями{' '}
          <Badge showZero className={with_offers === 0 ? 'order__badge-grey' : 'order__badge'} count={with_offers} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          loading={loading}
          tabName={CustomerTableTabs.WITH_OFFERS}
          totalCount={with_offers}
        />
      ),
    },
    {
      key: 'with_draft_agreements',
      label: (
        <Typography.Text className="order__info">
          С подтверждениями{' '}
          <Badge
            showZero
            className={with_draft_agreements === 0 ? 'order__badge-grey' : 'order__badge'}
            count={with_draft_agreements}
          />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          loading={loading}
          tabName={CustomerTableTabs.WITH_AGREEMENTS}
          totalCount={with_draft_agreements}
        />
      ),
    },
    // {
    //   key: 'on_signing',
    //   label: (
    //     <Typography.Text className="order__info">
    //       На подписании <Badge showZero className={'order__badge-grey'} count={on_signing} />
    //     </Typography.Text>
    //   ),
    //   children: (
    //     <OrdersTable
    //       columnsSettings={columnsSettings}
    //       data={orders}
    //       loading={loading}
    //       tabName={CustomerTableTabs.AT_SIGNING}
    //     />
    //   ),
    // },
    {
      key: 'signed',
      label: (
        <Typography.Text className="order__info">
          В исполнении <Badge showZero className={'order__badge-grey'} count={signed} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          loading={loading}
          tabName={CustomerTableTabs.WITH_FINAL_AGREEMENT}
          totalCount={signed}
        />
      ),
    },
    {
      key: 'drafts',
      label: (
        <Typography.Text className="order__info">
          Черновики <Badge showZero className={'order__badge-grey'} count={drafts} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          loading={loading}
          tabName={CustomerTableTabs.DRAFT}
          totalCount={drafts}
        />
      ),
    },
    {
      key: 'archive',
      label: <Typography.Text className="order__info">Архив</Typography.Text>,
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={orders}
          loading={loading}
          tabName={CustomerTableTabs.ARCHIVED}
          totalCount={archive}
        />
      ),
    },
  ];

  return tabsItems;
};

import { Badge, Typography } from 'antd';
import { ContractsProps } from '../../../../../Models/Contract';
import { CustomerTableTabs } from '../Table/Component/empty-message';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ContractsTable from '../Table/ContractsTable';

interface CustomerContractsProps extends ContractsProps {
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
}

export const getContractsTabsItems = (props: CustomerContractsProps) => {
  const { agreements, loading, columnsSettings, setTab, all, in_process, delayed, finished } = props;

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (+searchParams.get('tab')! <= 0) {
      if (in_process > 0) {
        setTab('in_process');
      } else setTab('all');
    }
  }, [agreements]);

  const tabsItems = [
    {
      key: 'all',
      label: (
        <Typography.Text className="contract__info">
          Все <Badge showZero className={'contract__badge-grey'} count={all} />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={agreements}
          loading={loading}
          tabName={CustomerTableTabs.ALL}
          totalCount={all}
        />
      ),
    },
    {
      key: 'in_process',
      label: (
        <Typography.Text className="contract__info">
          В работе{' '}
          <Badge
            showZero
            className={in_process === 0 ? 'contract__badge-grey' : 'contract__badge'}
            count={in_process}
          />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={agreements}
          loading={loading}
          tabName={CustomerTableTabs.IN_PROCESS}
          totalCount={in_process}
        />
      ),
    },
    {
      key: 'delayed',
      label: (
        <Typography.Text className="contract__info">
          Задерживаются{' '}
          <Badge showZero className={delayed === 0 ? 'contract__badge-grey' : 'contract__badge'} count={delayed} />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={agreements}
          loading={loading}
          tabName={CustomerTableTabs.DELAYED}
          totalCount={delayed}
        />
      ),
    },
    {
      key: 'finished',
      label: (
        <Typography.Text className="contract__info">
          Завершены <Badge showZero className={'contract__badge-grey'} count={finished} />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={agreements}
          loading={loading}
          tabName={CustomerTableTabs.COMPLETED}
          totalCount={finished}
        />
      ),
    },
  ];

  return tabsItems;
};
